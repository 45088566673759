@import "color-scheme";

.button {
	position: relative;
	padding: 8px 24px;
	border: 1px solid transparent;
	font-weight: 500;
	font-size: 13px;
	color: var(--white);
	text-transform: none;
	margin-bottom: 0;
	text-align: center;
	cursor: pointer;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	background-image: none;
	white-space: nowrap;
	line-height: 1.5384616;
	border-radius: 3px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	transition: all 0.5s ease;

	&:hover {
		-webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
		-moz-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
		box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
	}

	i {
		transition: all 0.5s ease;
	}
}

.button:disabled,
.button[disabled],
.btn:disabled,
.btn[disabled] {
	opacity: 0.6;
}

.button.button-tiny {
	padding: 2px 6px;
}

.button.button-small {
	padding: 6px 8px;
}

a.button,
a.btn {
	padding: 11px 24px;
}

.button-link {
	&:hover {
		color: inherit;
	}
}

.link-primary {
	color: #11b1da;
	border: none;
	background-color: inherit;

	&:disabled {
		opacity: 0.4;
	}

	&:focus {
		color: #11b1da;
	}
}

.link-success {
	color: $dark-green;
	border: none;
	background-color: inherit;

	&:disabled {
		opacity: 0.4;
	}

	&:focus {
		color: $dark-green;
	}
}

.link-danger {
	color: $red;
	border: none;
	background-color: inherit;

	&:disabled {
		opacity: 0.4;
	}

	&:focus {
		color: $red;
	}
}

.button-primary,
.btn-primary {
	background-color: var(--blue);

	&:focus {
		color: var(--white);
	}
}

a.button-primary,
a.btn-primary {
	&:hover {
		color: var(--white);
	}
}

.button-primary-outline,
.btn-primary-outline {
	border: 1px solid var(--blue);
	background-color: transparent;
	color: var(--blue);

	&:hover {
		background-color: var(--blue) !important;
		color: var(--white);
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;

		i {
			color: var(--white);
		}
	}
}

.button-success,
.btn-success {
	background-color: var(--affirmative);
}

.button-success-outline,
.btn-success-outline {
	border: 1px solid var(--affirmative);
	background-color: transparent;
	color: var(--affirmative);

	&:hover {
		background-color: var(--affirmative);
		color: var(--white);
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;

		i {
			color: var(--white);
		}
	}
}

.button-default,
.btn-default {
	background-color: $light-gray;
	color: $gray-4;

	&:focus {
		color: $gray-4;
	}
}

.button-default-outline,
.btn-default-outline {
	border: 1px solid $light-gray;
	background-color: transparent;
	color: $gray;
}

.button-danger,
.btn-danger {
	background-color: $red;
}

.button-danger-outline,
.btn-danger-outline {
	border: 1px solid $red;
	background-color: transparent;
	color: $red;

	&:hover {
		background-color: $red;
		color: var(--white);
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;

		i {
			color: var(--white);
		}
	}
}

.button-unstyled,
.btn-unstyled {
	color: $blue-4 !important;
	transition: all 0.3s ease;
	border: none;
	background: none;
	padding: 0;

	&:hover {
		box-shadow: none;
	}
}

.button-loading {
	padding-left: 36px;

	.fa-spinner {
		position: absolute;
		left: 15px;
		top: 50%;
		margin-top: -7.5px; // half of the spinner height
	}

	.fa-spinner-tiny {
		position: absolute;
		left: 6px;
		top: 5px;
	}
}

.btn.btn-default.btn-clear.clear-filters {
	border: 1px solid $light-gray-13;
	border-radius: 0;
	padding: 7px 17px;
	margin-left: 5px;
}

.button-xs {
	padding: 7px 13px;
}

@media (max-width: 768px) {
	.button {
		padding: 5px 18px;
	}

	.button-loading {
		padding-left: 27px;

		.fa-spinner {
			left: 8px;
		}
	}
}
