.avatar-view {
    display: block;
    margin: 15% auto 5%;
    height: 220px;
    width: 220px;
    border: 3px solid #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0,0,0,.15);
    cursor: pointer;
    overflow: hidden;
}

.avatar-view img {
    width: 100%;
}

.avatar-body {
    padding-right: 15px;
    padding-left: 15px;
}

.avatar-upload {
    overflow: hidden;
}

.avatar-upload label {
    display: block;
    float: left;
    clear: left;
    width: 100px;
}

.avatar-upload input {
    display: block;
    margin-left: 110px;
}

.avatar-alert {
    margin-top: 10px;
    margin-bottom: 10px;
}

.avatar-wrapper, .featured-media-wrapper {
    /*height: 640px;*/
    width: 100%;
    margin-top: 15px;
    box-shadow: inset 0 0 5px rgba(0,0,0,.25);
    background-color: #fcfcfc;
    overflow: hidden;
}

.avatar-wrapper img, .featured-media-wrapper img {
    height: auto;
    max-width: 100%;
}

.avatar-preview, .featured-media-preview {
    float: left;
    margin-top: 15px;
    margin-right: 15px;
    border: 1px solid #eee;
    border-radius: 4px;
    background-color: #fff;
    overflow: hidden;
}

.avatar-preview:hover, .featured-media-preview:hover {
    border-color: #ccf;
    box-shadow: 0 0 5px rgba(0,0,0,.15);
}

.avatar-preview img, .featured-media-preview img {
    width: 100%;
}

.preview-lg {
    height: 184px;
    width: 184px;
    margin-top: 15px;
}

.preview-sm {
    height: 100px;
    width: 100px;
}

.preview-tiny {
    height: 50px;
    width: 50px;
}

@media (min-width: 992px) {
    .avatar-preview, .featured-media-preview {
        float: none;
    }
}

.avatar-btns {
    margin-top: 30px;
    margin-bottom: 15px;
}

.avatar-btns .btn-group {
    margin-right: 5px;
}
