@import "color-scheme";

// ng5-slider
.ng5-slider {
	margin: 18px 0 15px !important;
	width: 100% !important;
}

.ng5-slider-floor,
.ng5-slider-ceil,
.ng5-slider-model-value {
	visibility: hidden !important;
}

.ng5-slider[disabled] .ng5-slider-pointer {
	display: none;
}

.ng5-slider .ng5-slider-pointer {
	width: 18px !important;
	height: 18px !important;
	top: -4px !important;
	background-color: $green-2 !important;
	border-radius: 3px !important;

	&:after {
		top: 5px !important;
		left: 5px !important;
		background-color: $green-2 !important;
	}
}

.ng5-slider .ng5-slider-selection {
	border-radius: 7px !important;
	background: #c2e8c2 !important;
}

.ng5-slider .ng5-slider-bar {
	height: 10px !important;
	border-radius: 7px !important;
}

.ng5-slider-readonly {
	width: 100% !important; // must override previously set value
	margin: 15px 0 !important;

	.ng5-slider-selection-bar {
		visibility: visible !important;
	}

	&.ng5-low .ng5-slider-selection {
		background: var(--danger) !important;
	}

	&.ng5-avg .ng5-slider-selection {
		background: var(--warning) !important;
	}

	&.ng5-high .ng5-slider-selection {
		background: $green-2 !important;
	}

	.ng5-slider-pointer {
		display: none !important;
	}
}

// Gauge
.reading-block {
	top: -4px;
	font-size: 20.6px !important;
	color: #697692;

	.reading-affix {
		font-size: 11px;
	}
}

.reading-label {
	font-size: 11.15385px !important;
	color: #939cb0 !important;
}

// Range Slider
.rangeslider,
.rangeslider__fill {
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.rangeslider__fill {
	background: #c2e8c2;
}

// NGX Datatables
.ngx-datatable {
	&.material {
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;

		.datatable-body {
			.datatable-row-detail {
				background: inherit;

				.ngx-datatable.material {
					background: inherit;

					.datatable-header {
						padding-top: 0;
					}
				}
			}
		}
	}

	.datatable-header {
		height: auto !important;
	}

	.datatable-body {
		.datatable-body-row {
			.datatable-icon-down,
			.datatable-icon-right {
				font-size: 18px;
				position: relative;
				top: 5px;
			}
		}
	}

	// TODO Fix
	//.datatable-footer {
	//	.datatable-footer-inner {
	//		height: auto !important;
	//	}
	//}

	@media screen and (max-width: 1024px) {
		// sorting arrow for header
		.sortable .sort-btn:before {
			display: none;
		}
		// center pager
		.datatable-pager .pager {
			width: 100%;
		}
	}
}

.rangeslider--horizontal {
	height: 12px;
}

.rangeslider--horizontal .rangeslider__handle {
	top: -4px;
}

.rangeslider__handle {
	border: none;
	background: var(--affirmative) none;
	width: 18px;
	height: 20px;
	border-radius: 4px;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.rangeslider__handle:after {
	background-image: none;
}

// File Input
.file-input:not(.file-input-ajax-new) {
	.btn-upload-file {
		color: $gray-4;
		background-color: $light-gray;
	}
}

// Multiselect
.dropdown .search .input-group-sm > .input-group-addon {
	padding-left: 8px;
	padding-right: 8px;
}

.dropdown {
	.dropdown-menu {
		min-width: 240px;

		.search-container {
			left: 5px;
			width: 95%;

			.input-group-prepend {
				display: none;
			}

			.input-group-append {
				display: none;
			}
		}

		.dropdown-divider {
			display: block;
		}

		.dropdown-item {
			display: block;
			padding: 6px 10px;
			color: #000;

			&.check-control {
				span.fa {
					margin-right: 6px;
				}
			}
		}
	}
}

.gray-labels {
	.br-text {
		font-size: 14px;
		color: $gray;
		top: -3px;
	}
}

// Ngx Pagination
.ngx-pagination li {
	border-radius: 3px !important;
	padding: 5px 10px !important;
}

.ngx-pagination .current {
	background-color: $gray !important;
	border-radius: 3px !important;
	padding: 5px 10px !important;
}

// Pretty Checkboxes
.pretty.p-badge .state label {
	text-indent: initial;

	&:before,
	&:after {
		top: 35%;
	}
}

// Popovers
.popover {
	font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;

	.popover-title {
		padding: 8px;
	}

	.popover-body {
		padding: 8px;
		font-size: 13px;
	}
}

// Ng Select
.ng-dropdown-panel {
	.ng-dropdown-header {
		.ng-option {
			padding: 4px 0;
		}
	}
}

.ng-select.ng-select-multiple .ng-select-container {
	.ng-value-container {
		padding-top: 5px;

		.ng-placeholder {
			top: 8px;
		}

		.ng-summary {
			padding: 4px;
			font-weight: 500;
		}
	}
}

.ng-select.ng-select-dark {
	border-color: $light-gray-13;
	box-shadow: none;

	.ng-value {
		color: #0a0a0a;
	}
}

.ng-select.not-rounded {
	.ng-select-container {
		border-radius: 0;
	}
}

.ng-select.ng-select-dark .ng-select-container {
	color: #0a0a0a;
	background-color: $light-gray-4;
	border: 1px solid $light-gray-13;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.ng-select.without-arrow .ng-arrow-wrapper {
	display: none;
}

.ng-select.ng-select-dark.ng-select-focused:not(.ng-select-opened) {
	.ng-select-container {
		border-color: var(--blue);
		box-shadow: none;
	}
}

.ng-select.ng-select-dark.ng-select-focused {
	.ng-select-container {
		border-color: var(--blue);
	}
}

.ng-select.ng-select-dark.ng-select-disabled {
	.ng-select-container .ng-value-container {
		cursor: not-allowed;

		input {
			cursor: not-allowed;
		}
	}

	.ng-value {
		color: $gray-3;
	}
}

.ng-select.ng-select-multiple
	.ng-select-container
	.ng-value-container
	.ng-input {
	padding: 0;
}

.ng-select .ng-spinner-loader {
	position: absolute !important;
	right: 20px;
}

.ng-select.ng-select-dark.ng-select-disabled .ng-select-container {
	color: $gray-3;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
	cursor: default !important;
}

.ng-select.auto-grow {
	display: flex;

	.ng-select-container {
		min-width: 120px;
		width: auto;
	}

	.ng-dropdown-panel {
		width: auto;
	}
}

// Ng Select
// style when omitting items array and bind options directly in html using ng-option

.ng-select .ng-select-container {
	border: none;
	border-radius: 0;
	border-bottom: 1px solid $light-gray-14;
}

.ng-select.ng-select-multiple
	.ng-select-container
	.ng-value-container
	.ng-value {
	border: none;
	border-radius: 15px;
	padding: 4px 10px;
	background: var(--blue);
	color: var(--white);

	.ng-value-icon {
		&:hover {
			background-color: inherit;
		}
	}

	.ng-value-icon.left {
		border-right: none;
	}
}

.ng-select.ng-select-multiple.ng-select-disabled {
	.ng-select-container {
		background: inherit;
		border: none;

		&:hover {
			box-shadow: none;
		}
	}

	.ng-select-container .ng-value-container .ng-value {
		border: none;
		background: var(--blue);
	}

	.ng-arrow-wrapper {
		display: none;
	}
}

// ngx-bootstrap/accordion (reuses a lot of `panel` styles)
accordion {
	accordion-group {
		box-shadow: none !important;

		.panel {
			box-shadow: none !important;

			.panel-heading {
				padding: 0;
				border-bottom: none;

				.panel-title {
					text-transform: none !important;
				}
			}

			.panel-body {
				border-top: none !important;
				padding: 6px 22px;
			}
		}

		.accordion-toggle {
			background-color: var(--white);
		}
	}
}

.bs-tooltip-top .tooltip-arrow.arrow,
.bs-tooltip-bottom .tooltip-arrow.arrow,
.bs-tooltip-left .tooltip-arrow.arrow,
.bs-tooltip-right .tooltip-arrow.arrow {
	margin-left: 0 !important;
}

bs-dropdown-container {
	z-index: 9;
}
