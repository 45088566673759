/* ------------------------------------------------------------------------------
 *
 *  # Color system
 *
 *  Keep all color classes in a separate file as it's quite heavy and still optional
 *
 *  Version: 1.1
 *  Latest update: Feb 5, 2016
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Components
 *
 *  Common less file with imports of plugins and pages
 *
 *  Version: 1.0
 *  Latest update: Feb 5, 2016
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
*
*  # Custom color system
*
*  Custom color system styles, includes background, border and text colors
*
*  Version: 1.1
*  Latest update: Oct 20, 2015
*
* ---------------------------------------------------------------------------- */
@import url('../sass/color-scheme.scss');

.bg-primary {
  background-color: #2196F3;
  border-color: #2196F3;
  color: #fff;
}
.bg-primary-300 {
  background-color: #64B5F6;
  border-color: #64B5F6;
  color: #fff;
}
.bg-primary-400 {
  background-color: #42A5F5;
  border-color: #42A5F5;
  color: #fff;
}
.bg-primary-600 {
  background-color: #1E88E5;
  border-color: #1E88E5;
  color: #fff;
}
.bg-primary-700 {
  background-color: #1976D2;
  border-color: #1976D2;
  color: #fff;
}
.bg-primary-800 {
  background-color: #1565C0;
  border-color: #1565C0;
  color: #fff;
}
.bg-danger {
  background-color: #F44336;
  border-color: #F44336;
  color: #fff;
}
.bg-danger-300 {
  background-color: #E57373;
  border-color: #E57373;
  color: #fff;
}
.bg-danger-400 {
  background-color: #EF5350;
  border-color: #EF5350;
  color: #fff;
}
.bg-danger-600 {
  background-color: #E53935;
  border-color: #E53935;
  color: #fff;
}
.bg-danger-700 {
  background-color: #D32F2F;
  border-color: #D32F2F;
  color: #fff;
}
.bg-danger-800 {
  background-color: #C62828;
  border-color: #C62828;
  color: #fff;
}
.bg-success {
  background-color: #4CAF50;
  border-color: #4CAF50;
  color: #fff;
}
.bg-success-300 {
  background-color: #81C784;
  border-color: #81C784;
  color: #fff;
}
.bg-success-400 {
  background-color: #66BB6A;
  border-color: #66BB6A;
  color: #fff;
}
.bg-success-600 {
  background-color: #43A047;
  border-color: #43A047;
  color: #fff;
}
.bg-success-700 {
  background-color: #388E3C;
  border-color: #388E3C;
  color: #fff;
}
.bg-success-800 {
  background-color: #2E7D32;
  border-color: #2E7D32;
  color: #fff;
}
.bg-warning {
  background-color: #FF5722;
  border-color: #FF5722;
  color: #fff;
}
.bg-warning-300 {
  background-color: #FF8A65;
  border-color: #FF8A65;
  color: #fff;
}
.bg-warning-400 {
  background-color: #FF7043;
  border-color: #FF7043;
  color: #fff;
}
.bg-warning-600 {
  background-color: #F4511E;
  border-color: #F4511E;
  color: #fff;
}
.bg-warning-700 {
  background-color: #E64A19;
  border-color: #E64A19;
  color: #fff;
}
.bg-warning-800 {
  background-color: #D84315;
  border-color: #D84315;
  color: #fff;
}
.bg-info {
  background-color: #00BCD4;
  border-color: #00BCD4;
  color: #fff;
}
.bg-info-300 {
  background-color: #4DD0E1;
  border-color: #4DD0E1;
  color: #fff;
}
.bg-info-400 {
  background-color: #26C6DA;
  border-color: #26C6DA;
  color: #fff;
}
.bg-info-600 {
  background-color: #00ACC1;
  border-color: #00ACC1;
  color: #fff;
}
.bg-info-700 {
  background-color: #0097A7;
  border-color: #0097A7;
  color: #fff;
}
.bg-info-800 {
  background-color: #00838F;
  border-color: #00838F;
  color: #fff;
}
.bg-pink {
  background-color: #E91E63;
  border-color: #E91E63;
  color: #fff;
}
.bg-pink-300 {
  background-color: #F06292;
  border-color: #F06292;
  color: #fff;
}
.bg-pink-400 {
  background-color: #EC407A;
  border-color: #EC407A;
  color: #fff;
}
.bg-pink-600 {
  background-color: #D81B60;
  border-color: #D81B60;
  color: #fff;
}
.bg-pink-700 {
  background-color: #C2185B;
  border-color: #C2185B;
  color: #fff;
}
.bg-pink-800 {
  background-color: #AD1457;
  border-color: #AD1457;
  color: #fff;
}
.bg-violet {
  background-color: #9C27B0;
  border-color: #9C27B0;
  color: #fff;
}
.bg-violet-300 {
  background-color: #BA68C8;
  border-color: #BA68C8;
  color: #fff;
}
.bg-violet-400 {
  background-color: #AB47BC;
  border-color: #AB47BC;
  color: #fff;
}
.bg-violet-600 {
  background-color: #8E24AA;
  border-color: #8E24AA;
  color: #fff;
}
.bg-violet-700 {
  background-color: #7B1FA2;
  border-color: #7B1FA2;
  color: #fff;
}
.bg-violet-800 {
  background-color: #6A1B9A;
  border-color: #6A1B9A;
  color: #fff;
}
.bg-purple {
  background-color: #673AB7;
  border-color: #673AB7;
  color: #fff;
}
.bg-purple-300 {
  background-color: #9575CD;
  border-color: #9575CD;
  color: #fff;
}
.bg-purple-400 {
  background-color: #7E57C2;
  border-color: #7E57C2;
  color: #fff;
}
.bg-purple-600 {
  background-color: #5E35B1;
  border-color: #5E35B1;
  color: #fff;
}
.bg-purple-700 {
  background-color: #512DA8;
  border-color: #512DA8;
  color: #fff;
}
.bg-purple-800 {
  background-color: #4527A0;
  border-color: #4527A0;
  color: #fff;
}
.bg-indigo {
  background-color: #3F51B5;
  border-color: #3F51B5;
  color: #fff;
}
.bg-indigo-300 {
  background-color: #7986CB;
  border-color: #7986CB;
  color: #fff;
}
.bg-indigo-400 {
  background-color: #5C6BC0;
  border-color: #5C6BC0;
  color: #fff;
}
.bg-indigo-600 {
  background-color: #3949AB;
  border-color: #3949AB;
  color: #fff;
}
.bg-indigo-700 {
  background-color: #303F9F;
  border-color: #303F9F;
  color: #fff;
}
.bg-indigo-800 {
  background-color: #283593;
  border-color: #283593;
  color: #fff;
}
.bg-blue {
  background-color: #03A9F4;
  border-color: #03A9F4;
  color: #fff;
}
.bg-blue-300 {
  background-color: #4FC3F7;
  border-color: #4FC3F7;
  color: #fff;
}
.bg-blue-400 {
  background-color: #29B6F6;
  border-color: #29B6F6;
  color: #fff;
}
.bg-blue-600 {
  background-color: #039BE5;
  border-color: #039BE5;
  color: #fff;
}
.bg-blue-700 {
  background-color: #0288D1;
  border-color: #0288D1;
  color: #fff;
}
.bg-blue-800 {
  background-color: #0277BD;
  border-color: #0277BD;
  color: #fff;
}
.bg-teal {
  background-color: #009688;
  border-color: #009688;
  color: #fff;
}
.bg-teal-300 {
  background-color: #4DB6AC;
  border-color: #4DB6AC;
  color: #fff;
}
.bg-teal-400 {
  background-color: #26A69A;
  border-color: #26A69A;
  color: #fff;
}
.bg-teal-600 {
  background-color: #00897B;
  border-color: #00897B;
  color: #fff;
}
.bg-teal-700 {
  background-color: #00796B;
  border-color: #00796B;
  color: #fff;
}
.bg-teal-800 {
  background-color: #00695C;
  border-color: #00695C;
  color: #fff;
}
.bg-green {
  background-color: #8BC34A;
  border-color: #8BC34A;
  color: #fff;
}
.bg-green-300 {
  background-color: #AED581;
  border-color: #AED581;
  color: #fff;
}
.bg-green-400 {
  background-color: #9CCC65;
  border-color: #9CCC65;
  color: #fff;
}
.bg-green-600 {
  background-color: #7CB342;
  border-color: #7CB342;
  color: #fff;
}
.bg-green-700 {
  background-color: #689F38;
  border-color: #689F38;
  color: #fff;
}
.bg-green-800 {
  background-color: #558B2F;
  border-color: #558B2F;
  color: #fff;
}
.bg-orange {
  background-color: #FF9800;
  border-color: #FF9800;
  color: #fff;
}
.bg-orange-300 {
  background-color: #FFB74D;
  border-color: #FFB74D;
  color: #fff;
}
.bg-orange-400 {
  background-color: #FFA726;
  border-color: #FFA726;
  color: #fff;
}
.bg-orange-600 {
  background-color: #FB8C00;
  border-color: #FB8C00;
  color: #fff;
}
.bg-orange-700 {
  background-color: #F57C00;
  border-color: #F57C00;
  color: #fff;
}
.bg-orange-800 {
  background-color: #EF6C00;
  border-color: #EF6C00;
  color: #fff;
}
.bg-brown {
  background-color: #795548;
  border-color: #795548;
  color: #fff;
}
.bg-brown-300 {
  background-color: #A1887F;
  border-color: #A1887F;
  color: #fff;
}
.bg-brown-400 {
  background-color: #8D6E63;
  border-color: #8D6E63;
  color: #fff;
}
.bg-brown-600 {
  background-color: #6D4C41;
  border-color: #6D4C41;
  color: #fff;
}
.bg-brown-700 {
  background-color: #5D4037;
  border-color: #5D4037;
  color: #fff;
}
.bg-brown-800 {
  background-color: #4E342E;
  border-color: #4E342E;
  color: #fff;
}
.bg-grey {
  background-color: #777;
  border-color: #777;
  color: #fff;
}
.bg-grey-300 {
  background-color: #999;
  border-color: #999;
  color: #fff;
}
.bg-grey-400 {
  background-color: #888;
  border-color: #888;
  color: #fff;
}
.bg-grey-600 {
  background-color: #666;
  border-color: #666;
  color: #fff;
}
.bg-grey-700 {
  background-color: #555;
  border-color: #555;
  color: #fff;
}
.bg-grey-800 {
  background-color: #444;
  border-color: #444;
  color: #fff;
}
.bg-slate {
  background-color: #607D8B;
  border-color: #607D8B;
  color: #fff;
}
.bg-slate-300 {
  background-color: #90A4AE;
  border-color: #90A4AE;
  color: #fff;
}
.bg-slate-400 {
  background-color: #78909C;
  border-color: #78909C;
  color: #fff;
}
.bg-slate-600 {
  background-color: #546E7A;
  border-color: #546E7A;
  color: #fff;
}
.bg-slate-700 {
  background-color: #455A64;
  border-color: #455A64;
  color: #fff;
}
.bg-slate-800 {
  background-color: var(--action);
  border-color: var(--action);
  color: #fff;
}
.nav-bar-header {
  background-color: var(--header);
  border-color: var(--header);
  color: #fff;
}
.bg-white {
  background-color: #fff;
  color: #333333;
}
.alpha-primary {
  background-color: #E3F2FD;
  border-color: #1E88E5;
}
.alpha-danger {
  background-color: #FFEBEE;
  border-color: #E53935;
}
.alpha-success {
  background-color: #E8F5E9;
  border-color: #43A047;
}
.alpha-warning {
  background-color: #FBE9E7;
  border-color: #F4511E;
}
.alpha-info {
  background-color: #E0F7FA;
  border-color: #00ACC1;
}
.alpha-pink {
  background-color: #FCE4EC;
  border-color: #D81B60;
}
.alpha-violet {
  background-color: #F3E5F5;
  border-color: #8E24AA;
}
.alpha-purple {
  background-color: #EDE7F6;
  border-color: #5E35B1;
}
.alpha-indigo {
  background-color: #E8EAF6;
  border-color: #3949AB;
}
.alpha-blue {
  background-color: #E1F5FE;
  border-color: #039BE5;
}
.alpha-teal {
  background-color: #E0F2F1;
  border-color: #00897B;
}
.alpha-green {
  background-color: #F1F8E9;
  border-color: #7CB342;
}
.alpha-orange {
  background-color: #FFF3E0;
  border-color: #FB8C00;
}
.alpha-brown {
  background-color: #EFEBE9;
  border-color: #6D4C41;
}
.alpha-grey {
  background-color: #FAFAFA;
  border-color: #666;
}
.alpha-slate {
  background-color: #ECEFF1;
  border-color: #546E7A;
}
.border-primary {
  border-color: #2196F3;
}
.border-primary-300 {
  border-color: #64B5F6;
}
.border-primary-400 {
  border-color: #42A5F5;
}
.border-primary-600 {
  border-color: #1E88E5;
}
.border-primary-700 {
  border-color: #1976D2;
}
.border-primary-800 {
  border-color: #1565C0;
}
.border-danger {
  border-color: #F44336;
}
.border-danger-300 {
  border-color: #E57373;
}
.border-danger-400 {
  border-color: #EF5350;
}
.border-danger-600 {
  border-color: #E53935;
}
.border-danger-700 {
  border-color: #D32F2F;
}
.border-danger-800 {
  border-color: #C62828;
}
.border-success {
  border-color: #4CAF50;
}
.border-success-300 {
  border-color: #81C784;
}
.border-success-400 {
  border-color: #66BB6A;
}
.border-success-600 {
  border-color: #43A047;
}
.border-success-700 {
  border-color: #388E3C;
}
.border-success-800 {
  border-color: #2E7D32;
}
.border-warning {
  border-color: #FF5722;
}
.border-warning-300 {
  border-color: #FF8A65;
}
.border-warning-400 {
  border-color: #FF7043;
}
.border-warning-600 {
  border-color: #F4511E;
}
.border-warning-700 {
  border-color: #E64A19;
}
.border-warning-800 {
  border-color: #D84315;
}
.border-info {
  border-color: #00BCD4;
}
.border-info-300 {
  border-color: #4DD0E1;
}
.border-info-400 {
  border-color: #26C6DA;
}
.border-info-600 {
  border-color: #00ACC1;
}
.border-info-700 {
  border-color: #0097A7;
}
.border-info-800 {
  border-color: #00838F;
}
.border-pink {
  border-color: #E91E63;
}
.border-pink-300 {
  border-color: #F06292;
}
.border-pink-400 {
  border-color: #EC407A;
}
.border-pink-600 {
  border-color: #D81B60;
}
.border-pink-700 {
  border-color: #C2185B;
}
.border-pink-800 {
  border-color: #AD1457;
}
.border-violet {
  border-color: #9C27B0;
}
.border-violet-300 {
  border-color: #BA68C8;
}
.border-violet-400 {
  border-color: #AB47BC;
}
.border-violet-600 {
  border-color: #8E24AA;
}
.border-violet-700 {
  border-color: #7B1FA2;
}
.border-violet-800 {
  border-color: #6A1B9A;
}
.border-purple {
  border-color: #673AB7;
}
.border-purple-300 {
  border-color: #9575CD;
}
.border-purple-400 {
  border-color: #7E57C2;
}
.border-purple-600 {
  border-color: #5E35B1;
}
.border-purple-700 {
  border-color: #512DA8;
}
.border-purple-800 {
  border-color: #4527A0;
}
.border-indigo {
  border-color: #3F51B5;
}
.border-indigo-300 {
  border-color: #7986CB;
}
.border-indigo-400 {
  border-color: #5C6BC0;
}
.border-indigo-600 {
  border-color: #3949AB;
}
.border-indigo-700 {
  border-color: #303F9F;
}
.border-indigo-800 {
  border-color: #283593;
}
.border-blue {
  border-color: #03A9F4;
}
.border-blue-300 {
  border-color: #4FC3F7;
}
.border-blue-400 {
  border-color: #29B6F6;
}
.border-blue-600 {
  border-color: #039BE5;
}
.border-blue-700 {
  border-color: #0288D1;
}
.border-blue-800 {
  border-color: #0277BD;
}
.border-teal {
  border-color: #009688;
}
.border-teal-300 {
  border-color: #4DB6AC;
}
.border-teal-400 {
  border-color: #26A69A;
}
.border-teal-600 {
  border-color: #00897B;
}
.border-teal-700 {
  border-color: #00796B;
}
.border-teal-800 {
  border-color: #00695C;
}
.border-green {
  border-color: #8BC34A;
}
.border-green-300 {
  border-color: #AED581;
}
.border-green-400 {
  border-color: #9CCC65;
}
.border-green-600 {
  border-color: #7CB342;
}
.border-green-700 {
  border-color: #689F38;
}
.border-green-800 {
  border-color: #558B2F;
}
.border-orange {
  border-color: #FF9800;
}
.border-orange-300 {
  border-color: #FFB74D;
}
.border-orange-400 {
  border-color: #FFA726;
}
.border-orange-600 {
  border-color: #FB8C00;
}
.border-orange-700 {
  border-color: #F57C00;
}
.border-orange-800 {
  border-color: #EF6C00;
}
.border-brown {
  border-color: #795548;
}
.border-brown-300 {
  border-color: #A1887F;
}
.border-brown-400 {
  border-color: #8D6E63;
}
.border-brown-600 {
  border-color: #6D4C41;
}
.border-brown-700 {
  border-color: #5D4037;
}
.border-brown-800 {
  border-color: #4E342E;
}
.border-grey {
  border-color: #777;
}
.border-grey-300 {
  border-color: #999;
}
.border-grey-400 {
  border-color: #888;
}
.border-grey-600 {
  border-color: #666;
}
.border-grey-700 {
  border-color: #555;
}
.border-grey-800 {
  border-color: #444;
}
.border-slate {
  border-color: #607D8B;
}
.border-slate-300 {
  border-color: #90A4AE;
}
.border-slate-400 {
  border-color: #78909C;
}
.border-slate-600 {
  border-color: #546E7A;
}
.border-slate-700 {
  border-color: #455A64;
}
.border-slate-800 {
  border-color: #37474F;
}
.border-white {
  border-color: #fff;
}
.border-default {
  border-color: #ddd;
}
.border-top-primary {
  border-top-color: #2196F3;
}
.border-top-primary-300 {
  border-top-color: #64B5F6;
}
.border-top-primary-400 {
  border-top-color: #42A5F5;
}
.border-top-primary-600 {
  border-top-color: #1E88E5;
}
.border-top-primary-700 {
  border-top-color: #1976D2;
}
.border-top-primary-800 {
  border-top-color: #1565C0;
}
.border-top-danger {
  border-top-color: #F44336;
}
.border-top-danger-300 {
  border-top-color: #E57373;
}
.border-top-danger-400 {
  border-top-color: #EF5350;
}
.border-top-danger-600 {
  border-top-color: #E53935;
}
.border-top-danger-700 {
  border-top-color: #D32F2F;
}
.border-top-danger-800 {
  border-top-color: #C62828;
}
.border-top-success {
  border-top-color: #4CAF50;
}
.border-top-success-300 {
  border-top-color: #81C784;
}
.border-top-success-400 {
  border-top-color: #66BB6A;
}
.border-top-success-600 {
  border-top-color: #43A047;
}
.border-top-success-700 {
  border-top-color: #388E3C;
}
.border-top-success-800 {
  border-top-color: #2E7D32;
}
.border-top-warning {
  border-top-color: #FF5722;
}
.border-top-warning-300 {
  border-top-color: #FF8A65;
}
.border-top-warning-400 {
  border-top-color: #FF7043;
}
.border-top-warning-600 {
  border-top-color: #F4511E;
}
.border-top-warning-700 {
  border-top-color: #E64A19;
}
.border-top-warning-800 {
  border-top-color: #D84315;
}
.border-top-info {
  border-top-color: #00BCD4;
}
.border-top-info-300 {
  border-top-color: #4DD0E1;
}
.border-top-info-400 {
  border-top-color: #26C6DA;
}
.border-top-info-600 {
  border-top-color: #00ACC1;
}
.border-top-info-700 {
  border-top-color: #0097A7;
}
.border-top-info-800 {
  border-top-color: #00838F;
}
.border-top-pink {
  border-top-color: #E91E63;
}
.border-top-pink-300 {
  border-top-color: #F06292;
}
.border-top-pink-400 {
  border-top-color: #EC407A;
}
.border-top-pink-600 {
  border-top-color: #D81B60;
}
.border-top-pink-700 {
  border-top-color: #C2185B;
}
.border-top-pink-800 {
  border-top-color: #AD1457;
}
.border-top-violet {
  border-top-color: #9C27B0;
}
.border-top-violet-300 {
  border-top-color: #BA68C8;
}
.border-top-violet-400 {
  border-top-color: #AB47BC;
}
.border-top-violet-600 {
  border-top-color: #8E24AA;
}
.border-top-violet-700 {
  border-top-color: #7B1FA2;
}
.border-top-violet-800 {
  border-top-color: #6A1B9A;
}
.border-top-purple {
  border-top-color: #673AB7;
}
.border-top-purple-300 {
  border-top-color: #9575CD;
}
.border-top-purple-400 {
  border-top-color: #7E57C2;
}
.border-top-purple-600 {
  border-top-color: #5E35B1;
}
.border-top-purple-700 {
  border-top-color: #512DA8;
}
.border-top-purple-800 {
  border-top-color: #4527A0;
}
.border-top-indigo {
  border-top-color: #3F51B5;
}
.border-top-indigo-300 {
  border-top-color: #7986CB;
}
.border-top-indigo-400 {
  border-top-color: #5C6BC0;
}
.border-top-indigo-600 {
  border-top-color: #3949AB;
}
.border-top-indigo-700 {
  border-top-color: #303F9F;
}
.border-top-indigo-800 {
  border-top-color: #283593;
}
.border-top-blue {
  border-top-color: #03A9F4;
}
.border-top-blue-300 {
  border-top-color: #4FC3F7;
}
.border-top-blue-400 {
  border-top-color: #29B6F6;
}
.border-top-blue-600 {
  border-top-color: #039BE5;
}
.border-top-blue-700 {
  border-top-color: #0288D1;
}
.border-top-blue-800 {
  border-top-color: #0277BD;
}
.border-top-teal {
  border-top-color: #009688;
}
.border-top-teal-300 {
  border-top-color: #4DB6AC;
}
.border-top-teal-400 {
  border-top-color: #26A69A;
}
.border-top-teal-600 {
  border-top-color: #00897B;
}
.border-top-teal-700 {
  border-top-color: #00796B;
}
.border-top-teal-800 {
  border-top-color: #00695C;
}
.border-top-green {
  border-top-color: #8BC34A;
}
.border-top-green-300 {
  border-top-color: #AED581;
}
.border-top-green-400 {
  border-top-color: #9CCC65;
}
.border-top-green-600 {
  border-top-color: #7CB342;
}
.border-top-green-700 {
  border-top-color: #689F38;
}
.border-top-green-800 {
  border-top-color: #558B2F;
}
.border-top-orange {
  border-top-color: #FF9800;
}
.border-top-orange-300 {
  border-top-color: #FFB74D;
}
.border-top-orange-400 {
  border-top-color: #FFA726;
}
.border-top-orange-600 {
  border-top-color: #FB8C00;
}
.border-top-orange-700 {
  border-top-color: #F57C00;
}
.border-top-orange-800 {
  border-top-color: #EF6C00;
}
.border-top-brown {
  border-top-color: #795548;
}
.border-top-brown-300 {
  border-top-color: #A1887F;
}
.border-top-brown-400 {
  border-top-color: #8D6E63;
}
.border-top-brown-600 {
  border-top-color: #6D4C41;
}
.border-top-brown-700 {
  border-top-color: #5D4037;
}
.border-top-brown-800 {
  border-top-color: #4E342E;
}
.border-top-grey {
  border-top-color: #777;
}
.border-top-grey-300 {
  border-top-color: #999;
}
.border-top-grey-400 {
  border-top-color: #888;
}
.border-top-grey-600 {
  border-top-color: #666;
}
.border-top-grey-700 {
  border-top-color: #555;
}
.border-top-grey-800 {
  border-top-color: #444;
}
.border-top-slate {
  border-top-color: #607D8B;
}
.border-top-slate-300 {
  border-top-color: #90A4AE;
}
.border-top-slate-400 {
  border-top-color: #78909C;
}
.border-top-slate-600 {
  border-top-color: #546E7A;
}
.border-top-slate-700 {
  border-top-color: #455A64;
}
.border-top-slate-800 {
  border-top-color: #37474F;
}
.border-top-white {
  border-top-color: #fff;
}
.border-bottom-primary {
  border-bottom-color: #2196F3;
}
.border-bottom-primary-300 {
  border-bottom-color: #64B5F6;
}
.border-bottom-primary-400 {
  border-bottom-color: #42A5F5;
}
.border-bottom-primary-600 {
  border-bottom-color: #1E88E5;
}
.border-bottom-primary-700 {
  border-bottom-color: #1976D2;
}
.border-bottom-primary-800 {
  border-bottom-color: #1565C0;
}
.border-bottom-danger {
  border-bottom-color: #F44336;
}
.border-bottom-danger-300 {
  border-bottom-color: #E57373;
}
.border-bottom-danger-400 {
  border-bottom-color: #EF5350;
}
.border-bottom-danger-600 {
  border-bottom-color: #E53935;
}
.border-bottom-danger-700 {
  border-bottom-color: #D32F2F;
}
.border-bottom-danger-800 {
  border-bottom-color: #C62828;
}
.border-bottom-success {
  border-bottom-color: #4CAF50;
}
.border-bottom-success-300 {
  border-bottom-color: #81C784;
}
.border-bottom-success-400 {
  border-bottom-color: #66BB6A;
}
.border-bottom-success-600 {
  border-bottom-color: #43A047;
}
.border-bottom-success-700 {
  border-bottom-color: #388E3C;
}
.border-bottom-success-800 {
  border-bottom-color: #2E7D32;
}
.border-bottom-warning {
  border-bottom-color: #FF5722;
}
.border-bottom-warning-300 {
  border-bottom-color: #FF8A65;
}
.border-bottom-warning-400 {
  border-bottom-color: #FF7043;
}
.border-bottom-warning-600 {
  border-bottom-color: #F4511E;
}
.border-bottom-warning-700 {
  border-bottom-color: #E64A19;
}
.border-bottom-warning-800 {
  border-bottom-color: #D84315;
}
.border-bottom-info {
  border-bottom-color: #00BCD4;
}
.border-bottom-info-300 {
  border-bottom-color: #4DD0E1;
}
.border-bottom-info-400 {
  border-bottom-color: #26C6DA;
}
.border-bottom-info-600 {
  border-bottom-color: #00ACC1;
}
.border-bottom-info-700 {
  border-bottom-color: #0097A7;
}
.border-bottom-info-800 {
  border-bottom-color: #00838F;
}
.border-bottom-pink {
  border-bottom-color: #E91E63;
}
.border-bottom-pink-300 {
  border-bottom-color: #F06292;
}
.border-bottom-pink-400 {
  border-bottom-color: #EC407A;
}
.border-bottom-pink-600 {
  border-bottom-color: #D81B60;
}
.border-bottom-pink-700 {
  border-bottom-color: #C2185B;
}
.border-bottom-pink-800 {
  border-bottom-color: #AD1457;
}
.border-bottom-violet {
  border-bottom-color: #9C27B0;
}
.border-bottom-violet-300 {
  border-bottom-color: #BA68C8;
}
.border-bottom-violet-400 {
  border-bottom-color: #AB47BC;
}
.border-bottom-violet-600 {
  border-bottom-color: #8E24AA;
}
.border-bottom-violet-700 {
  border-bottom-color: #7B1FA2;
}
.border-bottom-violet-800 {
  border-bottom-color: #6A1B9A;
}
.border-bottom-purple {
  border-bottom-color: #673AB7;
}
.border-bottom-purple-300 {
  border-bottom-color: #9575CD;
}
.border-bottom-purple-400 {
  border-bottom-color: #7E57C2;
}
.border-bottom-purple-600 {
  border-bottom-color: #5E35B1;
}
.border-bottom-purple-700 {
  border-bottom-color: #512DA8;
}
.border-bottom-purple-800 {
  border-bottom-color: #4527A0;
}
.border-bottom-indigo {
  border-bottom-color: #3F51B5;
}
.border-bottom-indigo-300 {
  border-bottom-color: #7986CB;
}
.border-bottom-indigo-400 {
  border-bottom-color: #5C6BC0;
}
.border-bottom-indigo-600 {
  border-bottom-color: #3949AB;
}
.border-bottom-indigo-700 {
  border-bottom-color: #303F9F;
}
.border-bottom-indigo-800 {
  border-bottom-color: #283593;
}
.border-bottom-blue {
  border-bottom-color: #03A9F4;
}
.border-bottom-blue-300 {
  border-bottom-color: #4FC3F7;
}
.border-bottom-blue-400 {
  border-bottom-color: #29B6F6;
}
.border-bottom-blue-600 {
  border-bottom-color: #039BE5;
}
.border-bottom-blue-700 {
  border-bottom-color: #0288D1;
}
.border-bottom-blue-800 {
  border-bottom-color: #0277BD;
}
.border-bottom-teal {
  border-bottom-color: #009688;
}
.border-bottom-teal-300 {
  border-bottom-color: #4DB6AC;
}
.border-bottom-teal-400 {
  border-bottom-color: #26A69A;
}
.border-bottom-teal-600 {
  border-bottom-color: #00897B;
}
.border-bottom-teal-700 {
  border-bottom-color: #00796B;
}
.border-bottom-teal-800 {
  border-bottom-color: #00695C;
}
.border-bottom-green {
  border-bottom-color: #8BC34A;
}
.border-bottom-green-300 {
  border-bottom-color: #AED581;
}
.border-bottom-green-400 {
  border-bottom-color: #9CCC65;
}
.border-bottom-green-600 {
  border-bottom-color: #7CB342;
}
.border-bottom-green-700 {
  border-bottom-color: #689F38;
}
.border-bottom-green-800 {
  border-bottom-color: #558B2F;
}
.border-bottom-orange {
  border-bottom-color: #FF9800;
}
.border-bottom-orange-300 {
  border-bottom-color: #FFB74D;
}
.border-bottom-orange-400 {
  border-bottom-color: #FFA726;
}
.border-bottom-orange-600 {
  border-bottom-color: #FB8C00;
}
.border-bottom-orange-700 {
  border-bottom-color: #F57C00;
}
.border-bottom-orange-800 {
  border-bottom-color: #EF6C00;
}
.border-bottom-brown {
  border-bottom-color: #795548;
}
.border-bottom-brown-300 {
  border-bottom-color: #A1887F;
}
.border-bottom-brown-400 {
  border-bottom-color: #8D6E63;
}
.border-bottom-brown-600 {
  border-bottom-color: #6D4C41;
}
.border-bottom-brown-700 {
  border-bottom-color: #5D4037;
}
.border-bottom-brown-800 {
  border-bottom-color: #4E342E;
}
.border-bottom-grey {
  border-bottom-color: #777;
}
.border-bottom-grey-300 {
  border-bottom-color: #999;
}
.border-bottom-grey-400 {
  border-bottom-color: #888;
}
.border-bottom-grey-600 {
  border-bottom-color: #666;
}
.border-bottom-grey-700 {
  border-bottom-color: #555;
}
.border-bottom-grey-800 {
  border-bottom-color: #444;
}
.border-bottom-slate {
  border-bottom-color: #607D8B;
}
.border-bottom-slate-300 {
  border-bottom-color: #90A4AE;
}
.border-bottom-slate-400 {
  border-bottom-color: #78909C;
}
.border-bottom-slate-600 {
  border-bottom-color: #546E7A;
}
.border-bottom-slate-700 {
  border-bottom-color: #455A64;
}
.border-bottom-slate-800 {
  border-bottom-color: #37474F;
}
.border-bottom-white {
  border-bottom-color: #fff;
}
.border-left-primary {
  border-left-color: #2196F3;
}
.border-left-primary-300 {
  border-left-color: #64B5F6;
}
.border-left-primary-400 {
  border-left-color: #42A5F5;
}
.border-left-primary-600 {
  border-left-color: #1E88E5;
}
.border-left-primary-700 {
  border-left-color: #1976D2;
}
.border-left-primary-800 {
  border-left-color: #1565C0;
}
.border-left-danger {
  border-left-color: #F44336;
}
.border-left-danger-300 {
  border-left-color: #E57373;
}
.border-left-danger-400 {
  border-left-color: #EF5350;
}
.border-left-danger-600 {
  border-left-color: #E53935;
}
.border-left-danger-700 {
  border-left-color: #D32F2F;
}
.border-left-danger-800 {
  border-left-color: #C62828;
}
.border-left-success {
  border-left-color: #4CAF50;
}
.border-left-success-300 {
  border-left-color: #81C784;
}
.border-left-success-400 {
  border-left-color: #66BB6A;
}
.border-left-success-600 {
  border-left-color: #43A047;
}
.border-left-success-700 {
  border-left-color: #388E3C;
}
.border-left-success-800 {
  border-left-color: #2E7D32;
}
.border-left-warning {
  border-left-color: #FF5722;
}
.border-left-warning-300 {
  border-left-color: #FF8A65;
}
.border-left-warning-400 {
  border-left-color: #FF7043;
}
.border-left-warning-600 {
  border-left-color: #F4511E;
}
.border-left-warning-700 {
  border-left-color: #E64A19;
}
.border-left-warning-800 {
  border-left-color: #D84315;
}
.border-left-info {
  border-left-color: #00BCD4;
}
.border-left-info-300 {
  border-left-color: #4DD0E1;
}
.border-left-info-400 {
  border-left-color: #26C6DA;
}
.border-left-info-600 {
  border-left-color: #00ACC1;
}
.border-left-info-700 {
  border-left-color: #0097A7;
}
.border-left-info-800 {
  border-left-color: #00838F;
}
.border-left-pink {
  border-left-color: #E91E63;
}
.border-left-pink-300 {
  border-left-color: #F06292;
}
.border-left-pink-400 {
  border-left-color: #EC407A;
}
.border-left-pink-600 {
  border-left-color: #D81B60;
}
.border-left-pink-700 {
  border-left-color: #C2185B;
}
.border-left-pink-800 {
  border-left-color: #AD1457;
}
.border-left-violet {
  border-left-color: #9C27B0;
}
.border-left-violet-300 {
  border-left-color: #BA68C8;
}
.border-left-violet-400 {
  border-left-color: #AB47BC;
}
.border-left-violet-600 {
  border-left-color: #8E24AA;
}
.border-left-violet-700 {
  border-left-color: #7B1FA2;
}
.border-left-violet-800 {
  border-left-color: #6A1B9A;
}
.border-left-purple {
  border-left-color: #673AB7;
}
.border-left-purple-300 {
  border-left-color: #9575CD;
}
.border-left-purple-400 {
  border-left-color: #7E57C2;
}
.border-left-purple-600 {
  border-left-color: #5E35B1;
}
.border-left-purple-700 {
  border-left-color: #512DA8;
}
.border-left-purple-800 {
  border-left-color: #4527A0;
}
.border-left-indigo {
  border-left-color: #3F51B5;
}
.border-left-indigo-300 {
  border-left-color: #7986CB;
}
.border-left-indigo-400 {
  border-left-color: #5C6BC0;
}
.border-left-indigo-600 {
  border-left-color: #3949AB;
}
.border-left-indigo-700 {
  border-left-color: #303F9F;
}
.border-left-indigo-800 {
  border-left-color: #283593;
}
.border-left-blue {
  border-left-color: #03A9F4;
}
.border-left-blue-300 {
  border-left-color: #4FC3F7;
}
.border-left-blue-400 {
  border-left-color: #29B6F6;
}
.border-left-blue-600 {
  border-left-color: #039BE5;
}
.border-left-blue-700 {
  border-left-color: #0288D1;
}
.border-left-blue-800 {
  border-left-color: #0277BD;
}
.border-left-teal {
  border-left-color: #009688;
}
.border-left-teal-300 {
  border-left-color: #4DB6AC;
}
.border-left-teal-400 {
  border-left-color: #26A69A;
}
.border-left-teal-600 {
  border-left-color: #00897B;
}
.border-left-teal-700 {
  border-left-color: #00796B;
}
.border-left-teal-800 {
  border-left-color: #00695C;
}
.border-left-green {
  border-left-color: #8BC34A;
}
.border-left-green-300 {
  border-left-color: #AED581;
}
.border-left-green-400 {
  border-left-color: #9CCC65;
}
.border-left-green-600 {
  border-left-color: #7CB342;
}
.border-left-green-700 {
  border-left-color: #689F38;
}
.border-left-green-800 {
  border-left-color: #558B2F;
}
.border-left-orange {
  border-left-color: #FF9800;
}
.border-left-orange-300 {
  border-left-color: #FFB74D;
}
.border-left-orange-400 {
  border-left-color: #FFA726;
}
.border-left-orange-600 {
  border-left-color: #FB8C00;
}
.border-left-orange-700 {
  border-left-color: #F57C00;
}
.border-left-orange-800 {
  border-left-color: #EF6C00;
}
.border-left-brown {
  border-left-color: #795548;
}
.border-left-brown-300 {
  border-left-color: #A1887F;
}
.border-left-brown-400 {
  border-left-color: #8D6E63;
}
.border-left-brown-600 {
  border-left-color: #6D4C41;
}
.border-left-brown-700 {
  border-left-color: #5D4037;
}
.border-left-brown-800 {
  border-left-color: #4E342E;
}
.border-left-grey {
  border-left-color: #777;
}
.border-left-grey-300 {
  border-left-color: #999;
}
.border-left-grey-400 {
  border-left-color: #888;
}
.border-left-grey-600 {
  border-left-color: #666;
}
.border-left-grey-700 {
  border-left-color: #555;
}
.border-left-grey-800 {
  border-left-color: #444;
}
.border-left-slate {
  border-left-color: #607D8B;
}
.border-left-slate-300 {
  border-left-color: #90A4AE;
}
.border-left-slate-400 {
  border-left-color: #78909C;
}
.border-left-slate-600 {
  border-left-color: #546E7A;
}
.border-left-slate-700 {
  border-left-color: #455A64;
}
.border-left-slate-800 {
  border-left-color: #37474F;
}
.border-left-white {
  border-left-color: #fff;
}
.border-right-primary {
  border-right-color: #2196F3;
}
.border-right-primary-300 {
  border-right-color: #64B5F6;
}
.border-right-primary-400 {
  border-right-color: #42A5F5;
}
.border-right-primary-600 {
  border-right-color: #1E88E5;
}
.border-right-primary-700 {
  border-right-color: #1976D2;
}
.border-right-primary-800 {
  border-right-color: #1565C0;
}
.border-right-danger {
  border-right-color: #F44336;
}
.border-right-danger-300 {
  border-right-color: #E57373;
}
.border-right-danger-400 {
  border-right-color: #EF5350;
}
.border-right-danger-600 {
  border-right-color: #E53935;
}
.border-right-danger-700 {
  border-right-color: #D32F2F;
}
.border-right-danger-800 {
  border-right-color: #C62828;
}
.border-right-success {
  border-right-color: #4CAF50;
}
.border-right-success-300 {
  border-right-color: #81C784;
}
.border-right-success-400 {
  border-right-color: #66BB6A;
}
.border-right-success-600 {
  border-right-color: #43A047;
}
.border-right-success-700 {
  border-right-color: #388E3C;
}
.border-right-success-800 {
  border-right-color: #2E7D32;
}
.border-right-warning {
  border-right-color: #FF5722;
}
.border-right-warning-300 {
  border-right-color: #FF8A65;
}
.border-right-warning-400 {
  border-right-color: #FF7043;
}
.border-right-warning-600 {
  border-right-color: #F4511E;
}
.border-right-warning-700 {
  border-right-color: #E64A19;
}
.border-right-warning-800 {
  border-right-color: #D84315;
}
.border-right-info {
  border-right-color: #00BCD4;
}
.border-right-info-300 {
  border-right-color: #4DD0E1;
}
.border-right-info-400 {
  border-right-color: #26C6DA;
}
.border-right-info-600 {
  border-right-color: #00ACC1;
}
.border-right-info-700 {
  border-right-color: #0097A7;
}
.border-right-info-800 {
  border-right-color: #00838F;
}
.border-right-pink {
  border-right-color: #E91E63;
}
.border-right-pink-300 {
  border-right-color: #F06292;
}
.border-right-pink-400 {
  border-right-color: #EC407A;
}
.border-right-pink-600 {
  border-right-color: #D81B60;
}
.border-right-pink-700 {
  border-right-color: #C2185B;
}
.border-right-pink-800 {
  border-right-color: #AD1457;
}
.border-right-violet {
  border-right-color: #9C27B0;
}
.border-right-violet-300 {
  border-right-color: #BA68C8;
}
.border-right-violet-400 {
  border-right-color: #AB47BC;
}
.border-right-violet-600 {
  border-right-color: #8E24AA;
}
.border-right-violet-700 {
  border-right-color: #7B1FA2;
}
.border-right-violet-800 {
  border-right-color: #6A1B9A;
}
.border-right-purple {
  border-right-color: #673AB7;
}
.border-right-purple-300 {
  border-right-color: #9575CD;
}
.border-right-purple-400 {
  border-right-color: #7E57C2;
}
.border-right-purple-600 {
  border-right-color: #5E35B1;
}
.border-right-purple-700 {
  border-right-color: #512DA8;
}
.border-right-purple-800 {
  border-right-color: #4527A0;
}
.border-right-indigo {
  border-right-color: #3F51B5;
}
.border-right-indigo-300 {
  border-right-color: #7986CB;
}
.border-right-indigo-400 {
  border-right-color: #5C6BC0;
}
.border-right-indigo-600 {
  border-right-color: #3949AB;
}
.border-right-indigo-700 {
  border-right-color: #303F9F;
}
.border-right-indigo-800 {
  border-right-color: #283593;
}
.border-right-blue {
  border-right-color: #03A9F4;
}
.border-right-blue-300 {
  border-right-color: #4FC3F7;
}
.border-right-blue-400 {
  border-right-color: #29B6F6;
}
.border-right-blue-600 {
  border-right-color: #039BE5;
}
.border-right-blue-700 {
  border-right-color: #0288D1;
}
.border-right-blue-800 {
  border-right-color: #0277BD;
}
.border-right-teal {
  border-right-color: #009688;
}
.border-right-teal-300 {
  border-right-color: #4DB6AC;
}
.border-right-teal-400 {
  border-right-color: #26A69A;
}
.border-right-teal-600 {
  border-right-color: #00897B;
}
.border-right-teal-700 {
  border-right-color: #00796B;
}
.border-right-teal-800 {
  border-right-color: #00695C;
}
.border-right-green {
  border-right-color: #8BC34A;
}
.border-right-green-300 {
  border-right-color: #AED581;
}
.border-right-green-400 {
  border-right-color: #9CCC65;
}
.border-right-green-600 {
  border-right-color: #7CB342;
}
.border-right-green-700 {
  border-right-color: #689F38;
}
.border-right-green-800 {
  border-right-color: #558B2F;
}
.border-right-orange {
  border-right-color: #FF9800;
}
.border-right-orange-300 {
  border-right-color: #FFB74D;
}
.border-right-orange-400 {
  border-right-color: #FFA726;
}
.border-right-orange-600 {
  border-right-color: #FB8C00;
}
.border-right-orange-700 {
  border-right-color: #F57C00;
}
.border-right-orange-800 {
  border-right-color: #EF6C00;
}
.border-right-brown {
  border-right-color: #795548;
}
.border-right-brown-300 {
  border-right-color: #A1887F;
}
.border-right-brown-400 {
  border-right-color: #8D6E63;
}
.border-right-brown-600 {
  border-right-color: #6D4C41;
}
.border-right-brown-700 {
  border-right-color: #5D4037;
}
.border-right-brown-800 {
  border-right-color: #4E342E;
}
.border-right-grey {
  border-right-color: #777;
}
.border-right-grey-300 {
  border-right-color: #999;
}
.border-right-grey-400 {
  border-right-color: #888;
}
.border-right-grey-600 {
  border-right-color: #666;
}
.border-right-grey-700 {
  border-right-color: #555;
}
.border-right-grey-800 {
  border-right-color: #444;
}
.border-right-slate {
  border-right-color: #607D8B;
}
.border-right-slate-300 {
  border-right-color: #90A4AE;
}
.border-right-slate-400 {
  border-right-color: #78909C;
}
.border-right-slate-600 {
  border-right-color: #546E7A;
}
.border-right-slate-700 {
  border-right-color: #455A64;
}
.border-right-slate-800 {
  border-right-color: #37474F;
}
.border-right-white {
  border-right-color: #fff;
}
.text-primary,
.text-primary:hover,
.text-primary:focus {
  color: #2196F3 !important;
}
.text-primary-300,
.text-primary-300:hover,
.text-primary-300:focus {
  color: #2196F3 !important;
}
.text-primary-400,
.text-primary-400:hover,
.text-primary-400:focus {
  color: #42A5F5 !important;
}
.text-primary-600,
.text-primary-600:hover,
.text-primary-600:focus {
  color: #1E88E5 !important;
}
.text-primary-700,
.text-primary-700:hover,
.text-primary-700:focus {
  color: #1976D2 !important;
}
.text-primary-800,
.text-primary-800:hover,
.text-primary-800:focus {
  color: #1565C0 !important;
}
.text-danger,
.text-danger:hover,
.text-danger:focus {
  color: #F44336 !important;
}
.text-danger-300,
.text-danger-300:hover,
.text-danger-300:focus {
  color: #E57373 !important;
}
.text-danger-400,
.text-danger-400:hover,
.text-danger-400:focus {
  color: #EF5350 !important;
}
.text-danger-600,
.text-danger-600:hover,
.text-danger-600:focus {
  color: #E53935 !important;
}
.text-danger-700,
.text-danger-700:hover,
.text-danger-700:focus {
  color: #D32F2F !important;
}
.text-danger-800,
.text-danger-800:hover,
.text-danger-800:focus {
  color: #C62828 !important;
}
.text-success,
.text-success:hover,
.text-success:focus {
  color: #46da60 !important;
}
.text-success-300,
.text-success-300:hover,
.text-success-300:focus {
  color: #81C784 !important;
}
.text-success-400,
.text-success-400:hover,
.text-success-400:focus {
  color: #66BB6A !important;
}
.text-success-600,
.text-success-600:hover,
.text-success-600:focus {
  color: #43A047 !important;
}
.text-success-700,
.text-success-700:hover,
.text-success-700:focus {
  color: #388E3C !important;
}
.text-success-800,
.text-success-800:hover,
.text-success-800:focus {
  color: #2E7D32 !important;
}
.text-warning,
.text-warning:hover,
.text-warning:focus {
  color: #FF5722 !important;
}
.text-warning-300,
.text-warning-300:hover,
.text-warning-300:focus {
  color: #FF8A65 !important;
}
.text-warning-400,
.text-warning-400:hover,
.text-warning-400:focus {
  color: #FF7043 !important;
}
.text-warning-600,
.text-warning-600:hover,
.text-warning-600:focus {
  color: #F4511E !important;
}
.text-warning-700,
.text-warning-700:hover,
.text-warning-700:focus {
  color: #E64A19 !important;
}
.text-warning-800,
.text-warning-800:hover,
.text-warning-800:focus {
  color: #D84315 !important;
}
.text-info,
.text-info:hover,
.text-info:focus {
  color: #04c9eb !important;
}
.text-info-300,
.text-info-300:hover,
.text-info-300:focus {
  color: #4DD0E1 !important;
}
.text-info-400,
.text-info-400:hover,
.text-info-400:focus {
  color: #26C6DA !important;
}
.text-info-600,
.text-info-600:hover,
.text-info-600:focus {
  color: #00ACC1 !important;
}
.text-info-700,
.text-info-700:hover,
.text-info-700:focus {
  color: #0097A7 !important;
}
.text-info-800,
.text-info-800:hover,
.text-info-800:focus {
  color: #00838F !important;
}
.text-pink,
.text-pink:hover,
.text-pink:focus {
  color: #E91E63 !important;
}
.text-pink-300,
.text-pink-300:hover,
.text-pink-300:focus {
  color: #F06292 !important;
}
.text-pink-400,
.text-pink-400:hover,
.text-pink-400:focus {
  color: #EC407A !important;
}
.text-pink-600,
.text-pink-600:hover,
.text-pink-600:focus {
  color: #D81B60 !important;
}
.text-pink-700,
.text-pink-700:hover,
.text-pink-700:focus {
  color: #C2185B !important;
}
.text-pink-800,
.text-pink-800:hover,
.text-pink-800:focus {
  color: #AD1457 !important;
}
.text-violet,
.text-violet:hover,
.text-violet:focus {
  color: #9C27B0 !important;
}
.text-violet-300,
.text-violet-300:hover,
.text-violet-300:focus {
  color: #BA68C8 !important;
}
.text-violet-400,
.text-violet-400:hover,
.text-violet-400:focus {
  color: #AB47BC !important;
}
.text-violet-600,
.text-violet-600:hover,
.text-violet-600:focus {
  color: #8E24AA !important;
}
.text-violet-700,
.text-violet-700:hover,
.text-violet-700:focus {
  color: #7B1FA2 !important;
}
.text-violet-800,
.text-violet-800:hover,
.text-violet-800:focus {
  color: #6A1B9A !important;
}
.text-purple,
.text-purple:hover,
.text-purple:focus {
  color: #673AB7 !important;
}
.text-purple-300,
.text-purple-300:hover,
.text-purple-300:focus {
  color: #9575CD !important;
}
.text-purple-400,
.text-purple-400:hover,
.text-purple-400:focus {
  color: #7E57C2 !important;
}
.text-purple-600,
.text-purple-600:hover,
.text-purple-600:focus {
  color: #5E35B1 !important;
}
.text-purple-700,
.text-purple-700:hover,
.text-purple-700:focus {
  color: #512DA8 !important;
}
.text-purple-800,
.text-purple-800:hover,
.text-purple-800:focus {
  color: #4527A0 !important;
}
.text-indigo,
.text-indigo:hover,
.text-indigo:focus {
  color: #3F51B5 !important;
}
.text-indigo-300,
.text-indigo-300:hover,
.text-indigo-300:focus {
  color: #7986CB !important;
}
.text-indigo-400,
.text-indigo-400:hover,
.text-indigo-400:focus {
  color: #5C6BC0 !important;
}
.text-indigo-600,
.text-indigo-600:hover,
.text-indigo-600:focus {
  color: #3949AB !important;
}
.text-indigo-700,
.text-indigo-700:hover,
.text-indigo-700:focus {
  color: #303F9F !important;
}
.text-indigo-800,
.text-indigo-800:hover,
.text-indigo-800:focus {
  color: #283593 !important;
}
.text-blue,
.text-blue:hover,
.text-blue:focus {
  color: #03A9F4 !important;
}
.text-blue-300,
.text-blue-300:hover,
.text-blue-300:focus {
  color: #4FC3F7 !important;
}
.text-blue-400,
.text-blue-400:hover,
.text-blue-400:focus {
  color: #29B6F6 !important;
}
.text-blue-600,
.text-blue-600:hover,
.text-blue-600:focus {
  color: #039BE5 !important;
}
.text-blue-700,
.text-blue-700:hover,
.text-blue-700:focus {
  color: #0288D1 !important;
}
.text-blue-800,
.text-blue-800:hover,
.text-blue-800:focus {
  color: #0277BD !important;
}
.text-teal,
.text-teal:hover,
.text-teal:focus {
  color: #009688 !important;
}
.text-teal-300,
.text-teal-300:hover,
.text-teal-300:focus {
  color: #4DB6AC !important;
}
.text-teal-400,
.text-teal-400:hover,
.text-teal-400:focus {
  color: #26A69A !important;
}
.text-teal-600,
.text-teal-600:hover,
.text-teal-600:focus {
  color: #00897B !important;
}
.text-teal-700,
.text-teal-700:hover,
.text-teal-700:focus {
  color: #00796B !important;
}
.text-teal-800,
.text-teal-800:hover,
.text-teal-800:focus {
  color: #00695C !important;
}
.text-green,
.text-green:hover,
.text-green:focus {
  color: #8BC34A !important;
}
.text-green-300,
.text-green-300:hover,
.text-green-300:focus {
  color: #AED581 !important;
}
.text-green-400,
.text-green-400:hover,
.text-green-400:focus {
  color: #9CCC65 !important;
}
.text-green-600,
.text-green-600:hover,
.text-green-600:focus {
  color: #7CB342 !important;
}
.text-green-700,
.text-green-700:hover,
.text-green-700:focus {
  color: #689F38 !important;
}
.text-green-800,
.text-green-800:hover,
.text-green-800:focus {
  color: #558B2F !important;
}
.text-orange,
.text-orange:hover,
.text-orange:focus {
  color: #FF9800 !important;
}
.text-orange-200,
.text-orange-200:hover,
.text-orange-200:focus {
	color: #FFB12A !important;
}
.text-orange-300,
.text-orange-300:hover,
.text-orange-300:focus {
  color: #FFB74D !important;
}
.text-orange-400,
.text-orange-400:hover,
.text-orange-400:focus {
  color: #FFA726 !important;
}
.text-orange-600,
.text-orange-600:hover,
.text-orange-600:focus {
  color: #FB8C00 !important;
}
.text-orange-700,
.text-orange-700:hover,
.text-orange-700:focus {
  color: #F57C00 !important;
}
.text-orange-800,
.text-orange-800:hover,
.text-orange-800:focus {
  color: #EF6C00 !important;
}
.text-brown,
.text-brown:hover,
.text-brown:focus {
  color: #795548 !important;
}
.text-brown-300,
.text-brown-300:hover,
.text-brown-300:focus {
  color: #A1887F !important;
}
.text-brown-400,
.text-brown-400:hover,
.text-brown-400:focus {
  color: #8D6E63 !important;
}
.text-brown-600,
.text-brown-600:hover,
.text-brown-600:focus {
  color: #6D4C41 !important;
}
.text-brown-700,
.text-brown-700:hover,
.text-brown-700:focus {
  color: #5D4037 !important;
}
.text-brown-800,
.text-brown-800:hover,
.text-brown-800:focus {
  color: #4E342E !important;
}
.text-grey,
.text-grey:hover,
.text-grey:focus {
  color: #777 !important;
}
.text-grey-300,
.text-grey-300:hover,
.text-grey-300:focus {
  color: #999 !important;
}
.text-grey-400,
.text-grey-400:hover,
.text-grey-400:focus {
  color: #888 !important;
}
.text-grey-600,
.text-grey-600:hover,
.text-grey-600:focus {
  color: #666 !important;
}
.text-grey-700,
.text-grey-700:hover,
.text-grey-700:focus {
  color: #555 !important;
}
.text-grey-800,
.text-grey-800:hover,
.text-grey-800:focus {
  color: #444 !important;
}
.text-slate,
.text-slate:hover,
.text-slate:focus {
  color: #607D8B !important;
}
.text-slate-300,
.text-slate-300:hover,
.text-slate-300:focus {
  color: #90A4AE !important;
}
.text-slate-400,
.text-slate-400:hover,
.text-slate-400:focus {
  color: #78909C !important;
}
.text-slate-600,
.text-slate-600:hover,
.text-slate-600:focus {
  color: #546E7A !important;
}
.text-slate-700,
.text-slate-700:hover,
.text-slate-700:focus {
  color: #455A64 !important;
}
.text-slate-800,
.text-slate-800:hover,
.text-slate-800:focus {
  color: #37474F !important;
}
.text-white,
.text-white:hover,
.text-white:focus {
  color: #fff!important;
}
.text-default,
.text-default:hover,
.text-default:focus {
  color: #333333 !important;
}
