/* ------------------------------------------------------------------------------
 *
 *  # Components
 *
 *  Common less file with imports of plugins and pages
 *
 *  Version: 1.0
 *  Latest update: Feb 5, 2016
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Glyphicons for Bootstrap
 *
 *  Glyphicons icon font path and style overrides
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
@font-face {
	font-family: 'Glyphicons Halflings';
	src: url('../css/icons/glyphicons/glyphicons-halflings-regular.eot');
	src: url('../css/icons/glyphicons/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('../css/icons/glyphicons/glyphicons-halflings-regular.woff2') format('woff2'), url('../css/icons/glyphicons/glyphicons-halflings-regular.woff') format('woff'), url('../css/icons/glyphicons/glyphicons-halflings-regular.ttf') format('truetype'), url('../css/icons/glyphicons/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}

.glyphicon {
	font-size: 16px;
	vertical-align: middle;
	top: -1px;
}

/* ------------------------------------------------------------------------------
*
*  # Scaffolding
*
*  Overrides for bootstrap scaffolding
*
*  Version: 1.3
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
html {
	height: 100%;
}

body {
	position: relative;
	height: 100%;
}

a {
	cursor: pointer;
}

a:focus {
	outline: 0;
}

figure {
	position: relative;
}

figcaption {
	position: absolute;
	bottom: 0;
	opacity: 0;
	visibility: hidden;
	width: 100%;
	color: #fff;
	padding: 10px 15px;
	z-index: 2;
	background-color: rgba(0, 0, 0, 0.7);
	-webkit-transition: all ease-in-out 0.2s;
	-o-transition: all ease-in-out 0.2s;
	transition: all ease-in-out 0.2s;
}

figure:hover figcaption {
	opacity: 1;
	visibility: visible;
}

.img-rounded {
	border-radius: 3px;
}

.hr-condensed {
	margin-top: 10px;
	margin-bottom: 10px;
}

/* ------------------------------------------------------------------------------
*
*  # Main typography
*
*  Main typography overrides, including custom content
*
*  Version: 1.2
*  Latest update: Nov 25, 2015
*
* ---------------------------------------------------------------------------- */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	letter-spacing: -0.015em;
}

h1 > .label,
h2 > .label,
h3 > .label,
h4 > .label,
h5 > .label,
h6 > .label,
.h1 > .label,
.h2 > .label,
.h3 > .label,
.h4 > .label,
.h5 > .label,
.h6 > .label,
h1 > .badge,
h2 > .badge,
h3 > .badge,
h4 > .badge,
h5 > .badge,
h6 > .badge,
.h1 > .badge,
.h2 > .badge,
.h3 > .badge,
.h4 > .badge,
.h5 > .badge,
.h6 > .badge {
	vertical-align: middle;
	margin-top: -2px;
}

h1 > .label.pull-right,
h2 > .label.pull-right,
h3 > .label.pull-right,
h4 > .label.pull-right,
h5 > .label.pull-right,
h6 > .label.pull-right,
.h1 > .label.pull-right,
.h2 > .label.pull-right,
.h3 > .label.pull-right,
.h4 > .label.pull-right,
.h5 > .label.pull-right,
.h6 > .label.pull-right,
h1 > .badge.pull-right,
h2 > .badge.pull-right,
h3 > .badge.pull-right,
h4 > .badge.pull-right,
h5 > .badge.pull-right,
h6 > .badge.pull-right,
.h1 > .badge.pull-right,
.h2 > .badge.pull-right,
.h3 > .badge.pull-right,
.h4 > .badge.pull-right,
.h5 > .badge.pull-right,
.h6 > .badge.pull-right {
	margin-top: 3px;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small {
	font-size: 13px;
}

h1 small.display-block,
h2 small.display-block,
h3 small.display-block,
h4 small.display-block,
h5 small.display-block,
h6 small.display-block,
.h1 small.display-block,
.h2 small.display-block,
.h3 small.display-block,
.h4 small.display-block,
.h5 small.display-block,
.h6 small.display-block {
	margin-top: 3px;
}

h1 > [class*=icon-],
h2 > [class*=icon-],
h3 > [class*=icon-],
.h1 > [class*=icon-],
.h2 > [class*=icon-],
.h3 > [class*=icon-] {
	top: -2px;
}

h1 small,
h2 small,
h3 small,
.h1 small,
.h2 small,
.h3 small {
	font-size: 13px;
}

.heading-divided {
	margin-bottom: 15px;
	padding-bottom: 10px;
	border-bottom: 1px solid #ddd;
}

a,
button,
input,
textarea {
	outline: 0;
}

mark,
.mark {
	background-color: #333333;
	padding: 2px 6px;
	color: #fff;
	border-radius: 2px;
}

svg {
	display: block;
}

.svg-inline svg {
	display: inline-block;
}

.svg-center svg {
	margin: auto;
}

.letter-icon {
	width: 16px;
	display: block;
}

.content-divider {
	text-align: center;
	position: relative;
	z-index: 1;
}

.content-divider > span {
	background-color: #eeeded;
	display: inline-block;
	padding-left: 16px;
	padding-right: 16px;
}

.panel .content-divider > span,
.tab-content-bordered .content-divider > span,
.modal .content-divider > span {
	background-color: #fff;
}

.content-divider > span:before {
	content: "";
	position: absolute;
	top: 50%;
	left: 0;
	height: 1px;
	background-color: #ddd;
	width: 100%;
	z-index: -1;
}

.icon-object {
	border-radius: 50%;
	text-align: center;
	margin: 10px;
	border-width: 3px;
	border-style: solid;
	padding: 20px;
	display: inline-block;
}

.icon-object > i {
	font-size: 32px;
	top: 0;
}

.img-preview {
	max-height: 70px;
}

.video-container {
	position: relative;
	height: 100%;
	overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.status-mark {
	width: 8px;
	height: 8px;
	display: inline-block;
	border-radius: 50%;
	border: 2px solid;
}

.dropdown-menu > .active .status-mark {
	background-color: #fff;
	border-color: #fff;
}

.position-left {
	margin-right: 8px;
}

.position-right {
	margin-left: 8px;
}

a.bg-primary:hover,
a.bg-primary:focus {
	background-color: #2196F3;
}

.bg-success {
	color: #fff;
	background-color: #4CAF50;
}

a.bg-success:hover,
a.bg-success:focus {
	background-color: #3d8b40;
}

a.bg-success:hover,
a.bg-success:focus {
	background-color: #4CAF50;
}

.bg-info {
	color: #fff;
	background-color: #00BCD4;
}

a.bg-info:hover,
a.bg-info:focus {
	background-color: #008fa1;
}

a.bg-info:hover,
a.bg-info:focus {
	background-color: #00BCD4;
}

.bg-warning {
	color: #fff;
	background-color: #FF5722;
}

a.bg-warning:hover,
a.bg-warning:focus {
	background-color: #ee3900;
}

a.bg-warning:hover,
a.bg-warning:focus {
	background-color: #FF5722;
}

.bg-danger {
	color: #fff;
	background-color: #F44336;
}

a.bg-danger:hover,
a.bg-danger:focus {
	background-color: #ea1c0d;
}

a.bg-danger:hover,
a.bg-danger:focus {
	background-color: #F44336;
}

.page-header {
	margin: 0;
	padding: 0;
	border-bottom-width: 0;
}

@media (min-width: 769px) {
	.page-header .heading-elements.collapse {
		display: block;
		visibility: visible;
	}
}

.page-header-inverse {
	background-color: #273246;
	color: #fff;
	margin-bottom: 30px;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.page-header-inverse .page-title small {
	color: rgba(255, 255, 255, 0.5);
}

.page-header-inverse > .breadcrumb > li > a,
.page-header-inverse .page-header-content .breadcrumb > li > a,
.page-header-inverse > .breadcrumb > li + li:before,
.page-header-inverse .page-header-content .breadcrumb > li + li:before {
	color: rgba(255, 255, 255, 0.9);
}

.page-header-inverse > .breadcrumb > li > a:hover,
.page-header-inverse .page-header-content .breadcrumb > li > a:hover,
.page-header-inverse > .breadcrumb > li > a:focus,
.page-header-inverse .page-header-content .breadcrumb > li > a:focus {
	color: #fff;
	opacity: 1;
	filter: alpha(opacity=100);
}

.page-header-inverse > .breadcrumb > .active,
.page-header-inverse .page-header-content .breadcrumb > .active {
	color: rgba(255, 255, 255, 0.5);
}

.page-header-inverse .form-control-feedback,
.page-header-inverse .input-group-addon {
	color: rgba(255, 255, 255, 0.75);
}

.page-header-inverse .heading-text > a {
	color: #fff;
}

.page-header-inverse .form-control {
	border-bottom-color: rgba(255, 255, 255, 0.3);
	color: #fff;
}

.page-header-inverse .form-control::-moz-placeholder {
	color: rgba(255, 255, 255, 0.75);
	opacity: 1;
}

.page-header-inverse .form-control:-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.75);
}

.page-header-inverse .form-control::-webkit-input-placeholder {
	color: rgba(255, 255, 255, 0.75);
}

.page-header-inverse .form-control:focus {
	border-bottom-color: #fff;
	-webkit-box-shadow: 0 1px 0 #fff;
	box-shadow: 0 1px 0 #fff;
}

.page-header-inverse.has-cover {
	/*background: url(../images/login_cover.jpg);*/
	background-size: cover;
}

.page-header-default {
	background-color: #fff;
	margin-bottom: 30px;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.page-header-default.has-cover {
	/*background: url(../images/backgrounds/seamless.png);*/
}

.page-title {
	padding: 32px 36px 32px 0;
	display: block;
	position: relative;
}

.page-title small {
	margin-left: 10px;
	display: inline-block;
}

.page-title small:before {
	content: '/';
	margin-right: 15px;
}

.page-title small.display-block {
	margin-left: 0;
	display: block;
}

.page-title small.display-block:before {
	content: none;
}

.page-title i ~ small.display-block {
	margin-left: 31px;
}

.page-header-lg .page-title {
	padding-top: 37px;
	padding-bottom: 37px;
}

.page-header-sm .page-title {
	padding-top: 27px;
	padding-bottom: 27px;
}

.page-header-xs .page-title {
	padding-top: 22px;
	padding-bottom: 22px;
}

.page-title h1,
.page-title h2,
.page-title h3,
.page-title h4,
.page-title h5,
.page-title h6 {
	margin: 0;
}

@media (min-width: 769px) {
	.page-title {
		padding-right: 0;
	}
}

.page-header-content {
	position: relative;
	background-color: inherit;
	padding: 0 20px;
}

.page-header-content[class*=border-bottom-] + .breadcrumb-line {
	border-top: 0;
}

.text-black {
	font-weight: 900;
}

.text-bold {
	font-weight: 700;
}

.text-semibold {
	font-weight: 500;
}

.text-regular {
	font-weight: 400;
}

.text-light {
	font-weight: 300;
}

.text-thin {
	font-weight: 100;
}

.text-italic {
	font-style: italic;
}

.text-highlight {
	padding: 4px 6px;
}

.text-size-large {
	font-size: 14px;
}

.text-size-base {
	font-size: 13px;
}

.text-size-small {
	font-size: 12px;
}

.text-size-mini {
	font-size: 11px;
}

ul,
ol {
	padding-left: 25px;
}

.list > li,
.list > li .list > li {
	margin-top: 8px;
}

.list > li:first-child {
	margin-top: 0;
}

.list-condensed > li,
.list-condensed > li .list > li {
	margin-top: 4px;
}

.list-extended > li,
.list-extended > li .list > li {
	margin-top: 12px;
}

ul.list-square {
	list-style-type: square;
}

ul.list-circle {
	list-style-type: circle;
}

.list-inline {
	margin-left: 0;
	font-size: 0;
}

.list-inline > li {
	padding-left: 0;
	padding-right: 20px;
	font-size: 13px;
}

.list-inline > li:last-child {
	padding-right: 0;
}

.list-inline-condensed > li {
	padding-right: 10px;
}

.list-inline-separate > li {
	padding-right: 20px;
	position: relative;
}

.list-inline-separate > li:before {
	content: '\2022';
	position: absolute;
	right: 8px;
	color: #ccc;
	top: 1px;
}

.list-inline-separate > li:last-child:before {
	content: none;
}

.list-icons {
	padding-left: 0;
}

.list-icons li {
	list-style: none;
}

.list-icons li i {
	margin-right: 8px;
}

.icons-list {
	margin: 0;
	padding: 0;
	list-style: none;
	line-height: 1;
	font-size: 0;
}

.icons-list > li {
	position: relative;
	display: inline-block;
	margin-left: 5px;
	font-size: 13px;
}

.icons-list > li:first-child {
	margin-left: 0;
}

.icons-list > li > a {
	color: inherit;
	display: block;
	opacity: 1;
	filter: alpha(opacity=100);
}

.icons-list > li > a:hover,
.icons-list > li > a:focus {
	opacity: 0.75;
	filter: alpha(opacity=75);
}

.icons-list > li > a > i {
	top: 0;
}

.icons-list > li > a > .caret {
	margin-top: 0;
	margin-bottom: 0;
}

.icons-list-extended > li {
	margin-left: 10px;
}

dl {
	margin-bottom: 0;
}

dt {
	margin-bottom: 5px;
	font-weight: 500;
}

dd + dt {
	margin-top: 20px;
}

@media (min-width: 769px) {
	.dl-horizontal dt + dd {
		margin-top: 20px;
	}

	.dl-horizontal dt:first-child + dd {
		margin-top: 0;
	}
}

blockquote {
	margin: 0;
}

blockquote img {
	height: 42px;
	float: left;
	margin-right: 20px;
}

blockquote img.pull-right {
	margin-right: 0;
	margin-left: 20px;
}

blockquote footer,
blockquote small,
blockquote .small {
	font-size: 13px;
	margin-top: 8px;
	display: block;
}

.blockquote-reverse,
blockquote.pull-right {
	padding-right: 20px;
}

/* ------------------------------------------------------------------------------
*
*  # Code related components
*
*  Overrides for code related bootstrap components
*
*  Version: 1.1
*  Latest update: Nov 25, 2015
*
* ---------------------------------------------------------------------------- */
code {
	border-radius: 2px;
	word-wrap: break-word;
}

kbd {
	font-size: 11px;
	vertical-align: text-top;
	-webkit-box-shadow: none;
	box-shadow: none;
}

pre {
	padding: 20px;
	margin: 0;
	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;
	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}

/* ------------------------------------------------------------------------------
*
*  # Tables component
*
*  Overrides for tables bootstrap component
*
*  Version: 1.1
*  Latest update: Oct 20, 2015
*
* ---------------------------------------------------------------------------- */
th {
	font-weight: 500;
}

.table {
	margin-bottom: 0;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
	vertical-align: middle;
}

.table > thead > tr + tr > th,
.table > thead > tr + tr > td {
	border-top-width: 0;
}

.panel[class*=bg-] .table > thead > tr > th,
.table[class*=bg-] > thead > tr > th,
.panel[class*=bg-] .table > tbody > tr > th,
.table[class*=bg-] > tbody > tr > th,
.panel[class*=bg-] .table > tfoot > tr > th,
.table[class*=bg-] > tfoot > tr > th,
.panel[class*=bg-] .table > thead > tr > td,
.table[class*=bg-] > thead > tr > td,
.panel[class*=bg-] .table > tbody > tr > td,
.table[class*=bg-] > tbody > tr > td,
.panel[class*=bg-] .table > tfoot > tr > td,
.table[class*=bg-] > tfoot > tr > td {
	border-color: rgba(255, 255, 255, 0.2);
}

.table tr[class*=bg-] > td,
.table tr[class*=bg-] > th {
	border-color: #fff;
}

.table tr > td[class*=bg-],
.table tr > th[class*=bg-] {
	border-color: #fff;
}

.table > thead > tr[class*=border-bottom-] > th,
.table > thead > tr[class*=border-bottom-] > td {
	border-bottom-color: inherit;
}

.table > tbody > tr[class*=border-top-] > th,
.table > tfoot > tr[class*=border-top-] > th,
.table > tbody > tr[class*=border-top-] > td,
.table > tfoot > tr[class*=border-top-] > td {
	border-top-color: inherit;
}

.table > thead > tr > th {
	border-bottom: 1px solid #bbb;
}

.table > tfoot > tr > th {
	border-top: 1px solid #bbb;
}

.table .table {
	background-color: #fff;
}

.table-responsive + .table-responsive > .table:not(.table-bordered):not(.table-framed),
.table:not(.table-bordered):not(.table-framed) + .table:not(.table-bordered):not(.table-framed) {
	border-top: 1px solid #ddd;
}

.modal-body + .table-responsive > .table,
.modal-body + .table {
	border-bottom: 1px solid #ddd;
}

.modal-body + .table-responsive {
	border-width: 0;
}

.panel[class*=bg-] > .panel-body + .table,
.panel[class*=bg-] > .panel-body + .table-responsive {
	border-color: #fff;
}

.table > thead > tr.border-solid > th,
.table > thead > tr.border-solid > td {
	border-bottom-width: 2px;
}

.table > tbody > tr.border-solid > td,
.table > tfoot > tr.border-solid > td,
.table > tbody > tr.border-solid > th,
.table > tfoot > tr.border-solid > th {
	border-top-width: 2px;
}

.table-bordered > thead > tr.border-solid:first-child > th,
.table-bordered > thead > tr.border-solid:first-child > td {
	border-bottom-width: 2px;
}

.table > thead > tr.border-double > th,
.table > thead > tr.border-double > td {
	border-bottom-width: 3px;
	border-bottom-style: double;
}

.table > tbody > tr.border-double > td,
.table > tfoot > tr.border-double > td,
.table > tbody > tr.border-double > th,
.table > tfoot > tr.border-double > th {
	border-top-width: 3px;
	border-top-style: double;
}

.table-bordered > thead > tr.border-double:first-child > th,
.table-bordered > thead > tr.border-double:first-child > td {
	border-bottom-width: 3px;
	border-bottom-style: double;
}

.table > tbody > tr.border-dashed > td,
.table > tbody > tr.border-dashed > th {
	border-top-style: dashed;
}

.table-framed,
.panel > .table-framed,
.panel > .table-responsive > .table-framed {
	border: 1px solid #ddd;
}

.table-framed > thead > tr > th:first-child,
.table-framed > thead > tr > td:first-child {
	border-left-color: #ddd;
}

.table-framed > thead > tr > th:last-child,
.table-framed > thead > tr > td:last-child {
	border-right-color: #ddd;
}

@media screen and (max-width: 768px) {
	.table-responsive > .table-framed {
		border: 0;
	}
}

.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th {
	border: 0;
}

.table-columned > tbody > tr > td,
.table-columned > tfoot > tr > td,
.table-columned > tbody > tr > th,
.table-columned > tfoot > tr > th {
	border: 0;
	border-left: 1px solid #ddd;
}

.table-columned > tbody > tr > td:first-child,
.table-columned > tfoot > tr > td:first-child,
.table-columned > tbody > tr > th:first-child,
.table-columned > tfoot > tr > th:first-child {
	border-left: 0;
}

.table-xlg > thead > tr > th,
.table-xlg > tbody > tr > th,
.table-xlg > tfoot > tr > th,
.table-xlg > thead > tr > td,
.table-xlg > tbody > tr > td,
.table-xlg > tfoot > tr > td {
	padding: 20px;
}

.table-lg > thead > tr > th,
.table-lg > tbody > tr > th,
.table-lg > tfoot > tr > th,
.table-lg > thead > tr > td,
.table-lg > tbody > tr > td,
.table-lg > tfoot > tr > td {
	padding: 15px 20px;
}

.table-sm > thead > tr > th,
.table-sm > tbody > tr > th,
.table-sm > tfoot > tr > th,
.table-sm > thead > tr > td,
.table-sm > tbody > tr > td,
.table-sm > tfoot > tr > td {
	padding: 10px 20px;
}

.table-xs > thead > tr > th,
.table-xs > tbody > tr > th,
.table-xs > tfoot > tr > th,
.table-xs > thead > tr > td,
.table-xs > tbody > tr > td,
.table-xs > tfoot > tr > td {
	padding: 8px 20px;
}

.table-xxs > thead > tr > th,
.table-xxs > tbody > tr > th,
.table-xxs > tfoot > tr > th,
.table-xxs > thead > tr > td,
.table-xxs > tbody > tr > td,
.table-xxs > tfoot > tr > td {
	padding: 6px 15px;
}

.panel > .table-responsive > .table-bordered > thead > tr > th,
.panel > .table-bordered > thead > tr > th,
.panel > .table-responsive > .table-bordered > thead > tr > td,
.panel > .table-bordered > thead > tr > td {
	border-left-color: transparent;
	border-right-color: transparent;
}

.table-bordered tr:first-child > td,
.table-bordered tr:first-child > th {
	border-top-color: #bbb;
}

.table-bordered tr[class*=bg-] > th,
.table-bordered tr[class*=bg-] > td,
.table-bordered tr > th[class*=bg-],
.table-bordered tr > td[class*=bg-] {
	border-color: #fff;
}

.panel[class*=bg-] .table-striped > tbody > tr:nth-child(odd),
.table-striped[class*=bg-] > tbody > tr:nth-child(odd) {
	background-color: rgba(0, 0, 0, 0.05);
}

.table-hover > tbody > tr:hover > th,
.table-hover > tbody > tr:hover > td {
	background-color: inherit;
}

.panel[class*=bg-] .table-hover > tbody > tr:hover,
.table-hover[class*=bg-] > tbody > tr:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

.panel[class*=bg-] .panel-body > .table .active > th,
.panel[class*=bg-] .panel-body > .table-responsive > .table .active > th,
.table[class*=bg-] .active > th,
.panel[class*=bg-] .panel-body > .table .active > td,
.panel[class*=bg-] .panel-body > .table-responsive > .table .active > td,
.table[class*=bg-] .active > td,
.panel[class*=bg-] .panel-body > .table th.active,
.panel[class*=bg-] .panel-body > .table-responsive > .table th.active,
.table[class*=bg-] th.active,
.panel[class*=bg-] .panel-body > .table td.active,
.panel[class*=bg-] .panel-body > .table-responsive > .table td.active,
.table[class*=bg-] td.active {
	background-color: rgba(0, 0, 0, 0.15);
}

/* ------------------------------------------------------------------------------
*
*  # Form related components
*
*  Overrides for bootstrap form related components
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
/* Form controls
----------------------------------*/
legend {
	font-size: 12px;
	padding-top: 10px;
	padding-bottom: 10px;
	text-transform: uppercase;
}

fieldset:first-child legend:first-child {
	padding-top: 0;
}

legend .control-arrow {
	float: right;
	color: #999999;
}

legend .control-arrow:hover {
	color: #333333;
}

label {
	margin-bottom: 8px;
	font-weight: 400;
}

select[multiple],
select[size] {
	height: 200px;
}

select[multiple] option,
select[size] option {
	padding: 8px 16px;
	border-radius: 3px;
}

select[multiple] option + option,
select[size] option + option {
	margin-top: 1px;
}

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
	outline: 0;
}

input[type="file"],
input[type="file"]:focus {
	border-color: transparent;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.form-control {
	padding-left: 0;
	padding-right: 0;
	border-width: 1px 0;
	border-color: transparent;
	border-bottom-color: #ddd;
	border-radius: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.form-control:focus {
	outline: 0;
	border-color: transparent;
	border-bottom-color: #009688;
	-webkit-box-shadow: 0 1px 0 #009688;
	box-shadow: 0 1px 0 #009688;
}

.form-control[disabled],
fieldset[disabled] .form-control {
	border-bottom-style: dashed;
	color: #999999;
}

.form-control[readonly] {
	border-bottom-style: dashed;
	border-bottom-color: #ddd;
	color: #333333;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.form-control-unstyled {
	padding: 0;
	border: 0;
	background-color: transparent;
}

input[type="text"],
input[type="password"],
input[type="search"],
input[type="email"],
input[type="number"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="url"],
input[type="tel"],
textarea {
	-webkit-appearance: none;
}

/* Form components
----------------------------------*/
.form-group {
	margin-bottom: 20px;
	position: relative;
}

.form-group .form-group:last-child {
	margin-bottom: 0;
}

@media (max-width: 1024px) {
	.form-group div[class*="col-md-"]:not(.control-label) + div[class*="col-md-"] {
		margin-top: 20px;
	}
}

@media (max-width: 1199px) {
	.form-group div[class*="col-lg-"]:not(.control-label) + div[class*="col-lg-"] {
		margin-top: 20px;
	}
}

@media (max-width: 768px) {
	.form-group div[class*="col-sm-"]:not(.control-label) + div[class*="col-sm-"] {
		margin-top: 20px;
	}
}

.form-group-material > .control-label {
	position: relative;
	top: 8px;
	margin-bottom: 0;
	opacity: 0;
	filter: alpha(opacity=0);
}

.form-group-material > .control-label ~ .form-control-feedback {
	top: 20px;
}

.form-group-material > .control-label.has-margin {
	margin-bottom: 8px;
}

.form-group-material > .control-label.has-margin ~ .form-control-feedback {
	top: 28px;
}

.form-group-material > .control-label.is-visible {
	top: 0;
	opacity: 1;
	filter: alpha(opacity=100);
}

.form-group-material > .control-label.animate {
	-webkit-transition: all linear 0.1s;
	-o-transition: all linear 0.1s;
	transition: all linear 0.1s;
}

.radio,
.checkbox {
	margin-top: 9px;
	margin-bottom: 9px;
}

.radio label,
.checkbox label {
	padding-left: 22px;
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
	margin-left: 0;
	left: 0;
}

.radio + .radio,
.checkbox + .checkbox {
	margin-top: 0;
}

.radio-inline,
.checkbox-inline {
	position: relative;
	padding-left: 28px;
}

.radio-right.radio-inline,
.radio-right label,
.checkbox-right.radio-inline,
.checkbox-right label,
.checkbox-right.checkbox-inline,
.checkbox-right label {
	padding-left: 0;
	padding-right: 28px;
}

.radio-right input[type="radio"],
.checkbox-right input[type="radio"],
.checkbox-right input[type="checkbox"] {
	left: auto;
	right: 0;
}

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
	margin-left: 15px;
}

.radio.disabled,
.checkbox.disabled,
.radio-inline.disabled,
.checkbox-inline.disabled,
fieldset[disabled] .radio,
fieldset[disabled] .checkbox,
fieldset[disabled] .radio-inline,
fieldset[disabled] .checkbox-inline {
	color: #999999;
}

/* Form control sizing
----------------------------------*/
.input-xlg {
	height: 42px;
	padding: 10px 0;
	font-size: 15px;
	line-height: 1.333334;
	border-radius: 0;
}

select.input-xlg {
	height: 42px;
	line-height: 42px;
}

textarea.input-xlg,
select[multiple].input-xlg {
	height: auto;
}

.form-group-xlg .form-control {
	height: 42px;
	padding: 10px 0;
	font-size: 15px;
	line-height: 1.333334;
}

.form-group-xlg .form-control-static {
	height: 42px;
	min-height: 35px;
	padding: 10px 0;
	font-size: 15px;
	line-height: 1.333334;
}

.input-lg {
	padding-left: 0;
	padding-right: 0;
	border-radius: 0;
}

.form-group-lg .form-control {
	padding-left: 0;
	padding-right: 0;
	border-radius: 0;
}

.form-group-lg .form-control-static {
	padding-left: 0;
	padding-right: 0;
}

.input-sm {
	padding-left: 0;
	padding-right: 0;
	border-radius: 0;
}

.form-group-sm .form-control {
	padding-left: 0;
	padding-right: 0;
	border-radius: 0;
}

.form-group-sm .form-control-static {
	padding-left: 0;
	padding-right: 0;
}

.input-xs {
	height: 34px;
	padding: 6px 0;
	font-size: 12px;
	line-height: 1.6666667;
	border-radius: 0;
}

select.input-xs {
	height: 34px;
	line-height: 34px;
}

textarea.input-xs,
select[multiple].input-xs {
	height: auto;
}

.form-group-xs .form-control {
	height: 34px;
	padding: 6px 0;
	font-size: 12px;
	line-height: 1.6666667;
}

.form-group-xs .form-control-static {
	height: 34px;
	min-height: 32px;
	padding: 6px 0;
	font-size: 12px;
	line-height: 1.6666667;
}

/* Form helpers
----------------------------------*/
.has-feedback .form-control,
.has-feedback.input-group .form-control {
	padding-right: 32px;
}

.has-feedback .form-control.input-xlg,
.has-feedback.input-group-xlg .form-control {
	padding-right: 36px;
}

.has-feedback .form-control.input-lg,
.has-feedback.input-group-lg .form-control {
	padding-right: 34px;
}

.has-feedback .form-control.input-sm,
.has-feedback.input-group-sm .form-control {
	padding-right: 30px;
}

.has-feedback .form-control.input-xs,
.has-feedback.input-group-xs .form-control {
	padding-right: 28px;
}

.form-control-feedback {
	width: 16px;
	color: #333333;
	z-index: 3;
}

.input-group > .form-control-feedback {
	height: 38px;
	line-height: 38px;
}

.has-feedback-left .form-control,
.has-feedback-left.input-group .form-control {
	padding-right: 0 !important;
	padding-left: 32px;
}

.has-feedback-left .form-control.input-xlg,
.has-feedback-left.input-group-xlg .form-control {
	padding-left: 36px;
}

.has-feedback-left .form-control.input-lg,
.has-feedback-left.input-group-lg .form-control {
	padding-left: 34px;
}

.has-feedback-left .form-control.input-sm,
.has-feedback-left.input-group-sm .form-control {
	padding-left: 30px;
}

.has-feedback-left .form-control.input-xs,
.has-feedback-left.input-group-xs .form-control {
	padding-left: 28px;
}

.has-feedback-left .form-control-feedback {
	right: auto;
	left: 0;
}

.input-xlg + .form-control-feedback,
.form-group-xlg > .form-control-feedback,
.input-group-xlg > .form-control-feedback {
	height: 42px;
	line-height: 42px;
}

.input-lg + .form-control-feedback,
.form-group-lg > .form-control-feedback {
	width: 16px;
}

.input-group-lg > .form-control-feedback {
	height: 40px;
	line-height: 40px;
}

.input-sm + .form-control-feedback,
.form-group-sm > .form-control-feedback {
	width: 16px;
}

.input-group-sm > .form-control-feedback {
	height: 36px;
	line-height: 36px;
}

.input-xs + .form-control-feedback,
.form-group-xs > .form-control-feedback,
.input-group-xs > .form-control-feedback {
	height: 34px;
	line-height: 34px;
}

.has-success .form-control {
	border-color: transparent transparent #43A047;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.has-success .form-control:focus {
	border-color: transparent transparent #43A047;
	-webkit-box-shadow: 0 1px 0 #43A047;
	box-shadow: 0 1px 0 #43A047;
}

.has-warning .form-control {
	border-color: transparent transparent #EF6C00;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.has-warning .form-control:focus {
	border-color: transparent transparent #EF6C00;
	-webkit-box-shadow: 0 1px 0 #EF6C00;
	box-shadow: 0 1px 0 #EF6C00;
}

.has-error .form-control {
	border-color: transparent transparent #D84315;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.has-error .form-control:focus {
	border-color: transparent transparent #D84315;
	-webkit-box-shadow: 0 1px 0 #D84315;
	box-shadow: 0 1px 0 #D84315;
}

.help-block {
	color: #999999;
	font-size: 12px;
	margin-top: 8px;
	margin-bottom: 8px;
}

.help-inline {
	display: inline-block;
	color: #999999;
	font-size: 12px;
	margin-top: 9px;
	margin-bottom: 9px;
}

.form-horizontal .form-group > div[class*="col-"] + .help-inline {
	margin-left: 10px;
	margin-right: 10px;
}

@media (min-width: 1025px) {
	.help-inline {
		display: inline-block;
		margin-top: 9px;
		margin-bottom: 9px;
		vertical-align: top;
	}

	.help-inline:not(.label) {
		color: #999999;
	}

	.form-group-lg .help-inline {
		margin-top: 10px;
	}

	.form-group-sm .help-inline {
		margin-top: 8px;
	}

	.form-group-xs .help-inline {
		margin-top: 7px;
	}
}

/* Form layouts
----------------------------------*/
@media (min-width: 769px) {
	.form-inline .form-group + .form-group {
		margin-left: 15px;
	}

	.form-inline .form-group > label {
		margin-right: 8px;
		position: relative;
		top: 1px;
	}
}

.form-horizontal .form-group .form-group {
	margin-left: 0;
	margin-right: 0;
}

@media (min-width: 769px) {
	.form-horizontal .control-label {
		padding-bottom: 8px;
		padding-top: 0;
	}

	.form-horizontal .control-label:not(.text-right) {
		text-align: left;
	}
}

@media (min-width: 480px) {
	.form-horizontal .control-label[class*=col-xs-] {
		padding-top: 9px;
	}
}

@media (min-width: 1025px) {
	.form-horizontal .control-label[class*=col-md-] {
		padding-top: 9px;
	}
}

@media (min-width: 769px) {
	.form-horizontal .control-label[class*=col-sm-] {
		padding-top: 9px;
	}
}

@media (min-width: 1200px) {
	.form-horizontal .control-label[class*=col-lg-] {
		padding-top: 9px;
	}
}

.form-horizontal .has-feedback > .form-control-feedback {
	right: 0;
}

.form-horizontal .has-feedback-left .form-control-feedback {
	right: auto;
	left: 10px;
}

.form-horizontal .has-feedback-left > .form-control-feedback {
	left: 0;
}

@media (min-width: 769px) {
	.form-horizontal .form-group-xlg .control-label {
		font-size: 15px;
		padding-top: 11px;
	}
}

@media (min-width: 769px) {
	.form-horizontal .form-group-lg .control-label {
		padding-top: 10px;
	}
}

@media (min-width: 769px) {
	.form-horizontal .form-group-sm .control-label {
		padding-top: 8px;
	}
}

@media (min-width: 769px) {
	.form-horizontal .form-group-xs .control-label {
		font-size: 12px;
		padding-top: 7px;
	}
}

/* ------------------------------------------------------------------------------
*
*  # Buttons component
*
*  Overrides for buttons bootstrap component
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.btn {
	position: relative;
	font-weight: 500;
	text-transform: uppercase;
	border-width: 0;
	padding: 9px 17px;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
	outline: 0;
}

.btn::-moz-focus-inner {
	border: 0;
}

.btn:hover,
.btn:focus,
.btn.focus {
	-webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
	box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
}

.btn:active,
.btn.active {
	-webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
	box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
}

.btn[class*=bg-]:hover,
.btn[class*=bg-]:focus,
.btn[class*=bg-].focus {
	color: #fff;
}

.btn.text-size-small {
	line-height: 1.6666667;
}

.btn.text-size-mini {
	line-height: 1.82;
}

.btn-raised:not(.btn-flat):not(.btn-link) {
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.btn-raised:not(.btn-flat):not(.btn-link).btn-default {
	background-color: #fcfcfc;
}

.btn-raised:not(.btn-flat):not(.btn-link).btn-default:hover,
.btn-raised:not(.btn-flat):not(.btn-link).btn-default:focus,
.btn-raised:not(.btn-flat):not(.btn-link).btn-default.focus {
	background-color: #f8f8f8;
}

.btn-raised:not(.btn-flat):not(.btn-link).btn-default:active,
.btn-raised:not(.btn-flat):not(.btn-link).btn-default.active,
.open > .dropdown-toggle.btn-raised:not(.btn-flat):not(.btn-link).btn-default {
	background-color: #f8f8f8;
}

.btn-group.open .dropdown-toggle.btn-raised:not(.btn-flat):not(.btn-link).btn-default,
.input-group-btn.open .dropdown-toggle.btn-raised:not(.btn-flat):not(.btn-link).btn-default {
	-webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.btn-raised:not(.btn-flat):not(.btn-link).btn-default.disabled:hover,
.btn-raised:not(.btn-flat):not(.btn-link).btn-default[disabled]:hover,
fieldset[disabled] .btn-raised:not(.btn-flat):not(.btn-link).btn-default:hover,
.btn-raised:not(.btn-flat):not(.btn-link).btn-default.disabled:focus,
.btn-raised:not(.btn-flat):not(.btn-link).btn-default[disabled]:focus,
fieldset[disabled] .btn-raised:not(.btn-flat):not(.btn-link).btn-default:focus,
.btn-raised:not(.btn-flat):not(.btn-link).btn-default.disabled.focus,
.btn-raised:not(.btn-flat):not(.btn-link).btn-default[disabled].focus,
fieldset[disabled] .btn-raised:not(.btn-flat):not(.btn-link).btn-default.focus {
	background-color: #fcfcfc;
}

.btn-raised:not(.btn-flat):not(.btn-link):hover,
.btn-raised:not(.btn-flat):not(.btn-link):focus,
.btn-raised:not(.btn-flat):not(.btn-link).focus {
	-webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.btn-raised:not(.btn-flat):not(.btn-link):active,
.btn-raised:not(.btn-flat):not(.btn-link).active {
	-webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.btn-group.open .dropdown-toggle.btn-raised:not(.btn-flat):not(.btn-link),
.input-group-btn.open .dropdown-toggle.btn-raised:not(.btn-flat):not(.btn-link) {
	-webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.btn-raised:not(.btn-flat):not(.btn-link).disabled,
.btn-raised:not(.btn-flat):not(.btn-link)[disabled],
fieldset[disabled] .btn-raised:not(.btn-flat):not(.btn-link) {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.btn-labeled {
	padding-left: 54px;
}

.btn-labeled.btn-default > b {
	background-color: #2196F3;
	color: #fff;
}

.btn-labeled > b {
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.15);
	display: block;
	line-height: 1;
	padding: 11px;
	border-bottom-left-radius: 3px;
	border-top-left-radius: 3px;
}

.btn-labeled > b > i {
	top: 0;
}

.btn-labeled.btn-labeled-right {
	padding-left: 16px;
	padding-right: 54px;
}

.btn-labeled.btn-labeled-right > b {
	left: auto;
	right: 0;
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	border-bottom-right-radius: 3px;
	border-top-right-radius: 3px;
}

.btn-labeled.btn-xlg {
	padding-left: 62px;
}

.btn-labeled.btn-xlg > b {
	padding: 13px;
}

.btn-labeled.btn-xlg.btn-labeled-right {
	padding-left: 20px;
	padding-right: 62px;
}

.btn-labeled.btn-lg {
	padding-left: 58px;
}

.btn-labeled.btn-lg > b {
	padding: 12px;
}

.btn-labeled.btn-lg.btn-labeled-right {
	padding-left: 18px;
	padding-right: 58px;
}

.btn-labeled.btn-sm {
	padding-left: 50px;
}

.btn-labeled.btn-sm > b {
	padding: 10px;
}

.btn-labeled.btn-sm.btn-labeled-right {
	padding-left: 14px;
	padding-right: 50px;
}

.btn-labeled.btn-xs {
	padding-left: 46px;
}

.btn-labeled.btn-xs > b {
	padding: 9px;
}

.btn-labeled.btn-xs.btn-labeled-right {
	padding-left: 12px;
	padding-left: 46px;
}

.btn-flat {
	border-width: 2px;
	background-color: transparent;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.btn-flat:hover,
.btn-flat:focus {
	opacity: 0.8;
	filter: alpha(opacity=80);
	-webkit-box-shadow: none;
	box-shadow: none;
}

.btn-flat:active {
	opacity: 0.95;
	filter: alpha(opacity=95);
}

.btn-group.open .dropdown-toggle.btn-flat {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.btn-icon {
	padding-left: 11px;
	padding-right: 11px;
}

.btn-icon.icon-2x {
	padding-left: 9px;
	padding-right: 9px;
}

.btn-icon.icon-2x > i {
	font-size: 32px;
	top: 0;
}

.btn-icon.icon-2x.btn-xlg {
	padding-left: 11px;
	padding-right: 11px;
}

.btn-icon.icon-2x.btn-lg {
	padding-left: 10px;
	padding-right: 10px;
}

.btn-icon.icon-2x.btn-sm {
	padding-left: 8px;
	padding-right: 8px;
}

.btn-icon.icon-2x.btn-xs {
	padding-left: 7px;
	padding-right: 7px;
}

.btn-icon.btn-xlg,
.input-group-xlg > .input-group-btn > .btn-icon {
	padding-left: 13px;
	padding-right: 13px;
}

.btn-icon.btn-lg,
.input-group-lg > .input-group-btn > .btn-icon {
	padding-left: 12px;
	padding-right: 12px;
}

.btn-icon.btn-sm,
.input-group-sm > .input-group-btn > .btn-icon {
	padding-left: 10px;
	padding-right: 10px;
}

.btn-icon.btn-xs,
.input-group-xs > .input-group-btn > .btn.btn-icon {
	padding-left: 9px;
	padding-right: 9px;
}

.btn-float {
	padding: 18px;
	white-space: normal;
	border-radius: 3px;
}

.btn-float.btn-link {
	padding: 13px;
}

.btn-float i {
	display: block;
	margin: 0;
	top: 0;
}

.btn-float img {
	border-radius: 3px;
}

.btn-float > span {
	display: block;
	padding-top: 10px;
	margin-bottom: -6px;
}

.btn-float.btn-float-lg i {
	font-size: 32px;
}

.btn-link {
	color: #333333;
	border-radius: 3px;
}

.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.btn-rounded,
.btn-rounded.btn-labeled > b,
.btn-rounded img {
	border-radius: 100px;
}

.btn-block + .btn-block {
	margin-top: 10px;
}

.btn-default:focus,
.btn-default.focus,
.btn-default:hover {
	background-color: #f5f5f5;
}

.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
	background-color: #f5f5f5;
}

.btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.open > .dropdown-toggle.btn-default:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus {
	background-color: #f5f5f5;
}

.btn-default.disabled {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
	background-color: #2196F3;
}

.btn-primary.disabled {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.btn-success:focus,
.btn-success.focus,
.btn-success:hover {
	background-color: #4CAF50;
}

.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
	background-color: #4CAF50;
}

.btn-success:active:hover,
.btn-success.active:hover,
.open > .dropdown-toggle.btn-success:hover,
.btn-success:active:focus,
.btn-success.active:focus,
.open > .dropdown-toggle.btn-success:focus,
.btn-success:active.focus,
.btn-success.active.focus,
.open > .dropdown-toggle.btn-success.focus {
	background-color: #4CAF50;
}

.btn-success.disabled {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.btn-info:focus,
.btn-info.focus,
.btn-info:hover {
	background-color: #00BCD4;
}

.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
	background-color: #00BCD4;
}

.btn-info:active:hover,
.btn-info.active:hover,
.open > .dropdown-toggle.btn-info:hover,
.btn-info:active:focus,
.btn-info.active:focus,
.open > .dropdown-toggle.btn-info:focus,
.btn-info:active.focus,
.btn-info.active.focus,
.open > .dropdown-toggle.btn-info.focus {
	background-color: #00BCD4;
}

.btn-info.disabled {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.btn-warning:focus,
.btn-warning.focus,
.btn-warning:hover {
	background-color: #FF5722;
}

.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
	background-color: #FF5722;
}

.btn-warning:active:hover,
.btn-warning.active:hover,
.open > .dropdown-toggle.btn-warning:hover,
.btn-warning:active:focus,
.btn-warning.active:focus,
.open > .dropdown-toggle.btn-warning:focus,
.btn-warning:active.focus,
.btn-warning.active.focus,
.open > .dropdown-toggle.btn-warning.focus {
	background-color: #FF5722;
}

.btn-warning.disabled {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.btn-danger:focus,
.btn-danger.focus,
.btn-danger:hover {
	background-color: #F44336;
}

.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
	background-color: #F44336;
}

.btn-danger:active:hover,
.btn-danger.active:hover,
.open > .dropdown-toggle.btn-danger:hover,
.btn-danger:active:focus,
.btn-danger.active:focus,
.open > .dropdown-toggle.btn-danger:focus,
.btn-danger:active.focus,
.btn-danger.active.focus,
.open > .dropdown-toggle.btn-danger.focus {
	background-color: #F44336;
}

.btn-danger.disabled {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.btn-xlg,
.btn-group-xlg > .btn {
	padding: 11px 21px;
	font-size: 15px;
	line-height: 1.333334;
	border-radius: 3px;
}

.btn-xlg.btn-rounded {
	border-radius: 100px;
}

.btn-lg,
.btn-group-lg > .btn {
	padding: 10px 19px;
	border-radius: 3px;
}

.btn-lg.btn-rounded {
	border-radius: 100px;
}

.btn-sm,
.btn-group-sm > .btn {
	padding: 8px 15px;
}

.btn-xs,
.btn-group-xs > .btn {
	padding: 7px 13px;
}

.btn-sm:not(.btn-rounded),
.btn-group-sm > .btn:not(.btn-rounded),
.btn-xs:not(.btn-rounded),
.btn-group-xs > .btn:not(.btn-rounded) {
	border-radius: 3px;
}

/* ------------------------------------------------------------------------------
*
*  # Dropdown menu component
*
*  Overrides for dropdown menu bootstrap component
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.caret {
	font-style: normal;
	font-weight: normal;
	border: 0;
	margin: 0;
	width: auto;
	height: auto;
	text-align: center;
	margin-top: -1px;
}

.caret:after {
	content: '\e9c5';
	font-family: 'icomoon';
	display: block;
	font-size: 16px;
	width: 16px;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.dropdown-menu {
	min-width: 180px;
	padding: 8px 0;
	color: #333333;
	-webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.dropdown-menu:not([class*=border-]) {
	border-width: 0;
}

.dropdown-menu .divider {
	margin: 8px 0;
}

.dropdown-menu > li {
	position: relative;
	margin-bottom: 1px;
}

.dropdown-menu > li:last-child {
	margin-bottom: 0;
}

.dropdown-menu > li > a {
	padding: 8px 16px;
	outline: 0;
	overflow: hidden;
	text-overflow: ellipsis;
}

.dropdown-menu > li > a > i,
.dropdown-menu > .dropdown-header > i,
.dropdown-menu > li > a > img,
.dropdown-menu > .dropdown-header > img {
	margin-right: 13px;
	float: left;
	margin-top: 2px;
	top: 0;
	min-width: 15px;
}

.dropdown-menu > li > a > i.pull-right,
.dropdown-menu > .dropdown-header > i.pull-right,
.dropdown-menu > li > a > img.pull-right,
.dropdown-menu > .dropdown-header > img.pull-right {
	margin-right: 0;
	margin-left: 16px;
}

.dropdown-menu > li > a > .label,
.dropdown-menu > .dropdown-header > .label,
.dropdown-menu > li > a > .badge,
.dropdown-menu > .dropdown-header > .badge {
	float: left;
	margin-right: 16px;
}

.dropdown-menu > li > a > .label.pull-right,
.dropdown-menu > .dropdown-header > .label.pull-right,
.dropdown-menu > li > a > .badge.pull-right,
.dropdown-menu > .dropdown-header > .badge.pull-right {
	margin-right: 0;
	margin-left: 16px;
}

.dropdown-menu > li > a > img,
.dropdown-menu > .dropdown-header > img {
	max-height: 16px;
}

.dropdown-menu > li > label:hover,
.dropdown-menu > li > label:focus {
	text-decoration: none;
	color: #333333;
	background-color: #f5f5f5;
}

.dropdown-menu > .active > label,
.dropdown-menu > .active > label:hover,
.dropdown-menu > .active > label:focus {
	color: #fff;
	outline: 0;
	background-color: #26A69A;
}

.dropdown-menu > .disabled > label,
.dropdown-menu > .disabled > label:hover,
.dropdown-menu > .disabled > label:focus {
	background-color: transparent;
	color: #999999;
}

.dropdown-menu > li > label {
	padding: 8px 16px;
	padding-left: 44px;
	display: block;
	cursor: pointer;
}

.dropdown-menu > li > label .checker,
.dropdown-menu > li > label .choice,
.dropdown-menu > li > label > input[type=checkbox],
.dropdown-menu > li > label > input[type=radio] {
	left: 16px;
	top: auto;
	margin-top: 1px;
}

.dropdown-menu > li.checkbox,
.dropdown-menu > li.radio {
	margin-top: 0;
}

.dropdown-menu > li.checkbox-right > label,
.dropdown-menu > li.radio-right > label,
.dropdown-menu > li.checkbox-right > label {
	padding-left: 16px;
	padding-right: 44px;
}

.dropdown-menu > li.checkbox-right > label .checker,
.dropdown-menu > li.checkbox-right > label > input[type=checkbox] {
	left: auto;
	right: 16px;
}

.dropdown-menu > li.radio-right > label .choice,
.dropdown-menu > li.radio-right > label > input[type=radio],
.dropdown-menu > li.checkbox-right > label .choice,
.dropdown-menu > li.checkbox-right > label > input[type=radio] {
	left: auto;
	right: 16px;
}

.dropdown-menu > .checkbox-switchery > label > .switchery {
	left: 16px;
}

.dropdown-menu > .checkbox-switchery.checkbox-right[class*=switchery-] > label {
	padding-left: 16px;
}

.dropdown-menu > .checkbox-switchery.checkbox-right[class*=switchery-] > label > .switchery {
	left: auto;
	right: 16px;
}

.dropdown-menu > .checkbox-switchery.switchery-sm {
	margin-bottom: 0;
}

.dropdown-menu > .checkbox-switchery.switchery-sm > label {
	padding-left: 70px;
}

.dropdown-menu > .checkbox-switchery.switchery-xs {
	margin-bottom: 0;
}

.dropdown-menu > .checkbox-switchery.switchery-xs > label {
	padding-left: 62px;
}

.dropdown-menu > .checkbox-switchery.checkbox-right.switchery-sm > label {
	padding-right: 70px;
}

.dropdown-menu > .checkbox-switchery.checkbox-right.switchery-xs > label {
	padding-right: 62px;
}

.dropdown-menu > .disabled .badge,
.dropdown-menu > .disabled .label,
.dropdown-menu > .disabled img {
	opacity: 0.8;
	filter: alpha(opacity=80);
}

.dropdown-menu[class*=bg-] > li > a,
.dropdown-menu[class*=bg-] > li > label {
	color: #fff;
}

.dropdown-menu[class*=bg-] > li > a:hover,
.dropdown-menu[class*=bg-] > li > label:hover,
.dropdown-menu[class*=bg-] > li > a:focus,
.dropdown-menu[class*=bg-] > li > label:focus {
	background-color: rgba(0, 0, 0, 0.1);
}

.dropdown-menu[class*=bg-] > li > a > .label,
.dropdown-menu[class*=bg-] > li > label > .label,
.dropdown-menu[class*=bg-] > li > a > .badge,
.dropdown-menu[class*=bg-] > li > label > .badge {
	color: #333333;
	background-color: #fff;
	border-color: #fff;
}

.dropdown-menu[class*=bg-] > .active > a,
.dropdown-menu[class*=bg-] > .active > label,
.dropdown-menu[class*=bg-] > .active > a:hover,
.dropdown-menu[class*=bg-] > .active > label:hover,
.dropdown-menu[class*=bg-] > .active > a:focus,
.dropdown-menu[class*=bg-] > .active > label:focus {
	background-color: rgba(0, 0, 0, 0.2);
}

.dropdown-menu[class*=bg-] > .disabled > a,
.dropdown-menu[class*=bg-] > .disabled > label,
.dropdown-menu[class*=bg-] > .disabled > a:hover,
.dropdown-menu[class*=bg-] > .disabled > label:hover,
.dropdown-menu[class*=bg-] > .disabled > a:focus,
.dropdown-menu[class*=bg-] > .disabled > label:focus {
	background-color: transparent;
	color: rgba(255, 255, 255, 0.6);
}

.dropdown-menu[class*=bg-] > .dropdown-header {
	color: rgba(255, 255, 255, 0.6);
}

.dropdown-menu[class*=bg-] > .dropdown-header.highlight {
	background-color: rgba(0, 0, 0, 0.1);
}

.dropdown-menu[class*=bg-] .divider {
	background-color: rgba(255, 255, 255, 0.4);
}

.dropdown-menu-lg > li > a {
	padding-top: 9px;
	padding-bottom: 9px;
	font-size: 14px;
	line-height: 1.4285715;
}

.dropdown-menu-sm > li > a {
	padding-top: 7px;
	padding-bottom: 7px;
	font-size: 12px;
	line-height: 1.6666667;
}

.dropdown-menu-xs > li > a {
	padding-top: 6px;
	padding-bottom: 6px;
	font-size: 12px;
	line-height: 1.6666667;
}

.dropdown-menu > .dropdown-submenu > a {
	padding-right: 40px;
	position: relative;
}

.dropdown-menu > .dropdown-submenu > a:after {
	content: '\e9c7';
	font-family: 'icomoon';
	position: absolute;
	top: 50%;
	margin-top: -8px;
	right: 16px;
	font-size: 16px;
	font-weight: 400;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	opacity: 0.8;
	filter: alpha(opacity=80);
}

.dropdown-menu > .dropdown-submenu:hover > a,
.dropdown-menu > .dropdown-submenu:focus > a {
	background-color: #f5f5f5;
}

.dropdown-menu > .dropdown-submenu:hover > a:after,
.dropdown-menu > .dropdown-submenu:focus > a:after {
	opacity: 1;
	filter: alpha(opacity=100);
}

.dropdown-menu > .dropdown-submenu.active > a {
	background-color: #26A69A;
	color: #fff;
}

.dropdown-menu > .dropdown-submenu:hover > .dropdown-menu {
	display: block;
}

.dropdown-menu > .dropdown-submenu.disabled > .dropdown-menu {
	display: none;
}

.dropdown-menu > .dropdown-submenu.disabled > a {
	background-color: transparent;
}

.dropdown-menu > .dropdown-submenu > .dropdown-menu {
	top: 0;
	left: 100%;
	margin-top: -8px;
}

.dropup .dropdown-menu > .dropdown-submenu > .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu > .dropdown-submenu > .dropdown-menu {
	top: auto;
	bottom: 0;
	margin-top: 0;
	margin-bottom: -8px;
}

.dropdown-menu > .dropdown-submenu.dropdown-submenu-left > .dropdown-menu {
	left: auto;
	right: 100%;
}

.dropup .dropdown-menu > .dropdown-submenu > .dropdown-menu,
.dropup.dropdown-menu > .dropdown-submenu > .dropdown-menu {
	top: auto;
	bottom: 0;
	margin-top: 0;
	margin-bottom: -8px;
}

@media (max-width: 768px) {
	.dropdown-menu > .dropdown-submenu {
		position: static;
	}

	.dropdown-menu > .dropdown-submenu > a:after {
		content: '\e9c5';
	}

	.dropdown-menu > .dropdown-submenu .dropdown-menu,
	.dropdown-menu > .dropdown-submenu.dropdown-submenu-left .dropdown-menu {
		position: relative;
		left: 0;
		right: 0;
		float: none;
		border-width: 0;
		border-color: rgba(0, 0, 0, 0.1);
		box-shadow: none;
		min-width: 100%;
		margin: 0;
	}

	.dropdown-menu > .dropdown-submenu .dropdown-menu > li > a,
	.dropdown-menu > .dropdown-submenu.dropdown-submenu-left .dropdown-menu > li > a {
		padding-left: 32px;
	}

	.dropdown-menu > .dropdown-submenu .dropdown-menu > li > ul > li > a,
	.dropdown-menu > .dropdown-submenu.dropdown-submenu-left .dropdown-menu > li > ul > li > a {
		padding-left: 48px;
	}
}

.dropdown-menu[class*=bg-] > .dropdown-submenu:hover > a,
.dropdown-menu[class*=bg-] > .dropdown-submenu:focus > a {
	background-color: rgba(0, 0, 0, 0.1);
}

.dropdown-menu[class*=bg-] > .dropdown-submenu.disabled:hover > a,
.dropdown-menu[class*=bg-] > .dropdown-submenu.disabled:focus > a {
	background-color: transparent;
}

.dropdown-header {
	padding: 8px 16px;
	font-size: 11px;
	line-height: 1.82;
	color: #999999;
	text-transform: uppercase;
	margin-top: 8px;
}

.dropdown-header.highlight {
	margin-top: 0;
	background-color: #f8f8f8;
	color: #999999;
}

li + .dropdown-header.highlight,
.dropdown-header.highlight + li {
	margin-top: 8px;
}

.dropdown-header.highlight:first-child {
	margin-top: 0;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
	border: 0;
}

.dropup .caret:after,
.navbar-fixed-bottom .dropdown .caret:after {
	content: '\e9c6';
}

/* ------------------------------------------------------------------------------
*
*  # Button group component
*
*  Overrides for button group bootstrap component
*
*  Version: 1.1
*  Latest update: Oct 20, 2015
*
* ---------------------------------------------------------------------------- */
.btn-group .btn + .btn,
.input-group-btn .btn + .btn,
.btn-group .btn + .btn-group,
.input-group-btn .btn + .btn-group,
.btn-group .btn-group + .btn,
.input-group-btn .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.input-group-btn .btn-group + .btn-group {
	margin-left: 1px;
}

.btn-toolbar {
	font-size: 0;
}

.btn-toolbar .btn-group,
.btn-toolbar .input-group {
	float: none;
}

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}

.btn-group > .btn + .dropdown-toggle {
	padding-left: 8px;
	padding-right: 8px;
}

.btn-group > .btn-xlg + .dropdown-toggle,
.btn-group-xlg > .btn + .dropdown-toggle {
	padding-left: 10px;
	padding-right: 10px;
}

.btn-group > .btn-lg + .dropdown-toggle,
.btn-group-lg > .btn + .dropdown-toggle {
	padding-left: 9px;
	padding-right: 9px;
}

.btn-group > .btn-sm + .dropdown-toggle,
.btn-group-sm > .btn + .dropdown-toggle {
	padding-left: 7px;
	padding-right: 7px;
}

.btn-group > .btn-xs + .dropdown-toggle,
.btn-group-xs > .btn + .dropdown-toggle {
	padding-left: 6px;
	padding-right: 6px;
}

.btn-group.open .dropdown-toggle:not(.btn-flat):not(.btn-link),
.input-group-btn.open .dropdown-toggle:not(.btn-flat):not(.btn-link) {
	-webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
	box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
}

/* ------------------------------------------------------------------------------
*
*  # Input groups component
*
*  Overrides for input groups bootstrap component
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.input-group .form-control-feedback {
	z-index: 3;
}

.input-group-xlg > .form-control,
.input-group-xlg > .input-group-addon,
.input-group-xlg > .input-group-btn > .btn {
	height: 42px;
	padding: 10px 0;
	font-size: 15px;
	line-height: 1.333334;
	border-radius: 0;
}

select.input-group-xlg > .form-control,
select.input-group-xlg > .input-group-addon,
select.input-group-xlg > .input-group-btn > .btn {
	height: 42px;
	line-height: 42px;
}

textarea.input-group-xlg > .form-control,
textarea.input-group-xlg > .input-group-addon,
textarea.input-group-xlg > .input-group-btn > .btn,
select[multiple].input-group-xlg > .form-control,
select[multiple].input-group-xlg > .input-group-addon,
select[multiple].input-group-xlg > .input-group-btn > .btn {
	height: auto;
}

.input-group-xlg > .input-group-addon {
	padding-right: 20px;
}

.input-group-xlg > .input-group-btn > .btn {
	padding-left: 20px;
	padding-right: 20px;
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
	padding-left: 0;
	padding-right: 0;
	border-radius: 0;
}

.input-group-lg > .input-group-addon {
	padding-right: 18px;
}

.input-group-lg > .input-group-btn > .btn {
	padding: 10px 19px;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
	padding-left: 0;
	padding-right: 0;
	border-radius: 0;
}

.input-group-sm > .input-group-addon {
	padding-right: 14px;
}

.input-group-sm > .input-group-btn > .btn {
	padding: 8px 15px;
}

.input-group-xs > .form-control,
.input-group-xs > .input-group-addon,
.input-group-xs > .input-group-btn > .btn {
	height: 34px;
	padding: 6px 0;
	font-size: 12px;
	line-height: 1.6666667;
	border-radius: 0;
}

select.input-group-xs > .form-control,
select.input-group-xs > .input-group-addon,
select.input-group-xs > .input-group-btn > .btn {
	height: 34px;
	line-height: 34px;
}

textarea.input-group-xs > .form-control,
textarea.input-group-xs > .input-group-addon,
textarea.input-group-xs > .input-group-btn > .btn,
select[multiple].input-group-xs > .form-control,
select[multiple].input-group-xs > .input-group-addon,
select[multiple].input-group-xs > .input-group-btn > .btn {
	height: auto;
}

.input-group-xs > .input-group-addon {
	padding-right: 12px;
}

.input-group-xs > .input-group-btn > .btn {
	padding: 7px 13px;
}

.input-group-transparent .input-group-addon {
	background-color: transparent;
	border-color: transparent;
	padding: 0;
}

.input-group-transparent .form-control {
	background-color: transparent;
	border-color: transparent;
	cursor: pointer;
	width: auto;
	padding: 0;
}

.input-group-transparent .form-control:hover,
.input-group-transparent .form-control:focus {
	border-color: transparent;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.input-group-addon {
	padding-left: 0;
	cursor: pointer;
}

.input-group-addon:last-child {
	padding-right: 0;
}

.input-group-addon > i {
	display: block;
	top: 0;
}

.input-group-addon .checker,
.input-group-addon .choice {
	display: block;
	margin-top: 1px;
}

.input-group input + .input-group-addon,
.input-group-btn + .input-group-addon,
.input-group > .fas-feedback + .input-group-addon {
	padding-left: 16px;
}

.has-warning .input-group-addon,
.has-success .input-group-addon,
.has-error .input-group-addon {
	border-color: transparent;
	background-color: transparent;
}

.input-group-xlg > .form-control + .input-group-addon {
	padding-left: 20px;
}

.input-group-lg > .form-control + .input-group-addon {
	padding-left: 18px;
}

.input-group-sm > .form-control + .input-group-addon {
	padding-left: 14px;
}

.input-group-xs > .form-control + .input-group-addon {
	padding-left: 12px;
}

.input-group-btn {
	padding-right: 16px;
}

.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group {
	margin-right: 0;
}

.input-group-btn:last-child {
	padding-right: 0;
}

.input-group > .form-control + .input-group-btn,
.input-group > .has-feedback + .input-group-btn {
	padding-left: 16px;
}

.input-group-btn + .input-group-addon {
	padding-left: 0;
}

.input-group-xlg .input-group-btn:not(:last-child) {
	padding-right: 20px;
}

.input-group-xlg > .form-control + .input-group-btn {
	padding-left: 20px;
}

.input-group-lg .input-group-btn:not(:last-child) {
	padding-right: 18px;
}

.input-group-lg > .form-control + .input-group-btn {
	padding-left: 18px;
}

.input-group-sm .input-group-btn:not(:last-child) {
	padding-right: 14px;
}

.input-group-sm > .form-control + .input-group-btn {
	padding-left: 14px;
}

.input-group-xs .input-group-btn:not(:last-child) {
	padding-right: 12px;
}

.input-group-xs > .form-control + .input-group-btn {
	padding-left: 12px;
}

.input-group-btn > .btn,
.input-group-btn > .btn-group > .btn {
	border-radius: 0;
}

.input-group-btn > .btn-rounded {
	border-radius: 100px !important;
}

.input-group-btn:first-child > .btn:first-child,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn > .btn-group:first-child > .btn,
.input-group-btn > .btn:first-child {
	border-bottom-left-radius: 3px;
	border-top-left-radius: 3px;
}

.input-group-btn > .btn:last-child,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn > .btn-group:last-child > .btn,
.input-group-btn > .btn:last-of-type {
	border-bottom-right-radius: 3px;
	border-top-right-radius: 3px;
}

/* ------------------------------------------------------------------------------
*
*  # Navs related component
*
*  Overrides for navs related bootstrap component
*
*  Version: 1.2
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.nav > li > a:focus {
	outline: 0;
}

.nav > li.disabled > a > .badge,
.nav > li.disabled > a > .label,
.nav > li.disabled > a > .status-mark,
.nav > li.disabled > a > img {
	opacity: 0.75;
	filter: alpha(opacity=75);
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
	background-color: transparent;
	border-color: transparent;
	color: #333333;
}

.nav.nav-lg > li > a {
	padding: 13.5px 18px;
}

.nav.nav-sm > li > a {
	padding: 10.5px 14px;
}

.nav.nav-xs > li > a {
	padding: 9px 12px;
}

.nav .tab-img {
	max-height: 20px;
	display: inline-block;
	vertical-align: top;
}

.nav-tabs {
	margin-bottom: 20px;
}

.nav-tabs > li {
	float: none;
}

.nav-tabs > li > a {
	margin-right: 0;
	color: #999;
	border-radius: 0;
	text-transform: uppercase;
}

.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
	background-color: transparent;
	border-color: transparent;
	color: #333333;
}

.nav-tabs > li > a.legitRipple {
	margin-bottom: -1px;
}

.nav-tabs > li > a > [class*=icon-].pull-right {
	float: right;
	margin-top: 2px;
}

.nav-tabs.nav-justified > li > a {
	border-radius: 0;
	margin-bottom: 0;
}

.nav-tabs.nav-justified > li > a:hover,
.nav-tabs.nav-justified > li > a:focus {
	border-bottom-color: #ddd;
}

@media (min-width: 769px) {
	.nav-tabs.nav-justified.nav-tabs-top {
		border-bottom: 1px solid #ddd;
	}

	.nav-tabs.nav-justified.nav-tabs-top > li > a,
	.nav-tabs.nav-justified.nav-tabs-top > li > a:hover,
	.nav-tabs.nav-justified.nav-tabs-top > li > a:focus {
		border: 0;
	}

	.nav-tabs.nav-justified.nav-tabs-bottom {
		border-bottom: 1px solid #ddd;
	}

	.nav-tabs.nav-justified.nav-tabs-highlight > li > a,
	.nav-tabs.nav-justified.nav-tabs-highlight > li > a:hover,
	.nav-tabs.nav-justified.nav-tabs-highlight > li > a:focus {
		border-top-width: 2px;
	}
}

@media (max-width: 768px) {
	.nav-tabs.nav-justified > li.active > a,
	.nav-tabs.nav-justified > li.active > a:hover,
	.nav-tabs.nav-justified > li.active > a:focus {
		border-width: 0 0 0 2px;
		border-left-color: #EC407A;
	}
}

@media (min-width: 769px) {
	.nav-tabs.nav-tabs-highlight > li > a,
	.nav-tabs.nav-tabs-highlight > li > a:hover,
	.nav-tabs.nav-tabs-highlight > li > a:focus {
		border-top-width: 2px;
	}

	.nav-tabs.nav-tabs-highlight > li.active > a,
	.nav-tabs.nav-tabs-highlight > li.active > a:hover,
	.nav-tabs.nav-tabs-highlight > li.active > a:focus {
		border-top-color: #677791;
	}
}

@media (min-width: 769px) {
	.nav-tabs.nav-tabs-top > li {
		margin-bottom: 0;
	}

	.nav-tabs.nav-tabs-top > li > a,
	.nav-tabs.nav-tabs-top > li > a:hover,
	.nav-tabs.nav-tabs-top > li > a:focus {
		border: 0;
	}

	.nav-tabs.nav-tabs-top > li > a:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 2px;
	}

	.nav-tabs.nav-tabs-top > li.open > a:after,
	.nav-tabs.nav-tabs-top > li > a:hover:after,
	.nav-tabs.nav-tabs-top > li > a:focus:after {
		background-color: #ddd;
	}

	.nav-tabs.nav-tabs-top > li.active > a:after {
		background-color: #F06292;
	}

	.nav-tabs.nav-tabs-top > li.active > a,
	.nav-tabs.nav-tabs-top > li.active > a:hover,
	.nav-tabs.nav-tabs-top > li.active > a:focus {
		background-color: transparent;
	}

	.nav-tabs.nav-tabs-top.top-divided {
		border-bottom-color: transparent;
	}
}

@media (min-width: 769px) {
	.nav-tabs.nav-tabs-bottom > li {
		margin-bottom: 0;
	}

	.nav-tabs.nav-tabs-bottom > li > a {
		border-width: 0;
		margin-bottom: -1px;
	}

	.nav-tabs.nav-tabs-bottom > li > a:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 2px;
	}

	.nav-tabs.nav-tabs-bottom > li.active > a:after {
		background-color: #F06292;
	}

	.nav-tabs.nav-tabs-bottom > li.active > a,
	.nav-tabs.nav-tabs-bottom > li.active > a:hover,
	.nav-tabs.nav-tabs-bottom > li.active > a:focus {
		background-color: transparent;
		border-width: 0;
	}

	.nav-tabs.nav-tabs-bottom.bottom-divided {
		border-bottom-color: transparent;
	}
}

.nav-tabs[class*=bg-] {
	border-bottom: 0;
}

.nav-tabs[class*=bg-] > li {
	margin-bottom: 0;
}

.nav-tabs[class*=bg-] > li > a {
	color: #fff;
	border-width: 0;
}

.nav-tabs[class*=bg-] > li > a:hover,
.nav-tabs[class*=bg-] > li > a:focus {
	background-color: rgba(0, 0, 0, 0.05);
}

.nav-tabs[class*=bg-] > li.open:not(.active) > a {
	color: #fff;
	background-color: rgba(0, 0, 0, 0.05);
}

.nav-tabs[class*=bg-] > .active > a,
.nav-tabs[class*=bg-] > .active > a:hover,
.nav-tabs[class*=bg-] > .active > a:focus {
	background-color: rgba(0, 0, 0, 0.1);
	border-width: 0;
	color: #fff;
}

.nav-tabs[class*=bg-] > .disabled > a,
.nav-tabs[class*=bg-] > .disabled > a:hover,
.nav-tabs[class*=bg-] > .disabled > a:focus {
	color: rgba(255, 255, 255, 0.5);
}

@media (min-width: 769px) {
	.nav-tabs[class*=bg-] {
		-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	}

	.tab-content-bordered .nav-tabs[class*=bg-] {
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}
}

@media (min-width: 769px) {
	.nav-tabs.nav-tabs-solid {
		background-color: #d5d3d3;
		border: 0;
		-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	}

	.panel .nav-tabs.nav-tabs-solid {
		background-color: #f8f8f8;
	}

	.nav-tabs.nav-tabs-solid > li {
		margin-bottom: 0;
	}

	.nav-tabs.nav-tabs-solid > li > a {
		border-width: 0;
	}

	.nav-tabs.nav-tabs-solid > li > a:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 2px;
	}

	.nav-tabs.nav-tabs-solid > li > a:hover,
	.nav-tabs.nav-tabs-solid > li > a:focus {
		background-color: transparent;
	}

	.nav-tabs.nav-tabs-solid > .active > a,
	.nav-tabs.nav-tabs-solid > .active > a:hover,
	.nav-tabs.nav-tabs-solid > .active > a:focus {
		background-color: transparent;
		border-width: 0;
	}

	.nav-tabs.nav-tabs-solid > .active > a:after,
	.nav-tabs.nav-tabs-solid > .active > a:hover:after,
	.nav-tabs.nav-tabs-solid > .active > a:focus:after {
		background-color: #F06292;
	}

	.nav-tabs.nav-tabs-solid > .open:not(.active) > a {
		background-color: transparent;
	}

	.nav-tabs.nav-tabs-solid.nav-tabs-component > li:first-child > a:after {
		border-radius: 0 0 0 3px;
	}

	.nav-tabs.nav-tabs-solid.nav-tabs-component.nav-justified > li:last-child > a:after {
		border-radius: 0 0 3px 0;
	}
}

.nav-tabs.nav-tabs-icon > li > a > i {
	margin-right: 8px;
}

@media (min-width: 769px) {
	.nav-tabs.nav-tabs-icon > li > a {
		padding-bottom: 8px;
	}

	.nav-tabs.nav-tabs-icon > li > a > i {
		display: block;
		margin: 0 0 8px;
	}

	.nav-tabs.nav-tabs-icon.nav-lg > li > a {
		padding-bottom: 9px;
	}

	.nav-tabs.nav-tabs-icon.nav-lg > li > a > i {
		margin-bottom: 9px;
	}

	.nav-tabs.nav-tabs-icon.nav-sm > li > a {
		padding-bottom: 6px;
	}

	.nav-tabs.nav-tabs-icon.nav-sm > li > a > i {
		margin-bottom: 7px;
	}

	.nav-tabs.nav-tabs-icon.nav-xs > li > a {
		padding-bottom: 4px;
	}

	.nav-tabs.nav-tabs-icon.nav-xs > li > a > i {
		margin-bottom: 6px;
	}
}

@media (min-width: 769px) {
	.nav-tabs {
		font-size: 0;
	}

	.nav-tabs > li {
		display: inline-block;
		font-size: 13px;
	}
}

@media (max-width: 768px) {
	.nav-tabs {
		border-bottom: 0;
		position: relative;
		background-color: #fff;
		padding: 8px 0;
		border-radius: 3px;
		-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	}

	.nav-tabs > li {
		margin-bottom: 0;
	}

	.nav-tabs > li > a {
		border-width: 0 0 0 2px;
		border-left-color: transparent;
	}

	.nav-tabs > li > a:hover,
	.nav-tabs > li > a:focus {
		background-color: #fafafa;
	}

	.nav-tabs > li > a .position-right[class*=icon-] {
		float: right;
		margin-top: 2px;
	}

	.nav-tabs > li > a .position-right.label,
	.nav-tabs > li > a .position-right.badge {
		float: right;
	}

	.nav-tabs > li.active > a,
	.nav-tabs > li.active > a:hover,
	.nav-tabs > li.active > a:focus {
		border-width: 0 0 0 2px;
		border-left-color: #EC407A;
		background-color: #f5f5f5;
	}

	.nav-tabs > li.open:not(.active) > a,
	.nav-tabs > li.open:not(.active) > a:hover,
	.nav-tabs > li.open:not(.active) > a:focus {
		background-color: #fafafa;
	}

	.nav-tabs > li.pull-right {
		float: none !important;
	}

	.nav-tabs:before {
		content: 'Contents';
		color: inherit;
		margin-top: 7px;
		margin-left: 16px;
		margin-bottom: 15px;
		opacity: 0.5;
		filter: alpha(opacity=50);
	}

	.nav-tabs[class*=bg-] > li > a {
		border-left-width: 0 !important;
	}

	.nav-tabs[class*=bg-] > li > a:hover,
	.nav-tabs[class*=bg-] > li > a:focus,
	.nav-tabs[class*=bg-] > li.open:not(.active) > a {
		background-color: rgba(0, 0, 0, 0.05);
	}

	.nav-tabs-right .nav-tabs {
		margin-bottom: 0;
		margin-top: 20px;
	}
}

@media (min-width: 769px) {
	.nav-tabs-vertical {
		display: table;
		width: 100%;
	}

	.nav-tabs-vertical .nav-tabs {
		display: table-cell;
		border-bottom: 0;
		width: 300px;
	}

	.nav-tabs-vertical .nav-tabs > li {
		display: block;
		margin-bottom: 0;
	}

	.nav-tabs-vertical .nav-tabs-solid > li:last-child > a:after {
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px;
	}

	.nav-tabs-vertical .nav-tabs[class*=bg-] > li:first-child > a {
		border-top-right-radius: 3px;
		border-top-left-radius: 3px;
	}

	.nav-tabs-vertical .nav-tabs[class*=bg-] > li:last-child > a {
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px;
	}

	.nav-tabs-vertical .tab-content {
		display: table-cell;
	}

	.nav-tabs-vertical .tab-content > .has-padding {
		padding: 0;
		padding-top: 12px;
	}

	.nav-tabs-vertical.tab-content-bordered .tab-content {
		border-top-width: 1px;
	}

	.nav-tabs-left .nav-tabs {
		border-right: 1px solid #ddd;
	}

	.nav-tabs-left .nav-tabs > li {
		margin-right: -1px;
	}

	.nav-tabs-left .nav-tabs > li.active > a,
	.nav-tabs-left .nav-tabs > li.active > a:hover,
	.nav-tabs-left .nav-tabs > li.active > a:focus {
		border-bottom-color: #ddd;
		border-right-color: transparent;
	}

	.nav-tabs-left .nav-tabs.nav-tabs-component > li > a {
		border-radius: 3px 0 0 3px;
	}

	.nav-tabs-left .nav-tabs-highlight > li > a,
	.nav-tabs-left .nav-tabs-highlight > li > a:hover,
	.nav-tabs-left .nav-tabs-highlight > li > a:focus {
		border-top-width: 1px;
		border-left-width: 2px;
	}

	.nav-tabs-left .nav-tabs-highlight > li.active > a,
	.nav-tabs-left .nav-tabs-highlight > li.active > a:hover,
	.nav-tabs-left .nav-tabs-highlight > li.active > a:focus {
		border-top-color: #ddd;
		border-left-color: #039BE5;
	}

	.nav-tabs-left .nav-tabs-top,
	.nav-tabs-left .nav-tabs-bottom {
		padding-right: 20px;
	}

	.nav-tabs-left .top-divided,
	.nav-tabs-left .bottom-divided {
		padding-right: 0;
		border-right-width: 0;
	}

	.nav-tabs-left .nav-tabs-solid,
	.nav-tabs-left .nav-tabs[class*=bg-] {
		border-right: 0;
		border-radius: 3px;
	}

	.nav-tabs-left .nav-tabs-solid > li,
	.nav-tabs-left .nav-tabs[class*=bg-] > li {
		margin-right: 0;
	}

	.nav-tabs-left .tab-content {
		padding-left: 20px;
	}

	.nav-tabs-left.tab-content-bordered .tab-content {
		border-left-width: 0;
	}

	.nav-tabs-right .nav-tabs {
		border-left: 1px solid #ddd;
	}

	.nav-tabs-right .nav-tabs > li {
		margin-left: -1px;
	}

	.nav-tabs-right .nav-tabs > li.active > a,
	.nav-tabs-right .nav-tabs > li.active > a:hover,
	.nav-tabs-right .nav-tabs > li.active > a:focus {
		border-bottom-color: #ddd;
		border-left-color: transparent;
	}

	.nav-tabs-right .nav-tabs.nav-tabs-component > li > a {
		border-radius: 0 3px 3px 0;
	}

	.nav-tabs-right .nav-tabs-highlight > li > a,
	.nav-tabs-right .nav-tabs-highlight > li > a:hover,
	.nav-tabs-right .nav-tabs-highlight > li > a:focus {
		border-top-width: 1px;
		border-right-width: 2px;
	}

	.nav-tabs-right .nav-tabs-highlight > li.active > a,
	.nav-tabs-right .nav-tabs-highlight > li.active > a:hover,
	.nav-tabs-right .nav-tabs-highlight > li.active > a:focus {
		border-top-color: #ddd;
		border-right-color: #039BE5;
	}

	.nav-tabs-right .nav-tabs-top,
	.nav-tabs-right .nav-tabs-bottom {
		padding-left: 20px;
	}

	.nav-tabs-right .top-divided,
	.nav-tabs-right .bottom-divided {
		padding-left: 0;
		border-left-width: 0;
	}

	.nav-tabs-right .nav-tabs-solid,
	.nav-tabs-right .nav-tabs[class*=bg-] {
		border-left: 0;
		border-radius: 3px;
	}

	.nav-tabs-right .nav-tabs-solid > li,
	.nav-tabs-right .nav-tabs[class*=bg-] > li {
		margin-left: 0;
	}

	.nav-tabs-right .tab-content {
		padding-right: 20px;
	}

	.nav-tabs-right.tab-content-bordered .tab-content {
		border-right-width: 0;
	}
}

.nav-pills {
	margin-bottom: 20px;
}

.nav-pills > li {
	float: none;
}

.nav-pills > li > a {
	color: #333333;
	text-transform: uppercase;
}

.nav-pills > li + li {
	margin-left: 0;
}

.nav-pills > li + li > a {
	margin-top: 2px;
}

.nav-pills .open > a,
.nav-pills .open > a:hover,
.nav-pills .open > a:focus {
	background-color: #f5f5f5;
}

.nav-pills.nav-pills-bordered > li > a,
.nav-pills.nav-pills-toolbar > li > a,
.nav-pills.nav-pills-bordered > .open > a,
.nav-pills.nav-pills-toolbar > .open > a {
	border: 1px solid #ddd;
}

.nav-pills.nav-pills-bordered > .active > a,
.nav-pills.nav-pills-toolbar > .active > a,
.nav-pills.nav-pills-bordered > .active > a:hover,
.nav-pills.nav-pills-toolbar > .active > a:hover,
.nav-pills.nav-pills-bordered > .active > a:focus,
.nav-pills.nav-pills-toolbar > .active > a:focus {
	border-color: #26A69A;
}

@media (min-width: 769px) {
	.nav-pills.nav-pills-toolbar > li > a {
		border: 1px solid #ddd;
		border-radius: 0;
	}

	.nav-pills.nav-pills-toolbar > li:first-child > a {
		border-radius: 3px 0 0 3px;
	}

	.nav-pills.nav-pills-toolbar > li:last-child > a {
		border-radius: 0 3px 3px 0;
	}

	.nav-pills.nav-pills-toolbar > li + li > a {
		margin-top: 0;
		margin-left: 0;
		border-left: 0;
	}
}

@media (min-width: 769px) {
	.nav-pills {
		font-size: 0;
	}

	.nav-pills > li {
		display: inline-block;
		font-size: 13px;
	}

	.nav-pills > li + li > a {
		margin-top: 0;
		margin-left: 6px;
	}

	.nav-pills.nav-justified > li {
		display: table-cell;
	}
}

@media (max-width: 768px) {
	.nav-pills > li > a .position-right[class*=icon-] {
		float: right;
		margin-top: 2px;
	}

	.nav-pills > li > a .position-right.label,
	.nav-pills > li > a .position-right.badge {
		float: right;
	}
}

.nav-stacked > li {
	display: block;
}

.nav-stacked > li > a .pull-right[class*=icon-] {
	float: right;
	margin-top: 2px;
}

.nav-stacked > li > a .pull-right.label,
.nav-stacked > li > a .pull-right.badge {
	float: right;
}

.nav-stacked > li + li > a {
	margin-left: 0;
}

.nav-justified > li > a {
	margin-bottom: 0;
}

.nav-tabs-justified > li > a {
	border-radius: 0;
	margin-bottom: 0;
}

.nav-tabs-justified > li > a:hover,
.nav-tabs-justified > li > a:focus {
	border-bottom-color: #ddd;
}

@media (min-width: 769px) {
	.nav-tabs-justified.nav-tabs-top {
		border-bottom: 1px solid #ddd;
	}

	.nav-tabs-justified.nav-tabs-top > li > a,
	.nav-tabs-justified.nav-tabs-top > li > a:hover,
	.nav-tabs-justified.nav-tabs-top > li > a:focus {
		border: 0;
	}

	.nav-tabs-justified.nav-tabs-bottom {
		border-bottom: 1px solid #ddd;
	}

	.nav-tabs-justified.nav-tabs-highlight > li > a,
	.nav-tabs-justified.nav-tabs-highlight > li > a:hover,
	.nav-tabs-justified.nav-tabs-highlight > li > a:focus {
		border-top-width: 2px;
	}
}

@media (max-width: 768px) {
	.nav-tabs-justified > li.active > a,
	.nav-tabs-justified > li.active > a:hover,
	.nav-tabs-justified > li.active > a:focus {
		border-width: 0 0 0 2px;
		border-left-color: #EC407A;
	}
}

@media (min-width: 769px) {
	.nav-tabs.nav-tabs-component > li > a {
		border-radius: 3px 3px 0 0;
	}

	.nav-tabs.nav-tabs-component.nav-tabs-solid,
	.nav-tabs.nav-tabs-component[class*=bg-] {
		border-radius: 3px;
	}

	.nav-tabs.nav-tabs-component.nav-tabs-solid > li > a,
	.nav-tabs.nav-tabs-component[class*=bg-] > li > a {
		border-radius: 0;
	}

	.nav-tabs.nav-tabs-component.nav-tabs-solid > li:first-child > a,
	.nav-tabs.nav-tabs-component[class*=bg-] > li:first-child > a {
		border-radius: 3px 0 0 3px;
	}

	.nav-tabs-component.nav-justified.nav-tabs-solid > li:last-child > a,
	.nav-tabs-component.nav-justified[class*=bg-] > li:last-child > a {
		border-radius: 0 3px 3px 0;
	}
}

.tab-content-bordered .tab-content > .has-padding {
	padding: 20px;
}

.panel-flat > .panel-heading + .tab-content > .has-padding {
	padding-top: 0;
}

@media (min-width: 769px) {
	.tab-content > .has-padding {
		padding: 20px;
	}
}

.tab-content-bordered .tab-content {
	border-radius: 3px;
	border: 1px solid transparent;
}

.tab-content-bordered .tab-content:not([class*=bg-]) {
	border-color: #ddd;
	background-color: #fff;
}

@media (min-width: 769px) {
	.tab-content-bordered .tab-content {
		border-top-width: 0;
		border-radius: 0 0 3px 3px;
	}
}

@media (min-width: 769px) {
	.tab-content-bordered .nav-tabs {
		margin-bottom: 0;
	}

	.tab-content-bordered .nav-tabs.nav-tabs-solid {
		border-radius: 3px 3px 0 0;
		-webkit-box-shadow: 0 0 0 1px #ddd inset;
		box-shadow: 0 0 0 1px #ddd inset;
	}

	.tab-content-bordered .nav-tabs.nav-tabs-solid > li:first-child > a {
		border-radius: 3px 0 0 0;
	}

	.tab-content-bordered .nav-tabs.nav-tabs-solid.nav-justified > li:last-child > a {
		border-radius: 0 3px 0 0;
	}

	.tab-content-bordered > .nav-tabs[class*=bg-] {
		border-top-right-radius: 3px;
		border-top-left-radius: 3px;
	}

	.tab-content-bordered > .nav-tabs[class*=bg-] + .tab-content[class*=bg-] {
		border-top-width: 1px;
		border-top-color: rgba(255, 255, 255, 0.5);
	}
}

@media (min-width: 769px) {
	.nav-tabs[class*=bg-] .dropdown-menu,
	.nav-tabs-top .dropdown-menu {
		margin-top: 0;
	}

	.nav-justified.bottom-divided .dropdown-menu {
		margin-top: 1px;
	}
}

@media (max-width: 768px) {
	.nav-tabs .dropdown-menu,
	.nav-pills .dropdown-menu,
	.nav-tabs.nav-justified > .dropdown .dropdown-menu,
	.nav-pills.nav-justified > .dropdown .dropdown-menu {
		left: -1px;
		right: -1px;
		margin-top: 2px;
	}

	.nav-tabs.nav-justified > li > a,
	.nav-pills.nav-justified > li > a,
	.nav-tabs.text-center > li > a,
	.nav-pills.text-center > li > a,
	.nav-tabs.text-right > li > a,
	.nav-pills.text-right > li > a {
		text-align: left;
	}
}

/* ------------------------------------------------------------------------------
*
*  # Navbar component
*
*  Overrides for navbar bootstrap component
*
*  Version: 1.3
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.navbar {
	margin-bottom: 0;
	border-width: 0;
	padding-left: 0;
	padding-right: 0;
	z-index: 1000;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

@media (min-width: 769px) {
	.navbar {
		padding-left: 20px;
		/*padding-right: 20px;*/
	}
}

.navbar-component {
	border-radius: 3px;
	margin-bottom: 20px;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

@media (min-width: 769px) {
	.navbar.navbar-component,
	.navbar-component > .navbar {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.navbar-component > .navbar {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.page-header .navbar-component {
	margin-left: 20px;
	margin-right: 20px;
}

.panel .navbar-component.navbar-default {
	border-width: 1px;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.panel .navbar-component.navbar-inverse {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.navbar-component > .navbar:first-child,
.navbar-component > .navbar-collapse:first-child > .navbar:first-child {
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
}

.navbar-component > .navbar:last-child,
.navbar-component > .navbar-collapse:last-child > .navbar:last-child {
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
}

.affix.navbar,
.affix.navbar-collapse {
	z-index: 1030;
	top: 0;
	width: 100%;
}

.navbar-header {
	min-width: 260px;
}

.navbar-collapse > .navbar-header {
	margin-left: 0;
}

.navbar-header .navbar-nav {
	float: right;
	margin-right: 5px;
}

.navbar-header .navbar-nav > li {
	float: left;
}

.navbar-header .navbar-nav > li > a {
	padding-left: 15px;
	padding-right: 15px;
}

@media (max-width: 768px) {
	.navbar-header .navbar-nav > li + li {
		margin-top: 0;
	}

	.navbar-collapse > .navbar-header {
		margin-left: 0;
	}
}

@media (min-width: 769px) {
	.navbar-header {
		margin-left: -20px;
	}
}

.navbar-collapse {
	text-align: center;
	padding-left: 0;
	padding-right: 0;
}

.navbar-collapse.collapsing {
	-webkit-transition-duration: 0.00000001s;
	transition-duration: 0.00000001s;
}

.navbar + .navbar-collapse {
	border-top: 0;
}

@media (min-width: 769px) {
	.navbar-collapse {
		text-align: left;
		margin-left: -20px;
	}
}

.navbar-fixed-bottom,
.navbar-fixed-bottom > .navbar {
	-webkit-box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.24);
	box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.24);
}

@media (max-width: 768px) {
	.navbar-fixed-top {
		position: static;
	}
}

@media (min-width: 769px) {
	.navbar-top-lg {
		padding-top: 54px;
	}

	.navbar-top-lg .sidebar-fixed.affix {
		top: 74px;
	}

	.navbar-top {
		padding-top: 50px;
	}

	.navbar-top .sidebar-fixed.affix {
		top: 70px;
	}

	.navbar-top-sm {
		padding-top: 48px;
	}

	.navbar-top-sm .sidebar-fixed.affix {
		top: 68px;
	}

	.navbar-top-xs {
		padding-top: 46px;
	}

	.navbar-top-xs .sidebar-fixed.affix {
		top: 66px;
	}

	.navbar-top-lg-lg {
		padding-top: 108px;
	}

	.navbar-top-lg-md,
	.navbar-top-md-lg {
		padding-top: 104px;
	}

	.navbar-top-lg-sm,
	.navbar-top-md-md,
	.navbar-top-sm-lg {
		padding-top: 100px;
	}

	.navbar-top-lg-xs,
	.navbar-top-md-sm,
	.navbar-top-sm-md,
	.navbar-top-xs-lg {
		padding-top: 98px;
	}

	.navbar-top-md-xs,
	.navbar-top-sm-sm,
	.navbar-top-xs-md {
		padding-top: 96px;
	}

	.navbar-top-sm-xs,
	.navbar-top-xs-sm {
		padding-top: 94px;
	}

	.navbar-top-xs-xs {
		padding-top: 92px;
	}
}

.navbar-bottom-lg {
	padding-bottom: 54px;
}

.navbar-bottom {
	padding-bottom: 50px;
}

.navbar-bottom-sm {
	padding-bottom: 48px;
}

.navbar-bottom-xs {
	padding-bottom: 46px;
}

.navbar-bottom-lg-lg {
	padding-bottom: 108px;
}

.navbar-bottom-lg-md,
.navbar-bottom-md-lg {
	padding-bottom: 104px;
}

.navbar-bottom-lg-sm,
.navbar-bottom-md-md,
.navbar-bottom-sm-lg {
	padding-bottom: 100px;
}

.navbar-bottom-lg-xs,
.navbar-bottom-md-sm,
.navbar-bottom-sm-md,
.navbar-bottom-xs-lg {
	padding-bottom: 98px;
}

.navbar-bottom-md-xs,
.navbar-bottom-sm-sm,
.navbar-bottom-xs-md {
	padding-bottom: 96px;
}

.navbar-bottom-sm-xs,
.navbar-bottom-xs-sm {
	padding-bottom: 94px;
}

.navbar-bottom-xs-xs {
	padding-bottom: 92px;
}

.navbar-brand > img {
	margin-top: 2px;
	height: 16px;
}

.navbar-nav {
	margin: 0;
	text-align: left;
}

.navbar-nav > li > a {
	padding-top: 15px;
	padding-bottom: 15px;
}

.navbar-nav > li > a:after {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	background-color: transparent;
	width: 2px;
}

@media (min-width: 769px) {
	.navbar-nav > li > a:after {
		top: auto;
		right: 0;
		width: auto;
		height: 2px;
	}
}

.navbar-nav > li > a > .label,
.navbar-nav > li > a > .badge {
	position: absolute;
	top: 0;
	right: 0;
}

.navbar-nav > li > a > .status-mark {
	position: absolute;
	top: 8px;
	right: 8px;
}

.navbar-nav > li > a > .status-mark-left {
	right: auto;
	left: 8px;
}

.navbar-nav > li > a > .label-left,
.navbar-nav > li > a > .badge-left {
	right: auto;
	left: 0;
}

.navbar-nav > li > a > .label-inline,
.navbar-nav > li > a > .badge-inline,
.navbar-nav > li > a > .status-mark-inline {
	position: static;
}

.navbar-nav .language-switch > a > img {
	position: relative;
	top: -1px;
}

.navbar-nav .language-switch .dropdown-menu > li > a > img {
	margin-top: 4.5px;
}

.navbar-nav > .dropdown-user > a,
.navbar-nav > .dropdown-user > a > span {
	padding-top: 7.5px;
	padding-bottom: 7.5px;
}

.navbar-nav > .dropdown-user > a > span {
	display: inline-block;
}

.navbar-nav > .dropdown-user > a > img + span {
	padding-left: 8px;
}

.navbar-nav > .dropdown-user img {
	max-height: 34px;
	margin-top: -3.5px;
	border-radius: 50%;
}

.navbar-lg .navbar-nav > .dropdown-user img {
	max-height: 38px;
	margin-top: -4.5px;
}

.navbar-sm .navbar-nav > .dropdown-user img {
	max-height: 32px;
	margin-top: -3px;
}

.navbar-xs .navbar-nav > .dropdown-user img {
	max-height: 30px;
	margin-top: -2.5px;
}

@media (max-width: 768px) {
	.navbar-collapse .navbar-nav {
		padding-top: 8px;
		padding-bottom: 8px;
	}

	.navbar-nav .open .dropdown-menu > li > a,
	.navbar-nav .open .dropdown-menu .dropdown-header {
		padding: 9px 20px;
	}

	.navbar-nav .open .dropdown-menu > .dropdown-submenu > ul > li > a {
		padding-left: 40px;
	}

	.navbar-nav .open .dropdown-menu > .dropdown-submenu > ul > li > ul > li > a {
		padding-left: 60px;
	}

	.navbar-nav > li + li {
		margin-top: 1px;
	}

	.navbar-nav > li > a {
		padding-left: 20px;
		padding-right: 20px;
	}

	.navbar-nav > li > a .caret {
		float: right;
		margin-top: 2px;
		margin-left: 5px;
	}

	.navbar-nav > li > a .label,
	.navbar-nav > li > a .badge {
		position: static;
		float: right;
	}

	.navbar-nav > li > a .status-mark {
		position: static;
		float: right;
		margin-top: 6px;
	}

	.navbar-nav > .dropdown-user .caret {
		margin-top: 8px;
	}

	.navbar-nav:last-child {
		border-bottom: 0;
	}
}

@media (min-width: 769px) {
	.navbar-nav {
		margin-left: 20px;
	}
}

.navbar-form {
	padding: 15px 20px;
	margin-left: 0;
	margin-right: 0;
	border-top: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.navbar-form:last-child {
	border-bottom: 0;
}

@media (max-width: 768px) {
	.navbar-form .form-group {
		margin-bottom: 10px;
	}
}

.navbar-form .input-sm,
.navbar-form .input-group-sm,
.navbar-form .btn-sm,
.navbar-form .select-sm,
.navbar-form .uploader-sm {
	margin-top: 1px;
	margin-bottom: 1px;
}

.navbar-form .input-sm + .form-control-feedback {
	top: 1px;
}

.navbar-form .input-xs,
.navbar-form .input-group-xs,
.navbar-form .btn-xs,
.navbar-form .select-xs,
.navbar-form .uploader-xs {
	margin-top: 2px;
	margin-bottom: 2px;
}

.navbar-form .input-xs + .form-control-feedback {
	top: 2px;
}

.navbar-lg .navbar-form {
	margin-top: 8px;
	margin-bottom: 8px;
}

.navbar-sm .navbar-form {
	margin-top: 5px;
	margin-bottom: 5px;
}

.navbar-xs .navbar-form {
	margin-top: 4px;
	margin-bottom: 4px;
}

.navbar-form .checkbox-switchery[class*=switchery-] {
	margin-bottom: 0;
}

.navbar-form .checkbox-inline.switchery-double {
	padding-left: 0;
}

@media (min-width: 769px) {
	.navbar-form {
		padding: 0;
	}

	.navbar-form .form-control {
		min-width: 200px;
	}

	.navbar-form .form-group {
		margin-left: 20px;
	}

	.navbar-form .checkbox-inline,
	.navbar-form .radio-inline {
		margin-top: 9px;
		margin-bottom: 9px;
	}
}

@media (max-width: 768px) {
	.navbar-form {
		margin: 0;
	}
}

.navbar-nav > li > .dropdown-menu {
	border-top-width: 0;
}

.navbar-nav > li > .dropdown-menu .media-list {
	max-height: 340px;
	overflow-y: auto;
}

.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
	border-top-width: 1px;
	border-bottom-width: 0;
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
}

.navbar-btn {
	margin-left: 20px;
}

.navbar-btn + .navbar-btn {
	margin-left: 0;
}

.navbar-btn,
.navbar-sm .navbar-btn.btn-sm,
.navbar-xs .navbar-btn.btn-xs {
	margin-top: 6px;
	margin-bottom: 6px;
}

.navbar-lg .navbar-btn {
	margin-top: 8px;
	margin-bottom: 8px;
}

.navbar-sm .navbar-btn,
.navbar-xs .navbar-btn.btn-sm {
	margin-top: 5px;
	margin-bottom: 5px;
}

.navbar-xs .navbar-btn {
	margin-top: 4px;
	margin-bottom: 4px;
}

.navbar-btn.btn-sm,
.navbar-sm .navbar-btn.btn-xs {
	margin-top: 7px;
	margin-bottom: 7px;
}

.navbar-btn.btn-xs,
.navbar-lg .navbar-btn.btn-sm {
	margin-top: 8px;
	margin-bottom: 8px;
}

.navbar-lg .navbar-btn.btn-xs {
	margin-top: 10px;
	margin-bottom: 10px;
}

@media (max-width: 768px) {
	.navbar-btn,
	.navbar-btn.btn-sm,
	.navbar-btn.btn-xs {
		margin: 15px 20px;
	}

	.navbar-btn + .navbar-btn {
		margin-left: 0;
	}
}

.navbar-text {
	margin: 0;
	padding: 15px 20px;
}

.navbar-text:last-child {
	border-bottom: 0;
}

.navbar-lg .navbar-text {
	padding-top: 17px;
	padding-bottom: 17px;
}

.navbar-sm .navbar-text {
	padding-top: 14px;
	padding-bottom: 14px;
}

.navbar-xs .navbar-text {
	padding-top: 13px;
	padding-bottom: 13px;
}

@media (min-width: 769px) {
	.navbar-text {
		padding-right: 0;
	}

	.navbar-text + .navbar-nav {
		margin-left: 15px;
	}

	.navbar-header + .navbar-text:first-child {
		padding-left: 0;
	}
}

.navbar-default .navbar-nav > .active > a:after {
	background-color: #7986CB;
}

.navbar-default .navbar-nav > li > .dropdown-menu {
	border-top-width: 1px;
	border-top-color: #ddd;
}

@media (max-width: 768px) {
	.navbar-default .navbar-nav .open .dropdown-menu {
		color: #333333;
		background-color: transparent;
		border-bottom: 1px solid #ddd;
	}

	.navbar-default .navbar-nav .open > .dropdown-menu {
		border-top: 1px solid #ddd;
	}

	.navbar-default .navbar-nav .open:last-child .dropdown-menu {
		border-bottom: 0;
	}
}

.navbar-default .navbar-link {
	color: #1E88E5;
}

.navbar-default .navbar-link:hover {
	color: #166dba;
}

@media (max-width: 768px) {
	.navbar-default .navbar-collapse .navbar-nav {
		border-bottom: 1px solid #ddd;
	}

	.navbar-default .dropdown-menu[class*=bg-] .label,
	.navbar-default .dropdown-menu[class*=bg-] .badge {
		color: #fff;
		background-color: #2196F3;
		border-color: transparent;
	}

	.navbar-default .dropdown-menu[class*=bg-] > .divider {
		background-color: #e5e5e5;
	}

	.navbar-default .dropdown-menu[class*=bg-] .dropdown-submenu:hover > a,
	.navbar-default .dropdown-menu[class*=bg-] .dropdown-submenu:focus > a {
		background-color: #f5f5f5;
	}

	.navbar-default .dropdown-menu .table-responsive {
		border-width: 0;
	}

	.navbar-default .dropdown-menu .dropdown-content-heading + .table-responsive {
		border-top-width: 1px;
	}

	.navbar-default .navbar-text:not([data-toggle="collapse"]) {
		border-bottom: 1px solid #ddd;
	}

	.navbar-default > .navbar-nav > li > a:not(.collapsed),
	.navbar-default > .navbar-nav > li > a:hover,
	.navbar-default > .navbar-nav > li > a:focus {
		background-color: #fcfcfc;
	}
}

.navbar-inverse {
	color: rgba(255, 255, 255, 0.9);
}

.navbar-inverse .navbar-form .form-control,
.navbar-inverse .navbar-form .form-control-feedback,
.navbar-inverse .navbar-form .input-group-addon {
	color: #fff;
}

.navbar-inverse .navbar-form .form-control {
	border-bottom-color: rgba(255, 255, 255, 0.3);
}

.navbar-inverse .navbar-form .form-control::-moz-placeholder {
	color: #fff;
	opacity: 1;
}

.navbar-inverse .navbar-form .form-control:-ms-input-placeholder {
	color: #fff;
}

.navbar-inverse .navbar-form .form-control::-webkit-input-placeholder {
	color: #fff;
}

.navbar-inverse .navbar-form .form-control:focus {
	border-bottom-color: #fff;
	-webkit-box-shadow: 0 1px 0 #fff;
	box-shadow: 0 1px 0 #fff;
}

.navbar-inverse .navbar-nav > li > a {
	color: rgba(255, 255, 255, 0.9);
}

.navbar-inverse .navbar-nav > .active > a:after {
	background-color: #fff;
}

@media (max-width: 768px) {
	.navbar-inverse .navbar-nav .open .dropdown-menu {
		color: rgba(255, 255, 255, 0.9);
		background-color: rgba(0, 0, 0, 0.1);
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	}

	.navbar-inverse .navbar-nav .open .dropdown-menu .text-muted,
	.navbar-inverse .navbar-nav .open .dropdown-menu .media-annotation {
		color: rgba(255, 255, 255, 0.8);
	}

	.navbar-inverse .navbar-nav .open .dropdown-menu .media-list-linked > li {
		border-top-color: rgba(255, 255, 255, 0.1);
	}

	.navbar-inverse .navbar-nav .open .dropdown-menu .media-list-linked .media-link {
		color: rgba(255, 255, 255, 0.9);
	}

	.navbar-inverse .navbar-nav .open .dropdown-menu .media-list-linked .media-link:hover,
	.navbar-inverse .navbar-nav .open .dropdown-menu .media-list-linked .media-link:focus {
		background-color: rgba(0, 0, 0, 0.1);
		color: rgba(255, 255, 255, 0.9);
	}

	.navbar-inverse .navbar-nav .open .dropdown-menu .checker span {
		border-color: #fff;
		color: #fff;
	}

	.navbar-inverse .navbar-nav .open .dropdown-menu .checker span.checked {
		border-color: transparent;
	}

	.navbar-inverse .navbar-nav .open .dropdown-menu .choice span {
		border-color: #fff;
	}

	.navbar-inverse .navbar-nav .open .dropdown-menu .form-control,
	.navbar-inverse .navbar-nav .open .dropdown-menu .form-control-feedback,
	.navbar-inverse .navbar-nav .open .dropdown-menu .input-group-addon,
	.navbar-inverse .navbar-nav .open .dropdown-menu .select2-selection__placeholder {
		color: #fff;
	}

	.navbar-inverse .navbar-nav .open .dropdown-menu .form-control {
		border-bottom-color: rgba(255, 255, 255, 0.3);
	}

	.navbar-inverse .navbar-nav .open .dropdown-menu .form-control::-moz-placeholder {
		color: #fff;
		opacity: 1;
	}

	.navbar-inverse .navbar-nav .open .dropdown-menu .form-control:-ms-input-placeholder {
		color: #fff;
	}

	.navbar-inverse .navbar-nav .open .dropdown-menu .form-control::-webkit-input-placeholder {
		color: #fff;
	}

	.navbar-inverse .navbar-nav .open .dropdown-menu .form-control:focus {
		border-bottom-color: #fff;
		-webkit-box-shadow: 0 1px 0 #fff;
		box-shadow: 0 1px 0 #fff;
	}

	.navbar-inverse .navbar-nav .open .dropdown-menu .select2-selection--single:not([class*=bg-]):not([class*=border-]) {
		border-bottom-color: rgba(255, 255, 255, 0.3);
	}

	.navbar-inverse .navbar-nav .open .dropdown-menu .select2-container--focus .select2-selection--single:not([class*=bg-]):not([class*=border-]),
	.navbar-inverse .navbar-nav .open .dropdown-menu .select2-container--open .select2-selection--single:not([class*=bg-]):not([class*=border-]) {
		border-bottom-color: #fff;
		-webkit-box-shadow: 0 1px 0 #fff;
		box-shadow: 0 1px 0 #fff;
	}

	.navbar-inverse .navbar-nav .open .dropdown-menu .divider {
		background-color: rgba(255, 255, 255, 0.1);
	}

	.navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-header {
		color: rgba(255, 255, 255, 0.6);
	}

	.navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-submenu:hover > a,
	.navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-submenu:focus > a {
		color: #fff;
		background-color: transparent;
	}

	.navbar-inverse .navbar-nav .open > .dropdown-menu {
		border-top: 1px solid rgba(255, 255, 255, 0.1);
	}

	.navbar-inverse .navbar-nav .open:last-child .dropdown-menu {
		border-bottom: 0;
	}

	.navbar-inverse .navbar-nav .label,
	.navbar-inverse .navbar-nav .badge,
	.navbar-inverse .navbar-nav .label:hover,
	.navbar-inverse .navbar-nav .badge:hover,
	.navbar-inverse .navbar-nav .label:focus,
	.navbar-inverse .navbar-nav .badge:focus {
		background-color: #fff;
		border-color: #fff;
		color: #333333;
	}

	.navbar-inverse .navbar-nav .nav-tabs {
		background-color: transparent;
		border: 0;
	}

	.navbar-inverse .navbar-nav .nav-tabs > li > a {
		background-color: transparent;
	}
}

@media (max-width: 768px) {
	.navbar-inverse .navbar-collapse {
		background-color: rgba(0, 0, 0, 0.05);
	}

	.navbar-inverse .navbar-collapse,
	.navbar-inverse .navbar-collapse .navbar-nav {
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	}

	.navbar-inverse .navbar-text {
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	}

	.navbar-inverse > .navbar-nav > li > a:not(.collapsed) {
		background-color: rgba(0, 0, 0, 0.1);
	}

	.navbar-inverse .dropdown-menu .table-responsive {
		border-width: 0 0 1px 0;
		border-color: rgba(255, 255, 255, 0.1);
	}

	.navbar-inverse .dropdown-menu .dropdown-content-heading + .table-responsive {
		border-top-width: 1px;
	}

	.navbar-inverse .dropdown-menu .table th,
	.navbar-inverse .dropdown-menu .table td {
		border-color: rgba(255, 255, 255, 0.1);
	}

	.navbar-inverse .label-flat,
	.navbar-inverse .badge-flat {
		border-color: #fff;
		color: #fff;
	}

	.navbar-inverse .btn-flat,
	.navbar-inverse .btn-flat:hover,
	.navbar-inverse .btn-flat:focus {
		border-color: #fff;
		color: #fff !important;
	}
}

.navbar-nav-material > li > a {
	text-transform: uppercase;
	font-size: 12px;
	position: relative;
	font-weight: 500;
}

.navbar-lg {
	min-height: 54px;
}

.navbar-lg .navbar-brand {
	height: 54px;
	padding-top: 17px;
	padding-bottom: 17px;
}

.navbar-lg .navbar-nav > li > a {
	padding-top: 17px;
	padding-bottom: 17px;
}

.navbar-lg .navbar-nav > .dropdown-user > a,
.navbar-lg .navbar-nav > .dropdown-user > a > span {
	padding-top: 8.5px;
	padding-bottom: 8.5px;
}

.navbar-sm {
	min-height: 48px;
}

.navbar-sm .navbar-brand {
	height: 48px;
	padding-top: 14px;
	padding-bottom: 14px;
}

.navbar-sm .navbar-nav > li > a {
	padding-top: 14px;
	padding-bottom: 14px;
}

.navbar-sm .navbar-nav > .dropdown-user > a,
.navbar-sm .navbar-nav > .dropdown-user > a > span {
	padding-top: 7px;
	padding-bottom: 7px;
}

.navbar-xs {
	min-height: 46px;
}

.navbar-xs .navbar-brand {
	height: 46px;
	padding-top: 13px;
	padding-bottom: 13px;
}

.navbar-xs .navbar-nav > li > a {
	padding-top: 13px;
	padding-bottom: 13px;
}

.navbar-xs .navbar-nav > .dropdown-user > a,
.navbar-xs .navbar-nav > .dropdown-user > a > span {
	padding-top: 6.5px;
	padding-bottom: 6.5px;
}

.nav .mega-menu {
	position: static;
}

.mega-menu .dropdown-menu {
	left: auto;
}

@media (min-width: 769px) {
	.mega-menu.mega-menu-wide > .dropdown-menu {
		left: 20px;
		right: 20px;
	}

	.navbar-component .mega-menu.mega-menu-wide > .dropdown-menu,
	.layout-boxed .mega-menu.mega-menu-wide > .dropdown-menu {
		left: 0;
		right: 0;
	}
}

.dropdown-content-heading {
	padding: 20px;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 500;
}

.dropdown-content-heading + .dropdown-menu-body {
	padding-top: 0;
}

.dropdown-content-heading + .dropdown-header {
	padding-top: 0 !important;
}

ul.dropdown-menu .dropdown-content-heading {
	padding-left: 15px;
	padding-right: 15px;
}

ul.dropdown-menu .dropdown-content-heading:first-child {
	padding-top: 12px;
	padding-bottom: 12px;
}

.dropdown-content-heading .icons-list {
	float: right;
}

@media (min-width: 769px) {
	.dropdown-content-heading + .table-responsive {
		border-top: 1px solid transparent;
	}
}

.dropdown-content-footer {
	color: #333333;
	border-top: 1px solid transparent;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
}

.dropdown-content-footer a {
	display: block;
	padding: 10px 20px;
	text-align: center;
	color: #333333;
}

.dropdown-content-footer a:hover,
.dropdown-content-footer a:focus {
	background-color: #f5f5f5;
}

.dropdown-content-footer a > i.display-block {
	top: 0;
}

@media (max-width: 768px) {
	.navbar-inverse .dropdown-content-footer {
		background-color: rgba(0, 0, 0, 0.1);
		color: #fff;
		border-color: transparent;
		border-radius: 0;
	}

	.navbar-inverse .dropdown-content-footer a {
		color: #fff;
	}

	.navbar-inverse .dropdown-content-footer a:hover,
	.navbar-inverse .dropdown-content-footer a:focus {
		background-color: rgba(0, 0, 0, 0.1);
	}
}

.dropdown-content-body {
	padding: 20px;
}

.dropdown-content-heading + .dropdown-content-body {
	padding-top: 0;
}

.dropdown-content:not(ul) {
	padding-top: 0;
	padding-bottom: 0;
}

.dropdown-content .form-inline {
	white-space: nowrap;
}

.menu-list {
	margin: 0 0 20px 0;
	list-style: none;
	padding: 0;
	overflow: hidden;
}

@media (min-width: 1200px) {
	[class*=col-lg-] .menu-list {
		margin-bottom: 9px;
	}
}

@media (min-width: 1025px) {
	[class*=col-md-] .menu-list {
		margin-bottom: 9px;
	}
}

@media (min-width: 769px) {
	[class*=col-sm-] .menu-list {
		margin-bottom: 9px;
	}
}

@media (min-width: 480px) {
	[class*=col-xs-] .menu-list {
		margin-bottom: 9px;
	}
}

.menu-list ul {
	margin: 0;
	padding: 0;
	list-style: none;
	position: absolute;
	display: none;
	left: 110%;
	width: 100%;
}

.menu-list li {
	position: relative;
	margin-top: 1px;
}

.menu-list li:first-child {
	margin-top: 0;
}

.menu-list li > a {
	display: block;
	color: #333333;
	padding: 8px 16px;
	border-radius: 3px;
}

.menu-list li > a:hover,
.menu-list li > a:focus {
	background-color: #f5f5f5;
}

.menu-list li > a > i {
	margin-right: 10px;
}

.menu-list li > a > .label,
.menu-list li > a > .badge {
	float: right;
	margin-left: 8px;
}

.menu-list li.active > a,
.menu-list li.active > a:hover,
.menu-list li.active > a:focus {
	color: #fff;
	background-color: #26A69A;
}

.menu-list li.disabled > a,
.menu-list li.disabled > a:hover,
.menu-list li.disabled > a:focus {
	background-color: transparent;
	color: #999999;
	cursor: not-allowed;
}

.menu-list li.disabled > a > .label,
.menu-list li.disabled > a > .badge,
.menu-list li.disabled > a > img {
	opacity: 0.8;
	filter: alpha(opacity=80);
}

@media (max-width: 768px) {
	.menu-list li > a {
		color: #fff;
	}

	.menu-list li > a:hover,
	.menu-list li > a:focus {
		background-color: rgba(0, 0, 0, 0.1);
	}

	.menu-list li.active > a,
	.menu-list li.active > a:hover,
	.menu-list li.active > a:focus {
		background-color: rgba(0, 0, 0, 0.1);
	}

	.menu-list li.disabled > a,
	.menu-list li.disabled > a:hover,
	.menu-list li.disabled > a:focus {
		background-color: transparent;
		color: rgba(255, 255, 255, 0.6);
		cursor: not-allowed;
	}

	.navbar-default .menu-list li > a {
		color: #333333;
	}

	.navbar-default .menu-list li > a:hover,
	.navbar-default .menu-list li > a:focus {
		background-color: #f5f5f5;
	}

	.navbar-default .menu-list li.active > a,
	.navbar-default .menu-list li.active > a:hover,
	.navbar-default .menu-list li.active > a:focus {
		color: #fff;
		background-color: #26A69A;
	}

	.navbar-default .menu-list li.disabled > a,
	.navbar-default .menu-list li.disabled > a:hover,
	.navbar-default .menu-list li.disabled > a:focus {
		background-color: transparent;
		color: #999999;
	}
}

@media (min-width: 769px) {
	.menu-list {
		overflow-y: auto;
		max-height: 340px;
	}
}

.dd-wrapper .dd-menu {
	overflow: hidden;
	position: relative;
}

.dd-wrapper .dd-header h6 .label,
.dd-wrapper .dd-header h6 .badge {
	margin-left: 8px;
}

.dd-wrapper .dd-header h6 > i {
	margin-right: 8px;
}

.dd-wrapper .dd-header h6:first-child {
	display: none;
}

.dd-wrapper .dd-parent .active-ul + ul {
	position: relative;
	display: block;
	left: 0;
}

.dd-wrapper .link-back {
	display: block;
	padding: 9px 0;
	border-radius: 3px;
	margin-bottom: 10px;
	font-size: 12px;
	text-transform: uppercase;
	line-height: 1.6666667;
}

.dd-wrapper .link-back:before {
	content: '\ede7';
	font-family: 'icomoon';
	font-size: 16px;
	line-height: 1;
	position: relative;
	top: -1px;
	vertical-align: middle;
	margin-right: 8px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.dd-wrapper .link-back .dd-icon,
.dd-wrapper .link-back i {
	display: none;
}

@media (max-width: 768px) {
	.navbar .dd-wrapper .link-back {
		padding: 20px 0;
		text-align: center;
		margin-top: -10px;
		margin-bottom: 20px;
		border-bottom: 1px solid #eeeeee;
	}

	.navbar-inverse .dd-wrapper .link-back {
		color: #fff;
		border-bottom-color: rgba(255, 255, 255, 0.1);
	}
}

.dd-wrapper .dd-icon {
	float: right;
	margin-top: 2px;
}

.dd-wrapper .dd-icon:after {
	content: '\e9c7';
	font-family: 'icomoon';
	font-size: 16px;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.dd-wrapper .dd-header .dd-icon:after {
	content: '\e9c5';
}

.menu-heading {
	display: block;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 500;
	margin-bottom: 10px;
	padding-top: 4px;
}

.menu-heading > i {
	float: left;
	margin-right: 8px;
}

.menu-heading.underlined {
	padding-bottom: 10px;
	border-bottom: 1px solid #eeeeee;
}

@media (max-width: 768px) {
	.menu-heading {
		color: #fff;
	}

	div:first-child > .menu-heading {
		margin-top: 0;
	}

	.navbar-default .menu-heading {
		color: #333333;
	}

	.menu-heading.underlined {
		border-bottom-color: rgba(255, 255, 255, 0.1);
	}

	.navbar-default .menu-heading.underlined {
		border-bottom-color: #eeeeee;
	}
}

.dropdown-menu .nav-tabs,
.dropdown-menu .nav-tabs > li > a {
	border-radius: 0;
}

.dropdown-menu .nav-tabs > li:first-child > a,
.dropdown-menu .nav-tabs > li.active:first-child > a {
	border-left: 0;
}

.dropdown-menu .nav-tabs > li:last-child > a,
.dropdown-menu .nav-tabs > li.active:last-child > a {
	border-right: 0;
}

@media (max-width: 768px) {
	.dropdown-menu .nav-tabs {
		margin-top: 0;
	}

	.dropdown-menu .nav-tabs:before {
		content: none;
	}

	.dropdown-menu .nav-tabs > li + li {
		margin-top: 1px;
	}

	.dropdown-menu .nav-tabs > li > a {
		border: 0;
		padding-left: 20px;
		padding-right: 20px;
	}

	.dropdown-menu .nav-tabs > li > a:hover,
	.dropdown-menu .nav-tabs > li > a:focus {
		background-color: rgba(0, 0, 0, 0.1);
	}

	.dropdown-menu .nav-tabs > li.active > a,
	.dropdown-menu .nav-tabs > li.active > a:hover,
	.dropdown-menu .nav-tabs > li.active > a:focus {
		border: 0;
		background-color: rgba(0, 0, 0, 0.1);
	}

	.navbar .dropdown-menu .nav-tabs {
		border-top: 1px solid rgba(255, 255, 255, 0.1);
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	}

	.navbar .dropdown-menu .nav-tabs > li {
		margin-bottom: 0;
	}

	.navbar .dropdown-menu .nav-tabs.active > a {
		border-color: transparent;
	}

	.navbar-inverse .dropdown-menu .nav-tabs > li > a {
		color: #fff;
	}

	.navbar-inverse .dropdown-menu .nav-tabs > li.disabled > a,
	.navbar-inverse .dropdown-menu .nav-tabs > li.disabled > a:hover,
	.navbar-inverse .dropdown-menu .nav-tabs > li.disabled > a:focus {
		color: rgba(255, 255, 255, 0.6);
		background-color: transparent;
	}

	.navbar-default .dropdown-menu .nav-tabs {
		border-top-color: #ddd;
		border-bottom-color: #ddd;
		border-left: 0;
		border-right: 0;
	}

	.navbar-default .dropdown-menu .nav-tabs > li > a:hover,
	.navbar-default .dropdown-menu .nav-tabs > li > a:focus {
		color: #777;
		background-color: transparent;
	}

	.navbar-default .dropdown-menu .nav-tabs > li.active > a,
	.navbar-default .dropdown-menu .nav-tabs > li.active > a:hover,
	.navbar-default .dropdown-menu .nav-tabs > li.active > a:focus {
		color: #777;
		background-color: transparent;
	}

	.navbar-default .dropdown-menu .nav-tabs > li.disabled > a,
	.navbar-default .dropdown-menu .nav-tabs > li.disabled > a:hover,
	.navbar-default .dropdown-menu .nav-tabs > li.disabled > a:focus {
		color: #999999;
		background-color: transparent;
	}
}

.navbar-progress {
	float: left;
	margin-left: 20px;
}

.navbar-progress .progress {
	width: 200px;
}

@media (max-width: 768px) {
	.navbar-progress {
		margin: 15px 20px;
		float: none;
	}

	.navbar-progress .progress {
		margin-top: 0;
		margin-bottom: 0;
		width: 100%;
	}
}

.navbar-xs .navbar-progress .progress {
	margin-top: 14px;
	margin-bottom: 14px;
}

.navbar-progress .progress,
.navbar-xs .navbar-progress .progress-sm {
	margin-top: 16px;
	margin-bottom: 16px;
}

.navbar-progress .progress-sm,
.navbar-xs .navbar-progress .progress-xs {
	margin-top: 18px;
	margin-bottom: 18px;
}

.navbar-progress .progress-xs,
.navbar-xs .navbar-progress .progress-xxs {
	margin-top: 20px;
	margin-bottom: 20px;
}

.navbar-progress .progress-xxs {
	margin-top: 22px;
	margin-bottom: 22px;
}

.navbar-sm .navbar-progress .progress {
	margin-top: 15px;
	margin-bottom: 15px;
}

.navbar-lg .navbar-progress .progress,
.navbar-sm .navbar-progress .progress-sm {
	margin-top: 18px;
	margin-bottom: 18px;
}

.navbar-lg .navbar-progress .progress-sm,
.navbar-sm .navbar-progress .progress-xs {
	margin-top: 20px;
	margin-bottom: 20px;
}

.navbar-lg .navbar-progress .progress-xs,
.navbar-sm .navbar-progress .progress-xxs {
	margin-top: 22px;
	margin-bottom: 22px;
}

.navbar-lg .navbar-progress .progress-xxs {
	margin-top: 24px;
	margin-bottom: 24px;
}

@media (max-width: 768px) {
	.navbar .btn-group .dropdown-menu,
	.navbar .dropdown .dropdown-menu,
	.navbar .input-group .dropdown-menu {
		width: 100%;
		border-width: 0 0 1px 0;
		border-radius: 0;
	}

	.navbar .btn-group .dropdown-menu > li > a,
	.navbar .dropdown .dropdown-menu > li > a,
	.navbar .input-group .dropdown-menu > li > a {
		padding-left: 20px;
		padding-right: 20px;
	}

	.navbar .btn-group,
	.navbar .input-group,
	.navbar .form-group:not(.has-feedback),
	.navbar .input-group-btn {
		position: static;
	}

	.navbar .select2-container {
		width: 100% !important;
	}

	.navbar-fixed-bottom .btn-group .dropdown-menu,
	.navbar-fixed-bottom .dropdown .dropdown-menu,
	.navbar-fixed-bottom .input-group .dropdown-menu {
		border-width: 1px 0 0 0;
	}

	.navbar-component .btn-group .dropdown-menu,
	.navbar-component .dropdown .dropdown-menu,
	.navbar-component .input-group .dropdown-menu {
		border-width: 0 1px 1px 1px;
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px;
	}

	.table-responsive {
		margin-bottom: 0;
	}
}

/* ------------------------------------------------------------------------------
*
*  # Breadcrumb component
*
*  Overrides for breadcrumb bootstrap component
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.breadcrumb {
	border-radius: 0;
	margin-bottom: 0;
}

.breadcrumb > li {
	position: relative;
}

.breadcrumb > li > a {
	color: #333333;
}

.breadcrumb > li > a:hover,
.breadcrumb > li > a:focus {
	opacity: 0.85;
	filter: alpha(opacity=85);
}

.breadcrumb > li i {
	display: inline-block;
	font-size: 12px;
}

.breadcrumb > li > .dropdown-menu {
	margin-top: 0;
	margin-left: 5px;
}

.breadcrumb > li:first-child > .dropdown-menu {
	margin-left: 0;
}

.breadcrumb > li > .dropdown-menu-right {
	margin-left: 0;
	margin-right: -10px;
}

.breadcrumb > li:hover > .dropdown-menu {
	display: block;
}

.breadcrumb > li.location-text {
	margin-right: 8px;
}

.breadcrumb > li.location-text + li:before {
	content: none;
}

.breadcrumb > li.location-text + li > .dropdown-menu {
	margin-left: 0;
}

@media (max-width: 768px) {
	.heading-elements .breadcrumb {
		padding-top: 0;
		padding-bottom: 0;
	}

	.breadcrumb > li {
		position: static;
	}

	.breadcrumb > li .dropdown-menu {
		width: 100%;
		margin: 0;
		border-radius: 0;
		border-width: 1px 0;
	}

	.breadcrumb > li .dropdown-menu > li {
		position: static;
	}

	.breadcrumb .dropdown-submenu > .dropdown-menu {
		position: static;
	}
}

.page-title .breadcrumb {
	float: none;
	display: block;
	margin: 0;
	padding-top: 3px;
	padding-bottom: 0;
}

.page-title .breadcrumb:first-child {
	padding-top: 0;
	padding-bottom: 3px;
}

.page-title .breadcrumb.position-right {
	margin-left: 29px;
}

.page-header-content > .breadcrumb {
	padding-top: 0;
	padding-bottom: 20px;
}

.page-header-content > .breadcrumb:first-child {
	padding-bottom: 0;
	padding-top: 20px;
}

.breadcrumb-dash > li + li:before {
	content: '\2013\00a0';
}

.breadcrumb-arrow > li + li:before {
	content: '\2192\00a0';
}

.breadcrumb-arrows > li + li:before {
	content: '\00bb\00a0';
}

.breadcrumb-caret > li + li:before {
	content: '\203A\00a0';
}

.breadcrumb-line {
	position: relative;
	padding-left: 20px;
	padding-right: 20px;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.breadcrumb-line:after {
	content: '';
	display: table;
	clear: both;
}

.page-header .breadcrumb-line:first-child {
	z-index: 994;
}

.breadcrumb-line:not([class*=bg-]) {
	background-color: #fff;
}

.page-header-content + .breadcrumb-line {
	margin-bottom: 20px;
}

.page-header-default .breadcrumb-line:not([class*=bg-]) {
	background-color: #fcfcfc;
}

.breadcrumb-line .breadcrumb {
	margin-right: 46px;
}

@media (min-width: 769px) {
	.breadcrumb-line .breadcrumb {
		float: left;
		margin-right: 0;
	}
}

@media (max-width: 768px) {
	.breadcrumb-line {
		z-index: 998;
		background-color: inherit;
	}
}

.page-header-default .page-header-content + .breadcrumb-line,
.page-header-inverse .page-header-content + .breadcrumb-line {
	border-top: 1px solid #ddd;
	margin-bottom: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.page-header-default .page-header-content + .breadcrumb-line-component,
.page-header-inverse .page-header-content + .breadcrumb-line-component {
	border-width: 0;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.breadcrumb-line-component {
	border-radius: 3px;
	padding-left: 0;
	padding-right: 0;
}

.breadcrumb-line-component:not([class*=bg-]) {
	background-color: #fff;
}

.breadcrumb-line-component .breadcrumb {
	margin-left: 20px;
}

.page-header .breadcrumb-line-component {
	margin-left: 20px;
	margin-right: 20px;
}

.breadcrumb-line[class*=bg-] a,
.breadcrumb-line[class*=bg-] i {
	color: inherit;
}

.breadcrumb-line[class*=bg-] .breadcrumb > .active,
.breadcrumb-line[class*=bg-] .breadcrumb > li + li:before {
	color: rgba(255, 255, 255, 0.75);
}

.breadcrumb-line[class*=bg-] .breadcrumb-elements {
	border-top-color: rgba(255, 255, 255, 0.1);
}

.breadcrumb-line[class*=bg-] .breadcrumb-elements > li > a {
	color: rgba(255, 255, 255, 0.9);
}

.breadcrumb-line[class*=bg-] .breadcrumb-elements > li.open > a,
.breadcrumb-line[class*=bg-] .breadcrumb-elements > li > a:hover,
.breadcrumb-line[class*=bg-] .breadcrumb-elements > li > a:focus {
	color: #fff;
}

.breadcrumb-elements {
	text-align: center;
	margin: 0;
	padding: 0;
	list-style: none;
	border-top: 1px solid #ddd;
	font-size: 0;
}

.breadcrumb-elements:after {
	content: '';
	display: table;
	clear: both;
}

.breadcrumb-elements > li {
	display: inline-block;
	position: static;
	font-size: 13px;
}

.breadcrumb-elements > li > a {
	display: block;
	padding: 10px 15px;
	color: #333333;
}

.breadcrumb-elements > li.open > a,
.breadcrumb-elements > li > a:hover,
.breadcrumb-elements > li > a:focus {
	background-color: #f9f9f9;
}

.breadcrumb-line[class*=bg-] .breadcrumb-elements > li.open > a,
.breadcrumb-line[class*=bg-] .breadcrumb-elements > li > a:hover,
.breadcrumb-line[class*=bg-] .breadcrumb-elements > li > a:focus {
	background-color: rgba(255, 255, 255, 0.1);
}

.breadcrumb-elements .dropdown-menu {
	margin-top: 0;
	left: auto;
	right: 0;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}

@media (max-width: 768px) {
	.breadcrumb-elements .dropdown-menu {
		left: 0;
	}

	.breadcrumb-elements .dropdown-menu > li > a {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.breadcrumb-elements .dropup > .dropdown-menu {
	margin-bottom: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.breadcrumb-elements [data-toggle="collapse"] {
	display: block;
	position: absolute;
	top: 0;
	right: 20px;
}

@media (min-width: 769px) {
	.breadcrumb-elements {
		float: right;
		text-align: inherit;
		border-top: 0;
	}

	.breadcrumb-elements.collapse {
		display: block;
		visibility: visible;
	}

	.breadcrumb-elements > li {
		float: left;
	}

	.breadcrumb-elements > li,
	.breadcrumb-elements > li .btn-group {
		position: relative;
	}

	.breadcrumb-line-component .breadcrumb-elements > li:last-child > a {
		border-bottom-right-radius: 3px;
		border-top-right-radius: 3px;
	}

	.breadcrumb-elements [data-toggle="collapse"] {
		display: none;
	}
}

@media (max-width: 768px) {
	.breadcrumb-elements {
		background-color: inherit;
		text-align: center;
		position: absolute;
		top: 50%;
		right: 20px;
		height: 38px;
		margin-top: -19px;
	}
}

/* ------------------------------------------------------------------------------
*
*  # Pagination (multiple pages) component
*
*  Overrides for pagination bootstrap component
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.pagination {
	margin-top: 0;
	margin-bottom: -6px;
}

.pagination,
.pagination.has-shadow {
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.pagination > li > a,
.pagination > li > span {
	min-width: 38px;
	padding: 9px;
	text-align: center;
	border-width: 0;
}

.pagination > li:not(.disabled) > a:hover,
.pagination > li:not(.disabled) > span:hover,
.pagination > li:not(.disabled) > a:focus,
.pagination > li:not(.disabled) > span:focus {
	-webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
	box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
}

.pagination > li:not(.disabled) > a:active,
.pagination > li:not(.disabled) > span:active {
	-webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
	box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
}

.panel .pagination {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.pagination.pagination-rounded > li:first-child > a,
.pagination.pagination-rounded > li:first-child > span {
	border-bottom-left-radius: 100px;
	border-top-left-radius: 100px;
}

.pagination.pagination-rounded > li:last-child > a,
.pagination.pagination-rounded > li:last-child > span {
	border-bottom-right-radius: 100px;
	border-top-right-radius: 100px;
}

.pagination-flat {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.pagination-flat > li > a,
.pagination-flat > li > span {
	margin-left: 1px;
	border-radius: 3px;
	min-width: 38px;
	background-color: transparent;
}

.pagination-flat > li > a,
.pagination-flat > li > span,
.pagination-flat > li > a:hover,
.pagination-flat > li > span:hover,
.pagination-flat > li > a:focus,
.pagination-flat > li > span:focus {
	border-color: transparent;
}

.pagination-flat > .active > a,
.pagination-flat > .active > span,
.pagination-flat > .active > a:hover,
.pagination-flat > .active > span:hover,
.pagination-flat > .active > a:focus,
.pagination-flat > .active > span:focus {
	border-color: transparent;
}

.pagination-flat > .disabled > span,
.pagination-flat > .disabled > span:hover,
.pagination-flat > .disabled > span:focus,
.pagination-flat > .disabled > a,
.pagination-flat > .disabled > a:hover,
.pagination-flat > .disabled > a:focus {
	border-color: transparent;
	background-color: transparent;
}

.pagination-flat.pagination-rounded > li > a,
.pagination-flat.pagination-rounded > li > span {
	border-radius: 100px;
	padding-left: 11px;
	padding-right: 11px;
}

.pagination-flat.pagination-lg > li > a,
.pagination-flat.pagination-lg > li > span {
	min-width: 40px;
}

.pagination-flat.pagination-lg.pagination-rounded > li > a,
.pagination-flat.pagination-lg.pagination-rounded > li > span {
	padding-left: 10px;
	padding-right: 10px;
}

.pagination-flat.pagination-sm > li > a,
.pagination-flat.pagination-sm > li > span {
	min-width: 36px;
}

.pagination-flat.pagination-sm.pagination-rounded > li > a,
.pagination-flat.pagination-sm.pagination-rounded > li > span {
	padding-left: 12px;
	padding-right: 12px;
}

.pagination-flat.pagination-xs > li > a,
.pagination-flat.pagination-xs > li > span {
	min-width: 34px;
}

.pagination-flat.pagination-xs.pagination-rounded > li > a,
.pagination-flat.pagination-xs.pagination-rounded > li > span {
	padding-left: 13px;
	padding-right: 13px;
}

.pagination-separated > li > a,
.pagination-separated > li > span {
	margin-left: 2px;
}

.pagination-lg > li > a,
.pagination-lg > li > span {
	padding: 10px;
	min-width: 40px;
}

.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
	border-bottom-left-radius: 3px;
	border-top-left-radius: 3px;
}

.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
	border-bottom-right-radius: 3px;
	border-top-right-radius: 3px;
}

.pagination-sm > li > a,
.pagination-sm > li > span {
	padding: 8px;
	min-width: 36px;
}

.pagination-xs > li > a,
.pagination-xs > li > span {
	padding: 6px 12px;
	font-size: 12px;
	line-height: 1.6666667;
}

.pagination-xs > li:first-child > a,
.pagination-xs > li:first-child > span {
	border-bottom-left-radius: 3px;
	border-top-left-radius: 3px;
}

.pagination-xs > li:last-child > a,
.pagination-xs > li:last-child > span {
	border-bottom-right-radius: 3px;
	border-top-right-radius: 3px;
}

.pagination-xs > li > a,
.pagination-xs > li > span {
	padding: 7px;
	min-width: 34px;
}

/* ------------------------------------------------------------------------------
*
*  # Pager component
*
*  Overrides for pager bootstrap component
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.pager {
	margin-top: 0;
	margin-bottom: -6px;
	font-size: 0;
}

.pager li > a,
.pager li > span {
	padding: 9px 17px;
	color: #333333;
	border-width: 0;
	overflow: hidden;
	font-size: 13px;
}

.pager li > a:hover,
.pager li > a:focus {
	border-color: #2196F3;
	color: #fff;
}

.pager li + li {
	margin-left: 10px;
}

.pager .disabled > a,
.pager .disabled > a:hover,
.pager .disabled > a:focus,
.pager .disabled > span {
	border-color: #ddd;
}

.pager.text-left {
	text-align: left;
}

.pager.text-right {
	text-align: right;
}

.pager-lg li > a,
.pager-lg li > span {
	padding: 10px 19px;
	font-size: 14px;
	line-height: 1.4285715;
}

.pager-sm li > a,
.pager-sm li > span {
	padding: 8px 15px;
	font-size: 12px;
	line-height: 1.6666667;
}

.pager-xs li > a,
.pager-xs li > span {
	padding: 7px 13px;
	font-size: 12px;
	line-height: 1.6666667;
}

.pager-rounded li > a,
.pager-rounded li > span {
	border-radius: 100px;
}

.pager-linked li > a,
.pager-linked li > span {
	border-color: transparent;
	background-color: transparent;
	color: #1E88E5;
}

.pager-linked li > a:hover,
.pager-linked li > span:hover {
	background-color: #2196F3;
	color: #fff;
}

.pager-linked .disabled > a,
.pager-linked .disabled > a:hover,
.pager-linked .disabled > a:focus,
.pager-linked .disabled > span {
	background-color: transparent;
	border-color: transparent;
}

/* ------------------------------------------------------------------------------
*
*  # Labels component
*
*  Overrides for labels bootstrap component
*
*  Version: 1.2
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.label {
	display: inline-block;
	font-weight: 500;
	padding: 2px 5px 1px 5px;
	line-height: 1.5384616;
	border: 1px solid transparent;
	text-transform: uppercase;
	font-size: 10px;
	letter-spacing: 0.1px;
	border-radius: 2px;
}

.btn .label {
	top: 0;
}

.list-group-item.active > .label,
.nav-pills > .active > a > .label,
.nav-tabs[class*=bg-] > li > a > .label {
	color: #333333;
	background-color: #fff;
	border-color: #fff;
}

@media (min-width: 769px) {
	.list-group-item > .label {
		float: right;
	}

	.list-group-item > .label + .label {
		margin-right: 8px;
	}
}

.label > .caret,
.badge > .caret {
	margin-top: -2px;
}

.open .label.dropdown-toggle,
.open .badge.dropdown-toggle {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.label[href]:hover,
.badge[href]:hover,
.label[href]:focus,
.badge[href]:focus {
	opacity: 0.85;
	filter: alpha(opacity=85);
}

.label-default {
	border-color: #999999;
}

.label-default[href]:hover,
.label-default[href]:focus {
	background-color: #999999;
}

.label-primary {
	border-color: #2196F3;
}

.label-primary[href]:hover,
.label-primary[href]:focus {
	background-color: #2196F3;
}

.label-success {
	border-color: #46da60;
}

.label-success[href]:hover,
.label-success[href]:focus {
	background-color: #46da60;
}

.label-info {
	border-color: #00BCD4;
}

.label-info[href]:hover,
.label-info[href]:focus {
	background-color: #00BCD4;
}

.label-warning {
	border-color: #FF5722;
}

.label-warning[href]:hover,
.label-warning[href]:focus {
	background-color: #FF5722;
}

.label-danger {
	border-color: #F44336;
}

.label-danger[href]:hover,
.label-danger[href]:focus {
	background-color: #F44336;
}

.label-striped {
	background-color: #f5f5f5;
	color: #333333;
	border-left-width: 2px;
	padding: 6px 12px;
}

.label-striped.label-striped-right {
	border-left-width: 1px;
	border-right-width: 2px;
}

.label-striped,
.label-striped.label-icon {
	border-radius: 0;
}

.label-striped[href]:hover,
.label-striped[href]:focus {
	color: #333333;
	background-color: #eeeeee;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.label-flat {
	background-color: transparent;
	border-width: 2px;
	border-radius: 0;
	padding: 1px 4px 0 4px;
}

.label-flat[href]:hover,
.label-flat[href]:focus {
	background-color: transparent;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.label-icon {
	padding: 4px;
	border-radius: 2px;
	line-height: 1;
}

.label-icon > i {
	top: 0;
}

.label-icon.label-flat {
	padding: 6px;
}

.label-icon-xlg {
	padding: 10px;
}

.label-icon-xlg.label-flat {
	padding: 8px;
}

.label-icon-lg {
	padding: 9px;
}

.label-icon-lg.label-flat {
	padding: 7px;
}

.label-icon-sm {
	padding: 7px;
}

.label-icon-sm.label-flat {
	padding: 5px;
}

.label-icon-xs {
	padding: 6px;
}

.label-icon-xs.label-flat {
	padding: 4px;
}

.label-rounded {
	border-radius: 100px;
}

.label-rounded:not(.label-icon) {
	padding-left: 8px;
	padding-right: 8px;
}

.label-roundless {
	border-radius: 0;
}

.label-block {
	display: block;
}

.form-control + .label-block {
	margin-top: 8px;
}

.label-block.text-left {
	text-align: left;
	margin-right: 0;
}

.label-block.text-right {
	text-align: right;
	margin-left: 0;
}

/* ------------------------------------------------------------------------------
*
*  # Badges component
*
*  Overrides for badges bootstrap component
*
*  Version: 1.2
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.badge {
	padding: 2px 6px 1px 6px;
	font-size: 10px;
	letter-spacing: 0.1px;
	vertical-align: baseline;
	background-color: transparent;
	border: 1px solid transparent;
	border-radius: 100px;
}

.btn .badge {
	top: 0;
}

.btn-xs .badge {
	padding: 2px 6px 1px 6px;
}

.list-group-item.active > .badge,
.nav-pills > .active > a > .badge,
.nav-tabs[class*=bg-] > li > a > .badge {
	color: #333333;
	background-color: #fff;
	border-color: #fff;
}

.nav-pills > li > a > .badge {
	margin-left: 0;
}

.nav-pills > li > a > .badge.position-right {
	margin-left: 8px;
}

.badge-default {
	background-color: #999999;
	border-color: #999999;
}

.badge-primary {
	background-color: #2196F3;
	border-color: #2196F3;
}

.badge-success {
	background-color: #4CAF50;
	border-color: #4CAF50;
}

.badge-info {
	background-color: #00BCD4;
	border-color: #00BCD4;
}

.badge-warning {
	background-color: #FF5722;
	border-color: #FF5722;
}

.badge-danger {
	background-color: #F44336;
	border-color: #F44336;
}

.badge-flat {
	background-color: transparent;
	border-width: 2px;
	padding: 1px 5px 0 5px;
}

.badge-flat[href]:hover,
.badge-flat[href]:focus {
	background-color: transparent;
	-webkit-box-shadow: none;
	box-shadow: none;
}

/* ------------------------------------------------------------------------------
*
*  # Thumbnails component
*
*  Overrides for thumbnails bootstrap component
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.thumbnail {
	border-width: 0;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.panel .thumbnail {
	-webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.thumbnail > a {
	display: block;
}

.thumbnail > img,
.thumbnail a > img {
	width: 100%;
}

a.thumbnail:hover,
a.thumbnail:focus,
a.thumbnail.active {
	border-color: #ddd;
}

.thumbnail .caption {
	padding: 17px;
	padding-top: 20px;
}

.thumbnail .caption i.pull-right,
.thumbnail .caption .icons-list.pull-right {
	margin-top: 4px;
}

.thumbnail .caption .media-heading {
	margin-top: 0;
}

.thumbnail .caption .media-heading:after {
	content: '';
	display: table;
	clear: both;
}

.modal-dialog .thumbnail {
	border-width: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.thumbnail > .panel-heading {
	margin: -3px;
	margin-bottom: 0;
}

.thumbnail .panel-footer {
	margin: -3px;
	margin-top: 0;
}

.thumbnail.no-padding img,
.thumbnail.no-padding .thumb {
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.thumbnail.no-padding .caption {
	padding: 20px;
}

.thumbnail.no-padding .panel-heading,
.thumbnail.no-padding .panel-footer {
	margin: 0;
}

.thumb {
	position: relative;
	display: block;
}

.thumb img:not(.media-preview) {
	display: inline-block;
	width: 100%;
	max-width: 100%;
	height: auto;
}

.thumb:not(.thumb-rounded) img {
	border-radius: 3px;
}

.thumb:hover .caption-zoom {
	border-radius: 0;
	-webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.7);
	box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.7);
}

.thumb:hover .caption-offset {
	left: 8px;
	top: 8px;
}

.thumb .caption-collapse {
	top: 80%;
	z-index: 10;
	height: auto;
}

.thumb:hover .thumb .caption-collapse {
	top: 100%;
}

.thumb-rounded {
	width: 60%;
	margin: 20px auto 0 auto;
}

.thumb-rounded,
.thumb-rounded img,
.thumb-rounded .caption-overflow {
	border-radius: 50%;
}

.caption-overflow {
	position: absolute;
	top: 0;
	left: 0;
	color: #fff;
	width: 100%;
	height: 100%;
	visibility: hidden;
	border-radius: 3px;
	opacity: 0;
	filter: alpha(opacity=0);
	-webkit-transition: all 0.15s ease-in-out;
	-o-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out;
}

.caption-overflow span {
	position: absolute;
	top: 50%;
	margin-top: -18px;
	width: 100%;
	text-align: center;
}

.thumb:hover > .caption-overflow {
	background-color: rgba(0, 0, 0, 0.7);
	visibility: visible;
	opacity: 1;
	filter: alpha(opacity=100);
}

.zoom-image {
	color: #fff;
	display: inline-block;
	text-align: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	filter: alpha(opacity=0);
	-webkit-transition: all 0.15s ease-in-out;
	-o-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out;
}

.thumb:hover .zoom-image {
	background-color: rgba(0, 0, 0, 0.6);
	opacity: 1;
	filter: alpha(opacity=100);
}

.img-rounded + .zoom-image {
	border-radius: 3px;
}

.img-circle + .zoom-image {
	border-radius: 50%;
}

.zoom-image i {
	font-size: 32px;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -16px;
	margin-left: -16px;
}

.thumb-slide {
	overflow: hidden;
}

.thumb-slide .caption {
	position: absolute;
	bottom: -100%;
	left: 0;
	color: #fff;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 10;
	-webkit-transition: all 0.1s linear;
	-o-transition: all 0.1s linear;
	transition: all 0.1s linear;
}

.thumb-slide .caption span {
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -19px;
	width: 100%;
	text-align: center;
}

.thumb-slide:hover .caption {
	bottom: 0;
}

/* ------------------------------------------------------------------------------
*
*  # Alert component
*
*  Overrides for alerts bootstrap component
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.alert {
	position: relative;
	padding-left: 20px;
	padding-right: 20px;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.alert .alert-heading {
	margin-top: 0;
	margin-bottom: 5px;
}

.alert .alert-link {
	color: inherit;
}

.alert .close,
.alert .close:hover,
.alert .close:focus {
	color: inherit;
}

.alert-primary {
	background-color: #E3F2FD;
	border-color: #1E88E5;
	color: #1565C0;
}

.alert-primary hr {
	border-top-color: #187bd1;
}

.alert-primary .alert-link {
	color: #104d92;
}

.alert-primary,
.alert-primary .close {
	color: #104d92;
}

.alert-success,
.alert-success .close {
	color: #205823;
}

.alert-info,
.alert-info .close {
	color: #00545c;
}

.alert-warning,
.alert-warning .close {
	color: #aa3510;
}

.alert-danger,
.alert-danger .close {
	color: #9c1f1f;
}

.alert.alert-rounded {
	border-radius: 100px;
	padding-left: 25px;
	padding-right: 25px;
}

.alert-component {
	border-width: 0;
}

.alert-component[class*=alert-styled-] {
	background-color: #fff;
}

.alert[class*=bg-] a,
.alert[class*=bg-] .alert-link {
	color: #fff;
}

.alert[class*=alert-styled-]:after {
	content: '\e9a2';
	font-family: 'icomoon';
	color: #fff;
	width: 44px;
	left: -44px;
	text-align: center;
	position: absolute;
	top: 50%;
	margin-top: -8px;
	font-size: 16px;
	font-weight: 400;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.alert[class*=alert-styled-].alert-danger:after,
.alert[class*=alert-styled-][class*=bg-danger]:after {
	content: '\ed64';
}

.alert[class*=alert-styled-].alert-success:after,
.alert[class*=alert-styled-][class*=bg-success]:after {
	content: '\ed6e';
}

.alert[class*=alert-styled-].alert-warning:after,
.alert[class*=alert-styled-][class*=bg-warning]:after {
	content: '\e9bd';
}

.alert[class*=alert-styled-].alert-info:after,
.alert[class*=alert-styled-][class*=bg-info]:after {
	content: '\e9b9';
}

.alert.alert-styled-right:after {
	left: auto;
	right: -44px;
}

.alert.alert-styled-custom:after {
	content: "\e81b";
}

.alert.alert-styled-left {
	border-left-width: 44px;
}

.alert.alert-styled-left[class*=bg-] {
	border-left-color: rgba(0, 0, 0, 0.15) !important;
}

.alert.alert-styled-right {
	border-right-width: 44px;
}

.alert.alert-styled-right[class*=bg-] {
	border-right-color: rgba(0, 0, 0, 0.15) !important;
}

.alert:not(.ui-pnotify)[class*=alert-arrow-]:before,
.ui-pnotify.alert[class*=alert-arrow-] > .brighttheme:before {
	content: "";
	display: inline-block;
	position: absolute;
	top: 50%;
	left: 0;
	border-left: 5px solid;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;
	border-left-color: inherit;
	margin-top: -5px;
}

.alert:not(.ui-pnotify).alert-arrow-right:before,
.ui-pnotify.alert.alert-arrow-right > .brighttheme:before {
	left: auto;
	right: 0;
	border-left: 0;
	border-right: 5px solid;
	border-right-color: inherit;
}

/* ------------------------------------------------------------------------------
*
*  # Progress bars component
*
*  Overrides for progress bars bootstrap component
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.progress {
	position: relative;
	margin-bottom: 0;
	height: 18px;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.progress-bar {
	line-height: 18px;
	overflow: hidden;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.progress-rounded,
.progress-rounded > .progress-bar {
	border-radius: 100px;
}

.progress .progressbar-back-text {
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;
	text-align: center;
	font-size: 12px;
}

.progress .progressbar-front-text {
	display: block;
	width: 100%;
	text-align: center;
	position: relative;
	font-size: 12px;
}

.progress.right .progress-bar {
	right: 0;
	float: right;
}

.progress.right .progressbar-front-text {
	position: absolute;
	right: 0;
}

.progress.vertical {
	width: 50px;
	height: 100%;
	display: inline-block;
}

.progress.vertical + .progress.vertical {
	margin-left: 10px;
}

.progress.vertical .progress-bar {
	width: 100%;
	height: 0;
	-webkit-transition: height 0.6s ease;
	-o-transition: height 0.6s ease;
	transition: height 0.6s ease;
}

.progress.vertical.bottom {
	position: relative;
}

.progress.vertical.bottom .progressbar-front-text {
	position: absolute;
	bottom: 0;
}

.progress.vertical.bottom .progress-bar {
	position: absolute;
	bottom: 0;
}

.progress-lg {
	height: 22px;
}

.progress-lg .progress-bar {
	line-height: 22px;
}

.progress-sm {
	height: 14px;
}

.progress-xs {
	height: 10px;
}

.progress-xxs {
	height: 6px;
}

.progress-micro {
	height: 2px;
}

.progress-sm .progress-bar,
.progress-xs .progress-bar,
.progress-xxs .progress-bar,
.progress-micro .progress-bar {
	font-size: 0;
}

/* ------------------------------------------------------------------------------
*
*  # Media list component
*
*  Overrides for media list bootstrap component
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.media {
	margin-top: 20px;
	position: relative;
}

.media,
.media-body {
	overflow: visible;
}

.media-left,
.media-right,
.media-body {
	position: relative;
}

.media-heading {
	margin-bottom: 2px;
	display: block;
}

.media-list {
	margin-bottom: 0;
}

.media-right,
.media > .pull-right {
	padding-left: 20px;
}

.media-left,
.media > .pull-left {
	padding-right: 20px;
}

@media (max-width: 768px) {
	.stack-media-on-mobile {
		text-align: center;
	}

	.stack-media-on-mobile .media-annotation {
		display: block;
	}

	.stack-media-on-mobile .media-annotation.dotted:not(.pull-right):before {
		content: none;
		margin: 0;
	}

	.stack-media-on-mobile .media-heading .media-annotation {
		margin-left: 0;
		margin-right: 0;
		padding-bottom: 5px;
	}

	.stack-media-on-mobile .media-left,
	.stack-media-on-mobile .media-right,
	.stack-media-on-mobile .media-body {
		display: block;
		width: auto;
		padding-left: 0;
		padding-right: 0;
	}

	.stack-media-on-mobile .media-left img,
	.stack-media-on-mobile .media-right img,
	.stack-media-on-mobile .media-body img {
		width: 100%;
		height: auto;
		max-height: none;
	}

	.stack-media-on-mobile .media-body,
	.stack-media-on-mobile .media-right {
		margin-top: 15px;
	}

	.stack-media-on-mobile .media-heading {
		margin-bottom: 5px;
	}
}

.media-left img:not(.media-preview),
.media-right img:not(.media-preview),
.thumbnail .media img:not(.media-preview) {
	width: 40px;
	height: 40px;
	max-width: none;
}

.media-badge {
	position: absolute;
	left: -10px;
	top: -2px;
}

.media-badge,
.media-badge[class*=bg-] {
	border: 2px solid;
}

@media (max-width: 768px) {
	.navbar-inverse .media-badge {
		border: 0;
		top: 0;
	}
}

.media-annotation {
	color: #999999;
	font-size: 12px;
	line-height: 1.6666667;
	font-weight: 400;
}

.media-heading .media-annotation {
	margin-left: 8px;
}

.media-annotation i {
	font-size: 13px;
}

.media-annotation.dotted:not(.pull-right):before {
	content: '\2022';
	margin-right: 11px;
}

.media-header {
	white-space: nowrap;
	margin-top: 20px;
	font-weight: 500;
}

.media-header:first-child {
	margin-top: 0;
}

.media-list-bordered > li {
	border-top: 1px solid #eeeeee;
	padding-top: 15px;
	margin-top: 15px;
}

.media-list-bordered > li:first-child {
	padding-top: 0;
	border-top-width: 0;
}

.media-list-bordered.media-list-linked .media-header {
	margin-bottom: 15px;
}

.media-list-linked .media {
	margin-top: 0;
	padding: 0;
}

.media-list-linked .media-link {
	display: block;
	padding: 15px 20px;
	color: #333333;
}

.media-list-linked .media-link:hover,
.media-list-linked .media-link:focus {
	background-color: #fafafa;
	color: #333333;
}

.media-list-linked .media-header {
	padding-left: 20px;
	padding-right: 20px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.media-list-linked .media-header:first-child {
	margin-top: 0;
}

.media-list-linked.media-list-bordered > li:first-child {
	border-top-width: 1px;
}

.media-list-linked.media-list-bordered > .media-header {
	margin-top: 0;
}

.media-list-linked.media-list-bordered > .media-header:first-child {
	border-top-width: 0;
}

/* ------------------------------------------------------------------------------
*
*  # List groups component
*
*  Overrides for list groups bootstrap component
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.list-group {
	list-style: none;
	margin-bottom: 0;
	border: 1px solid #ddd;
	padding: 8px 0;
	border-radius: 3px;
}

.list-group-item {
	background-color: transparent;
	padding: 10px 20px;
	border: 0;
}

.list-group-item.disabled .label,
.list-group-item.disabled:hover .label,
.list-group-item.disabled:focus .label,
.list-group-item.disabled .badge,
.list-group-item.disabled:hover .badge,
.list-group-item.disabled:focus .badge {
	opacity: 0.75;
	filter: alpha(opacity=75);
}

.list-group-divider {
	height: 1px;
	display: block;
	background-color: #e5e5e5;
	margin-top: 8px;
	margin-bottom: 8px;
}

.list-group-header {
	padding: 8px 20px;
	font-size: 11px;
	line-height: 1.82;
	color: #999999;
	text-transform: uppercase;
}

.list-group-header:first-child {
	margin-top: 8px;
}

.list-group-item + .list-group-header,
.list-group-divider + .list-group-header {
	margin-top: 16px;
}

.list-group-item > i,
.list-group-header > i {
	margin-right: 8px;
}

.list-group-item > i.pull-right,
.list-group-header > i.pull-right {
	margin-right: 0;
	margin-left: 8px;
	margin-top: 2px;
}

.list-group-item-heading {
	margin-top: 8px;
	margin-bottom: 8px;
}

.list-group-item-text {
	line-height: 1.5384616;
	margin-bottom: 8px;
}

.list-group-item-success {
	color: #43A047;
	background-color: #E8F5E9;
}

a.list-group-item-success,
button.list-group-item-success {
	color: #43A047;
}

a.list-group-item-success .list-group-item-heading,
button.list-group-item-success .list-group-item-heading {
	color: inherit;
}

a.list-group-item-success:hover,
button.list-group-item-success:hover,
a.list-group-item-success:focus,
button.list-group-item-success:focus {
	color: #43A047;
	background-color: #d6edd8;
}

a.list-group-item-success.active,
button.list-group-item-success.active,
a.list-group-item-success.active:hover,
button.list-group-item-success.active:hover,
a.list-group-item-success.active:focus,
button.list-group-item-success.active:focus {
	color: #fff;
	background-color: #43A047;
	border-color: #43A047;
}

.list-group-item-success,
a.list-group-item-success,
a.list-group-item-success:hover,
a.list-group-item-success:focus {
	color: #205823;
}

.list-group-item-info {
	color: #1565C0;
	background-color: #E3F2FD;
}

a.list-group-item-info,
button.list-group-item-info {
	color: #1565C0;
}

a.list-group-item-info .list-group-item-heading,
button.list-group-item-info .list-group-item-heading {
	color: inherit;
}

a.list-group-item-info:hover,
button.list-group-item-info:hover,
a.list-group-item-info:focus,
button.list-group-item-info:focus {
	color: #1565C0;
	background-color: #cbe7fb;
}

a.list-group-item-info.active,
button.list-group-item-info.active,
a.list-group-item-info.active:hover,
button.list-group-item-info.active:hover,
a.list-group-item-info.active:focus,
button.list-group-item-info.active:focus {
	color: #fff;
	background-color: #1565C0;
	border-color: #1565C0;
}

.list-group-item-info,
a.list-group-item-info,
a.list-group-item-info:hover,
a.list-group-item-info:focus {
	color: #104d92;
}

.list-group-item-warning {
	color: #EF6C00;
	background-color: #FFF3E0;
}

a.list-group-item-warning,
button.list-group-item-warning {
	color: #EF6C00;
}

a.list-group-item-warning .list-group-item-heading,
button.list-group-item-warning .list-group-item-heading {
	color: inherit;
}

a.list-group-item-warning:hover,
button.list-group-item-warning:hover,
a.list-group-item-warning:focus,
button.list-group-item-warning:focus {
	color: #EF6C00;
	background-color: #ffe9c6;
}

a.list-group-item-warning.active,
button.list-group-item-warning.active,
a.list-group-item-warning.active:hover,
button.list-group-item-warning.active:hover,
a.list-group-item-warning.active:focus,
button.list-group-item-warning.active:focus {
	color: #fff;
	background-color: #EF6C00;
	border-color: #EF6C00;
}

.list-group-item-warning,
a.list-group-item-warning,
a.list-group-item-warning:hover,
a.list-group-item-warning:focus {
	color: #aa3510;
}

.list-group-item-danger {
	color: #D84315;
	background-color: #FBE9E7;
}

a.list-group-item-danger,
button.list-group-item-danger {
	color: #D84315;
}

a.list-group-item-danger .list-group-item-heading,
button.list-group-item-danger .list-group-item-heading {
	color: inherit;
}

a.list-group-item-danger:hover,
button.list-group-item-danger:hover,
a.list-group-item-danger:focus,
button.list-group-item-danger:focus {
	color: #D84315;
	background-color: #f7d5d1;
}

a.list-group-item-danger.active,
button.list-group-item-danger.active,
a.list-group-item-danger.active:hover,
button.list-group-item-danger.active:hover,
a.list-group-item-danger.active:focus,
button.list-group-item-danger.active:focus {
	color: #fff;
	background-color: #D84315;
	border-color: #D84315;
}

.list-group-item-danger,
a.list-group-item-danger,
a.list-group-item-danger:hover,
a.list-group-item-danger:focus {
	color: #9c1f1f;
}

/* ------------------------------------------------------------------------------
*
*  # Panels component
*
*  Overrides for panels bootstrap component
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.panel {
	margin-bottom: 20px;
	border-width: 0;
	color: #333333;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.panel[class*=bg-] > .panel-heading {
	border-color: rgba(255, 255, 255, 0.2);
}

@media (max-width: 768px) {
	.panel[class*=bg-] > .panel-heading {
		background-color: inherit;
	}
}

.panel[class*=bg-].panel-flat > .panel-heading {
	border-bottom-color: transparent;
}

.panel[class*=bg-] > .panel-body {
	background-color: inherit;
}

.panel[class*=bg-] .panel-title {
	color: #fff;
}

.panel[class*=bg-] .table thead td,
.panel[class*=bg-] .table tbody td,
.panel[class*=bg-] .table thead th,
.panel[class*=bg-] .table tbody th {
	border-color: rgba(255, 255, 255, 0.1);
}

.panel[class*=bg-] .text-muted,
.panel[class*=bg-] .help-block,
.panel[class*=bg-] .help-inline {
	color: rgba(255, 255, 255, 0.8);
}

.panel-bordered {
	border-width: 1px;
}

.panel[class*=border-top-] {
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}

.panel[class*=border-top-]:not(.border-top-lg):not(.border-top-xlg) {
	border-top-width: 1px;
}

.panel[class*=border-bottom-] {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.panel[class*=border-bottom-]:not(.border-bottom-lg):not(.border-bottom-xlg) {
	border-bottom-width: 1px;
}

.panel[class*=border-left-] {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}

.panel[class*=border-left-]:not(.border-left-lg):not(.border-left-xlg) {
	border-left-width: 1px;
}

.panel[class*=border-right-] {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}

.panel[class*=border-right-]:not(.border-right-lg):not(.border-right-xlg) {
	border-right-width: 1px;
}

.panel-body {
	position: relative;
}

.panel-flat > .panel-heading + .panel-body {
	padding-top: 0;
}

.panel-heading {
	position: relative;
	padding-top: 20px;
	padding-bottom: 20px;
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
}

.panel-bordered > .panel-heading {
	margin: 0;
}

.panel-flat > .panel-heading {
	background-color: #fff;
}

.panel-flat > .panel-heading > .panel-title {
	margin-top: 2px;
	margin-bottom: 2px;
}

.panel-flat > .panel-heading + .tab-content > .has-padding {
	padding-top: 0;
}

.panel-flat[class*=bg-] > .panel-heading {
	background-color: inherit;
}

.panel-heading[class*=bg-],
.panel-primary .panel-heading,
.panel-danger .panel-heading,
.panel-success .panel-heading,
.panel-warning .panel-heading,
.panel-info .panel-heading {
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
}

.panel-white > .panel-heading {
	background-color: #fff;
	border-bottom-color: #ddd;
}

.panel-title {
	position: relative;
	font-size: 13px;
}

a.panel-title {
	display: block;
}

.panel-title img {
	max-height: 20px;
	display: inline-block;
	vertical-align: top;
}

.panel-title > small:not(.display-block),
.panel-title > .small:not(.display-block) {
	margin-left: 5px;
}

h1.panel-title,
.h1.panel-title {
	font-size: 25px;
}

h2.panel-title,
.h2.panel-title {
	font-size: 23px;
}

h3.panel-title,
.h3.panel-title {
	font-size: 21px;
}

h4.panel-title,
.h4.panel-title {
	font-size: 19px;
}

h5.panel-title,
.h5.panel-title {
	font-size: 17px;
}

h6.panel-title,
.h6.panel-title {
	font-size: 15px;
}

.icons-list a[data-action] {
	vertical-align: middle;
}

.icons-list a[data-action]:after {
	font-family: 'icomoon';
	font-size: 16px;
	min-width: 16px;
	text-align: center;
	display: inline-block;
	line-height: 1;
	vertical-align: middle;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icons-list a[data-action="collapse"]:after {
	content: '\e9c1';
}

.icons-list a[data-action="reload"]:after {
	content: '\e9fb';
}

.icons-list a[data-action="close"]:after {
	content: '\e9b6';
}

.icons-list a[data-action="move"]:after {
	content: '\e986';
}

.icons-list a[data-action="modal"]:after {
	content: '\e9eb';
}

.panel-footer {
	position: relative;
	padding-left: 0;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
}

.panel-footer:after {
	content: '';
	display: table;
	clear: both;
}

.panel-footer .form-control,
.panel-footer .selectboxit-btn,
.panel-footer .multiselect.btn-default,
.panel-footer .bootstrap-select .btn-default,
.panel-footer .select2-selection--single:not([class*=bg-]):not([class*=border-]) {
	border-bottom-color: transparent !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

@media (max-width: 768px) {
	.panel-footer {
		padding: 0;
	}
}

.panel-footer-transparent {
	background-color: transparent;
	border-top: 0;
	padding-top: 0;
	padding-bottom: 11px;
}

.panel-footer-condensed {
	padding-top: 2px;
	padding-bottom: 2px;
}

.panel-footer-bordered {
	background-color: #fff;
	padding-right: 0;
}

@media (min-width: 769px) {
	.panel-footer-bordered {
		margin-left: 20px;
		margin-right: 20px;
	}
}

.panel > .panel-body + .table,
.panel > .panel-body + .table-responsive {
	border-top-width: 0;
}

.panel-group-control .panel-title > a {
	padding-left: 26px;
	display: inline-block;
}

.panel-group-control .panel-title > a:before {
	content: '\e9b7';
	font-family: 'icomoon';
	position: absolute;
	top: 50%;
	margin-top: -8px;
	left: 0;
	font-size: 16px;
	font-weight: 400;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.panel-group-control .panel-title > a.collapsed:before {
	content: '\e9b8';
}

.panel-group-control.panel-group-control-right .panel-title > a {
	padding-left: 0;
	padding-right: 26px;
}

.panel-group-control.panel-group-control-right .panel-title > a:before {
	left: auto;
	right: 0;
}

.panel-default {
	border-color: transparent;
}

.panel-primary {
	border-color: transparent;
}

.panel-primary.panel-bordered {
	border-color: #2196F3;
}

.panel-success {
	border-color: transparent;
}

.panel-success.panel-bordered {
	border-color: #4CAF50;
}

.panel-info {
	border-color: transparent;
}

.panel-info.panel-bordered {
	border-color: #00BCD4;
}

.panel-warning {
	border-color: transparent;
}

.panel-warning.panel-bordered {
	border-color: #FF5722;
}

.panel-danger {
	border-color: transparent;
}

.panel-danger.panel-bordered {
	border-color: #F44336;
}

/* ------------------------------------------------------------------------------
*
*  # Wells component
*
*  Overrides for wells bootstrap component
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.well {
	margin-bottom: 0;
	padding: 20px;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.well-white {
	background-color: #fff;
}

.well[class*=border-top-] {
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}

.well[class*=border-bottom-] {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.well[class*=border-left-] {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}

.well[class*=border-right-] {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}

.well-lg {
	padding: 25px;
	border-radius: 3px;
}

.well-sm {
	padding: 15px;
	border-radius: 3px;
}

/* ------------------------------------------------------------------------------
*
*  # Close button component
*
*  Overrides for close button bootstrap component
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.close {
	text-shadow: none;
	opacity: 0.6;
	filter: alpha(opacity=60);
}

.close:hover,
.close:focus {
	outline: 0;
	opacity: 1;
	filter: alpha(opacity=100);
}

/* ------------------------------------------------------------------------------
*
*  # Modals component
*
*  Overrides for modals bootstrap component
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.modal-content {
	border-radius: 3px;
	-webkit-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.modal-header {
	position: relative;
	padding-bottom: 0;
}

.modal-header[class*=bg-] {
	padding: 15px 20px;
	border-top-right-radius: 2px;
	border-top-left-radius: 2px;
}

.modal-header[class*=bg-] .close {
	margin-top: -9.75px;
}

.modal-content[class*=bg-] .modal-header .close,
.modal-header[class*=bg-] .close {
	color: #fff;
}

.modal-header .close {
	position: absolute;
	right: 20px;
	top: 50%;
	margin-top: 0;
}

.modal-body .close {
	margin-top: 0 !important;
}

.modal-footer {
	padding-top: 0;
}

.modal-footer.text-center {
	text-align: center;
}

.modal-footer.text-left {
	text-align: left;
}

@media (min-width: 769px) {
	.modal-xs {
		width: 300px;
	}

	.modal-full {
		width: 94%;
		margin-left: 3%;
		margin-right: 3%;
	}
}

/* ------------------------------------------------------------------------------
*
*  # Tooltips component
*
*  Overrides for tooltips bootstrap component
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.tooltip {
	font-size: 13px;
	line-height: 1.5384616;
}

.tooltip .tooltip-arrow {
	display: none;
}

.tooltip [class*=bg-] {
	border-radius: 3px;
}

.tooltip [class*=bg-] > .tooltip-inner {
	background-color: inherit;
}

.tooltip-inner {
	padding: 8px 16px;
}

/* ------------------------------------------------------------------------------
*
*  # Popovers component
*
*  Overrides for popovers bootstrap component
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.popover {
	border-radius: 3px;
	padding: 0;
	border-width: 0;
	-webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.popover .arrow {
	display: none;
}

.popover-title {
	font-size: 12px;
	line-height: 1.6666667;
	border: 0;
	padding: 15px 15px 0 15px;
	text-transform: uppercase;
	font-weight: 500;
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
}

.popover-title[class*=bg-] {
	padding: 10px 15px;
	margin: -1px -1px 0 -1px;
}

.popover-content {
	padding: 15px;
}
