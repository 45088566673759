@import "../../assets/sass/color-scheme.scss";

.note-modal-content {
	.note-modal-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row-reverse;
		border-radius: 5px 5px 0 0;
	}

	.note-modal-body {
		padding: 20px;

		.checkbox {
			label {
				padding-left: 20px;
			}
		}
	}

	.note-modal-footer {
		height: 60px;
		padding: 0 20px 20px 20px;

		.btn {
			&:hover {
				border-radius: 3px;
				border: 0;
			}
		}
	}
}

.note-editor {
	display: flex;
	flex-direction: column;
	border-radius: 6px;

	.note-status-output {
		display: none !important;
	}

	.note-toolbar {
		order: 1;
		background-color: $light-gray-4;
		border-bottom: 0;
		border-top: 1px solid $light-gray-13;
		border-radius: 0 0 6px 6px;

		.note-btn-group {
			.note-btn {
				border: 0;
				background-color: $light-gray-4;
			}
		}
	}

	.note-statusbar {
		order: 2;
		border-top: 0 !important;
		border-bottom-left-radius: 6px !important;
		border-bottom-right-radius: 6px !important;
		background-color: $light-gray-4 !important;
	}

	&.note-frame {
		.note-editing-area {
			border-radius: 6px 6px 0 0;

			.note-placeholder {
				padding: 12px 15px;
				font-style: italic;
			}

			.note-editable {
				padding: 12px 15px;

				&[contenteditable="false"] {
					background-color: var(--white);
					cursor: not-allowed;
				}

				p {
					padding-left: 0;
				}
			}
		}
	}
}
