/* ================================================
				Authentication part
================================================= */
@import "variables";

.setup-organization-form {

	#logo {
		background: url($images-folder + "/logo.svg") no-repeat;
		width: 175px;
		height: 26px;
		margin: 10px auto;
	}

	.separator {
		height: 1px;
		width: 100%;
		background: #f2f2f2;
		clear: both;
		display: block;
		margin: 20px auto;
	}
}

.outer-text {
	color: rgba(255, 255, 255, 0.7);
}

.outer-text a {
	color: rgba(85, 200, 255, 0.7);
}

.outer-text a:hover {
	color: rgba(85, 200, 255, 1);
}
