@import "color-scheme";

a {
	transition: all 0.3s ease;

	&:hover {
		text-decoration: none;
		color: #005297;
	}
}

.btn {
	text-transform: none;
}

.form-control {
	border-radius: 0;
	background: $light-gray-4;
	box-shadow: none;
	height: 38px;

	border-width: 1px;
	border-color: $light-gray-13;

	&:focus {
		border-color: var(--blue);
		-webkit-box-shadow: none;
		box-shadow: none;
	}
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
	color: inherit;
}

.sidebar-default .navigation > li > a.active,
.sidebar-default .navigation > li > a.active:hover,
.sidebar-default .navigation > li > a.active:focus {
	background-color: $light-gray;
	color: $gray-4;
}

.sidebar-default .navigation li > a.active,
.sidebar-default .navigation li > a.active:hover,
.sidebar-default .navigation li > a.active:focus {
	background-color: $light-gray;
	color: $gray-4;
}

.input-group-addon {
	color: #55595c;
	background-color: #eceeef;
	border: 1px solid $light-gray-14;
}

.has-feedback label ~ .form-control-feedback {
	top: 28px;
}

.has-feedback-left .form-control-feedback {
	left: 8px;
}

.input-group input + .input-group-addon,
.input-group-btn + .input-group-addon,
.input-group > .fas-feedback + .input-group-addon {
	padding-right: 16px;
}

.input-group-addon > i {
	left: 8px;
}

.fa-exclamation-circle {
	color: $red;
}

.btn-fa-icon {
	cursor: pointer;
	margin: 0 2px;
	transition: 0.3s all;
	padding: 3px 5px;
}

.btn-fa-icon-orgchart {
	cursor: pointer;
	margin: 0 2px;
	transition: 0.3s all;
	padding: 12px 0 7px 3px;
}

.btn-fa-icon-orgchart:first-of-type {
	padding-left: 7px;
}

.dt-buttons {
	a {
		i {
			left: -2px;
		}
	}
}

.dt-button-collection {
	.buttons-columnVisibility {
		display: block;
		margin-top: 2px;
	}
}

.dt-multiple-actions {
	position: absolute;
	top: 38%;
	left: 50px;
	width: 200px;
}

tr.selected {
	background-color: rgba(38, 185, 154, 0.07) !important;
}

.stepy-navigator {
	margin-top: 12px;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
	width: auto;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover,
.bootstrap-datetimepicker-widget table td span.active {
	background-color: $blue-4 !important;
}

.bootstrap-datetimepicker-widget table td.today:before {
	border-bottom-color: $blue-4 !important;
}

.bootstrap-datetimepicker-widget table th.disabled,
.bootstrap-datetimepicker-widget table th.disabled:hover,
.bootstrap-datetimepicker-widget table td.disabled,
.bootstrap-datetimepicker-widget table td.disabled:hover {
	cursor: not-allowed !important;
}

.file-preview {
	.file-preview-frame {
		width: 100%;

		.file-preview-text {
			width: 100% !important;
		}
	}
}

.news-upload-container {
	.file-preview {
		.file-preview-frame {
			width: auto;
		}
	}
}

#reportsToDetails {
	cursor: pointer;
	color: $blue-7;
	display: inline-block;
}

.panel-heading-button {
	cursor: pointer;
}

.request-verify-token {
	color: dodgerblue;
	cursor: pointer;
}

.request-verify-token:hover {
	text-decoration: underline;
}

.tooltip-inner {
	max-width: inherit;
}

.tooltip-button-ok {
	color: green;
	font-size: 20px;
	top: 75px;
	position: absolute;
	right: 10px;
	border: 1px solid;
	cursor: pointer;
	z-index: 10;
}

.arrow {
	display: block !important;
}

.tooltip {
	z-index: 1040;
}

.ui-draggable-handle {
	cursor: move;
}

.messages {
	p {
		margin-bottom: 0;
	}
}

.heading-elements .icons-list > li {
	margin-left: 16px;
}

.fa-icon-fixed {
	width: 18px;
	text-align: center;
}

.org-chart-panel ul.icons-list {
	margin-top: 10px !important;
	margin-left: 0 !important;

	li {
		margin-left: 0 !important;
	}
}

.nav-tabs > li > a {
	border-right: none;
	border-top: none;
	border-left: none;
}

.modal {
	.modal-header {
		border-top-right-radius: 3px;
		border-top-left-radius: 3px;
		border-bottom: 1px solid $light-gray-13;
		border-top: 4px solid var(--blue);
		padding-bottom: 15px;
		padding-top: 15px;

		.close {
			top: 20px;
		}
	}

	&.modal-blue {
		.modal-header {
			border-top: 4px solid var(--blue);
		}
	}

	&.modal-green {
		.modal-header {
			border-top: 4px solid var(--affirmative);
		}
	}
}

#upload-image-modal {
	.modal-body {
		max-height: calc(100vh - 200px);
		overflow-y: auto;
	}
}

#user-attachment-modal {
	.input-group {
		width: auto;
	}
}

@media (min-width: 769px) {
	.nav-tabs.nav-tabs-highlight > li.active > a,
	.nav-tabs.nav-tabs-highlight > li.active > a:hover,
	.nav-tabs.nav-tabs-highlight > li.active > a:focus {
		border: none;
		border-top: 3px solid var(--blue);
		color: var(--blue);
		font-weight: 600;
	}

	.nav-tabs.nav-tabs-highlight.nav-tabs-inverse > li.active > a,
	.nav-tabs.nav-tabs-highlight.nav-tabs-inverse > li.active > a:hover,
	.nav-tabs.nav-tabs-highlight.nav-tabs-inverse > li.active > a:focus {
		background: transparent;
		border: none;
		border-bottom: 3px solid var(--blue);
		color: var(--blue);
		font-weight: 600;
	}
}
