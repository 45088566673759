app-new-users, app-users-anniversaries, app-birthdays, app-users-job-changes, app-badges-page {
	.author-name {
		margin-top: 10px;

		&::after {
			margin-bottom: 0;
			margin-top: 2px;
		}
	}

	.image-holder {
		float: left;
		text-align: center;

		.profile-picture,
		img {
			width: 120px;
			height: 120px;
		}
	}

	.contact-detail {
		margin-right: 10px;
	}

	.blog-section {
		margin-top: 20px;

		.single-content {
			margin-left: 120px;
		}

		.read-more {
			a {
				margin-top: 30px;
			}
		}
	}

	.media-body {
		width: 0;
	}
}

@media (max-width: 1024px) {
	app-new-users, app-users-anniversaries, app-birthdays, app-users-job-changes {
		.blog-section {
			padding-bottom: 102px;

			.read-more {
				a {
					margin-top: 0;
				}
			}
		}
	}
}

@media (max-width: 480px) {
	app-new-users, app-users-anniversaries, app-birthdays, app-users-job-changes {
		.blog-section {
			.single-content {
				margin-left: 0;
			}
		}

		.image-holder {
			float: none;
			width: 100%;
			height: auto;

			img {
				width: 100%;
				height: auto;
			}
		}
	}
}
