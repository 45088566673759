@import "./color-scheme.scss";

.br {
	display: flex;
	align-items: baseline;

	.br-units {
		.br-unit {
			i {
				font-size: 25px;

				&:hover {
					color: var(--affirmative);
				}
			}

			.fa-star-o,
			.fa-star,
			.fa-star-half-o {
				color: var(--affirmative);
				margin: 2px;
			}
		}
	}

	.br-text {
		display: inline;
		position: relative;
		left: 10px;
		font-size: 18px;
		color: var(--affirmative);
	}

	&.br-readonly {
		.br-unit {
			i {
				cursor: default;
			}
		}
	}
}
