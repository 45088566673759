@import "color-scheme";
@import "variables";

$gray-shade-1: #eeeded;
$gray-shade-2: #e2e0e0;
$gray-shade-3: #263238;
$gray-shade-4: #304047;
$gray-shade-5: #9e9e9e;
$gray-shade-6: #304047;
$gray-shade-7: #304047;
$gray-shade-8: #304047;
$gray-shade-9: #75809d;
$gray-shade-10: #455a64;


$blue-shade-1: #2196f3;

$orange-shade-1: #f8d486;
$orange-shade-2: #f8bb86;

.no-transitions * {
	-webkit-transition: none !important;
	-o-transition: none !important;
	transition: none !important;
}

.btn,
.navbar-link,
.nav-tabs > li > a,
.nav-tabs > li > a:after,
.nav-pills > li > a,
.nav li > a > .label,
.nav li > a > .badge,
.breadcrumb > li > a,
.breadcrumb-elements > li > a,
.tt-suggestion,
.tokenfield .token,
.selectboxit-btn,
.bootstrap-select .btn-default,
.select2-results__option,
.dropdown-menu > li > a,
.dropdown-menu > li > label,
.wizard .actions a,
.choice span:after,
.selectboxit-option-anchor,
.dt-autofill-list ul li,
.dt-autofill-button .btn,
.dataTable .select-checkbox:before,
.dataTable .select-checkbox:after,
.pika-button,
.sp-input,
.navbar-nav > li > a,
.dropdown-content-footer a,
.icons-list > li > a,
.picker__nav--prev,
.picker__nav--next,
.multiselect.btn-default,
.list-group-item,
.pagination > li > a,
.pagination > li > span,
.pager > li > a,
.pager > li > span,
.datepicker-dropdown .day,
a.label,
a.badge,
.ui-datepicker-buttonpane > button,
.ui-button,
.ui-menu-item,
.ui-selectmenu-button,
.ui-datepicker a,
.media-link,
.menu-list li > a,
.plupload_file_action > a,
.dataTables_paginate .paginate_button,
.dataTables_filter input,
.dt-button,
.picker__list-item,
.picker__day,
.picker__footer,
.sp-replacer,
.sp-cancel,
.sp-choose,
.sp-palette-toggle,
.daterangepicker td,
.daterangepicker th,
.noUi-handle,
.fc-button,
.plupload_button,
.picker__footer button,
.picker__list button,
.AnyTime-btn,
.plupload_filelist li,
.password-indicator-group.input-group-addon,
.password-indicator-label-absolute,
.select2-selection--single:not([class*="bg-"]),
.select2-selection--multiple:not([class*="bg-"]) .select2-selection__choice,
.bootstrap-select.btn-group .dropdown-menu > li > a .check-mark {
	-webkit-transition: all ease-in-out 0.15s;
	-o-transition: all ease-in-out 0.15s;
	transition: all ease-in-out 0.15s;
}

.close,
.tag [data-role="remove"],
.select2-selection__choice__remove {
	-webkit-transition: opacity ease-in-out 0.15s;
	-o-transition: opacity ease-in-out 0.15s;
	transition: opacity ease-in-out 0.15s;
}

.table tr,
.table th,
.table td,
.htContextMenu td,
.sidebar-user-material-menu > a,
.navbar-nav > .active > a:after,
.ranges ul li {
	-webkit-transition: background-color ease-in-out 0.15s;
	-o-transition: background-color ease-in-out 0.15s;
	transition: background-color ease-in-out 0.15s;
}

.sidebar-user-material-menu > a > .caret {
	-webkit-transition: -webkit-transform ease-in-out 0.15s;
	-o-transition: -webkit-transform ease-in-out 0.15s;
	transition: -webkit-transform ease-in-out 0.15s;
}

/* ------------------------------------------------------------------------------
 *
 *  # Sidebar layouts
 *
 *  Styles for sidebar components, main navigation and sidebar itself
 *
 *  Version: 1.2
 *  Latest update: Nov 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.sidebar {
	background-color: $gray-shade-3;
	color: var(--white);
	position: relative;
	width: 100%;
	display: none;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
		0 1px 2px rgba(0, 0, 0, 0.24);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

@media (min-width: 769px) {
	.sidebar {
		display: table-cell;
		vertical-align: top;
		width: 260px;
	}
}

.sidebar-main {
	z-index: 99;
}

@media (min-width: 769px) {
	.sidebar-opposite-visible .sidebar-opposite {
		display: table-cell;
	}
}

.sidebar-default {
	background-color: var(--white);
	color: $gray-4;
}

.sidebar-content {
	position: relative;
	padding-bottom: 8px;
}

.sidebar-all-hidden .sidebar-main,
.sidebar-all-hidden .sidebar-secondary,
.sidebar-main-hidden .sidebar-main,
.sidebar-detached-hidden .sidebar-detached > .sidebar,
.sidebar-secondary-hidden .sidebar-secondary {
	display: none;
}

@media (max-width: 768px) {
	.sidebar-mobile-main .sidebar-main,
	.sidebar-mobile-secondary .sidebar-secondary,
	.sidebar-mobile-opposite .sidebar-opposite,
	.sidebar-mobile-detached .sidebar-detached > .sidebar {
		display: block;
	}
}

.category-title {
	position: relative;
	margin: 0;
	padding: 12px 20px;
	padding-right: 46px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.category-title > span {
	display: block;
	margin-top: 2px;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 11px;
	line-height: 1.82;
}

.category-title > i {
	position: absolute;
	right: 20px;
	top: 50%;
	margin-top: -8px;
}

.category-title .icons-list {
	position: absolute;
	right: 20px;
	top: 50%;
	margin-top: -8px;
}

.sidebar-default .category-title {
	border-bottom-color: $light-gray-13;
}

.category-content {
	position: relative;
	padding: 20px;
}

.sidebar-user-material .category-content {
	background: url($images-folder + "/backgrounds/user_bg4.jpg") center center
		no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

.sidebar-user-material .navigation-wrapper {
	background-color: rgba(0, 0, 0, 0.1);
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebar-default .sidebar-user-material .navigation-wrapper {
	background-color: rgba(0, 0, 0, 0.01);
	border-bottom-color: $light-gray-13;
}

.sidebar-user-material .navigation {
	padding: 8px 0;
}

.sidebar-detached .sidebar-user-material:first-child .category-content {
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
}

.sidebar-user-material-menu {
	margin: -20px;
	margin-top: 20px;
}

.sidebar-user-material-menu > a {
	padding: 10px 20px;
	display: block;
	color: var(--white);
	background-color: rgba(0, 0, 0, 0.1);
	text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
}

.sidebar-user-material-menu > a:hover,
.sidebar-user-material-menu > a:focus,
.sidebar-user-material-menu > a[aria-expanded="true"] {
	background-color: rgba(0, 0, 0, 0.15);
}

.sidebar-user-material-menu > a > i {
	float: right;
	margin-top: 2px;
}

.sidebar-user-material-menu > a > .caret {
	-webkit-transition-duration: 0.35s;
	transition-duration: 0.35s;
}

.sidebar-user-material-menu > a[aria-expanded="true"] > .caret {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);
}

.sidebar-user-material-content {
	text-align: center;
}

.sidebar-user-material-content > a {
	display: inline-block;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
		0 1px 2px rgba(0, 0, 0, 0.24);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.sidebar-user-material-content > a > img {
	height: 80px;
}

.sidebar-user-material-content > h6 {
	margin-bottom: 0;
}

.sidebar-user-material-content > h6,
.sidebar-user-material-content > span {
	color: var(--white);
	text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
}

.sidebar-xs .sidebar-user-material-content {
	display: none;
}

.sidebar-xs .sidebar-user-material > .category-content {
	padding: 0;
}

.sidebar-xs .sidebar-user-material-menu {
	margin: 0;
}

.sidebar-xs .sidebar-user-material-menu > a {
	text-align: center;
	padding: 20px;
	line-height: 1;
}

.sidebar-xs .sidebar-user-material-menu > a > span {
	display: none;
}

.sidebar-xs .sidebar-user-material-menu > a > i {
	float: none;
	margin-top: 0;
}

.navigation {
	margin: 0;
	padding: 10px 0;
	list-style: none;
	position: relative;
}

.sidebar-user + .sidebar-category .navigation {
	padding-top: 0;
}

.navigation .hidden-ul {
	display: none;
}

.navigation li {
	position: relative;
}

.navigation li + li {
	margin-top: 1px;
}

.navigation li + .navigation-header {
	margin-top: 10px;
}

.navigation li a {
	color: rgba(255, 255, 255, 0.75);
	display: block;
	-webkit-transition: background 0.15s linear, color 0.15s linear;
	-o-transition: background 0.15s linear, color 0.15s linear;
	transition: background 0.15s linear, color 0.15s linear;
}

.navigation li a:hover,
.navigation li a:focus {
	background-color: rgba(0, 0, 0, 0.1);
	color: var(--white);
}

.navigation li a > i {
	float: left;
	top: 0;
	margin-top: 2px;
	margin-right: 15px;
	-webkit-transition: opacity 0.2s ease-in-out;
	-o-transition: opacity 0.2s ease-in-out;
	transition: opacity 0.2s ease-in-out;
}

.navigation li a > i.pull-right {
	margin-right: 0;
	margin-left: 15px;
}

.navigation li.disabled > a,
.navigation li.disabled > a:hover,
.navigation li.disabled > a:focus {
	color: inherit;
	background-color: transparent;
	cursor: not-allowed;
	opacity: 0.3;
	filter: alpha(opacity=30);
}

.navigation li > .has-ul {
	position: relative;
	padding-right: 36px;
}

.navigation li > .has-ul:after {
	content: "\e9c7";
	font-family: "icomoon";
	font-size: 16px;
	display: block;
	position: absolute;
	top: 50%;
	margin-top: -8px;
	right: 20px;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: -webkit-transform 0.2s ease-in-out;
	-o-transition: -webkit-transform 0.2s ease-in-out;
	transition: -webkit-transform 0.2s ease-in-out;
}

.navigation li.active > .has-ul:after {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
}

.navigation li.navigation-divider {
	margin: 10px 0;
	height: 1px;
	background-color: rgba(255, 255, 255, 0.1);
}

.sidebar-default .navigation li.navigation-divider {
	background-color: $light-gray-9;
}

.navigation > li > a {
	padding: 12px 12px;
	min-height: 44px;
	font-weight: 500;
}

.navigation > li.active > a,
.navigation > li.active > a:hover,
.navigation > li.active > a:focus {
	background-color: $gray-shade-4;
	color: var(--white);
}

.navigation > li.active > a > [class*="text-"] {
	color: var(--white);
}

.navigation > li.active > a .label,
.navigation > li.active > a .badge {
	background-color: transparent;
	border-color: transparent;
	color: var(--white);
	-webkit-transition: background-color ease-in-out 0.2s;
	-o-transition: background-color ease-in-out 0.2s;
	transition: background-color ease-in-out 0.2s;
}

.navigation > li ul {
	list-style: none;
	margin: 0;
	padding: 0;
	background-color: rgba(0, 0, 0, 0.15);
	-webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05);
	box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05);
}

.navigation > li ul li a {
	padding: 10px 20px;
	padding-left: 51px;
	min-height: 40px;
}

.navigation > li ul .navigation-header {
	padding-left: 51px;
}

.navigation > li > ul > li > ul > li > a,
.navigation > li > ul > li > ul > .navigation-header {
	padding-left: 71px;
}

.navigation > li > ul > li > ul > li > ul > li > a,
.navigation > li > ul > li > ul > li > ul .navigation-header {
	padding-left: 91px;
}

.navigation > li > ul li:first-child {
	padding-top: 10px;
}

.navigation > li > ul li:last-child {
	padding-bottom: 10px;
}

.navigation > li > ul li.active > a,
.navigation > li > ul li.active > a:hover,
.navigation > li > ul li.active > a:focus {
	background-color: rgba(0, 0, 0, 0.2);
	color: var(--white);
}

.navigation .navigation-header {
	min-height: 30px;
	padding: 10px 20px;
	border-bottom: 0;
	text-transform: uppercase;
	font-size: 11px;
	line-height: 1.82;
}

.navigation .navigation-header > span {
	display: block;
	margin-top: 2px;
}

.navigation .navigation-header > i {
	display: none;
}

.navigation .navigation-header > i.pull-right {
	margin-top: 2px;
}

.navigation .navigation-header,
.navigation .navigation-header a {
	color: rgba(255, 255, 255, 0.5);
	font-weight: 400;
}

.navigation .navigation-header a:hover,
.navigation .navigation-header a:focus {
	color: var(--white);
}

.navigation .navigation-header a i {
	float: none;
	margin: 0;
}

.sidebar-default .navigation .navigation-header,
.sidebar-default .navigation .navigation-header a {
	color: $gray-3;
}

.sidebar-default .navigation .navigation-header a:hover,
.sidebar-default .navigation .navigation-header a:focus {
	color: $gray-4;
}

.navigation.nav > .active > .hidden-ul {
	display: block;
}

@media (max-width: 768px) {
	.navigation.nav > li > .hidden-ul {
		display: block;
	}
}

.navigation .label,
.navigation .badge {
	float: right;
}

.sidebar-default .navigation li > a {
	color: $gray-4;
}

.sidebar-default .navigation li > a:hover,
.sidebar-default .navigation li > a:focus {
	background-color: $light-gray-11;
}

.sidebar-default .navigation li.active > a,
.sidebar-default .navigation li.active > a:hover,
.sidebar-default .navigation li.active > a:focus {
	background-color: $light-gray;
	color: $gray-4;
}

.sidebar-default .navigation li.active > a > [class*="text-"] {
	color: $gray-4;
}

.sidebar-default .navigation li.disabled > a,
.sidebar-default .navigation li.disabled > a:hover,
.sidebar-default .navigation li.disabled > a:focus {
	background-color: transparent;
}

.sidebar-default .navigation li .label-transparent {
	color: $gray-4;
}

.sidebar-default .navigation > li ul {
	background-color: transparent;
}

.sidebar-default .navigation > li.active > a,
.sidebar-default .navigation > li.active > a:hover,
.sidebar-default .navigation > li.active > a:focus {
	background-color: $light-gray;
	color: $gray-4;
}

.sidebar-default .navigation > li.active > a .label-transparent {
	color: $gray-4;
}

.navigation-icons-right > li > a,
.navigation-icons-right > li > a.has-ul {
	padding-right: 20px;
}

.navigation-icons-right > li > a > i {
	float: right;
	margin-right: 0;
	margin-left: 15px;
}

.navigation-icons-right > li > a.has-ul:after,
.navigation-icons-right > li.active > .has-ul:after {
	content: none;
}

.navigation-bordered > li {
	border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.navigation-bordered > li:last-child {
	border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.navigation-bordered > li.navigation-header {
	background-color: rgba(0, 0, 0, 0.1);
}

.navigation-bordered > li + li {
	margin-top: 0;
}

.navigation-bordered > li ul {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.navigation-bordered li + .navigation-header {
	margin-top: 0;
}

.sidebar-default .navigation-bordered > li {
	border-top: 1px solid $light-gray-9;
}

.sidebar-default .navigation-bordered > li.navigation-header {
	background-color: $light-gray-15;
}

.navigation-lg > li > a,
.navigation-lg > li > a > span {
	padding-top: 14px;
	padding-bottom: 14px;
	min-height: 48px;
}

.navigation-lg > li ul li a {
	padding-top: 10px;
	padding-bottom: 10px;
	min-height: 40px;
}

.navigation-sm > li > a,
.navigation-sm > li > a > span {
	padding-top: 10px;
	padding-bottom: 10px;
	min-height: 40px;
}

.navigation-sm > li ul li a {
	padding-top: 6px;
	padding-bottom: 6px;
	min-height: 32px;
}

.navigation-xs > li > a,
.navigation-xs > li > a > span {
	padding-top: 8px;
	padding-bottom: 8px;
	min-height: 36px;
}

.navigation-xs > li ul li a {
	padding-top: 6px;
	padding-bottom: 6px;
	min-height: 32px;
}

@media (min-width: 769px) {
	.sidebar-fixed .sidebar-content {
		overflow-y: initial !important; /* fix for hidden overflow from a script */
	}
	.sidebar-fixed-expanded .sidebar-fixed.sidebar-main {
		position: fixed;
		display: block;
		height: 100%;
		z-index: 1001;
	}
	.sidebar-fixed-expanded .sidebar-fixed.sidebar-main .sidebar-content {
		position: fixed;
	}
	.sidebar-xs .sidebar-fixed-expanded .sidebar-fixed.sidebar-main {
		width: 260px;
	}
	.sidebar-fixed-expanded .content-wrapper {
		padding-left: 56px;
	}
}

@media (min-width: 769px) {
	.sidebar-separate {
		background-color: transparent;
	}
	.sidebar-separate .sidebar-category {
		background-color: $gray-shade-3;
		border-radius: 3px;
		margin-bottom: 20px;
	}
	.sidebar-separate.sidebar-default {
		background-color: transparent;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	.sidebar-separate.sidebar-default .sidebar-category {
		background-color: var(--white);
		-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
			0 1px 2px rgba(0, 0, 0, 0.24);
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	}
}

@media (min-width: 769px) {
	.sidebar-xs .sidebar-main {
		width: 56px;
	}
	.sidebar-xs .sidebar-main .sidebar-category {
		display: none;
	}
	.sidebar-xs .sidebar-main .sidebar-category-visible {
		display: block;
	}
	.sidebar-xs .sidebar-main .category-title {
		padding: 0;
	}
	.sidebar-xs .sidebar-main .category-title > i {
		padding: 13px 0;
		float: none;
		display: block;
		top: 0;
	}
	.sidebar-xs .sidebar-main .category-title > span {
		display: none;
	}
	.sidebar-xs .sidebar-main .category-title .icons-list {
		position: static;
		text-align: center;
		margin-top: 0;
		padding-top: 14px;
		padding-bottom: 14px;
	}
	.sidebar-xs .sidebar-main .category-title .icons-list > li {
		display: block;
		margin-left: 0;
	}
	.sidebar-xs .sidebar-main .category-title .icons-list > li + li {
		margin-top: 10px;
	}
	.sidebar-xs .sidebar-main .category-title.h6 .icons-list {
		padding-top: 23.538462px;
		padding-bottom: 23.538462px;
	}
	.sidebar-xs .sidebar-main .category-title.h5 .icons-list {
		padding-top: 25.0769236px;
		padding-bottom: 25.0769236px;
	}
	.sidebar-xs .sidebar-main .navigation > li > a {
		display: block;
		text-align: center;
		padding-left: 0;
		padding-right: 0;
	}
	.sidebar-xs .sidebar-main .navigation > li > a > span {
		display: none;
		position: absolute;
		top: 0;
		right: -260px;
		background-color: $gray-shade-4;
		padding: 12px 20px;
		width: 260px;
		text-align: left;
		color: var(--white);
		cursor: pointer;
		border-bottom-right-radius: 3px;
		border-top-right-radius: 3px;
		-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
			0 1px 2px rgba(0, 0, 0, 0.24);
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	}
	.sidebar-xs .sidebar-main .navigation > li > a > span .label,
	.sidebar-xs .sidebar-main .navigation > li > a > span .badge {
		background-color: transparent;
		border-color: transparent;
		color: var(--white);
		padding-left: 0;
		padding-right: 0;
	}
	.sidebar-xs .sidebar-main .navigation > li > a > i {
		margin: 2px 0;
		display: block;
		float: none;
	}
	.sidebar-xs .sidebar-main .navigation > li > a {
		overflow: visible;
	}
	.sidebar-xs .sidebar-main .navigation > li > .has-ul:after,
	.sidebar-xs .sidebar-main .navigation > li.active > .has-ul:after {
		content: none;
	}
	.sidebar-xs .sidebar-main .navigation > li .has-ul > span {
		border-radius: 0 3px 0 0;
		cursor: default;
	}
	.sidebar-xs .sidebar-main .navigation > li.active > ul {
		display: none !important;
	}
	.sidebar-xs .sidebar-main .navigation > li.disabled:hover > ul,
	.sidebar-xs .sidebar-main .navigation > li.disabled:hover > a > span {
		display: none !important;
	}
	.sidebar-xs .sidebar-main .navigation > li:hover > ul {
		display: block !important;
	}
	.sidebar-xs .sidebar-main .navigation > li:hover > a > span {
		display: block;
	}
	.sidebar-xs .sidebar-main .navigation > li:hover:not(.active) > a {
		background-color: rgba(0, 0, 0, 0.1);
		color: var(--white);
	}
	.sidebar-xs .sidebar-main .navigation > li > ul {
		position: absolute;
		right: -260px;
		top: 44px;
		width: 260px;
		display: none;
		background-color: $gray-shade-3;
		border-radius: 0 0 3px 0;
		-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
			0 1px 2px rgba(0, 0, 0, 0.24);
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	}
	.sidebar-xs .sidebar-main .navigation > li > ul > li > a {
		padding-left: 20px;
		padding-right: 20px;
	}
	.sidebar-xs .sidebar-main .navigation > li > ul > li > ul > li > a {
		padding-left: 30px;
	}
	.sidebar-xs
		.sidebar-main
		.navigation
		> li
		> ul
		> li
		> ul
		> li
		> ul
		> li
		> a {
		padding-left: 60px;
	}
	.sidebar-xs .sidebar-main .navigation > .navigation-header {
		padding: 0;
		text-align: center;
	}
	.sidebar-xs .sidebar-main .navigation > .navigation-header > i {
		display: block;
		top: 0;
		padding: 13px 0;
	}
	.sidebar-xs .sidebar-main .navigation > .navigation-header > span {
		display: none;
	}
	.sidebar-xs .sidebar-main .sidebar-user .category-content {
		padding-left: 0;
		padding-right: 0;
	}
	.sidebar-xs .sidebar-main .sidebar-user .media-left,
	.sidebar-xs .sidebar-main .sidebar-user .media-right {
		padding: 0;
		text-align: center;
		display: block;
	}
	.sidebar-xs .sidebar-main .sidebar-user .media-left > img,
	.sidebar-xs .sidebar-main .sidebar-user .media-right > img {
		max-width: 100%;
		height: auto !important;
	}
	.sidebar-xs .sidebar-main .sidebar-user .media-left > .img-sm,
	.sidebar-xs .sidebar-main .sidebar-user .media-right > .img-sm {
		margin-top: 1px;
		margin-bottom: 1px;
	}
	.sidebar-xs .sidebar-main .sidebar-user .media-left > .img-xs,
	.sidebar-xs .sidebar-main .sidebar-user .media-right > .img-xs {
		margin-top: 3px;
		margin-bottom: 3px;
	}
	.sidebar-xs .sidebar-main .sidebar-user .media-body,
	.sidebar-xs .sidebar-main .sidebar-user .media-right {
		display: none;
	}
	.sidebar-xs .sidebar-main .navigation-lg > li > ul {
		top: 48px;
	}
	.sidebar-xs .sidebar-main .navigation-sm > li > ul {
		top: 40px;
	}
	.sidebar-xs .sidebar-main .navigation-xs > li > ul {
		top: 36px;
	}
	.sidebar-xs .sidebar-main.sidebar-default .navigation > li > a > span {
		background-color: $light-gray;
		color: $gray-4;
	}
	.sidebar-xs
		.sidebar-main.sidebar-default
		.navigation
		> li
		> a
		> span
		.label,
	.sidebar-xs
		.sidebar-main.sidebar-default
		.navigation
		> li
		> a
		> span
		.badge {
		color: $gray-4;
	}
	.sidebar-xs .sidebar-main.sidebar-default .navigation > li > ul {
		background-color: var(--white);
		border-top: 0;
	}
	.sidebar-xs
		.sidebar-main.sidebar-default
		.navigation
		> li:hover:not(.active)
		> a {
		background-color: $light-gray;
		color: $gray-4;
	}
}

.sidebar .row {
	margin-left: -5px;
	margin-right: -5px;
}

.sidebar .row [class*="col-"] {
	padding-left: 5px;
	padding-right: 5px;
}

@media (max-width: 768px) {
	.sidebar .sp-container {
		display: block;
		width: 220px;
		margin-left: auto;
		margin-right: auto;
	}
}

.sidebar .sp-flat .sp-picker-container {
	display: block;
	width: 218px;
}

.sidebar .panel-group .panel {
	border-radius: 0;
	border-width: 0 0 1px 0;
}

.sidebar .panel-group .panel:first-child {
	border-top-width: 1px;
}

.sidebar .panel-group .panel + .panel {
	margin-top: 0;
}

.sidebar .media-list-bordered > li {
	border-top: 0;
	border-bottom: 1px solid $light-gray-9;
}

.sidebar:not(.sidebar-default) .media .text-muted,
.sidebar:not(.sidebar-default) .media .media-annotation {
	color: rgba(255, 255, 255, 0.8);
}

.sidebar:not(.sidebar-default) .media .media-left > a,
.sidebar:not(.sidebar-default) .media .media-body > a,
.sidebar:not(.sidebar-default) .media .media-right > a {
	color: var(--white);
}

.sidebar:not(.sidebar-default) .media .media-link:hover,
.sidebar:not(.sidebar-default) .media .media-link:focus {
	background-color: rgba(0, 0, 0, 0.1);
}

.sidebar:not(.sidebar-default) .media .media-badge {
	border-color: $gray-shade-3;
}

.sidebar:not(.sidebar-default) .media-list-bordered > li {
	border-color: rgba(255, 255, 255, 0.1);
}

.sidebar .thumbnail {
	margin-bottom: 10px;
}

.sidebar .thumbnail:last-child {
	margin-bottom: 0;
}

.sidebar .thumbnail .zoom-image i {
	font-size: 16px;
	margin-top: -8px;
	margin-left: -8px;
}

.sidebar .sidebar-category .checkbox,
.sidebar .sidebar-category .radio {
	margin-top: 0;
}

.sidebar .sidebar-category .checkbox:last-child,
.sidebar .sidebar-category .radio:last-child {
	margin-bottom: 0;
}

.sidebar .form-group:last-child {
	margin-bottom: 0;
}

.sidebar .nav-tabs {
	border-width: 0 0 1px 0;
}

@media (min-width: 769px) {
	.sidebar .nav-tabs {
		border-width: 1px;
	}
	.sidebar .nav-tabs > li > a {
		border-bottom-color: rgba(255, 255, 255, 0.1);
		background-color: rgba(0, 0, 0, 0.2);
		color: rgba(255, 255, 255, 0.6);
		border-top: 0;
		border-radius: 0;
	}
	.sidebar .nav-tabs > li > a:hover,
	.sidebar .nav-tabs > li > a:focus {
		color: var(--white);
		border-bottom-color: rgba(255, 255, 255, 0.1);
	}
	.sidebar .nav-tabs > .active > a,
	.sidebar .nav-tabs > .active > a:hover,
	.sidebar .nav-tabs > .active > a:focus {
		border-top: 0;
		border-bottom: 0;
		background-color: transparent;
		border-color: rgba(255, 255, 255, 0.1);
		color: var(--white);
	}
	.sidebar .nav-tabs > .active:first-child > a {
		border-left-color: transparent !important;
	}
	.sidebar .nav-tabs > .active:last-child > a {
		border-right-color: transparent !important;
	}
	.sidebar .nav-tabs > .open > a {
		color: var(--white);
	}
	.sidebar .nav-tabs > .open:not(.active) > a {
		background-color: rgba(0, 0, 0, 0.2);
	}
}

@media (min-width: 769px) {
	.sidebar-default .nav-tabs > li > a {
		background-color: $light-gray-15;
		border-bottom-color: $light-gray-13;
		color: $gray-3;
	}
	.sidebar-default .nav-tabs > li > a:hover,
	.sidebar-default .nav-tabs > li > a:focus {
		color: $gray-4;
		border-bottom-color: $light-gray-13;
	}
	.sidebar-default .nav-tabs > .active > a,
	.sidebar-default .nav-tabs > .active > a:hover,
	.sidebar-default .nav-tabs > .active > a:focus {
		border-color: $light-gray-13;
		color: $gray-4;
	}
	.sidebar-default .nav-tabs > .open > a {
		border-bottom-color: $light-gray-13;
		color: $gray-4;
	}
	.sidebar-default .nav-tabs > .open:not(.active) > a {
		background-color: $light-gray-15;
	}
}

@media (min-width: 769px) {
	.sidebar-default
		.nav-justified
		> li:first-child
		.dropdown-menu:not(.dropdown-menu-right) {
		left: -1px;
	}
	.sidebar-default .nav-justified > li:last-child .dropdown-menu-right {
		right: -1px;
	}
}

/* ------------------------------------------------------------------------------
*
*  # Main content layout
*
*  Styles for main structure of content area
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.page-container {
	position: relative;
	height: 100%;
}

@media (min-width: 769px) {
	.page-container {
		width: 100%;
		display: table;
		table-layout: fixed;
		padding-top: 50px;
	}
	@-moz-document url-prefix() {
		.page-container {
			height: 1px;
		}
	}
}

@media (min-width: 769px) {
	.page-content {
		display: table-row;
	}
}

.content-wrapper {
	width: 100%;
}

@media (min-width: 769px) {
	.content-wrapper {
		display: table-cell;
		vertical-align: top;
	}
}

.content {
	padding: 0 20px 60px 20px;
}

.content:after {
	content: "";
	display: table;
	clear: both;
}

.content:first-child {
	padding-top: 20px;
}

body[class*="navbar-bottom"] .content {
	padding-bottom: 20px;
}

/* ------------------------------------------------------------------------------
*
*  # Boxed layout
*
*  Styles for main structure of content area in boxed layout
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */

.layout-boxed > .navbar {
	margin-left: auto;
	margin-right: auto;
}

.layout-boxed .page-container {
	background-color: $gray-shade-1;
	margin-left: auto;
	margin-right: auto;
	min-width: 0;
}

@media (min-width: 769px) {
	.layout-boxed .page-container,
	.layout-boxed > .navbar {
		width: 769px;
	}
}

@media (min-width: 1025px) {
	.layout-boxed .page-container,
	.layout-boxed > .navbar {
		width: 1025px;
	}
}

@media (min-width: 1200px) {
	.layout-boxed .page-container,
	.layout-boxed > .navbar {
		width: 1200px;
	}
}

/* ------------------------------------------------------------------------------
*
*  # Footer
*
*  Main footer styles, currently 1 version only
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.footer {
	position: absolute;
	bottom: 30px;
}

.footer:not(.navbar-fixed-bottom) {
	z-index: 998;
}

.footer.navbar {
	left: 0;
	right: 0;
	bottom: 0;
}

body[class*="navbar-bottom"] .footer:not(.navbar) {
	display: none;
}

.footer-boxed {
	left: 0;
	right: 0;
	padding-left: 20px;
	padding-right: 20px;
}

/* ------------------------------------------------------------------------------
*
*  # Utilites
*
*  Additional animations, transitions, transforms and other utilities
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.rotate-45 {
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

.rotate-90 {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
}

.rotate-180 {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);
}

.rotate-45-inverse {
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.rotate-90-inverse {
	-webkit-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	transform: rotate(-90deg);
}

.rotate-180-inverse {
	-webkit-transform: rotate(-180deg);
	-ms-transform: rotate(-180deg);
	-o-transform: rotate(-180deg);
	transform: rotate(-180deg);
}

.spinner {
	display: inline-block;
	-webkit-animation: rotation 1s linear infinite;
	-o-animation: rotation 1s linear infinite;
	animation: rotation 1s linear infinite;
}

.spinner-reverse {
	display: inline-block;
	-webkit-animation: rotation_reverse 1s linear infinite;
	-o-animation: rotation_reverse 1s linear infinite;
	animation: rotation_reverse 1s linear infinite;
}

@-webkit-keyframes rotation {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@-moz-keyframes rotation {
	0% {
		-moz-transform: rotate(0deg);
	}
	100% {
		-moz-transform: rotate(360deg);
	}
}

@-ms-keyframes rotation {
	0% {
		-ms-transform: rotate(0deg);
	}
	100% {
		-ms-transform: rotate(360deg);
	}
}

@-o-keyframes rotation {
	0% {
		-o-transform: rotate(0deg);
	}
	100% {
		-o-transform: rotate(360deg);
	}
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@-webkit-keyframes rotation_reverse {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(-360deg);
	}
}

@-moz-keyframes rotation_reverse {
	0% {
		-moz-transform: rotate(0deg);
	}
	100% {
		-moz-transform: rotate(-360deg);
	}
}

@-ms-keyframes rotation_reverse {
	0% {
		-ms-transform: rotate(0deg);
	}
	100% {
		-ms-transform: rotate(-360deg);
	}
}

@-o-keyframes rotation_reverse {
	0% {
		-o-transform: rotate(0deg);
	}
	100% {
		-o-transform: rotate(-360deg);
	}
}

@keyframes rotation_reverse {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}

@-webkit-keyframes bounceIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes bounceOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

/* ------------------------------------------------------------------------------
 *
 *  # Pace. Default theme
 *
 *  Minimal preloader theme, used as a default theme
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.pace {
	-webkit-pointer-events: none;
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.pace .pace-progress {
	background: var(--affirmative);
	position: fixed;
	z-index: 2000;
	top: 0;
	width: 100%;
	right: 100%;
	height: 2px;
}

.pace-inactive {
	display: none;
}

.choice {
	position: relative;
	display: inline-block;
	cursor: pointer;
	vertical-align: middle;
	border-radius: 100%;
}

.choice,
.choice span,
.choice input {
	width: 14px;
	height: 14px;
}

.choice span {
	border: 1px solid $light-gray-14;
	display: -moz-inline-box;
	display: inline-block;
	border-radius: 100%;
	text-align: center;
	position: relative;
}

.choice span:after {
	content: "";
	position: absolute;
	top: 3px;
	left: 3px;
	border: 3px solid #42a7f5;
	width: 0;
	height: 0;
	border-radius: 100%;
	opacity: 0;
	filter: alpha(opacity=0);
	-webkit-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
}

.choice span.checked:after {
	opacity: 1;
	filter: alpha(opacity=100);
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}

.radio .choice,
.radio-inline .choice {
	position: absolute;
	top: 2px;
	left: 0;
}

.form-horizontal .radio .choice,
.form-horizontal .radio-inline .choice {
	top: 10px;
}

.radio-right .choice {
	left: auto;
	right: 0;
}

.choice.active span:after,
.radio > label:active .choice span:after,
.radio-inline:active .choice span:after {
	opacity: 0.75;
	filter: alpha(opacity=75);
}

.choice.disabled {
	opacity: 0.8;
	filter: alpha(opacity=80);
}

.choice.disabled,
.choice.disabled input[type="radio"] {
	cursor: not-allowed;
}

.radio > label:active .choice.disabled span:after,
.radio-inline:active .choice.disabled span:after {
	opacity: 0;
	filter: alpha(opacity=0);
}

.radio > label:active .choice.disabled span.checked:after,
.radio-inline:active .choice.disabled span.checked:after {
	opacity: 1;
	filter: alpha(opacity=100);
}

.choice[class*="border-"] span {
	border-color: inherit;
}

.choice[class*="border-"] span:after {
	border-color: inherit;
}

.dropdown-menu > .active .choice span,
.dropdown-menu[class*="bg-"] .choice span,
.page-header-inverse .form-group > .radio .choice span,
.page-header-inverse .form-group > .radio-inline .choice span,
.navbar-inverse .navbar-form .form-group > .radio .choice span,
.navbar-inverse .navbar-form .form-group > .radio-inline .choice span,
.sidebar:not(.sidebar-default) .radio .choice span,
.sidebar:not(.sidebar-default) .radio-inline .choice span {
	border-color: var(--white);
}

.uploader {
	position: relative;
	display: inline-block;
}

.uploader .filename {
	color: $gray-3;
	padding: 8px 16px;
	cursor: pointer;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	border: 1px solid transparent;
	float: right;
}

.uploader .action {
	cursor: pointer;
	margin: 0;
}

.uploader .action.btn {
	margin-top: 0;
}

.uploader:hover .action,
.uploader:focus .action {
	-webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
	box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
}

.uploader:active .action {
	-webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
	box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
}

.uploader input[type="file"] {
	width: 100%;
	margin-top: 0;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	height: 38px;
	border: 0;
	cursor: pointer;
	z-index: 10;
	opacity: 0;
	filter: alpha(opacity=0);
}

.uploader.disabled input[type="file"] {
	cursor: not-allowed;
}

.uploader.disabled .action {
	opacity: 0.6;
	filter: alpha(opacity=60);
}

.sidebar .form-group > .uploader {
	display: block;
}

.sidebar .form-group > .uploader .filename {
	float: none;
	padding-left: 0;
	padding-right: 0;
}

.sidebar .form-group > .uploader .action {
	display: block;
}

.page-header-inverse .form-group > .uploader .filename,
.navbar-inverse .navbar-form .form-group > .uploader .filename {
	color: rgba(255, 255, 255, 0.9);
}

.uploader-lg input[type="file"],
.uploader-lg .action,
.uploader-lg .filename {
	height: 40px;
}

.uploader-lg .filename {
	padding: 9px 18px;
	font-size: 14px;
	line-height: 1.4285715;
}

.uploader-sm input[type="file"],
.uploader-sm .action,
.uploader-sm .filename {
	height: 36px;
}

.uploader-sm .filename {
	padding: 7px 14px;
	font-size: 12px;
	line-height: 1.6666667;
}

.uploader-xs input[type="file"],
.uploader-xs .action,
.uploader-xs .filename {
	height: 34px;
}

.uploader-xs .filename {
	padding: 6px 12px;
	font-size: 12px;
	line-height: 1.6666667;
}

/* ------------------------------------------------------------------------------
 *
 *  # Form Validation
 *
 *  jQuery plugin for simple clientside form validation
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.validation-error-label,
.validation-valid-label {
	margin-top: 8px;
	margin-bottom: 8px;
	display: block;
	color: $red;
	position: relative;
	padding-left: 26px;
}

.validation-valid-label {
	color: $green-1;
}

.validation-error-label:before,
.validation-valid-label:before {
	font-family: "icomoon";
	position: absolute;
	top: 2px;
	left: 0;
	display: inline-block;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 16px;
}

.validation-error-label:empty,
.validation-valid-label:empty {
	display: none;
}

.validation-error-label:before {
	content: "\ed63";
}

.validation-valid-label:before {
	content: "\ee73";
}

/* ------------------------------------------------------------------------------
*
*  # X-editable
*
*  In-place editing with Twitter Bootstrap, jQuery UI or pure jQuery
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.editable {
	background-color: transparent;
}

.form-horizontal .editable {
	padding-top: 9px;
	display: inline-block;
}

.editable-pre-wrapped {
	white-space: pre-wrap;
}

.editableform .form-group:last-child {
	margin-bottom: 0;
}

.editableform .form-control {
	min-width: 200px;
}

.editableform .checkbox,
.editableform .radio {
	margin-top: 0;
	margin-bottom: 0;
}

.editableform .checkbox > div,
.editableform .radio > div {
	margin-top: 9px;
	margin-bottom: 9px;
	position: relative;
}

.editable-buttons {
	display: inline-block;
	vertical-align: top;
	margin-left: 8px;
}

.editable-buttons.editable-buttons-bottom {
	display: block;
	margin-top: 20px;
	margin-left: 0;
	text-align: center;
}

.editable-buttons .editable-cancel {
	margin-left: 8px;
}

.editable-input {
	vertical-align: top;
	display: inline-block;
	width: auto;
	white-space: normal;
}

.editable-input .help-block {
	margin-bottom: 0;
}

.editable-input .input-group {
	width: 250px;
}

.editable-input .elastic {
	height: 100px;
}

.editable-input .datepicker-inline {
	padding: 16px;
}

.editableform-loading {
	width: 16px;
	height: 16px;
	margin: auto;
}

.editableform-loading:after {
	content: "\eb55";
	font-family: "icomoon";
	font-size: 16px;
	display: block;
	line-height: 1;
	margin: auto;
	width: 16px;
	height: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-animation: rotation 1s ease infinite;
	-o-animation: rotation 1s ease infinite;
	animation: rotation 1s ease infinite;
}

.editable-error {
	color: $red;
}

.editable-error-block {
	max-width: 300px;
	margin: 8px 0 0 0;
	width: auto;
	white-space: normal;
}

.editable-bg-transition {
	color: inherit;
	background-color: transparent;
	-webkit-transition: background-color 0.2s ease-out;
	-o-transition: background-color 0.2s ease-out;
	transition: background-color 0.2s ease-out;
}

.editable-clear {
	clear: both;
	text-decoration: none;
	text-align: right;
}

.editable-clear-x {
	position: absolute;
	right: 5px;
	top: 11px;
	line-height: 1;
	cursor: pointer;
	z-index: 100;
	opacity: 0.6;
	filter: alpha(opacity=60);
}

.editable-clear-x:hover {
	opacity: 1;
	filter: alpha(opacity=100);
}

.editable-clear-x:after {
	content: "\ed6b";
	font-family: "icomoon";
	display: block;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

//.editable-click,
//a.editable-click,
//.editable-click:hover,
//a.editable-click:hover {
//	border-bottom: 1px dashed #9E9E9E;
//}

.editable-click.editable-disabled,
a.editable-click.editable-disabled,
.editable-click.editable-disabled:hover,
a.editable-click.editable-disabled:hover {
	color: $gray-3;
	cursor: default;
	border-bottom: 0;
}

.editable-click .label {
	margin-top: 1px;
	margin-bottom: 1px;
}

.editable-empty,
.editable-empty:hover,
.editable-empty:focus {
	color: $gray-shade-5;
}

a.editable-empty,
a.editable-empty:hover,
a.editable-empty:focus {
	border-bottom-color: $gray-shade-5;
}

.combodate {
	white-space: nowrap;
}

.combodate .day,
.combodate .year,
.combodate .hour,
.combodate .minute {
	min-width: 80px;
}

.combodate .form-control {
	display: inline-block;
}

.input-daterange input {
	text-align: center;
}

.input-daterange input:first-child {
	border-bottom-left-radius: 3px;
	border-top-left-radius: 3px;
}

.input-daterange input:last-child {
	border-bottom-right-radius: 3px;
	border-top-right-radius: 3px;
}

.editable-address {
	display: block;
}

.editable-address > label > span {
	width: 70px;
	display: inline-block;
	margin-bottom: 8px;
}

.editable-date {
	padding: 0;
	margin: 0;
	float: left;
}

.editable-checklist label {
	white-space: nowrap;
}

.editable-checklist label > input[type="checkbox"] {
	vertical-align: middle;
	margin: 0;
}

.editable-wysihtml5 {
	width: 566px;
	height: 250px;
}

.editable-container.editable-popup {
	max-width: none !important;
}

.editable-container.editable-inline {
	display: block;
	vertical-align: middle;
	width: auto;
}

.editable-container.editable-inline .editableform-loading {
	background-position: left 5px;
}

.editable-container.editable-inline .add-on .icon-th {
	margin-top: 3px;
	margin-left: 1px;
}

.editable-container.popover {
	width: auto;
	min-width: 200px;
}

/* ------------------------------------------------------------------------------
*
*  # Tokenfield for Bootstrap
*
*  Styles for tokenfield.js - Advanced tagging/tokenizing plugin for Bootstrap
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.tokenfield {
	border-width: 0;
}

.tokenfield.form-control {
	height: auto;
	padding: 0;
}

.tokenfield:after {
	content: "";
	display: table;
	clear: both;
}

.tokenfield .token {
	margin-top: 3px;
	margin-bottom: 3px;
	margin-right: 6px;
	cursor: default;
	float: left;
	position: relative;
	border-radius: 100px;
}

.tokenfield .token:not([class*="bg-"]) {
	background-color: $light-gray-9;
	color: $gray-4;
}

.tokenfield .token:not([class*="bg-"]):hover,
.tokenfield .token:not([class*="bg-"]):focus {
	background-color: #03a9f4;
	color: var(--white);
}

.tokenfield .token.invalid {
	background-color: transparent;
	border: 1px solid transparent;
	border-radius: 0;
	border-bottom: 1px dotted $red;
}

.tokenfield .token.invalid.active {
	background-color: $light-gray-9;
	border: 1px solid $light-gray-9;
}

.tokenfield .token .token-label {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 8px 16px;
	padding-right: 35px;
}

.tokenfield .token > .close {
	font-size: 0;
	cursor: pointer;
	position: absolute;
	top: 50%;
	color: inherit;
	right: 16px;
	line-height: 1;
	margin-top: -5.5px;
}

.tokenfield .token > .close:after {
	content: "\ed6a";
	font-family: "icomoon";
	display: block;
	font-size: 11px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.tokenfield .token > .close:hover {
	opacity: 1;
	filter: alpha(opacity=100);
}

.tokenfield + .form-control-feedback {
	margin-top: 3px;
}

.tokenfield .twitter-typeahead {
	width: auto;
}

.tokenfield .token-input {
	direction: ltr;
	background: none;
	width: 80px !important;
	max-width: 50%;
	min-width: 60px;
	padding: 8px 0;
	margin-top: 3px;
	margin-bottom: 3px;
	border: 0;
	outline: 0;
}

.tokenfield.readonly .token .token-label {
	padding-right: 16px;
}

.tokenfield.readonly .token .close {
	display: none;
}

.tokenfield.disabled .token,
.tokenfield.disabled .token .close {
	opacity: 0.5;
	filter: alpha(opacity=50);
}

.tokenfield.disabled .token:hover,
.tokenfield.disabled .token:focus {
	background-color: $light-gray-9;
	color: $gray-4;
}

.tokenfield.disabled,
.tokenfield.disabled .token-input,
.tokenfield.disabled .token,
.tokenfield.disabled .token .close {
	cursor: not-allowed;
}

.input-group-lg .token > .token-label,
.tokenfield.input-lg .token > span {
	padding: 9px 18px;
	padding-right: 38px;
}

.input-group-lg .token-input,
.tokenfield.input-lg .token-input {
	padding-top: 9px;
	padding-bottom: 9px;
}

.input-group-lg .token > .close,
.tokenfield.input-lg .token > .close {
	right: 18px;
}

.input-group-lg.readonly .token > .token-label,
.tokenfield.input-lg.readonly .token > .token-label {
	padding-right: 18px;
}

.input-group-sm .token > .token-label,
.tokenfield.input-sm .token > span {
	padding: 7px 14px;
	padding-right: 32px;
}

.input-group-sm .token-input,
.tokenfield.input-sm .token-input {
	padding-top: 7px;
	padding-bottom: 7px;
}

.input-group-sm .token > .close,
.tokenfield.input-sm .token > .close {
	right: 14px;
}

.input-group-sm.readonly .token > .token-label,
.tokenfield.input-sm.readonly .token > .token-label {
	padding-right: 14px;
}

/* ------------------------------------------------------------------------------
*
*  # Steps wizard
*
*  An all-in-one wizard plugin that is extremely flexible, compact and feature-rich
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.wizard {
	width: 100%;
	overflow: hidden;
}

.wizard > .steps .current-info,
.wizard > .content > .title {
	position: absolute;
	left: -99999px;
}

.wizard > .content {
	overflow: hidden;
	position: relative;
	width: auto;
	padding: 0;
}

.wizard > .content > .body {
	padding: 0 20px;
}

.wizard > .content > iframe {
	border: 0 none;
	width: 100%;
	height: 100%;
}

.wizard > .steps {
	position: relative;
	display: block;
	width: 100%;
}

.wizard > .steps > ul {
	display: table;
	width: 100%;
	table-layout: fixed;
	margin: 0;
	padding: 0;
	list-style: none;
}

.wizard > .steps > ul > li {
	display: table-cell;
	width: auto;
	vertical-align: top;
	text-align: center;
	position: relative;
}

.wizard > .steps > ul > li a {
	position: relative;
	padding-top: 48px;
	margin-top: 20px;
	margin-bottom: 20px;
	display: block;
}

.wizard > .steps > ul > li:before,
.wizard > .steps > ul > li:after {
	content: "";
	display: block;
	position: absolute;
	top: 43px;
	width: 50%;
	height: 2px;
	background-color: $blue-9;
	z-index: 9;
}

.wizard > .steps > ul > li:before {
	left: 0;
}

.wizard > .steps > ul > li:after {
	right: 0;
}

.wizard > .steps > ul > li:first-child:before,
.wizard > .steps > ul > li:last-child:after {
	content: none;
}

.wizard > .steps > ul > li.current:after,
.wizard > .steps > ul > li.current ~ li:before,
.wizard > .steps > ul > li.current ~ li:after {
	background-color: $light-gray-9;
}

.wizard > .steps > ul > li.current > a {
	color: $gray-4;
	cursor: default;
}

.wizard > .steps > ul > li.current .number {
	font-size: 0;
	border-color: $blue-9;
	background-color: var(--white);
	color: $blue-9;
}

.wizard > .steps > ul > li.current .number:after {
	content: "\e913";
	font-family: "icomoon";
	display: inline-block;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 34px;
	-webkit-transition: all 0.15s ease-in-out;
	-o-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out;
}

.wizard > .steps > ul > li.disabled a,
.wizard > .steps > ul > li.disabled a:hover,
.wizard > .steps > ul > li.disabled a:focus {
	color: $gray-3;
	cursor: default;
}

.wizard > .steps > ul > li.done a,
.wizard > .steps > ul > li.done a:hover,
.wizard > .steps > ul > li.done a:focus {
	color: $gray-3;
}

.wizard > .steps > ul > li.done .number {
	font-size: 0;
	background-color: $blue-9;
	border-color: $blue-9;
	color: var(--white);
}

.wizard > .steps > ul > li.done .number:after {
	content: "\ed6f";
	font-family: "icomoon";
	display: inline-block;
	font-size: 16px;
	line-height: 34px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-transition: all 0.15s ease-in-out;
	-o-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out;
}

.wizard > .steps > ul > li.error .number {
	border-color: $red;
	color: $red;
}

@media (max-width: 768px) {
	.wizard > .steps > ul {
		margin-bottom: 20px;
	}
	.wizard > .steps > ul > li {
		display: block;
		float: left;
		width: 50%;
	}
	.wizard > .steps > ul > li > a {
		margin-bottom: 0;
	}
	.wizard > .steps > ul > li:first-child:before,
	.wizard > .steps > ul > li:last-child:after {
		content: "";
	}
	.wizard > .steps > ul > li:last-child:after {
		background-color: $blue-9;
	}
}

@media (max-width: 480px) {
	.wizard > .steps > ul > li {
		width: 100%;
	}
	.wizard > .steps > ul > li.current:after {
		background-color: $blue-9;
	}
}

.wizard > .steps .number {
	background-color: var(--white);
	color: $light-gray-14;
	display: inline-block;
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: -19px;
	width: 38px;
	height: 38px;
	border: 2px solid $light-gray-9;
	font-size: 14px;
	border-radius: 50%;
	z-index: 10;
	line-height: 34px;
	text-align: center;
}

.panel-flat > .wizard > .steps > ul {
	border-top: 1px solid $light-gray-13;
}

.wizard > .actions {
	position: relative;
	display: block;
	text-align: right;
	padding: 20px;
	padding-top: 0;
}

.wizard > .actions > ul {
	float: right;
	list-style: none;
	padding: 0;
	margin: 0;
}

.wizard > .actions > ul:after {
	content: "";
	display: table;
	clear: both;
}

.wizard > .actions > ul > li {
	float: left;
}

.wizard > .actions > ul > li + li {
	margin-left: 10px;
}

.wizard > .actions > ul > li > a {
	background: $blue-shade-1;
	color: var(--white);
	display: block;
	padding: 8px 16px;
	border-radius: 3px;
	text-transform: uppercase;
	font-weight: 500;
	border: 1px solid transparent;
}

.wizard > .actions > ul > li > a:hover,
.wizard > .actions > ul > li > a:focus {
	-webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
	box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
}

.wizard > .actions > ul > li > a:active {
	-webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
	box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
}

.wizard > .actions > ul > li > a[href="#previous"] {
	background-color: $light-gray;
	color: $gray-4;
	border: 1px solid transparent;
}

.wizard > .actions > ul > li > a[href="#previous"]:hover,
.wizard > .actions > ul > li > a[href="#previous"]:focus {
	-webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.02) inset;
	box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.02) inset;
}

.wizard > .actions > ul > li > a[href="#previous"]:active {
	-webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.04) inset;
	box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.04) inset;
}

.wizard > .actions > ul > li.disabled > a,
.wizard > .actions > ul > li.disabled > a:hover,
.wizard > .actions > ul > li.disabled > a:focus {
	color: $gray-3;
}

.wizard > .actions > ul > li.disabled > a[href="#previous"],
.wizard > .actions > ul > li.disabled > a[href="#previous"]:hover,
.wizard > .actions > ul > li.disabled > a[href="#previous"]:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
}

/* ------------------------------------------------------------------------------
*
*  # Form wizard
*
*  jQuery plugin which turns a form into a multistep wizard
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.panel-flat > .ui-formwizard {
	border-top: 1px solid $light-gray-13;
}

.form-wizard-title {
	margin: 0 0 20px 0;
	padding-bottom: 20px;
	border-bottom: 1px solid $light-gray-13;
}

.form-wizard-count {
	float: left;
	display: block;
	width: 38px;
	height: 38px;
	border-radius: 50%;
	border: 2px solid $green-1;
	color: $green-1;
	text-align: center;
	line-height: 34px;
	margin-right: 10px;
}

.ui-formwizard .step {
	padding: 20px;
	padding-bottom: 0;
}

.form-wizard-actions {
	text-align: right;
	padding: 20px;
	padding-top: 0;
}

.panel-body .form-wizard-actions {
	padding: 0;
}

.form-wizard-actions .btn + .btn {
	margin-left: 5px;
}

/* ------------------------------------------------------------------------------
*
*  # Stepy wizard
*
*  jQuery plugin which generates a customizable wizard from form fieldsets
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.stepy-step {
	padding: 20px;
	padding-top: 0;
}

.stepy-header {
	list-style: none;
	padding: 0;
	margin: 0;
	display: table;
	table-layout: fixed;
	width: 100%;
}

.panel-flat > .stepy-header {
	border-top: 1px solid $light-gray-13;
}

.stepy-header li {
	cursor: pointer;
	display: table-cell;
	vertical-align: top;
	width: auto;
	padding: 20px 0;
	text-align: center;
	position: relative;
}

.stepy-header li span {
	display: block;
	margin-top: 10px;
	color: $gray-3;
}

.stepy-header li:before,
.stepy-header li:after {
	content: "";
	display: block;
	position: absolute;
	top: 43px;
	width: 50%;
	height: 2px;
	background-color: $blue-9;
	z-index: 9;
}

.stepy-header li:before {
	left: 0;
}

.stepy-header li:after {
	right: 0;
}

.stepy-header li:first-child:before,
.stepy-header li:last-child:after {
	content: none;
}

.stepy-header li:focus {
	outline: 0;
}

.stepy-header li div {
	background-color: $blue-9;
	font-size: 0;
	position: relative;
	color: var(--white);
	margin-left: auto;
	margin-right: auto;
	width: 38px;
	height: 38px;
	border: 2px solid $blue-9;
	z-index: 10;
	line-height: 34px;
	text-align: center;
	border-radius: 50%;
}

.stepy-header li div:after {
	content: "\ed6c";
	font-family: "icomoon";
	display: inline-block;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 34px;
	-webkit-transition: all 0.15s ease-in-out;
	-o-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out;
}

.stepy-header li.stepy-active:after,
.stepy-header li.stepy-active ~ li:before,
.stepy-header li.stepy-active ~ li:after {
	background-color: $light-gray;
}

.stepy-header li.stepy-active ~ li div {
	border-color: $light-gray-9;
	background-color: var(--white);
	color: $light-gray-14;
	font-size: 14px;
}

.stepy-header li.stepy-active ~ li div:after {
	content: none;
}

.stepy-header li.stepy-active div {
	cursor: auto;
	border-color: $blue-9;
	background-color: var(--white);
	color: $blue-9;
}

.stepy-header li.stepy-active div:after {
	content: "\e913";
}

.stepy-header li.stepy-active span {
	color: $gray-4;
}

@media (max-width: 769px) {
	.stepy-header {
		margin-bottom: 20px;
	}
	.stepy-header li {
		display: block;
		float: left;
		width: 50%;
		padding-bottom: 0;
	}
	.stepy-header li:first-child:before,
	.stepy-header li:last-child:after {
		content: "";
	}
	.stepy-header li.stepy-active:last-child:after {
		background-color: $blue-9;
	}
}

@media (max-width: 480px) {
	.stepy-header li {
		width: 100%;
	}
	.stepy-header li.stepy-active:after {
		background-color: $blue-9;
	}
}

.stepy-navigator {
	text-align: right;
}

.stepy-navigator .btn + .btn {
	margin-left: 5px;
}

/* ------------------------------------------------------------------------------
 *
 *  # Datepicker for Bootstrap
 *
 *  Styles for bootstrap datepicker. Comes as a part of datepaginator.js plugin
 *
 *  Version: 1.2
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.datepicker > div {
	display: none;
}

.datepicker.datepicker-dropdown {
	top: 0;
	left: 0;
	margin: 0;
	padding: 16px;
}

.datepicker table {
	margin: 0;
}

.datepicker table tr td.day:hover {
	background-color: $light-gray;
	cursor: pointer;
}

.datepicker table tr td.old,
.datepicker table tr td.new {
	color: $gray-3;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
	background-color: transparent;
	color: $gray-3;
	cursor: default;
}

.datepicker table tr td.today,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled:hover {
	background-color: $light-gray;
}

.datepicker table tr td.range,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled:hover {
	background-color: $light-gray;
	border-radius: 0;
}

.datepicker table tr td.range.today,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today.active:hover {
	background-color: $light-gray;
}

.datepicker table tr td.active,
.datepicker table tr td span.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td span.active.disabled,
.datepicker table tr td.active:hover,
.datepicker table tr td span.active:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover {
	background-color: #26a69a;
	color: var(--white);
}

.datepicker table tr td span {
	display: block;
	width: 31%;
	padding: 8px 16px;
	float: left;
	margin: 1%;
	cursor: pointer;
	border-radius: 3px;
}

.datepicker table tr td span:hover {
	background-color: $light-gray;
}

.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
	background-color: transparent;
	color: $gray-3;
	cursor: default;
}

.datepicker table tr td span.old,
.datepicker table tr td span.new {
	color: $gray-3;
}

.datepicker table th.datepicker-switch {
	font-size: 17px;
	font-weight: 400;
}

.datepicker table thead tr:first-child th,
.datepicker table tfoot tr th {
	cursor: pointer;
	padding-top: 15px;
	padding-bottom: 15px;
	line-height: 1;
}

.datepicker table .cw {
	width: 16px;
	padding: 0 2px 0 5px;
	vertical-align: middle;
}

.datepicker table thead tr:first-child th.cw {
	cursor: default;
	background-color: transparent;
}

.datepicker .table-condensed td,
.datepicker .table-condensed th {
	text-align: center;
	padding: 10px;
	border-radius: 3px;
	border: 0;
	line-height: 1;
}

.datepicker .table-condensed th.dow {
	padding-top: 16px;
	color: $gray-3;
}

.table-striped .datepicker table tr th,
.table-striped .datepicker table tr td {
	background-color: transparent;
}

.custom-today-class {
	border: 1px solid lightblue;
	background-color: var(--white);
}

/* ------------------------------------------------------------------------------
 *
 *  # Sweet alerts
 *
 *  Styles for sweet_alert.min.js - A beautiful replacement for JavaScript's "Alert"
 *
 *  Version: 1.1
 *  Latest update: Oct 28, 2015
 *
 * ---------------------------------------------------------------------------- */
/* # Core
-------------------------------------------------- */
body.stop-scrolling {
	height: 100%;
	overflow: hidden;
}

.sweet-overlay {
	background-color: rgba(0, 0, 0, 0.4);
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: none;
	z-index: 1050;
}

.sweet-alert {
	background-color: var(--white);
	width: 470px;
	padding: 20px;
	border-radius: 3px;
	text-align: center;
	position: fixed;
	left: 50%;
	top: 30%;
	margin-left: -235px;
	margin-top: -200px;
	overflow: hidden;
	display: none;
	z-index: 1060;
	-webkit-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
		0 10px 10px rgba(0, 0, 0, 0.22);
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.sweet-alert h2 {
	margin-top: 10px;
	font-size: 19px;
	text-align: center;
	display: block;
	position: relative;
}

.sweet-alert p {
	text-align: center;
	position: relative;
}

.sweet-alert fieldset {
	border: none;
	position: relative;
}

.sweet-alert button {
	background-color: $blue-shade-1;
	color: var(--white);
	border: 0;
	border-radius: 3px;
	padding: 8px 15px;
	margin: 10px 5px 0 5px;
	box-shadow: none !important;
}

.sweet-alert button:hover {
	background-color: $blue-7;
}

.sweet-alert button:focus {
	outline: 0;
}

.sweet-alert button:active {
	background-color: $blue-6;
}

.sweet-alert button.cancel {
	background-color: transparent;
	color: $gray-4;
}

.sweet-alert button[disabled] {
	cursor: default;
	opacity: 0.6;
	filter: alpha(opacity=60);
}

.sweet-alert button.confirm[disabled] {
	color: transparent;
}

.sweet-alert button.confirm[disabled] ~ .la-ball-fall {
	visibility: visible;
	-webkit-transition-delay: 0s;
	transition-delay: 0s;
	opacity: 1;
	filter: alpha(opacity=100);
}

.sweet-alert button::-moz-focus-inner {
	border: 0;
}

.sweet-alert[data-has-cancel-button="false"] button {
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

.sweet-alert[data-has-cancel-button="false"][data-has-confirm-button="false"] {
	padding-bottom: 40px;
}

.sweet-alert .sa-error-container {
	background-color: $light-gray;
	overflow: hidden;
	padding: 0 10px;
	max-height: 0;
	border-radius: 3px;
	-webkit-transition: padding 0.15s, max-height 0.15s;
	-o-transition: padding 0.15s, max-height 0.15s;
	transition: padding 0.15s, max-height 0.15s;
}

.sweet-alert .sa-error-container p {
	display: inline-block;
	margin-bottom: 0;
}

.sweet-alert .sa-error-container.show {
	padding: 10px 0;
	max-height: 100px;
	-webkit-transition: padding 0.2s, max-height 0.2s;
	-o-transition: padding 0.2s, max-height 0.2s;
	transition: padding 0.2s, max-height 0.2s;
}

.sweet-alert .sa-error-container .icon {
	display: inline-block;
	width: 16px;
	height: 16px;
	line-height: 16px;
	border-radius: 50%;
	background-color: #ff7043;
	color: var(--white);
	text-align: center;
	margin-right: 8px;
}

.sweet-alert .sa-input-error {
	position: absolute;
	top: 20px;
	right: 16px;
	width: 16px;
	height: 16px;
	-webkit-transform: scale(0.5);
	-ms-transform: scale(0.5);
	-o-transform: scale(0.5);
	transform: scale(0.5);
	-webkit-transform-origin: 50% 50%;
	-moz-transform-origin: 50% 50%;
	-ms-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
	-webkit-transition: all 0.1s;
	-o-transition: all 0.1s;
	transition: all 0.1s;
	opacity: 0;
	filter: alpha(opacity=0);
}

.sweet-alert .sa-input-error:before,
.sweet-alert .sa-input-error:after {
	content: "";
	width: 16px;
	height: 2px;
	background-color: #ef5350;
	border-radius: 3px;
	position: absolute;
	top: 50%;
	margin-top: -1px;
	left: 50%;
	margin-left: -8px;
}

.sweet-alert .sa-input-error:before {
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.sweet-alert .sa-input-error:after {
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

.sweet-alert .sa-input-error.show {
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
	opacity: 1;
	filter: alpha(opacity=100);
}

.sweet-alert input {
	width: 100%;
	border-radius: 3px;
	border: 1px solid $light-gray-13;
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 13px;
	padding: 8px 16px;
	display: none;
	-webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05) inset;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05) inset;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}

.sweet-alert input::-moz-placeholder {
	color: #bdbdbd;
	opacity: 1;
}

.sweet-alert input:-ms-input-placeholder {
	color: #bdbdbd;
}

.sweet-alert input::-webkit-input-placeholder {
	color: #bdbdbd;
}

.sweet-alert input:focus {
	outline: 0;
}

.sweet-alert input:focus::-moz-placeholder,
.sweet-alert input:focus:-ms-input-placeholder,
.sweet-alert input:focus::-webkit-input-placeholder {
	-webkit-transition: opacity ease 0.3s 0.03s;
	-o-transition: opacity ease 0.3s 0.03s;
	transition: opacity ease 0.3s 0.03s;
	opacity: 0.5;
	filter: alpha(opacity=50);
}

.sweet-alert.show-input input {
	display: block;
}

.sweet-alert .sa-confirm-button-container {
	display: inline-block;
	position: relative;
}

.sweet-alert .la-ball-fall {
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -27px;
	margin-top: 0;
	visibility: hidden;
	opacity: 0;
	filter: alpha(opacity=0);
}

.sweet-alert .sa-icon {
	width: 80px;
	height: 80px;
	border: 4px solid $light-gray-13;
	border-radius: 50%;
	margin: 10px auto 20px auto;
	padding: 0;
	position: relative;
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}

.sweet-alert .sa-icon.sa-error {
	border-color: $red;
}

.sweet-alert .sa-icon.sa-error .sa-x-mark {
	position: relative;
	display: block;
}

.sweet-alert .sa-icon.sa-error .sa-line {
	position: absolute;
	height: 5px;
	width: 47px;
	background-color: $red;
	display: block;
	top: 37px;
	border-radius: 2px;
}

.sweet-alert .sa-icon.sa-error .sa-line.sa-left {
	left: 17px;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

.sweet-alert .sa-icon.sa-error .sa-line.sa-right {
	right: 16px;
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.sweet-alert .sa-icon.sa-warning {
	border-color: $red-1;
}

.sweet-alert .sa-icon.sa-warning .sa-body {
	position: absolute;
	width: 5px;
	height: 47px;
	left: 50%;
	top: 10px;
	border-radius: 2px;
	margin-left: -2px;
	background-color: $red-1;
}

.sweet-alert .sa-icon.sa-warning .sa-dot {
	position: absolute;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	margin-left: -3px;
	left: 50%;
	bottom: 10px;
	background-color: $red-1;
}

.sweet-alert .sa-icon.sa-info {
	border-color: $blue-shade-1;
}

.sweet-alert .sa-icon.sa-info:before {
	content: "";
	position: absolute;
	width: 5px;
	height: 29px;
	left: 50%;
	bottom: 17px;
	border-radius: 2px;
	margin-left: -2px;
	background-color: $blue-shade-1;
}

.sweet-alert .sa-icon.sa-info:after {
	content: "";
	position: absolute;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	margin-left: -3px;
	top: 19px;
	background-color: $blue-shade-1;
}

.sweet-alert .sa-icon.sa-success {
	border-color: $green-1;
}

.sweet-alert .sa-icon.sa-success:before,
.sweet-alert .sa-icon.sa-success:after {
	content: "";
	border-radius: 50%;
	position: absolute;
	width: 60px;
	height: 120px;
	background-color: var(--white);
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

.sweet-alert .sa-icon.sa-success:before {
	border-radius: 120px 0 0 120px;
	top: -7px;
	left: -33px;
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
	-webkit-transform-origin: 60px 60px;
	-moz-transform-origin: 60px 60px;
	-ms-transform-origin: 60px 60px;
	transform-origin: 60px 60px;
}

.sweet-alert .sa-icon.sa-success:after {
	border-radius: 0 120px 120px 0;
	top: -11px;
	left: 30px;
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
	-webkit-transform-origin: 0 60px;
	-moz-transform-origin: 0 60px;
	-ms-transform-origin: 0 60px;
	transform-origin: 0 60px;
}

.sweet-alert .sa-icon.sa-success .sa-placeholder {
	width: 80px;
	height: 80px;
	border: 4px solid rgba(76, 175, 80, 0.2);
	border-radius: 50%;
	position: absolute;
	left: -4px;
	top: -4px;
	z-index: 2;
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}

.sweet-alert .sa-icon.sa-success .sa-fix {
	width: 5px;
	height: 90px;
	background-color: var(--white);
	position: absolute;
	left: 28px;
	top: 8px;
	z-index: 1;
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.sweet-alert .sa-icon.sa-success .sa-line {
	height: 5px;
	background-color: $green-1;
	display: block;
	border-radius: 2px;
	position: absolute;
	z-index: 2;
}

.sweet-alert .sa-icon.sa-success .sa-line.sa-tip {
	width: 25px;
	left: 14px;
	top: 46px;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

.sweet-alert .sa-icon.sa-success .sa-line.sa-long {
	width: 47px;
	right: 8px;
	top: 38px;
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.sweet-alert .sa-icon.sa-custom {
	background-size: contain;
	border-radius: 0;
	border: 0;
	background-position: center center;
	background-repeat: no-repeat;
}

@media (max-width: 480px) {
	.sweet-alert {
		width: auto;
		margin-left: 0;
		margin-right: 0;
		left: 20px;
		right: 20px;
	}
}

/* # Animations
-------------------------------------------------- */
@-webkit-keyframes showSweetAlert {
	0% {
		-webkit-transform: scale(0.7);
		-ms-transform: scale(0.7);
		-o-transform: scale(0.7);
		transform: scale(0.7);
	}
	45% {
		-webkit-transform: scale(1.05);
		-ms-transform: scale(1.05);
		-o-transform: scale(1.05);
		transform: scale(1.05);
	}
	80% {
		-webkit-transform: scale(0.95);
		-ms-transform: scale(0.95);
		-o-transform: scale(0.95);
		transform: scale(0.95);
	}
	100% {
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		-o-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes showSweetAlert {
	0% {
		-webkit-transform: scale(0.7);
		-ms-transform: scale(0.7);
		-o-transform: scale(0.7);
		transform: scale(0.7);
	}
	45% {
		-webkit-transform: scale(1.05);
		-ms-transform: scale(1.05);
		-o-transform: scale(1.05);
		transform: scale(1.05);
	}
	80% {
		-webkit-transform: scale(0.95);
		-ms-transform: scale(0.95);
		-o-transform: scale(0.95);
		transform: scale(0.95);
	}
	100% {
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		-o-transform: scale(1);
		transform: scale(1);
	}
}

.showSweetAlert[data-animation="pop"] {
	-webkit-animation: showSweetAlert 0.3s;
	-o-animation: showSweetAlert 0.3s;
	animation: showSweetAlert 0.3s;
}

.showSweetAlert[data-animation="none"] {
	-webkit-animation: none;
	-o-animation: none;
	animation: none;
}

@-webkit-keyframes hideSweetAlert {
	0% {
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		-o-transform: scale(1);
		transform: scale(1);
	}
	100% {
		-webkit-transform: scale(0.5);
		-ms-transform: scale(0.5);
		-o-transform: scale(0.5);
		transform: scale(0.5);
	}
}

@keyframes hideSweetAlert {
	0% {
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		-o-transform: scale(1);
		transform: scale(1);
	}
	100% {
		-webkit-transform: scale(0.5);
		-ms-transform: scale(0.5);
		-o-transform: scale(0.5);
		transform: scale(0.5);
	}
}

.hideSweetAlert[data-animation="pop"] {
	-webkit-animation: hideSweetAlert 0.2s;
	-o-animation: hideSweetAlert 0.2s;
	animation: hideSweetAlert 0.2s;
}

.hideSweetAlert[data-animation="none"] {
	-webkit-animation: none;
	-o-animation: none;
	animation: none;
}

@-webkit-keyframes slideFromTop {
	0% {
		top: 0%;
	}
	100% {
		top: 50%;
	}
}

@keyframes slideFromTop {
	0% {
		top: 0%;
	}
	100% {
		top: 50%;
	}
}

.showSweetAlert[data-animation="slide-from-top"] {
	-webkit-animation: slideFromTop 0.3s;
	-o-animation: slideFromTop 0.3s;
	animation: slideFromTop 0.3s;
}

@-webkit-keyframes slideToTop {
	0% {
		top: 50%;
	}
	100% {
		top: 0%;
	}
}

@keyframes slideToTop {
	0% {
		top: 50%;
	}
	100% {
		top: 0%;
	}
}

.hideSweetAlert[data-animation="slide-from-top"] {
	-webkit-animation: slideToTop 0.4s;
	-o-animation: slideToTop 0.4s;
	animation: slideToTop 0.4s;
}

@-webkit-keyframes slideFromBottom {
	0% {
		top: 70%;
	}
	100% {
		top: 50%;
	}
}

@keyframes slideFromBottom {
	0% {
		top: 70%;
	}
	100% {
		top: 50%;
	}
}

.showSweetAlert[data-animation="slide-from-bottom"] {
	-webkit-animation: slideFromBottom 0.3s;
	-o-animation: slideFromBottom 0.3s;
	animation: slideFromBottom 0.3s;
}

@-webkit-keyframes slideToBottom {
	0% {
		top: 50%;
	}
	100% {
		top: 70%;
	}
}

@keyframes slideToBottom {
	0% {
		top: 50%;
	}
	100% {
		top: 70%;
	}
}

.hideSweetAlert[data-animation="slide-from-bottom"] {
	-webkit-animation: slideToBottom 0.3s;
	-o-animation: slideToBottom 0.3s;
	animation: slideToBottom 0.3s;
}

@-webkit-keyframes animateSuccessTip {
	0% {
		width: 0;
		left: 1px;
		top: 19px;
	}
	54% {
		width: 0;
		left: 1px;
		top: 19px;
	}
	70% {
		width: 50px;
		left: -8px;
		top: 37px;
	}
	84% {
		width: 17px;
		left: 21px;
		top: 48px;
	}
	100% {
		width: 25px;
		left: 14px;
		top: 45px;
	}
}

@keyframes animateSuccessTip {
	0% {
		width: 0;
		left: 1px;
		top: 19px;
	}
	54% {
		width: 0;
		left: 1px;
		top: 19px;
	}
	70% {
		width: 50px;
		left: -8px;
		top: 37px;
	}
	84% {
		width: 17px;
		left: 21px;
		top: 48px;
	}
	100% {
		width: 25px;
		left: 14px;
		top: 45px;
	}
}

.animateSuccessTip {
	-webkit-animation: animateSuccessTip 0.75s;
	-o-animation: animateSuccessTip 0.75s;
	animation: animateSuccessTip 0.75s;
}

@-webkit-keyframes animateSuccessLong {
	0% {
		width: 0;
		right: 46px;
		top: 54px;
	}
	65% {
		width: 0;
		right: 46px;
		top: 54px;
	}
	84% {
		width: 55px;
		right: 0px;
		top: 35px;
	}
	100% {
		width: 47px;
		right: 8px;
		top: 38px;
	}
}

@keyframes animateSuccessLong {
	0% {
		width: 0;
		right: 46px;
		top: 54px;
	}
	65% {
		width: 0;
		right: 46px;
		top: 54px;
	}
	84% {
		width: 55px;
		right: 0px;
		top: 35px;
	}
	100% {
		width: 47px;
		right: 8px;
		top: 38px;
	}
}

.animateSuccessLong {
	-webkit-animation: animateSuccessLong 0.75s;
	-o-animation: animateSuccessLong 0.75s;
	animation: animateSuccessLong 0.75s;
}

@-webkit-keyframes rotatePlaceholder {
	0% {
		-webkit-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		-o-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}
	5% {
		-webkit-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		-o-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}
	12% {
		-webkit-transform: rotate(-405deg);
		-ms-transform: rotate(-405deg);
		-o-transform: rotate(-405deg);
		transform: rotate(-405deg);
	}
	100% {
		-webkit-transform: rotate(-405deg);
		-ms-transform: rotate(-405deg);
		-o-transform: rotate(-405deg);
		transform: rotate(-405deg);
	}
}

@keyframes rotatePlaceholder {
	0% {
		-webkit-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		-o-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}
	5% {
		-webkit-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		-o-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}
	12% {
		-webkit-transform: rotate(-405deg);
		-ms-transform: rotate(-405deg);
		-o-transform: rotate(-405deg);
		transform: rotate(-405deg);
	}
	100% {
		-webkit-transform: rotate(-405deg);
		-ms-transform: rotate(-405deg);
		-o-transform: rotate(-405deg);
		transform: rotate(-405deg);
	}
}

.sa-icon.sa-success.animate::after {
	-webkit-animation: rotatePlaceholder 4.25s ease-in;
	-o-animation: rotatePlaceholder 4.25s ease-in;
	animation: rotatePlaceholder 4.25s ease-in;
}

@-webkit-keyframes animateErrorIcon {
	0% {
		-webkit-transform: rotateX(100deg);
		-ms-transform: rotateX(100deg);
		-o-transform: rotateX(100deg);
		transform: rotateX(100deg);
		opacity: 0;
		filter: alpha(opacity=0);
	}
	100% {
		-webkit-transform: rotateX(0deg);
		-ms-transform: rotateX(0deg);
		-o-transform: rotateX(0deg);
		transform: rotateX(0deg);
		opacity: 1;
		filter: alpha(opacity=100);
	}
}

@keyframes animateErrorIcon {
	0% {
		-webkit-transform: rotateX(100deg);
		-ms-transform: rotateX(100deg);
		-o-transform: rotateX(100deg);
		transform: rotateX(100deg);
		opacity: 0;
		filter: alpha(opacity=0);
	}
	100% {
		-webkit-transform: rotateX(0deg);
		-ms-transform: rotateX(0deg);
		-o-transform: rotateX(0deg);
		transform: rotateX(0deg);
		opacity: 1;
		filter: alpha(opacity=100);
	}
}

.animateErrorIcon {
	-webkit-animation: animateErrorIcon 0.5s;
	-o-animation: animateErrorIcon 0.5s;
	animation: animateErrorIcon 0.5s;
}

@-webkit-keyframes animateXMark {
	0% {
		margin-top: 26px;
		-webkit-transform: scale(0.4);
		-ms-transform: scale(0.4);
		-o-transform: scale(0.4);
		transform: scale(0.4);
		opacity: 0;
		filter: alpha(opacity=0);
	}
	50% {
		margin-top: 26px;
		-webkit-transform: scale(0.4);
		-ms-transform: scale(0.4);
		-o-transform: scale(0.4);
		transform: scale(0.4);
		opacity: 0;
		filter: alpha(opacity=0);
	}
	80% {
		margin-top: -6px;
		-webkit-transform: scale(1.15);
		-ms-transform: scale(1.15);
		-o-transform: scale(1.15);
		transform: scale(1.15);
	}
	100% {
		margin-top: 0;
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		-o-transform: scale(1);
		transform: scale(1);
		opacity: 1;
		filter: alpha(opacity=100);
	}
}

@keyframes animateXMark {
	0% {
		margin-top: 26px;
		-webkit-transform: scale(0.4);
		-ms-transform: scale(0.4);
		-o-transform: scale(0.4);
		transform: scale(0.4);
		opacity: 0;
		filter: alpha(opacity=0);
	}
	50% {
		margin-top: 26px;
		-webkit-transform: scale(0.4);
		-ms-transform: scale(0.4);
		-o-transform: scale(0.4);
		transform: scale(0.4);
		opacity: 0;
		filter: alpha(opacity=0);
	}
	80% {
		margin-top: -6px;
		-webkit-transform: scale(1.15);
		-ms-transform: scale(1.15);
		-o-transform: scale(1.15);
		transform: scale(1.15);
	}
	100% {
		margin-top: 0;
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		-o-transform: scale(1);
		transform: scale(1);
		opacity: 1;
		filter: alpha(opacity=100);
	}
}

.animateXMark {
	-webkit-animation: animateXMark 0.5s;
	-o-animation: animateXMark 0.5s;
	animation: animateXMark 0.5s;
}

@-webkit-keyframes pulseWarning {
	0% {
		border-color: $orange-shade-1;
	}
	100% {
		border-color: $orange-shade-2;
	}
}

@keyframes pulseWarning {
	0% {
		border-color: $orange-shade-1;
	}
	100% {
		border-color: $orange-shade-2;
	}
}

.pulseWarning {
	-webkit-animation: pulseWarning 0.75s infinite alternate;
	-o-animation: pulseWarning 0.75s infinite alternate;
	animation: pulseWarning 0.75s infinite alternate;
}

@-webkit-keyframes pulseWarningIns {
	0% {
		background-color: $orange-shade-1;
	}
	100% {
		background-color: $orange-shade-2;
	}
}

@keyframes pulseWarningIns {
	0% {
		background-color: $orange-shade-1;
	}
	100% {
		background-color: $orange-shade-2;
	}
}

.pulseWarningIns {
	-webkit-animation: pulseWarningIns 0.75s infinite alternate;
	-o-animation: pulseWarningIns 0.75s infinite alternate;
	animation: pulseWarningIns 0.75s infinite alternate;
}

@-webkit-keyframes rotate-loading {
	0% {
		-webkit-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes rotate-loading {
	0% {
		-webkit-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.sweet-alert .sa-icon.sa-error .sa-line.sa-left {
	-ms-transform: rotate(45deg);
}

.sweet-alert .sa-icon.sa-error .sa-line.sa-right {
	-ms-transform: rotate(-45deg);
}

.sweet-alert .sa-icon.sa-success {
	border-color: transparent;
}

.sweet-alert .sa-icon.sa-success .sa-line.sa-tip {
	-ms-transform: rotate(45deg);
}

.sweet-alert .sa-icon.sa-success .sa-line.sa-long {
	-ms-transform: rotate(-45deg);
}

.la-ball-fall {
	display: block;
	font-size: 0;
	color: var(--white);
	width: 54px;
	height: 12px;
}

.la-ball-fall.la-dark {
	color: $gray-4;
}

.la-ball-fall > div {
	display: inline-block;
	float: none;
	background-color: var(--white);
	border: 0 solid var(--white);
	width: 6px;
	height: 6px;
	margin: 2px;
	border-radius: 100%;
	-webkit-animation: ball-fall 1s ease-in-out infinite;
	-o-animation: ball-fall 1s ease-in-out infinite;
	animation: ball-fall 1s ease-in-out infinite;
	opacity: 0;
	filter: alpha(opacity=0);
}

.la-ball-fall > div:nth-child(1) {
	-webkit-animation-delay: -200ms;
	animation-delay: -200ms;
}

.la-ball-fall > div:nth-child(2) {
	-webkit-animation-delay: -100ms;
	animation-delay: -100ms;
}

.la-ball-fall > div:nth-child(3) {
	-webkit-animation-delay: 0ms;
	animation-delay: 0ms;
}

.la-ball-fall.la-sm {
	width: 26px;
	height: 8px;
}

.la-ball-fall.la-sm > div {
	width: 4px;
	height: 4px;
	margin: 2px;
}

.la-ball-fall.la-2x {
	width: 108px;
	height: 36px;
}

.la-ball-fall.la-2x > div {
	width: 20px;
	height: 20px;
	margin: 8px;
}

.la-ball-fall.la-3x {
	width: 162px;
	height: 54px;
}

.la-ball-fall.la-3x > div {
	width: 30px;
	height: 30px;
	margin: 12px;
}

@-webkit-keyframes ball-fall {
	0% {
		-webkit-transform: translate(0, -145%);
		-ms-transform: translate(0, -145%);
		-o-transform: translate(0, -145%);
		transform: translate(0, -145%);
		opacity: 0;
		filter: alpha(opacity=0);
	}
	10% {
		opacity: 0.5;
		filter: alpha(opacity=50);
	}
	20% {
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		-o-transform: translate(0, 0);
		transform: translate(0, 0);
		opacity: 1;
		filter: alpha(opacity=100);
	}
	80% {
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		-o-transform: translate(0, 0);
		transform: translate(0, 0);
		opacity: 1;
		filter: alpha(opacity=100);
	}
	90% {
		opacity: 0.5;
		filter: alpha(opacity=50);
	}
	100% {
		-webkit-transform: translate(0, 145%);
		-ms-transform: translate(0, 145%);
		-o-transform: translate(0, 145%);
		transform: translate(0, 145%);
		opacity: 0;
		filter: alpha(opacity=0);
	}
}

@-moz-keyframes ball-fall {
	0% {
		-webkit-transform: translate(0, -145%);
		-ms-transform: translate(0, -145%);
		-o-transform: translate(0, -145%);
		transform: translate(0, -145%);
		opacity: 0;
		filter: alpha(opacity=0);
	}
	10% {
		opacity: 0.5;
		filter: alpha(opacity=50);
	}
	20% {
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		-o-transform: translate(0, 0);
		transform: translate(0, 0);
		opacity: 1;
		filter: alpha(opacity=100);
	}
	80% {
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		-o-transform: translate(0, 0);
		transform: translate(0, 0);
		opacity: 1;
		filter: alpha(opacity=100);
	}
	90% {
		opacity: 0.5;
		filter: alpha(opacity=50);
	}
	100% {
		-webkit-transform: translate(0, 145%);
		-ms-transform: translate(0, 145%);
		-o-transform: translate(0, 145%);
		transform: translate(0, 145%);
		opacity: 0;
		filter: alpha(opacity=0);
	}
}

@-o-keyframes ball-fall {
	0% {
		-webkit-transform: translate(0, -145%);
		-ms-transform: translate(0, -145%);
		-o-transform: translate(0, -145%);
		transform: translate(0, -145%);
		opacity: 0;
		filter: alpha(opacity=0);
	}
	10% {
		opacity: 0.5;
		filter: alpha(opacity=50);
	}
	20% {
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		-o-transform: translate(0, 0);
		transform: translate(0, 0);
		opacity: 1;
		filter: alpha(opacity=100);
	}
	80% {
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		-o-transform: translate(0, 0);
		transform: translate(0, 0);
		opacity: 1;
		filter: alpha(opacity=100);
	}
	90% {
		opacity: 0.5;
		filter: alpha(opacity=50);
	}
	100% {
		-webkit-transform: translate(0, 145%);
		-ms-transform: translate(0, 145%);
		-o-transform: translate(0, 145%);
		transform: translate(0, 145%);
		opacity: 0;
		filter: alpha(opacity=0);
	}
}

@keyframes ball-fall {
	0% {
		-webkit-transform: translate(0, -145%);
		-ms-transform: translate(0, -145%);
		-o-transform: translate(0, -145%);
		transform: translate(0, -145%);
		opacity: 0;
		filter: alpha(opacity=0);
	}
	10% {
		opacity: 0.5;
		filter: alpha(opacity=50);
	}
	20% {
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		-o-transform: translate(0, 0);
		transform: translate(0, 0);
		opacity: 1;
		filter: alpha(opacity=100);
	}
	80% {
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		-o-transform: translate(0, 0);
		transform: translate(0, 0);
		opacity: 1;
		filter: alpha(opacity=100);
	}
	90% {
		opacity: 0.5;
		filter: alpha(opacity=50);
	}
	100% {
		-webkit-transform: translate(0, 145%);
		-ms-transform: translate(0, 145%);
		-o-transform: translate(0, 145%);
		transform: translate(0, 145%);
		opacity: 0;
		filter: alpha(opacity=0);
	}
}

/* ------------------------------------------------------------------------------
 *
 *  # Bootbox modal extension
 *
 *  Styles for bootbox.min.js - advanced Bootstrap modals
 *
 *  Version: 1.0
 *  Latest update: Oct 9, 2015
 *
 * ---------------------------------------------------------------------------- */
.bootbox .modal-title {
	font-size: 15px;
}

/* ------------------------------------------------------------------------------
*
*  # jQuery UI slider pips
*
*  Styles for jQuery UI slider pips extension
*
*  Version: 1.1
*  Latest update: Nov 15, 2015
*
* ---------------------------------------------------------------------------- */
/* # Pips
-------------------------------------------------- */
.ui-slider-pips.ui-slider-horizontal {
	margin-bottom: 32px;
	margin-left: 10px;
	margin-right: 10px;
}

.ui-slider-pips .ui-slider-label,
.ui-slider-pips .ui-slider-pip-hide {
	display: none;
}

.ui-slider-pips .ui-slider-pip-label .ui-slider-label {
	display: block;
}

.ui-slider-pips .ui-slider-pip {
	height: 10px;
	line-height: 10px;
	font-size: 85%;
	width: 20px;
	margin-left: -11px;
	position: absolute;
	overflow: visible;
	text-align: center;
	top: 20px;
	left: 20px;
	cursor: pointer;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.ui-slider-pips .ui-slider-pip:hover .ui-slider-label {
	font-weight: 500;
}

.ui-slider-pips .ui-slider-line {
	background-color: $gray-3;
	width: 1px;
	height: 3px;
	position: absolute;
	left: 50%;
}

.ui-slider-pips .ui-slider-label {
	position: absolute;
	top: 8px;
	left: 50%;
	width: 20px;
	margin-left: -10px;
}

.ui-slider-pips.ui-slider-vertical {
	margin: 10px 40px 10px 10px;
}

.ui-slider-pips.ui-slider-vertical .ui-slider-pip {
	text-align: left;
	top: auto;
	left: 18px;
	margin-left: 0;
	margin-bottom: -4px;
}

.ui-slider-pips.ui-slider-vertical .ui-slider-line {
	width: 3px;
	height: 1px;
	position: absolute;
	top: 50%;
	left: 0;
}

.ui-slider-pips.ui-slider-vertical .ui-slider-label {
	top: 50%;
	margin-left: 0;
	margin-top: -5px;
	width: 20px;
	left: 5px;
}

/* # Tooltips
-------------------------------------------------- */
.ui-slider-float .ui-slider-handle:focus,
.ui-slider-float .ui-slider-handle:focus .ui-slider-tip,
.ui-slider-float .ui-slider-handle:focus .ui-slider-tip-label {
	outline: 0;
}

.ui-slider-float .ui-slider-tip,
.ui-slider-float .ui-slider-tip-label {
	position: absolute;
	visibility: hidden;
	top: -45px;
	display: block;
	width: 34px;
	margin-left: -16px;
	left: 50%;
	height: 30px;
	line-height: 32px;
	background: $gray-4;
	border-radius: 3px;
	text-align: center;
	font-size: 12px;
	color: var(--white);
	opacity: 0;
	filter: alpha(opacity=0);
	-webkit-transition: all ease-in-out 0.2s 0.2s;
	-o-transition: all ease-in-out 0.2s 0.2s;
	transition: all ease-in-out 0.2s 0.2s;
}

.ui-slider-float .ui-slider-handle:hover .ui-slider-tip,
.ui-slider-float .ui-slider-handle:focus .ui-slider-tip {
	top: -40px;
	visibility: visible;
	opacity: 1;
	filter: alpha(opacity=100);
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

.ui-slider-float .ui-slider-pip .ui-slider-tip-label {
	top: 42px;
}

.ui-slider-float .ui-slider-pip:hover .ui-slider-tip-label {
	top: 32px;
	font-weight: normal;
}

.ui-slider-float .ui-slider-tip:after,
.ui-slider-float .ui-slider-pip .ui-slider-tip-label:after {
	content: "";
	width: 0;
	height: 0;
	border: 5px solid transparent;
	border-top-color: $gray-4;
	position: absolute;
	bottom: -10px;
	left: 50%;
	margin-left: -5px;
}

.ui-slider-float.ui-slider-vertical .ui-slider-tip,
.ui-slider-float.ui-slider-vertical .ui-slider-tip-label {
	top: 50%;
	margin-top: -16px;
	width: 34px;
	margin-left: 0px;
	left: -50px;
	-webkit-transition: all ease-in-out 0.2s 0.2s;
	-o-transition: all ease-in-out 0.2s 0.2s;
	transition: all ease-in-out 0.2s 0.2s;
}

.ui-slider-float.ui-slider-vertical .ui-slider-handle:hover .ui-slider-tip,
.ui-slider-float.ui-slider-vertical .ui-slider-handle:focus .ui-slider-tip,
.ui-slider-float.ui-slider-vertical .ui-slider-pip:hover .ui-slider-tip-label,
.ui-slider-float.ui-slider-vertical .ui-slider-pip:focus .ui-slider-tip-label {
	top: 50%;
	margin-top: -16px;
	left: -45px;
}

.ui-slider-float.ui-slider-vertical .ui-slider-pip .ui-slider-tip-label {
	left: 47px;
}

.ui-slider-float.ui-slider-vertical .ui-slider-pip:hover .ui-slider-tip-label {
	left: 37px;
}

.ui-slider-float.ui-slider-vertical .ui-slider-tip:after,
.ui-slider-float.ui-slider-vertical .ui-slider-pip .ui-slider-tip-label:after {
	border-left-color: $gray-4;
	bottom: 9px;
	left: auto;
	margin-right: -9px;
	border-top-color: transparent;
	right: 0;
	margin-left: 0;
}

/* ------------------------------------------------------------------------------
*
*  # NoUI slider
*
*  Styles for NoUI range slider plugin
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.noUi-target {
	border-radius: 100px;
	position: relative;
	direction: ltr;
}

.noUi-target,
.noUi-target * {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.noUi-base {
	width: 100%;
	height: 100%;
	position: relative;
}

.noUi-origin {
	position: absolute;
	right: 0;
	top: 0;
	left: 0;
	bottom: 0;
	border-radius: 100px;
}

.noUi-state-drag * {
	cursor: inherit !important;
}

.noUi-background {
	background-color: $light-gray-9;
}

.noUi-connect {
	background-color: #5c6bc0;
}

[disabled] .noUI-background {
	opacity: 0.75;
	filter: alpha(opacity=75);
}

[disabled] .noUi-handle {
	cursor: not-allowed;
}

[disabled] .noUi-handle:focus,
[disabled] .noUi-handle:active {
	-webkit-transform: scale(1.25);
	-ms-transform: scale(1.25);
	-o-transform: scale(1.25);
	transform: scale(1.25);
	-webkit-box-shadow: none;
	box-shadow: none;
}

.noUi-horizontal {
	height: 4px;
}

.noUi-horizontal .noUi-handle {
	top: -4px;
	left: -6px;
}

.noUi-horizontal.noUi-extended {
	padding: 0 15px;
}

.noUi-horizontal.noUi-extended .noUi-origin {
	right: -15px;
}

.noUi-horizontal.has-pips {
	margin-bottom: 35px;
}

.noUi-vertical {
	display: inline-block;
	width: 4px;
	height: 150px;
}

.noUi-vertical + .noUi-vertical {
	margin-left: 20px;
}

.noUi-vertical .noUi-handle {
	top: -6px;
	left: -4px;
}

.noUi-vertical.noUi-extended {
	padding: 15px 0;
}

.noUi-vertical.noUi-extended .noUi-origin {
	bottom: -15px;
}

.noUi-vertical.has-pips {
	margin-right: 35px;
}

.noUi-dragable {
	cursor: w-resize;
}

.noUi-vertical .noUi-dragable {
	cursor: n-resize;
}

.noUi-handle {
	width: 12px;
	height: 12px;
	background-color: #5c6bc0;
	cursor: pointer;
	position: relative;
	z-index: 1;
	border-radius: 50%;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}

.noUi-stacking .noUi-handle {
	z-index: 10;
}

.noUi-handle:hover {
	-webkit-transform: scale(1.25);
	-ms-transform: scale(1.25);
	-o-transform: scale(1.25);
	transform: scale(1.25);
}

.noUi-handle:focus,
.noUi-handle:active {
	-webkit-transform: scale(1.5);
	-ms-transform: scale(1.5);
	-o-transform: scale(1.5);
	transform: scale(1.5);
	-webkit-box-shadow: 0 0 0 10px rgba(92, 107, 192, 0.1);
	box-shadow: 0 0 0 10px rgba(92, 107, 192, 0.1);
}

.noui-slider-white .noUi-handle {
	background-color: var(--white) !important;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
		0 1px 2px rgba(0, 0, 0, 0.24);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.noui-slider-white .noUi-handle:focus,
.noui-slider-white .noUi-handle:active {
	-webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.05),
		0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
	box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.12),
		0 1px 2px rgba(0, 0, 0, 0.24) !important;
}

.noui-slider-lg .noUi-handle {
	width: 15px;
	height: 15px;
	top: -5px;
}

.noui-slider-lg.noui-slider-circle .noUi-handle:after {
	width: 5px;
	height: 5px;
	margin-top: -2.5px;
	margin-left: -2.5px;
}

.noui-slider-lg.noUi-horizontal {
	height: 5px;
}

.noui-slider-lg.noUi-horizontal .noUi-handle {
	left: -7.5px;
}

.noui-slider-lg.noUi-vertical {
	width: 5px;
}

.noui-slider-lg.noUi-vertical .noUi-handle {
	top: -7.5px;
	left: -5px;
}

.noui-slider-sm.noUi-horizontal {
	height: 3px;
}

.noui-slider-sm.noUi-horizontal .noUi-handle {
	top: -4.5px;
	left: -6px;
}

.noui-slider-sm.noUi-vertical {
	width: 3px;
}

.noui-slider-sm.noUi-vertical .noUi-handle {
	top: -6px;
	left: -4.5px;
}

.noui-slider-xs.noUi-horizontal {
	height: 2px;
}

.noui-slider-xs.noUi-horizontal .noUi-handle {
	top: -5px;
	left: -6px;
}

.noui-slider-xs.noUi-vertical {
	width: 2px;
}

.noui-slider-xs.noUi-vertical .noUi-handle {
	top: -6px;
	left: -5px;
}

.noui-slider-circle .noUi-handle {
	background-color: $gray-3;
	border-color: $gray-3;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.noui-slider-circle .noUi-handle:after {
	content: "";
	display: inline-block;
	width: 4px;
	height: 4px;
	background-color: var(--white);
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -2px;
	margin-left: -2px;
	border-radius: 100px;
}

.noui-slider-primary .noUi-connect,
.noui-slider-primary.noUi-connect {
	background-color: $blue-shade-1;
}

.noui-slider-primary .noUi-handle {
	background-color: $blue-shade-1;
}

.noui-slider-primary .noUi-handle:focus,
.noui-slider-primary .noUi-handle:active {
	-webkit-box-shadow: 0 0 0 10px rgba(33, 150, 243, 0.1);
	box-shadow: 0 0 0 10px rgba(33, 150, 243, 0.1);
}

.noui-slider-danger .noUi-connect,
.noui-slider-danger.noUi-connect {
	background-color: $red;
}

.noui-slider-danger .noUi-handle {
	background-color: $red;
}

.noui-slider-danger .noUi-handle:focus,
.noui-slider-danger .noUi-handle:active {
	-webkit-box-shadow: 0 0 0 10px rgba(244, 67, 54, 0.1);
	box-shadow: 0 0 0 10px rgba(244, 67, 54, 0.1);
}

.noui-slider-success .noUi-connect,
.noui-slider-success.noUi-connect {
	background-color: $green-1;
}

.noui-slider-success .noUi-handle {
	background-color: $green-1;
}

.noui-slider-success .noUi-handle:focus,
.noui-slider-success .noUi-handle:active {
	-webkit-box-shadow: 0 0 0 10px rgba(76, 175, 80, 0.1);
	box-shadow: 0 0 0 10px rgba(76, 175, 80, 0.1);
}

.noui-slider-warning .noUi-connect,
.noui-slider-warning.noUi-connect {
	background-color: $red-1;
}

.noui-slider-warning .noUi-handle {
	background-color: $red-1;
}

.noui-slider-warning .noUi-handle:focus,
.noui-slider-warning .noUi-handle:active {
	-webkit-box-shadow: 0 0 0 10px rgba(255, 87, 34, 0.1);
	box-shadow: 0 0 0 10px rgba(255, 87, 34, 0.1);
}

.noui-slider-info .noUi-connect,
.noui-slider-info.noUi-connect {
	background-color: $blue-9;
}

.noui-slider-info .noUi-handle {
	background-color: $blue-9;
}

.noui-slider-info .noUi-handle:focus,
.noui-slider-info .noUi-handle:active {
	-webkit-box-shadow: 0 0 0 10px rgba(0, 188, 212, 0.1);
	box-shadow: 0 0 0 10px rgba(0, 188, 212, 0.1);
}

.noUi-tooltip {
	display: none;
	position: absolute;
	border-radius: 3px;
	background: $gray-4;
	color: var(--white);
	top: -43px;
	padding: 8px 16px;
	left: 50%;
	margin-left: -27px;
	text-align: center;
	font-size: 12px;
	width: 54px;
}

.noUi-tooltip:after {
	content: "";
	width: 0;
	height: 0;
	border: 5px solid transparent;
	border-top-color: $gray-4;
	position: absolute;
	bottom: -10px;
	left: 50%;
	margin-left: -5px;
}

.noUi-handle:active .noUi-tooltip {
	display: block;
	-webkit-transform: scale(0.75);
	-ms-transform: scale(0.75);
	-o-transform: scale(0.75);
	transform: scale(0.75);
}

.noUi-pips {
	position: absolute;
	color: $gray-3;
}

.noUi-value {
	width: 40px;
	position: absolute;
	text-align: center;
	font-size: 11px;
}

.noUi-value-sub {
	color: $light-gray-14;
	font-size: 11px;
}

.noUi-marker {
	position: absolute;
	background-color: $light-gray-14;
}

.noUi-pips-horizontal {
	padding-top: 10px;
	height: 35px;
	top: 100%;
	left: 0;
	width: 100%;
}

.noUi-value-horizontal {
	margin-left: -20px;
	padding-top: 15px;
}

.noUi-value-horizontal.noUi-value-sub {
	padding-top: 10px;
}

.noUi-marker-horizontal.noUi-marker {
	width: 1px;
	height: 3px;
}

.noUi-marker-horizontal.noUi-marker-sub {
	height: 6px;
}

.noUi-marker-horizontal.noUi-marker-large {
	height: 10px;
}

.noUi-pips-vertical {
	padding-left: 10px;
	height: 100%;
	top: 0;
	left: 100%;
}

.noUi-value-vertical {
	width: 15px;
	margin-left: 15px;
	margin-top: -6px;
}

.noUi-marker-vertical.noUi-marker {
	width: 3px;
	height: 1px;
}

.noUi-marker-vertical.noUi-marker-sub {
	width: 6px;
}

.noUi-marker-vertical.noUi-marker-large {
	width: 10px;
}

/* ------------------------------------------------------------------------------
*
*  # ION Range Slider
*
*  Styles for ION range slider plugin
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.irs {
	position: relative;
	display: block;
	height: 43px;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.irs-hidden-input {
	position: absolute !important;
	display: block !important;
	top: 0 !important;
	left: 0 !important;
	width: 0 !important;
	height: 0 !important;
	font-size: 0 !important;
	line-height: 0 !important;
	padding: 0 !important;
	margin: 0 !important;
	outline: none !important;
	z-index: -9999 !important;
	background: none !important;
	border-style: solid !important;
	border-color: transparent !important;
}

.irs-line {
	position: relative;
	display: block;
	overflow: hidden;
	outline: none;
	height: 4px;
	top: 37px;
	background-color: $light-gray-9;
	border-radius: 100px;
}

.irs-line-left,
.irs-line-mid,
.irs-line-right {
	position: absolute;
	display: block;
	top: 0;
}

.irs-line-left {
	left: 0;
	width: 11%;
}

.irs-line-mid {
	left: 9%;
	width: 82%;
}

.irs-line-right {
	right: 0;
	width: 11%;
}

.irs-bar {
	position: absolute;
	display: block;
	top: 37px;
	left: 0;
	width: 0;
	height: 4px;
	background-color: #5c6bc0;
	border-radius: 100px;
}

.irs-bar-edge {
	position: absolute;
	display: block;
	top: 25px;
	left: 0;
	height: 12px;
	width: 9px;
}

.irs-shadow {
	position: absolute;
	display: none;
	top: 37px;
	left: 0;
	width: 0;
	height: 4px;
	background-color: $gray-4;
	border-radius: 100px;
	opacity: 0.25;
	filter: alpha(opacity=25);
}

.irs-slider {
	position: absolute;
	display: block;
	width: 12px;
	height: 12px;
	background-color: #5c6bc0;
	top: 33px;
	border-radius: 100px;
	cursor: pointer;
	z-index: 1;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
	-webkit-transition: -webkit-transform ease-in-out 0.15s;
	-o-transition: -webkit-transform ease-in-out 0.15s;
	transition: -webkit-transform ease-in-out 0.15s;
}

.irs-slider:hover {
	-webkit-transform: scale(1.25);
	-ms-transform: scale(1.25);
	-o-transform: scale(1.25);
	transform: scale(1.25);
}

.irs-slider:focus,
.irs-slider:active {
	-webkit-transform: scale(1.5);
	-ms-transform: scale(1.5);
	-o-transform: scale(1.5);
	transform: scale(1.5);
	-webkit-box-shadow: 0 0 0 10px rgba(92, 107, 192, 0.1);
	box-shadow: 0 0 0 10px rgba(92, 107, 192, 0.1);
}

.irs-slider.type_last {
	z-index: 2;
}

.irs-min,
.irs-max,
.irs-from,
.irs-to,
.irs-single {
	position: absolute;
	display: block;
	cursor: default;
}

.irs-min,
.irs-max {
	color: $gray-3;
	font-size: 11px;
	line-height: 1;
	top: 0;
	padding: 6px;
	background-color: $light-gray-9;
	border-radius: 2px;
}

.irs-min {
	left: 0;
}

.irs-max {
	right: 0;
}

.irs-from,
.irs-to,
.irs-single {
	left: 0;
	white-space: nowrap;
	color: var(--white);
	font-size: 11px;
	line-height: 1;
	padding: 6px;
	top: 0;
	background-color: $gray-4;
	border-radius: 2px;
}

.irs-from:after,
.irs-to:after,
.irs-single:after {
	content: "";
	position: absolute;
	display: block;
	bottom: -8px;
	left: 50%;
	width: 0;
	height: 0;
	margin-left: -4px;
	overflow: hidden;
	border: 4px solid transparent;
	border-top-color: $gray-4;
}

.irs-grid {
	position: absolute;
	display: none;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 24px;
}

.irs-with-grid {
	height: 75px;
}

.irs-with-grid .irs-grid {
	display: block;
}

.irs-grid-pol {
	position: absolute;
	top: 0;
	left: 0;
	width: 1px;
	height: 8px;
	background-color: $gray-3;
}

.irs-grid-pol.small {
	height: 4px;
}

.irs-grid-text {
	position: absolute;
	bottom: 0;
	left: 0;
	white-space: nowrap;
	text-align: center;
	font-size: 11px;
	color: $gray-3;
	line-height: 1;
	padding: 0 3px;
}

.irs-disable-mask {
	position: absolute;
	display: block;
	top: 0;
	left: -1%;
	width: 102%;
	height: 100%;
	cursor: default;
	background: transparent;
	z-index: 2;
}

.irs-disabled {
	opacity: 0.6;
	filter: alpha(opacity=60);
}

/* ------------------------------------------------------------------------------
 *
 *  # jQuery UI Interactions
 *
 *  Separate styles for jQuery UI library. Component's interactions
 *
 *  Version: 1.1
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.ui-draggable-handle,
.ui-sortable-handle {
	-ms-touch-action: none;
	touch-action: none;
}

.ui-sortable .ui-state-disabled {
	color: $gray-3;
	cursor: not-allowed;
	opacity: 0.75;
	filter: alpha(opacity=75);
}

.sortable-placeholder {
	position: relative;
}

.sortable-placeholder:before {
	content: "";
	display: inline-block;
	background-color: rgba(255, 255, 255, 0.8);
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	border: 1px dashed rgba(0, 0, 0, 0.2);
}

.sidebar .sortable-placeholder:before {
	border-left: 0;
	border-right: 0;
	background-color: rgba(0, 0, 0, 0.05);
}

.sidebar-category.ui-sortable-helper {
	background-color: rgba(0, 0, 0, 0.4);
}

.sidebar-default .sidebar-category.ui-sortable-helper {
	background-color: $light-gray-3;
}

.panel + .sortable-placeholder {
	margin-bottom: 20px;
}

.sortable-placeholder + .panel {
	margin-top: 20px;
}

.panel-group > .sortable-placeholder:before {
	border-radius: 3px;
}

.panel-group .panel + .sortable-placeholder {
	margin-top: 5px;
	margin-bottom: 5px;
}

.panel-group .sortable-placeholder + .panel {
	margin-top: 5px;
}

.table .ui-sortable-helper {
	width: 100%;
	background-color: var(--white);
	display: table;
}

.table .sortable-placeholder {
	margin: 0;
}

.table .sortable-placeholder:before {
	content: none;
}

.table.ui-sortable {
	position: relative;
}

.ui-resizable {
	position: relative;
}

.ui-resizable,
.ui-resizable .ui-dialog-content {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}

.ui-resizable-handle {
	position: absolute;
	font-size: 0.1px;
	display: block;
	-ms-touch-action: none;
	touch-action: none;
}

.ui-resizable-handle.ui-icon {
	display: inline-block;
	border-style: solid;
	border-width: 0 0 6px 6px;
	border-color: transparent transparent $gray-4 transparent;
}

.ui-resizable-disabled .ui-resizable-handle,
.ui-resizable-autohide .ui-resizable-handle {
	display: none;
}

.ui-resizable-helper {
	border: 1px dashed $light-gray-14;
}

.ui-resizable-n {
	cursor: n-resize;
	height: 7px;
	width: 100%;
	top: -5px;
	left: 0;
}

.ui-resizable-s {
	cursor: s-resize;
	height: 7px;
	width: 100%;
	bottom: -5px;
	left: 0;
}

.ui-resizable-e {
	cursor: e-resize;
	width: 7px;
	right: -5px;
	top: 0;
	height: 100%;
}

.ui-resizable-w {
	cursor: w-resize;
	width: 7px;
	left: -5px;
	top: 0;
	height: 100%;
}

.ui-resizable-se {
	cursor: se-resize;
	right: 1px;
	bottom: 1px;
}

.ui-resizable-sw {
	cursor: sw-resize;
	width: 9px;
	height: 9px;
	left: -5px;
	bottom: -5px;
}

.ui-resizable-nw {
	cursor: nw-resize;
	width: 9px;
	height: 9px;
	left: -5px;
	top: -5px;
}

.ui-resizable-ne {
	cursor: ne-resize;
	width: 9px;
	height: 9px;
	right: -5px;
	top: -5px;
}

.ui-selectable {
	-ms-touch-action: none;
	touch-action: none;
}

.ui-selectable-helper {
	position: absolute;
	z-index: 100;
	border: 1px dashed $gray-4;
}

/* ------------------------------------------------------------------------------
*
*  # jQuery UI Widgets
*
*  Styles for jQuery UI widgets
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.ui-accordion .ui-accordion-header {
	display: block;
	cursor: pointer;
	margin: 0;
	outline: 0;
	position: relative;
	background-color: var(--white);
	padding: 15px 20px;
	border: 1px solid $light-gray-13;
	margin-top: 5px;
	border-radius: 3px;
}

.ui-accordion .ui-accordion-header:first-child {
	margin-top: 0;
}

.ui-accordion .ui-accordion-header.ui-accordion-header-active {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
	position: absolute;
	top: 50%;
	right: 20px;
	margin-top: -8px;
}

.ui-accordion .ui-accordion-header .ui-accordion-header-icon:before {
	content: "\e9b8";
	font-family: "Icomoon";
	display: block;
	width: 16px;
	font-size: 16px;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.ui-accordion .ui-accordion-header-active .ui-accordion-header-icon:before {
	content: "\e9b7";
}

.ui-accordion .ui-accordion-icons {
	padding-right: 46px;
}

.ui-accordion .ui-accordion-content {
	padding: 20px;
	background-color: var(--white);
	border: 1px solid $light-gray-13;
	border-top: 0;
	overflow: auto;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
}

.accordion-sortable-group + .accordion-sortable-group {
	margin-top: 5px;
}

.ui-autocomplete {
	position: absolute;
	display: none;
	padding: 8px 0;
	z-index: 1000;
	max-height: 250px;
	overflow-y: auto;
	overflow-x: hidden;
}

.ui-autocomplete .ui-autocomplete-category {
	padding: 8px 16px;
	font-size: 11px;
	line-height: 1.82;
	text-transform: uppercase;
	font-weight: 700;
	margin-top: 2px;
	margin-bottom: 2px;
}

.ui-autocomplete .ui-autocomplete-category ~ .ui-menu-item {
	padding-left: 32px;
}

.ui-autocomplete-processing:after {
	content: "\eb51";
	font-family: "Icomoon";
	display: inline-block;
	position: absolute;
	top: 50%;
	right: 0;
	width: 16px;
	text-align: right;
	margin-top: -8px;
	font-size: 16px;
	line-height: 1;
	color: inherit;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-animation: rotation 1s linear infinite;
	-o-animation: rotation 1s linear infinite;
	animation: rotation 1s linear infinite;
}

.ui-button {
	display: inline-block;
	position: relative;
	touch-action: manipulation;
	margin-bottom: 0;
	padding: 9px 17px;
	border-radius: 3px;
	cursor: pointer;
	vertical-align: middle;
	text-align: center;
	background-color: $light-gray;
	border: 0;
	text-transform: uppercase;
	font-weight: 500;
	color: $gray-4;
}

.ui-button.ui-state-hover,
.ui-button.ui-state-focus {
	color: $gray-4;
	-webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
	box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
}

.ui-button.ui-state-active {
	-webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
	box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
}

.ui-button.ui-state-disabled {
	cursor: not-allowed;
	opacity: 0.65;
	filter: alpha(opacity=65);
	-webkit-box-shadow: none;
	box-shadow: none;
}

.ui-button[class*="bg-"].ui-state-hover,
.ui-button[class*="bg-"].ui-state-focus {
	color: var(--white);
}

.ui-button.ui-button-link {
	background-color: transparent;
	border-color: transparent;
}

.ui-button.ui-button-link.ui-state-hover,
.ui-button.ui-button-link.ui-state-focus {
	color: $blue-8;
}

.ui-button.ui-button-link.ui-state-hover,
.ui-button.ui-button-link.ui-state-focus,
.ui-button.ui-button-link.ui-state-active {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.ui-button::-moz-focus-inner {
	border: 0;
	padding: 0;
}

.ui-button-text-icon-primary .ui-button-icon-primary,
.ui-button-text-icons .ui-button-icon-primary,
.ui-button-icon-primary .ui-button-icons-only {
	margin-right: 10px;
}

.ui-button-text-icon-secondary .ui-button-icon-secondary,
.ui-button-text-icons .ui-button-icon-secondary,
.ui-button-icons-only .ui-button-icon-secondary {
	margin-left: 10px;
}

.ui-button-icon-only {
	padding-left: 11px;
	padding-right: 11px;
}

.ui-button-icon-only .ui-button-text,
.ui-button-icons-only .ui-button-text {
	display: none;
}

.ui-buttonset {
	display: inline-block;
	vertical-align: middle;
	position: relative;
}

.ui-buttonset .ui-button {
	border-radius: 0;
	margin-left: 1px;
	float: left;
}

.ui-buttonset .ui-button:first-child,
.ui-buttonset .ui-helper-hidden-accessible:first-child + .ui-button {
	margin-left: 0;
}

.ui-buttonset .ui-button:first-child,
.ui-buttonset
	.ui-helper-hidden-accessible:first-child
	+ .ui-button:not(:last-child) {
	border-bottom-left-radius: 3px;
	border-top-left-radius: 3px;
}

.ui-buttonset .ui-button:last-child {
	border-bottom-right-radius: 3px;
	border-top-right-radius: 3px;
}

.ui-buttonset-split {
	position: relative;
	display: inline-block;
}

.ui-buttonset-split > ul {
	position: absolute;
	text-align: left;
	min-width: 180px;
}

.ui-datepicker {
	min-width: 270px;
	background-color: var(--white);
	padding: 16px;
	display: none;
	border-radius: 3px;
	z-index: 190 !important;
	-webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
		0 3px 6px rgba(0, 0, 0, 0.23);
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.sidebar .ui-datepicker {
	min-width: 0;
}

.ui-datepicker .ui-datepicker-header {
	position: relative;
}

.ui-datepicker .ui-datepicker-title {
	margin: 0 40px;
	padding-top: 15px;
	padding-bottom: 15px;
	font-size: 17px;
	text-align: center;
	line-height: 1;
}

.ui-datepicker .ui-datepicker-title .ui-datepicker-year {
	font-size: 13px;
	color: $gray-3;
	margin-left: 5px;
}

.ui-datepicker .ui-datepicker-title select {
	outline: 0;
	height: 32px;
	border-color: $light-gray-13;
}

.ui-datepicker .ui-datepicker-title select.ui-datepicker-month,
.ui-datepicker .ui-datepicker-title select.ui-datepicker-year {
	width: 48%;
	font-size: 13px;
	color: $gray-4;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
	position: absolute;
	top: 50%;
	margin-top: -16px;
	color: $gray-4;
	padding: 8px;
	border-radius: 2px;
}

.ui-datepicker .ui-datepicker-prev:after,
.ui-datepicker .ui-datepicker-next:after {
	font-family: "icomoon";
	display: block;
	font-size: 16px;
	width: 16px;
	text-align: center;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
	display: none;
}

.ui-datepicker .ui-datepicker-prev {
	left: 0;
}

.ui-datepicker .ui-datepicker-prev:after {
	content: "\e9c8";
}

.ui-datepicker .ui-datepicker-next {
	right: 0;
}

.ui-datepicker .ui-datepicker-next:after {
	content: "\e9cb";
}

.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {
	background-color: $light-gray;
}

.ui-datepicker table {
	width: 100%;
	border-collapse: collapse;
	margin: 0;
}

.ui-datepicker table th {
	text-align: center;
	font-weight: 400;
	border: 0;
	padding-top: 10px;
	padding-bottom: 10px;
	font-size: 12px;
	color: $gray-3;
}

.ui-datepicker table td {
	border: 0;
	padding: 1px;
	position: relative;
}

.ui-datepicker table td span,
.ui-datepicker table td a {
	display: block;
	padding: 6px;
	text-align: center;
	text-decoration: none;
	border-radius: 3px;
	color: $gray-4;
	min-width: 34px;
}

.ui-datepicker table td .ui-state-hover {
	background-color: $light-gray;
}

.ui-datepicker table td.ui-state-disabled span {
	color: $light-gray-14;
}

.ui-datepicker table td.ui-datepicker-week-col {
	padding: 6px;
	color: $gray-3;
}

.ui-datepicker table td.ui-datepicker-current-day .ui-state-active {
	background-color: #26a69a;
	color: var(--white);
}

.ui-datepicker table td.ui-datepicker-today .ui-state-highlight {
	background-color: $blue-shade-1;
	color: var(--white);
}

.ui-datepicker table td.ui-datepicker-today .ui-state-highlight:after {
	content: "";
	position: absolute;
	top: 3px;
	right: 3px;
	width: 0;
	height: 0;
	border-top: 6px solid var(--white);
	border-left: 6px solid transparent;
}

.ui-datepicker table td a.ui-priority-secondary {
	opacity: 0.6;
	filter: alpha(opacity=60);
}

.ui-datepicker .ui-datepicker-buttonpane button {
	float: right;
	background-color: $light-gray;
	font-weight: 400;
	margin-top: 16px;
	border: 0;
	cursor: pointer;
	padding: 8px 16px;
	width: auto;
	overflow: visible;
	outline: 0;
	border-radius: 3px;
}

.ui-datepicker .ui-datepicker-buttonpane button.ui-state-hover {
	-webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
	box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
}

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
	float: left;
}

.ui-datepicker .ui-datepicker-buttonpane button:active {
	-webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
	box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
}

.ui-datepicker.ui-datepicker-multi {
	width: auto !important;
}

.datepicker-inline {
	width: 264px;
	max-width: 100%;
	overflow-x: auto;
}

.full-width.datepicker-inline,
.sidebar .datepicker-inline,
.popover .datepicker-inline {
	width: 100%;
}

.ui-datepicker-trigger {
	position: absolute;
	top: 4px;
	right: 0;
	z-index: 4;
	padding: 6px 0;
	cursor: pointer;
}

.ui-datepicker-row-break {
	clear: both;
	width: 100%;
	font-size: 0;
}

.ui-datepicker-multi .ui-datepicker-group {
	float: left;
}

.ui-datepicker-multi .ui-datepicker-group + .ui-datepicker-group {
	padding-left: 15px;
}

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header {
	border-left-width: 0;
}

.ui-datepicker-multi .ui-datepicker-buttonpane {
	clear: left;
}

.ui-datepicker-multi-2 .ui-datepicker-group {
	width: 46%;
}

.ui-datepicker-multi-3 .ui-datepicker-group {
	width: 33.3%;
}

.ui-datepicker-multi-4 .ui-datepicker-group {
	width: 25%;
}

.ui-dialog {
	position: fixed;
	top: 0;
	left: 0;
	background-color: var(--white);
	border-radius: 3px;
	border: 1px solid transparent;
	outline: 0;
	overflow: hidden;
	-webkit-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
		0 10px 10px rgba(0, 0, 0, 0.22);
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

@media (max-width: 768px) {
	.ui-dialog {
		width: 90% !important;
	}
}

.ui-dialog-titlebar {
	position: relative;
	padding: 20px;
	padding-bottom: 0;
}

.ui-draggable .ui-dialog-titlebar {
	cursor: move;
}

.ui-dialog-title {
	float: left;
	font-size: 17px;
	white-space: nowrap;
	width: 90%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.ui-dialog-titlebar-close {
	position: absolute;
	background-color: transparent;
	border: 0;
	right: 20px;
	top: 50%;
	padding: 0;
	margin-top: 2px;
}

.ui-dialog-titlebar-close:after {
	content: "\ed6b";
	font-family: "Icomoon";
	display: block;
	width: 16px;
	font-size: 16px;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.ui-dialog-titlebar-close.ui-state-hover,
.ui-dialog-titlebar-close.ui-state-focus {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.ui-dialog-content {
	position: relative;
	border: 0;
	padding: 20px;
	background: none;
	overflow: auto;
}

.ui-dialog-content .form-group:last-child,
.ui-dialog-content p:last-child {
	margin-bottom: 0;
}

.ui-dialog-buttonpane {
	padding: 20px;
	padding-top: 0;
}

.ui-dialog-buttonpane .ui-dialog-buttonset {
	float: right;
}

.ui-dialog-buttonpane button + button {
	margin-left: 5px;
	cursor: pointer;
}

.ui-menu {
	list-style: none;
	padding: 8px 0;
	margin: 0;
	display: block;
	outline: none;
	min-width: 180px;
	white-space: nowrap;
	background-color: var(--white);
	border-radius: 3px;
	z-index: 1000;
	-webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
		0 3px 6px rgba(0, 0, 0, 0.23);
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.ui-menu .ui-menu {
	position: absolute;
	top: -8px !important;
}

.ui-menu .ui-menu-item {
	position: relative;
	margin: 0;
	cursor: pointer;
	padding: 8px 16px;
	min-height: 0;
	outline: 0;
}

.ui-menu .ui-menu-item > a {
	display: block;
	color: $gray-4;
}

.ui-menu .ui-menu-item > .ui-menu-icon {
	position: absolute;
	top: 50%;
	margin-top: -8px;
	right: 15px;
}

.ui-menu .ui-menu-item > .ui-menu-icon:after {
	content: "\e9c7";
	font-family: "icomoon";
	font-size: 16px;
	font-weight: 400;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	opacity: 0.8;
	filter: alpha(opacity=80);
}

.ui-menu .ui-menu-item.ui-state-disabled,
.ui-menu .ui-menu-item.ui-state-disabled a {
	color: $gray-3;
	cursor: not-allowed;
}

.ui-menu .ui-menu-header {
	padding: 8px 16px;
	font-size: 11px;
	line-height: 1.82;
	text-transform: uppercase;
	color: $gray-3;
	margin-top: 8px;
}

.ui-menu .ui-menu-header.highlight {
	margin-top: 0;
	background-color: $light-gray-11;
	color: $gray-3;
}

.ui-menu .ui-menu-header.highlight:first-child {
	margin-top: 0;
}

.ui-menu .ui-menu-item + .highlight,
.ui-menu .highlight + .ui-menu-item {
	margin-top: 8px;
}

.ui-menu .ui-menu-item > a > i[class*="icon-"],
.ui-menu .ui-menu-header > i[class*="icon-"] {
	margin-top: 2px;
	float: left;
	margin-right: 16px;
	top: 0;
}

.ui-menu .ui-menu-item > a > i[class*="icon-"].pull-right,
.ui-menu .ui-menu-header > i[class*="icon-"].pull-right {
	margin-right: 0;
	margin-left: 16px;
}

.ui-menu .ui-menu-divider {
	margin: 8px 0;
	height: 0;
	font-size: 0;
	line-height: 0;
	border-top: 1px solid $light-gray-10;
}

.ui-menu .ui-state-focus,
.ui-menu .ui-state-active {
	color: $gray-4;
	background-color: $light-gray;
}

.ui-menu.ui-state-disabled,
.ui-menu.ui-state-disabled .ui-menu-item,
.ui-menu.ui-state-disabled a {
	color: $gray-3;
	cursor: not-allowed;
}

.ui-progressbar {
	height: 18px;
	overflow: hidden;
	position: relative;
	background-color: $light-gray-9;
	border-radius: 3px;
}

.ui-progressbar-value {
	float: left;
	width: 0%;
	height: 100%;
	background-color: $blue-shade-1;
	color: var(--white);
	overflow: hidden;
	-webkit-transition: width 0.6s ease;
	-o-transition: width 0.6s ease;
	transition: width 0.6s ease;
}

.ui-progressbar-value,
.ui-progressbar-overlay {
	height: 100%;
	background-size: 40px 40px;
}

.ui-progressbar-striped .ui-progressbar-value,
.ui-progressbar-striped .ui-progressbar-overlay {
	background-image: -webkit-linear-gradient(
		45deg,
		rgba(255, 255, 255, 0.15) 25%,
		transparent 25%,
		transparent 50%,
		rgba(255, 255, 255, 0.15) 50%,
		rgba(255, 255, 255, 0.15) 75%,
		transparent 75%,
		transparent
	);
	background-image: -o-linear-gradient(
		45deg,
		rgba(255, 255, 255, 0.15) 25%,
		transparent 25%,
		transparent 50%,
		rgba(255, 255, 255, 0.15) 50%,
		rgba(255, 255, 255, 0.15) 75%,
		transparent 75%,
		transparent
	);
	background-image: linear-gradient(
		45deg,
		rgba(255, 255, 255, 0.15) 25%,
		transparent 25%,
		transparent 50%,
		rgba(255, 255, 255, 0.15) 50%,
		rgba(255, 255, 255, 0.15) 75%,
		transparent 75%,
		transparent
	);
}

.ui-progressbar-overlay {
	background-image: -webkit-linear-gradient(
		45deg,
		rgba(255, 255, 255, 0.15) 25%,
		transparent 25%,
		transparent 50%,
		rgba(255, 255, 255, 0.15) 50%,
		rgba(255, 255, 255, 0.15) 75%,
		transparent 75%,
		transparent
	);
	background-image: -o-linear-gradient(
		45deg,
		rgba(255, 255, 255, 0.15) 25%,
		transparent 25%,
		transparent 50%,
		rgba(255, 255, 255, 0.15) 50%,
		rgba(255, 255, 255, 0.15) 75%,
		transparent 75%,
		transparent
	);
	background-image: linear-gradient(
		45deg,
		rgba(255, 255, 255, 0.15) 25%,
		transparent 25%,
		transparent 50%,
		rgba(255, 255, 255, 0.15) 50%,
		rgba(255, 255, 255, 0.15) 75%,
		transparent 75%,
		transparent
	);
}

.ui-progressbar-active .ui-progressbar-value,
.ui-progressbar-overlay {
	-webkit-animation: progress-bar-stripes 2s linear infinite;
	-o-animation: progress-bar-stripes 2s linear infinite;
	animation: progress-bar-stripes 2s linear infinite;
}

.ui-progressbar-indeterminate .ui-progressbar-value {
	background-image: none;
}

.ui-selectmenu-menu {
	padding: 0;
	margin: 0;
	position: absolute;
	top: 0;
	left: 0;
	display: none;
}

.ui-selectmenu-menu .ui-menu {
	max-height: 250px;
	overflow-y: auto;
	overflow-x: hidden;
}

.ui-selectmenu-menu .ui-menu .ui-selectmenu-optgroup {
	font-size: 11px;
	font-weight: 700;
	line-height: 1.82;
	padding: 8px 16px;
	margin: 8px 0;
	text-transform: uppercase;
	height: auto;
	border: 0;
}

.ui-selectmenu-menu .ui-menu .ui-selectmenu-optgroup ~ .ui-menu-item {
	padding-left: 32px;
}

.ui-selectmenu-open {
	display: block;
}

.ui-selectmenu-button {
	display: inline-block;
	position: relative;
	text-decoration: none;
	cursor: pointer;
	height: 38px;
	padding: 8px 0;
	padding-right: 26px;
	background-color: transparent;
	border: 1px solid transparent;
	border-bottom-color: $light-gray-13;
	outline: 0;
}

.ui-selectmenu-button.ui-state-focus,
.ui-selectmenu-button[aria-expanded="true"] {
	border-bottom-color: #009688;
	-webkit-box-shadow: 0 1px 0 #009688;
	box-shadow: 0 1px 0 #009688;
}

.ui-selectmenu-button:after {
	content: "\e9c5";
	font-family: "Icomoon";
	display: inline-block;
	position: absolute;
	top: 50%;
	right: 0;
	text-align: right;
	margin-top: -8px;
	font-size: 16px;
	line-height: 1;
	color: inherit;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.ui-selectmenu-button .ui-selectmenu-text {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.ui-selectmenu-disabled {
	cursor: not-allowed;
	color: $gray-3;
	border-bottom-style: dashed;
}

.ui-selectmenu-lg .ui-selectmenu-button {
	height: 40px;
}

.ui-selectmenu-lg .ui-selectmenu-button .ui-selectmenu-text {
	padding: 9px 0;
	padding-right: 28px;
}

.ui-selectmenu-sm .ui-selectmenu-button {
	height: 36px;
}

.ui-selectmenu-sm .ui-selectmenu-button .ui-selectmenu-text {
	padding: 7px 0;
	padding-right: 24px;
	font-size: 12px;
	line-height: 1.6666667;
}

.ui-selectmenu-xs .ui-selectmenu-button {
	height: 34px;
}

.ui-selectmenu-xs .ui-selectmenu-button .ui-selectmenu-text {
	padding: 6px 0;
	padding-right: 22px;
	font-size: 12px;
	line-height: 1.6666667;
}

.ui-selectmenu-images .ui-menu-item > span {
	display: inline-block;
	margin-right: 10px;
	border-radius: 100px;
	width: 20px;
	height: 20px;
	background-size: 20px 20px;
	float: left;
}

.ui-slider {
	position: relative;
	text-align: left;
	background-color: $light-gray-9;
	border-radius: 100px;
}

.ui-slider .ui-slider-handle {
	position: absolute;
	z-index: 2;
	width: 12px;
	height: 12px;
	cursor: pointer;
	border-radius: 50%;
	background-color: #5c6bc0;
	outline: 0;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
	-webkit-transition: -webkit-transform ease-in-out 0.15s,
		box-shadow ease-in-out 0.15s;
	-o-transition: -webkit-transform ease-in-out 0.15s,
		box-shadow ease-in-out 0.15s;
	transition: -webkit-transform ease-in-out 0.15s,
		box-shadow ease-in-out 0.15s;
}

.ui-slider .ui-slider-handle.ui-state-hover {
	-webkit-transform: scale(1.25);
	-ms-transform: scale(1.25);
	-o-transform: scale(1.25);
	transform: scale(1.25);
}

.ui-slider .ui-slider-handle.ui-state-focus,
.ui-slider .ui-slider-handle.ui-state-active {
	-webkit-transform: scale(1.5);
	-ms-transform: scale(1.5);
	-o-transform: scale(1.5);
	transform: scale(1.5);
	-webkit-box-shadow: 0 0 0 10px rgba(92, 107, 192, 0.1);
	box-shadow: 0 0 0 10px rgba(92, 107, 192, 0.1);
}

.ui-slider .ui-slider-range {
	position: absolute;
	z-index: 1;
	display: block;
	border: 0;
	background-color: #5c6bc0;
	border-radius: 100px;
}

.ui-slider.ui-slider-disabled {
	opacity: 0.6;
	filter: alpha(opacity=60);
}

.ui-slider-horizontal {
	height: 4px;
}

.ui-slider-horizontal .ui-slider-handle {
	top: -4px;
	margin-left: -6px;
}

.ui-slider-horizontal .ui-slider-range {
	top: 0;
	height: 100%;
}

.ui-slider-horizontal .ui-slider-range-min {
	left: 0;
}

.ui-slider-horizontal .ui-slider-range-max {
	right: 0;
}

.ui-slider-vertical {
	width: 4px;
	height: 150px;
	display: inline-block;
	margin: 0 10px;
}

.ui-slider-vertical .ui-slider-handle {
	left: -4px;
	margin-bottom: -6px;
}

.ui-slider-vertical .ui-slider-range {
	left: 0;
	width: 100%;
}

.ui-slider-vertical .ui-slider-range-min {
	bottom: 0;
}

.ui-slider-vertical .ui-slider-range-max {
	top: 0;
}

.ui-slider-circle .ui-slider-handle:after {
	content: "";
	display: inline-block;
	width: 4px;
	height: 4px;
	background-color: var(--white);
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -2px;
	margin-left: -2px;
	border-radius: 100px;
}

.ui-handle-white .ui-slider-handle {
	background-color: var(--white) !important;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
		0 1px 2px rgba(0, 0, 0, 0.24);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.ui-handle-white .ui-slider-handle.ui-state-focus,
.ui-handle-white .ui-slider-handle.ui-state-active {
	-webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.05),
		0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
	box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.12),
		0 1px 2px rgba(0, 0, 0, 0.24) !important;
}

.ui-slider-lg .ui-slider-handle {
	width: 15px;
	height: 15px;
}

.ui-slider-lg .ui-slider-handle:after {
	width: 5px;
	height: 5px;
	margin-top: -2.5px;
	margin-left: -2.5px;
}

.ui-slider-lg.ui-slider-horizontal {
	height: 5px;
}

.ui-slider-lg.ui-slider-horizontal .ui-slider-handle {
	top: -5px;
	margin-left: -7.5px;
}

.ui-slider-lg.ui-slider-vertical {
	width: 5px;
}

.ui-slider-lg.ui-slider-vertical .ui-slider-handle {
	left: -5px;
	margin-bottom: -7.5px;
}

.ui-slider-sm.ui-slider-horizontal {
	height: 3px;
}

.ui-slider-sm.ui-slider-horizontal .ui-slider-handle {
	top: -4.5px;
	margin-left: -6px;
}

.ui-slider-sm.ui-slider-vertical {
	width: 3px;
}

.ui-slider-sm.ui-slider-vertical .ui-slider-handle {
	left: -4.5px;
	margin-bottom: -6px;
}

.ui-slider-xs.ui-slider-horizontal {
	height: 2px;
}

.ui-slider-xs.ui-slider-horizontal .ui-slider-handle {
	top: -5px;
	margin-left: -6px;
}

.ui-slider-xs.ui-slider-vertical {
	width: 2px;
}

.ui-slider-xs.ui-slider-vertical .ui-slider-handle {
	left: -5px;
	margin-bottom: -6px;
}

.ui-slider-primary .ui-slider-range,
.ui-slider-primary .ui-slider-handle {
	background-color: $blue-shade-1;
}

.ui-slider-primary .ui-slider-handle.ui-state-focus,
.ui-slider-primary .ui-slider-handle.ui-state-active {
	-webkit-box-shadow: 0 0 0 10px rgba(33, 150, 243, 0.1);
	box-shadow: 0 0 0 10px rgba(33, 150, 243, 0.1);
}

.ui-slider-danger .ui-slider-range,
.ui-slider-danger .ui-slider-handle {
	background-color: $red;
}

.ui-slider-danger .ui-slider-handle.ui-state-focus,
.ui-slider-danger .ui-slider-handle.ui-state-active {
	-webkit-box-shadow: 0 0 0 10px rgba(244, 67, 54, 0.1);
	box-shadow: 0 0 0 10px rgba(244, 67, 54, 0.1);
}

.ui-slider-success .ui-slider-range,
.ui-slider-success .ui-slider-handle {
	background-color: $green-1;
}

.ui-slider-success .ui-slider-handle.ui-state-focus,
.ui-slider-success .ui-slider-handle.ui-state-active {
	-webkit-box-shadow: 0 0 0 10px rgba(76, 175, 80, 0.1);
	box-shadow: 0 0 0 10px rgba(76, 175, 80, 0.1);
}

.ui-slider-warning .ui-slider-range,
.ui-slider-warning .ui-slider-handle {
	background-color: $red-1;
}

.ui-slider-warning .ui-slider-handle.ui-state-focus,
.ui-slider-warning .ui-slider-handle.ui-state-active {
	-webkit-box-shadow: 0 0 0 10px rgba(255, 87, 34, 0.1);
	box-shadow: 0 0 0 10px rgba(255, 87, 34, 0.1);
}

.ui-slider-info .ui-slider-range,
.ui-slider-info .ui-slider-handle {
	background-color: $blue-9;
}

.ui-slider-info .ui-slider-handle.ui-state-focus,
.ui-slider-info .ui-slider-handle.ui-state-active {
	-webkit-box-shadow: 0 0 0 10px rgba(0, 188, 212, 0.1);
	box-shadow: 0 0 0 10px rgba(0, 188, 212, 0.1);
}

.ui-spinner {
	position: relative;
	display: table;
}

.ui-spinner-input {
	display: table-cell;
	width: calc(100% - 16px);
}

.ui-spinner-button {
	font-size: 0;
	cursor: pointer;
	display: table-cell;
	width: 1%;
	border-radius: 0;
	padding-left: 11px;
	padding-right: 11px;
}

.ui-spinner-button:after {
	font-family: "Icomoon";
	display: inline-block;
	width: 16px;
	font-size: 16px;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.ui-spinner-button + .ui-spinner-button {
	border-left: 1px solid var(--white);
}

.ui-spinner-button .ui-button-text {
	display: none;
}

.ui-spinner-button.ui-state-hover,
.ui-spinner-button.ui-state-focus {
	color: $gray-4;
}

.ui-spinner-button.ui-state-disabled {
	background-color: transparent;
	color: $gray-3;
	cursor: not-allowed;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.ui-spinner-up {
	border-bottom-left-radius: 3px;
	border-top-left-radius: 3px;
}

.ui-spinner-up:after {
	content: "\e9f7";
}

.ui-spinner-down {
	margin-left: 1px;
	border-bottom-right-radius: 3px;
	border-top-right-radius: 3px;
}

.ui-spinner-down:after {
	content: "\e9e2";
}

.ui-tabs {
	position: relative;
}

.ui-tabs .ui-tabs-nav {
	margin-bottom: 20px;
	border-bottom: 1px solid $light-gray-13;
}

.ui-tabs .ui-tabs-nav li {
	list-style: none;
	position: relative;
	padding: 0;
	white-space: nowrap;
	margin-bottom: -1px;
}

@media (min-width: 769px) {
	.ui-tabs .ui-tabs-nav li {
		float: left;
	}
}

.ui-tabs .ui-tabs-nav .ui-tabs-anchor {
	display: block;
	color: #888;
	padding: 12px 16px;
	border: 1px solid transparent;
	text-transform: uppercase;
	border-top-width: 2px;
}

.ui-tabs .ui-tabs-nav .ui-state-hover .ui-tabs-anchor:not(.ui-state-disabled),
.ui-tabs .ui-tabs-nav .ui-state-focus .ui-tabs-anchor:not(.ui-state-disabled) {
	color: $gray-4;
}

.ui-tabs .ui-tabs-nav .ui-tabs-active .ui-tabs-anchor {
	color: $gray-4;
	background-color: var(--white);
	cursor: default;
	border-color: $blue-shade-1 $light-gray-13 transparent;
}

.ui-tabs .ui-tabs-nav .ui-state-disabled {
	color: $gray-3;
	cursor: not-allowed;
	opacity: 0.75;
	filter: alpha(opacity=75);
}

.ui-tabs .ui-tabs-nav .ui-sortable-helper:not(.ui-tabs-active) .ui-tabs-anchor {
	background-color: var(--white);
	border-bottom-color: $light-gray-13;
}

@media (max-width: 768px) {
	.ui-tabs .ui-tabs-nav {
		border-bottom: 0;
		position: relative;
		background-color: var(--white);
		padding: 8px 0;
		border: 1px solid $light-gray-13;
		border-radius: 3px;
	}
	.ui-tabs .ui-tabs-nav li {
		margin-bottom: 0;
	}
	.ui-tabs .ui-tabs-nav li + li {
		margin-top: 1px;
	}
	.ui-tabs .ui-tabs-nav .ui-tabs-anchor {
		border-width: 0 0 0 2px;
		border-left-color: transparent;
	}
	.ui-tabs .ui-tabs-nav .ui-state-hover .ui-tabs-anchor,
	.ui-tabs .ui-tabs-nav .ui-state-focus .ui-tabs-anchor {
		background-color: $light-gray-15;
	}
	.ui-tabs .ui-tabs-nav .ui-state-active .ui-tabs-anchor {
		border-left-color: $blue-shade-1;
		background-color: $light-gray;
	}
	.ui-tabs .ui-tabs-nav:before {
		content: "Contents";
		color: inherit;
		font-size: 12px;
		line-height: 1.6666667;
		font-weight: 500;
		margin-top: 7px;
		margin-left: 15px;
		margin-bottom: 15px;
		text-transform: uppercase;
		opacity: 0.5;
		filter: alpha(opacity=50);
	}
}

.ui-tabs .ui-tabs-panel {
	display: block;
}

.ui-tooltip {
	position: absolute;
	z-index: 1070;
	max-width: 200px;
	padding: 3px 8px;
	color: var(--white);
	text-align: center;
	background-color: $gray-4;
	border-radius: 3px;
}

.ui-widget-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000;
	opacity: 0.5;
	filter: alpha(opacity=50);
}

.ui-helper-hidden {
	display: none;
}

.ui-helper-hidden-accessible {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.ui-helper-reset {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	list-style: none;
}

.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
	content: "";
	display: table;
	border-collapse: collapse;
}

.ui-helper-clearfix:after {
	clear: both;
}

.ui-helper-zfix {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	opacity: 0;
	filter: alpha(opacity=0);
}

.ui-front {
	z-index: 1040;
}

/* ------------------------------------------------------------------------------
*
*  # Ripple effect
*
*  Material design ripple click effect
*
*  Version: 1.0
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.legitRipple {
	position: relative;
	overflow: hidden;
	z-index: 0;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.legitRipple-ripple {
	position: absolute;
	width: 0;
	z-index: -1;
	pointer-events: none;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, 0.1);
	will-change: transform, width, opacity;
	opacity: 1;
	filter: alpha(opacity=100);
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-transition: width 0.2s linear, opacity 0.5s ease-out;
	-o-transition: width 0.2s linear, opacity 0.5s ease-out;
	transition: width 0.2s linear, opacity 0.5s ease-out;
}

.legitRipple-ripple:before {
	content: "";
	padding-top: 100%;
	display: block;
}

.breadcrumb-line[class*="bg-"] .breadcrumb-elements > li .legitRipple-ripple {
	background-color: rgba(255, 255, 255, 0.1);
}

.btn-default .legitRipple-ripple,
.btn-link .legitRipple-ripple,
.btn-flat .legitRipple-ripple,
.icons-list > li > a .legitRipple-ripple,
.pagination > li:not(.active) > a .legitRipple-ripple,
.pagination > li:not(.active) > span .legitRipple-ripple,
.nav-tabs:not([class*="bg-"]) > li > a .legitRipple-ripple,
.breadcrumb-elements > li .legitRipple-ripple,
.nav-pills > li > a .legitRipple-ripple,
.ui-button:not([class*="bg-"]) .legitRipple-ripple,
.ui-tabs-anchor .legitRipple-ripple,
.fc-button .legitRipple-ripple,
.checkbox .legitRipple-ripple,
.wizard > .actions > ul > li:first-child > a .legitRipple-ripple,
.sidebar-default .navigation > li > a .legitRipple-ripple,
.sidebar-default .navigation > li ul li > a .legitRipple-ripple,
.navbar-default .navbar-nav > li > a .legitRipple-ripple {
	background-color: rgba(0, 0, 0, 0.05);
}

img ~ .legitRipple-ripple {
	z-index: auto;
}

.sidebar-user-material-content .legitRipple {
	border-radius: 50%;
}

.legitRipple-template {
	display: none;
}

.legitRipple-custom {
	overflow: hidden;
}

.legitRipple-custom > * {
	position: absolute;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

/* ------------------------------------------------------------------------------
*
*  # Floating action buttons
*
*  Material design floating action button with menu
*
*  Version: 1.0
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.fab-menu {
	position: relative;
	display: inline-block;
	white-space: nowrap;
	padding: 0;
	margin: 0;
	list-style: none;
	z-index: 999;
}

.fab-menu-absolute {
	position: absolute;
}

.fab-menu-absolute.affix {
	position: fixed;
}

.fab-menu-fixed {
	position: fixed;
	z-index: 1030;
}

.fab-menu-top-left,
.fab-menu-top-right {
	margin-top: -26px;
}

.fab-menu-top-left.affix,
.fab-menu-top-right.affix {
	top: 20px;
	margin-top: 0;
}

.content-wrapper > .fab-menu-top-left,
.content-wrapper > .fab-menu-top-right {
	top: -26px;
}

.content-wrapper > .fab-menu-top-left.affix,
.content-wrapper > .fab-menu-top-right.affix {
	top: 20px;
}

.navbar-top .fab-menu-top-left.affix,
.navbar-top .fab-menu-top-right.affix,
.navbar-affixed-top .fab-menu-top-left.affix,
.navbar-affixed-top .fab-menu-top-right.affix {
	top: 70px;
}

.navbar-top-lg .fab-menu-top-left.affix,
.navbar-top-lg .fab-menu-top-right.affix,
.navbar-affixed-top-lg .fab-menu-top-left.affix,
.navbar-affixed-top-lg .fab-menu-top-right.affix {
	top: 74px;
}

.navbar-top-sm .fab-menu-top-left.affix,
.navbar-top-sm .fab-menu-top-right.affix,
.navbar-affixed-top-sm .fab-menu-top-left.affix,
.navbar-affixed-top-sm .fab-menu-top-right.affix {
	top: 68px;
}

.navbar-top-xs .fab-menu-top-left.affix,
.navbar-top-xs .fab-menu-top-right.affix,
.navbar-affixed-top-xs .fab-menu-top-left.affix,
.navbar-affixed-top-xs .fab-menu-top-right.affix {
	top: 66px;
}

.fab-menu-bottom-left,
.fab-menu-bottom-right {
	bottom: 20px;
	-webkit-transition: bottom ease-in-out 0.15s;
	-o-transition: bottom ease-in-out 0.15s;
	transition: bottom ease-in-out 0.15s;
}

.fab-menu-bottom-left.reached-bottom,
.fab-menu-bottom-right.reached-bottom {
	bottom: 80px;
}

.navbar-bottom .fab-menu-bottom-left.reached-bottom,
.navbar-bottom .fab-menu-bottom-right.reached-bottom {
	bottom: 90px;
}

.navbar-bottom-lg .fab-menu-bottom-left.reached-bottom,
.navbar-bottom-lg .fab-menu-bottom-right.reached-bottom {
	bottom: 94px;
}

.navbar-bottom-sm .fab-menu-bottom-left.reached-bottom,
.navbar-bottom-sm .fab-menu-bottom-right.reached-bottom {
	bottom: 88px;
}

.navbar-bottom-xs .fab-menu-bottom-left.reached-bottom,
.navbar-bottom-xs .fab-menu-bottom-right.reached-bottom {
	bottom: 86px;
}

.fab-menu-top-right,
.fab-menu-bottom-right {
	right: 20px;
}

.fab-menu-inner {
	list-style: none;
	margin: 0;
	padding: 0;
}

.fab-menu-inner > li {
	display: block;
	position: absolute;
	top: 2px;
	left: 0;
	right: 0;
	text-align: center;
	padding-top: 20px;
	margin-top: -20px;
}

.fab-menu-inner > li .btn {
	border-width: 0;
}

.fab-menu-inner > li .btn-float {
	padding: 0;
	width: 48px;
	height: 48px;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
		0 1px 2px rgba(0, 0, 0, 0.24);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.fab-menu-inner > li .btn-float:hover,
.fab-menu-inner > li .btn-float:focus {
	-webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
		0 3px 6px rgba(0, 0, 0, 0.23);
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.fab-menu-inner > li .btn-float > i {
	margin: 16px;
}

.fab-menu-inner > li .btn-float.btn-flat > i {
	margin: 14px;
}

.fab-menu-inner > li .badge,
.fab-menu-inner > li .label,
.fab-menu-inner > li .status-mark {
	position: absolute;
}

@media (min-width: 769px) {
	.fab-menu-inner > li .badge,
	.fab-menu-inner > li .label,
	.fab-menu-inner > li .status-mark {
		-webkit-box-shadow: 0 0 0 2px $gray-shade-1;
		box-shadow: 0 0 0 2px $gray-shade-1;
	}
}

.fab-menu-inner > li .badge,
.fab-menu-inner > li .label {
	top: 16px;
	right: -4px;
}

.fab-menu-inner > li .status-mark {
	top: 22px;
	right: 2px;
}

.fab-menu-bottom-left .fab-menu-inner > li,
.fab-menu-bottom-right .fab-menu-inner > li {
	padding-top: 0;
	margin-top: 0;
	padding-bottom: 20px;
	margin-bottom: -20px;
}

.fab-menu-bottom-left .fab-menu-inner > li .badge,
.fab-menu-bottom-right .fab-menu-inner > li .badge,
.fab-menu-bottom-left .fab-menu-inner > li .label,
.fab-menu-bottom-right .fab-menu-inner > li .label {
	top: -4px;
}

.fab-menu-bottom-left .fab-menu-inner > li .status-mark,
.fab-menu-bottom-right .fab-menu-inner > li .status-mark {
	top: 2px;
}

.fab-menu-inner .dropdown-menu {
	margin-top: 0;
	margin-right: 10px;
	top: 19px;
	left: auto;
	right: 100%;
}

.fab-menu-inner .dropdown-menu-right {
	margin-right: 0;
	margin-left: 10px;
	right: auto;
	left: 100%;
}

.fab-menu-btn {
	z-index: 1001;
	border-width: 0;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
		0 1px 2px rgba(0, 0, 0, 0.24);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.fab-menu-btn.btn-float {
	padding: 26px;
}

.fab-menu-btn.btn-float > i {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -8px;
	margin-left: -8px;
}

.fab-menu-btn:hover,
.fab-menu-btn:focus,
.fab-menu[data-fab-toggle="hover"]:hover .fab-menu-btn,
.fab-menu[data-fab-state="open"] .fab-menu-btn {
	-webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
		0 3px 6px rgba(0, 0, 0, 0.23);
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.fab-icon-close,
.fab-icon-open {
	-webkit-transform: rotate(360deg);
	-ms-transform: rotate(360deg);
	-o-transform: rotate(360deg);
	transform: rotate(360deg);
	-webkit-transition: all ease-in-out 0.3s;
	-o-transition: all ease-in-out 0.3s;
	transition: all ease-in-out 0.3s;
}

.fab-menu[data-fab-toggle="hover"]:hover .fab-icon-close,
.fab-menu[data-fab-toggle="hover"]:hover .fab-icon-open,
.fab-menu[data-fab-state="open"] .fab-icon-close,
.fab-menu[data-fab-state="open"] .fab-icon-open {
	-webkit-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
}

.fab-menu[data-fab-toggle="hover"]:hover .fab-icon-open,
.fab-menu[data-fab-state="open"] .fab-icon-open {
	opacity: 0;
	filter: alpha(opacity=0);
}

.fab-icon-close {
	opacity: 0;
	filter: alpha(opacity=0);
}

.fab-menu[data-fab-toggle="hover"]:hover .fab-icon-close,
.fab-menu[data-fab-state="open"] .fab-icon-close {
	opacity: 1;
	filter: alpha(opacity=100);
}

.fab-menu .fab-menu-inner > li {
	visibility: hidden;
	opacity: 0;
	filter: alpha(opacity=0);
	-webkit-transition: all ease-in-out 0.3s;
	-o-transition: all ease-in-out 0.3s;
	transition: all ease-in-out 0.3s;
}

.fab-menu .fab-menu-inner > li:nth-child(1) {
	-webkit-transition-delay: 0.05s;
	transition-delay: 0.05s;
}

.fab-menu .fab-menu-inner > li:nth-child(2) {
	-webkit-transition-delay: 0.1s;
	transition-delay: 0.1s;
}

.fab-menu .fab-menu-inner > li:nth-child(3) {
	-webkit-transition-delay: 0.15s;
	transition-delay: 0.15s;
}

.fab-menu .fab-menu-inner > li:nth-child(4) {
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

.fab-menu .fab-menu-inner > li:nth-child(5) {
	-webkit-transition-delay: 0.25s;
	transition-delay: 0.25s;
}

.fab-menu[data-fab-toggle="hover"]:hover .fab-menu-inner > li,
.fab-menu[data-fab-state="open"] .fab-menu-inner > li {
	visibility: visible;
	opacity: 1;
	filter: alpha(opacity=100);
}

.fab-menu-top[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(1),
.fab-menu-top-left[data-fab-toggle="hover"]:hover
	.fab-menu-inner
	> li:nth-child(1),
.fab-menu-top-right[data-fab-toggle="hover"]:hover
	.fab-menu-inner
	> li:nth-child(1),
.fab-menu-top[data-fab-state="open"] .fab-menu-inner > li:nth-child(1),
.fab-menu-top-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(1),
.fab-menu-top-right[data-fab-state="open"] .fab-menu-inner > li:nth-child(1) {
	top: 72px;
	-webkit-transition-delay: 0.05s;
	transition-delay: 0.05s;
}

.fab-menu-top[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(2),
.fab-menu-top-left[data-fab-toggle="hover"]:hover
	.fab-menu-inner
	> li:nth-child(2),
.fab-menu-top-right[data-fab-toggle="hover"]:hover
	.fab-menu-inner
	> li:nth-child(2),
.fab-menu-top[data-fab-state="open"] .fab-menu-inner > li:nth-child(2),
.fab-menu-top-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(2),
.fab-menu-top-right[data-fab-state="open"] .fab-menu-inner > li:nth-child(2) {
	top: 140px;
	-webkit-transition-delay: 0.1s;
	transition-delay: 0.1s;
}

.fab-menu-top[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(3),
.fab-menu-top-left[data-fab-toggle="hover"]:hover
	.fab-menu-inner
	> li:nth-child(3),
.fab-menu-top-right[data-fab-toggle="hover"]:hover
	.fab-menu-inner
	> li:nth-child(3),
.fab-menu-top[data-fab-state="open"] .fab-menu-inner > li:nth-child(3),
.fab-menu-top-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(3),
.fab-menu-top-right[data-fab-state="open"] .fab-menu-inner > li:nth-child(3) {
	top: 208px;
	-webkit-transition-delay: 0.15s;
	transition-delay: 0.15s;
}

.fab-menu-top[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(4),
.fab-menu-top-left[data-fab-toggle="hover"]:hover
	.fab-menu-inner
	> li:nth-child(4),
.fab-menu-top-right[data-fab-toggle="hover"]:hover
	.fab-menu-inner
	> li:nth-child(4),
.fab-menu-top[data-fab-state="open"] .fab-menu-inner > li:nth-child(4),
.fab-menu-top-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(4),
.fab-menu-top-right[data-fab-state="open"] .fab-menu-inner > li:nth-child(4) {
	top: 276px;
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

.fab-menu-top[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(5),
.fab-menu-top-left[data-fab-toggle="hover"]:hover
	.fab-menu-inner
	> li:nth-child(5),
.fab-menu-top-right[data-fab-toggle="hover"]:hover
	.fab-menu-inner
	> li:nth-child(5),
.fab-menu-top[data-fab-state="open"] .fab-menu-inner > li:nth-child(5),
.fab-menu-top-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(5),
.fab-menu-top-right[data-fab-state="open"] .fab-menu-inner > li:nth-child(5) {
	top: 344px;
	-webkit-transition-delay: 0.25s;
	transition-delay: 0.25s;
}

.fab-menu-bottom[data-fab-toggle="hover"]:hover
	.fab-menu-inner
	> li:nth-child(1),
.fab-menu-bottom-left[data-fab-toggle="hover"]:hover
	.fab-menu-inner
	> li:nth-child(1),
.fab-menu-bottom-right[data-fab-toggle="hover"]:hover
	.fab-menu-inner
	> li:nth-child(1),
.fab-menu-bottom[data-fab-state="open"] .fab-menu-inner > li:nth-child(1),
.fab-menu-bottom-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(1),
.fab-menu-bottom-right[data-fab-state="open"]
	.fab-menu-inner
	> li:nth-child(1) {
	top: -64px;
	-webkit-transition-delay: 0.05s;
	transition-delay: 0.05s;
}

.fab-menu-bottom[data-fab-toggle="hover"]:hover
	.fab-menu-inner
	> li:nth-child(2),
.fab-menu-bottom-left[data-fab-toggle="hover"]:hover
	.fab-menu-inner
	> li:nth-child(2),
.fab-menu-bottom-right[data-fab-toggle="hover"]:hover
	.fab-menu-inner
	> li:nth-child(2),
.fab-menu-bottom[data-fab-state="open"] .fab-menu-inner > li:nth-child(2),
.fab-menu-bottom-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(2),
.fab-menu-bottom-right[data-fab-state="open"]
	.fab-menu-inner
	> li:nth-child(2) {
	top: -132px;
	-webkit-transition-delay: 0.1s;
	transition-delay: 0.1s;
}

.fab-menu-bottom[data-fab-toggle="hover"]:hover
	.fab-menu-inner
	> li:nth-child(3),
.fab-menu-bottom-left[data-fab-toggle="hover"]:hover
	.fab-menu-inner
	> li:nth-child(3),
.fab-menu-bottom-right[data-fab-toggle="hover"]:hover
	.fab-menu-inner
	> li:nth-child(3),
.fab-menu-bottom[data-fab-state="open"] .fab-menu-inner > li:nth-child(3),
.fab-menu-bottom-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(3),
.fab-menu-bottom-right[data-fab-state="open"]
	.fab-menu-inner
	> li:nth-child(3) {
	top: -200px;
	-webkit-transition-delay: 0.15s;
	transition-delay: 0.15s;
}

.fab-menu-bottom[data-fab-toggle="hover"]:hover
	.fab-menu-inner
	> li:nth-child(4),
.fab-menu-bottom-left[data-fab-toggle="hover"]:hover
	.fab-menu-inner
	> li:nth-child(4),
.fab-menu-bottom-right[data-fab-toggle="hover"]:hover
	.fab-menu-inner
	> li:nth-child(4),
.fab-menu-bottom[data-fab-state="open"] .fab-menu-inner > li:nth-child(4),
.fab-menu-bottom-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(4),
.fab-menu-bottom-right[data-fab-state="open"]
	.fab-menu-inner
	> li:nth-child(4) {
	top: -268px;
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

.fab-menu-bottom[data-fab-toggle="hover"]:hover
	.fab-menu-inner
	> li:nth-child(5),
.fab-menu-bottom-left[data-fab-toggle="hover"]:hover
	.fab-menu-inner
	> li:nth-child(5),
.fab-menu-bottom-right[data-fab-toggle="hover"]:hover
	.fab-menu-inner
	> li:nth-child(5),
.fab-menu-bottom[data-fab-state="open"] .fab-menu-inner > li:nth-child(5),
.fab-menu-bottom-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(5),
.fab-menu-bottom-right[data-fab-state="open"]
	.fab-menu-inner
	> li:nth-child(5) {
	top: -336px;
	-webkit-transition-delay: 0.25s;
	transition-delay: 0.25s;
}

.fab-menu-inner div[data-fab-label]:after {
	content: attr(data-fab-label);
	position: absolute;
	top: 50%;
	margin-top: -8px;
	right: 70px;
	color: var(--white);
	background-color: $gray-4;
	padding: 8px 16px;
	visibility: hidden;
	border-radius: 3px;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
		0 1px 2px rgba(0, 0, 0, 0.24);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	opacity: 0;
	filter: alpha(opacity=0);
	-webkit-transition: all ease-in-out 0.3s;
	-o-transition: all ease-in-out 0.3s;
	transition: all ease-in-out 0.3s;
}

.fab-menu-bottom-left .fab-menu-inner div[data-fab-label]:after,
.fab-menu-bottom-right .fab-menu-inner div[data-fab-label]:after {
	margin-top: -28px;
}

.fab-menu-top-left .fab-menu-inner div[data-fab-label]:after,
.fab-menu-bottom-left .fab-menu-inner div[data-fab-label]:after,
.fab-menu-inner div[data-fab-label].fab-label-right:after {
	right: auto;
	left: 70px;
}

.fab-menu[data-fab-toggle="hover"]
	.fab-menu-inner
	div[data-fab-label]:hover:after,
.fab-menu[data-fab-state="open"]
	.fab-menu-inner
	div[data-fab-label]:hover:after {
	visibility: visible;
	opacity: 1;
	filter: alpha(opacity=100);
}

.fab-menu-inner div[data-fab-label].fab-label-light:after {
	background-color: var(--white);
	color: $gray-4;
}

.fab-menu-inner div[data-fab-label].fab-label-visible:after {
	visibility: visible;
	opacity: 1;
	filter: alpha(opacity=100);
}

/* ------------------------------------------------------------------------------
 *
 *  # Prism
 *
 *  Lightweight, extensible syntax highlighter
 *
 *  Version: 1.0
 *  Latest update: May 10, 2015
 *
 * ---------------------------------------------------------------------------- */
code[class*="language-"],
pre[class*="language-"] {
	text-shadow: 0 1px var(--white);
	word-break: normal;
	line-height: 1.5;
	direction: ltr;
}

@media print {
	code[class*="language-"],
	pre[class*="language-"] {
		text-shadow: none;
	}
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata,
.token.punctuation {
	color: $gray-3;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol {
	color: #905;
}

.token.selector,
.token.attr-name,
.token.string,
.token.builtin {
	color: #690;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string,
.token.variable {
	color: #a67f59;
	background: rgba(255, 255, 255, 0.5);
}

.token.atrule,
.token.attr-value,
.token.keyword {
	color: #07a;
}

.token.function {
	color: #dd4a68;
}

.token.regex,
.token.important {
	color: #e90;
}

.token.important {
	font-weight: bold;
}

.token.entity {
	cursor: help;
}

.namespace {
	opacity: 0.7;
	filter: alpha(opacity=70);
}

.line-highlight {
	position: absolute;
	left: 0;
	right: 0;
	margin-top: 20px;
	background: rgba(133, 238, 149, 0.12);
	pointer-events: none;
	white-space: pre;
	line-height: inherit;
}

.line-highlight:before,
.line-highlight:after {
	content: attr(data-start);
	position: absolute;
	top: 0px;
	left: 10px;
	padding: 2px;
	text-align: center;
	font-size: 10px;
	color: $gray-3;
}

.line-highlight[data-end]:after {
	content: attr(data-end);
	top: auto;
	bottom: 0;
}

pre.line-numbers {
	position: relative;
	padding-left: 50px;
	counter-reset: linenumber;
}

pre.line-numbers > code {
	position: relative;
}

.line-numbers .line-numbers-rows {
	position: absolute;
	pointer-events: none;
	top: 0;
	font-size: 100%;
	left: -50px;
	width: 36px;
	letter-spacing: -1px;
	border-right: 1px solid $gray-3;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.line-numbers .line-numbers-rows > span {
	pointer-events: none;
	display: block;
	counter-increment: linenumber;
}

.line-numbers .line-numbers-rows > span:before {
	content: counter(linenumber);
	color: $gray-3;
	display: block;
	padding-right: 10px;
	text-align: right;
}

pre[class*="language-"] {
	overflow: auto;
	position: relative;
	border-left-width: 3px;
	border-left-color: $blue-shade-1;
	background-color: #fdfdfd;
	background-image: -webkit-linear-gradient(
		transparent 50%,
		rgba(25, 118, 210, 0.025) 50%
	);
	background-image: -moz-linear-gradient(
		transparent 50%,
		rgba(25, 118, 210, 0.025) 50%
	);
	background-image: -ms-linear-gradient(
		transparent 50%,
		rgba(25, 118, 210, 0.025) 50%
	);
	background-image: -o-linear-gradient(
		transparent 50%,
		rgba(25, 118, 210, 0.025) 50%
	);
	background-image: linear-gradient(
		transparent 50%,
		rgba(25, 118, 210, 0.025) 50%
	);
	background-size: 3em 3em;
	background-origin: content-box;
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}

pre[class*="language-"] > code[data-language] {
	display: block;
}

pre[class*="language-"] > code[data-language]:before {
	content: attr(data-language);
	background-color: $light-gray-11;
	display: inline-block;
	position: absolute;
	top: 0px;
	right: 0px;
	font-size: 11px;
	border-radius: 0 0 0 2px;
	padding: 4px 8px;
	text-shadow: none;
	color: $gray-4;
	border-left: 1px solid $light-gray-13;
	border-bottom: 1px solid $light-gray-13;
}

pre[class*="language-"].line-numbers > code[data-language]:before {
	top: -15px;
	right: -15px;
}

pre[data-line] {
	position: relative;
	padding-left: 36px;
}

/* ------------------------------------------------------------------------------
*
*  # Fancytree
*
*  Tree plugin for jQuery with support for persistence, keyboard, checkboxes,
*  tables (grid), drag'n'drop, and lazy loading
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
/* # Core
-------------------------------------------------- */
.fancytree-container {
	list-style: none;
	white-space: nowrap;
	padding: 0;
	margin: 0;
	overflow: auto;
	position: relative;
}

.fancytree-container:focus {
	outline: 0;
}

.fancytree-container ul {
	list-style: none;
	padding: 0 0 0 20px;
	margin: 0;
}

.fancytree-container ul li {
	margin-top: 1px;
}

.ui-fancytree-disabled .fancytree-container {
	opacity: 0.6;
	filter: alpha(opacity=60);
}

.ui-fancytree-disabled .fancytree-container,
.ui-fancytree-disabled .fancytree-title,
.ui-fancytree-disabled .fancytree-expander {
	cursor: not-allowed;
}

.ui-fancytree-disabled
	.fancytree-treefocus
	.fancytree-selected
	.fancytree-title {
	background-color: $light-gray;
	color: $gray-4;
}

.ui-helper-hidden {
	display: none;
}

.fancytree-expander:after,
.fancytree-icon:after,
.fancytree-checkbox:after,
.fancytree-drag-helper-img:after,
.fancytree-drop-before:after,
.fancytree-drop-after:after,
.fancytree-loading .fancytree-expander:after,
.fancytree-statusnode-wait .fancytree-icon:after {
	font-family: "icomoon";
	display: inline-block;
	font-size: 16px;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.fancytree-icon,
.fancytree-checkbox,
.fancytree-custom-icon {
	margin-top: 5px;
	float: left;
}

.fancytree-checkbox {
	margin: 4px 10px 0 2px;
}

.fancytree-custom-icon {
	display: inline-block;
}

img.fancytree-icon {
	width: 16px;
	height: 16px;
	margin-left: 3px;
	margin-top: 3px;
	vertical-align: top;
	border-style: none;
}

.fancytree-expander {
	cursor: pointer;
	float: left;
	margin-top: 5px;
	width: 16px;
	line-height: 1;
}

.fancytree-exp-c .fancytree-expander:after {
	content: "\e9c7";
}

.fancytree-exp-cl .fancytree-expander:after {
	content: "\e9c7";
}

.fancytree-exp-cd .fancytree-expander:after,
.fancytree-exp-cdl .fancytree-expander:after {
	content: "\e9c7";
}

.fancytree-exp-e .fancytree-expander:after,
.fancytree-exp-ed .fancytree-expander:after {
	content: "\e9c5";
}

.fancytree-exp-el .fancytree-expander:after,
.fancytree-exp-edl .fancytree-expander:after {
	content: "\e9c5";
}

.fancytree-statusnode-error .fancytree-icon:after {
	content: "\ed63";
}

.fancytree-loading .fancytree-expander,
.fancytree-statusnode-wait .fancytree-icon {
	margin-left: auto;
	margin-right: auto;
	width: 16px;
	height: 16px;
	display: inline-block;
	text-align: center;
}

.fancytree-loading .fancytree-expander:after,
.fancytree-statusnode-wait .fancytree-icon:after {
	content: "\ed6c";
	-webkit-transition: all 0.15s ease-in-out;
	-o-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out;
}

.fancytree-icon {
	display: inline-block;
	width: 16px;
	height: 16px;
}

.fancytree-ico-c .fancytree-icon:after,
.fancytree-ico-e .fancytree-icon:after {
	content: "\ea1a";
}

.fancytree-has-children.fancytree-ico-c .fancytree-icon:after {
	content: "\ea0f";
}

.fancytree-has-children.fancytree-ico-e .fancytree-icon:after {
	content: "\ea11";
}

.fancytree-ico-cf .fancytree-icon:after,
.fancytree-ico-ef .fancytree-icon:after {
	content: "\ea3d";
}

.fancytree-has-children.fancytree-ico-cf .fancytree-icon:after {
	content: "\ea41";
}

.fancytree-has-children.fancytree-ico-ef .fancytree-icon:after {
	content: "\ea43";
}

.fancytree-checkbox {
	width: 18px;
	height: 18px;
	border: 2px solid #777;
	display: block;
	text-align: center;
	position: relative;
	cursor: pointer;
	border-radius: 2px;
}

.fancytree-selected .fancytree-checkbox {
	border-color: transparent;
}

.fancytree-checkbox:after,
.fancytree-partsel.fancytree-selected .fancytree-checkbox:after {
	content: "\ed6e";
	margin-top: -1px;
	margin-left: -1px;
	display: none;
}

.fancytree-partsel > .fancytree-checkbox:after {
	content: "";
	width: 8px;
	height: 8px;
	display: none;
	background-color: #777;
	margin: 3px;
}

.fancytree-partsel.fancytree-selected .fancytree-checkbox:after {
	background-color: transparent;
	width: auto;
	height: auto;
}

.fancytree-selected .fancytree-checkbox:after,
.fancytree-partsel .fancytree-checkbox:after,
.fancytree-partsel.fancytree-selected .fancytree-checkbox:after {
	display: block;
}

.fancytree-radio .fancytree-checkbox {
	border-radius: 100%;
}

.fancytree-radio .fancytree-checkbox:after {
	content: "";
	width: 8px;
	height: 8px;
	background-color: #777;
	margin: 3px;
	border-radius: 100px;
}

.fancytree-radio .fancytree-selected .fancytree-checkbox {
	border-color: #777;
}

/* # Other styles
-------------------------------------------------- */
.fancytree-drag-helper {
	border: 1px solid $light-gray-13;
	background-color: var(--white);
	padding: 4px 5px;
	opacity: 0.8;
	filter: alpha(opacity=80);
}

.fancytree-drag-helper-img:after {
	position: absolute;
	top: 50%;
	margin-top: -9px;
	right: -26px;
}

.fancytree-drop-accept .fancytree-drag-helper-img:after {
	content: "\ee73";
	color: $green-1;
}

.fancytree-drop-reject .fancytree-drag-helper-img:after {
	content: "\ed63";
	color: $red;
}

#fancytree-drop-marker.fancytree-drop-before,
#fancytree-drop-marker.fancytree-drop-after {
	width: 200px;
	border-top: 1px solid $gray-4;
	position: absolute !important;
}

.fancytree-drag-source {
	background-color: $light-gray-13;
}

.fancytree-drag-source .fancytree.title {
	color: $gray-4;
}

.fancytree-drop-target.fancytree-drop-accept a {
	background-color: $blue-shade-1 !important;
	color: var(--white) !important;
	text-decoration: none;
}

.fancytree-node {
	display: inherit;
	width: 100%;
	position: relative;
}

.fancytree-title {
	border: 0;
	padding: 4px 6px;
	border-radius: 2px;
	margin-left: 4px;
	display: inline-block;
	cursor: pointer;
}

.fancytree-title > input {
	border: 0;
	outline: 0;
	padding: 0;
	background-color: transparent;
}

.fancytree-selected .fancytree-title {
	background-color: $light-gray-13;
	color: $gray-4;
}

.fancytree-treefocus .fancytree-selected .fancytree-title {
	background-color: $blue-shade-1;
	color: var(--white);
}

.fancytree-active .fancytree-title {
	background-color: $light-gray-13;
}

.sidebar:not(.sidebar-default) .fancytree-selected .fancytree-title,
.sidebar:not(.sidebar-default) .fancytree-active .fancytree-title {
	background-color: rgba(255, 255, 255, 0.2);
	color: var(--white);
}

.sidebar:not(.sidebar-default)
	.fancytree-treefocus
	.fancytree-selected
	.fancytree-title {
	background-color: $blue-shade-1;
	color: var(--white);
}

.fancytree-ext-table .fancytree-node {
	display: inline-block;
	width: auto;
}

.fancytree-ext-table .fancytree-title {
	display: inline;
}

.fancytree-ext-table.fancytree-container {
	white-space: normal;
}

.fancytree-ext-table .fancytree-expander,
.fancytree-ext-table .fancytree-icon,
.fancytree-ext-table .fancytree-custom-icon {
	margin-top: 1px;
}

.fancytree-ext-table .fancytree-checkbox {
	margin: 0 auto;
	float: none;
}

.fancytree-ext-table .checker {
	display: block;
	margin: 0 auto;
}

.fancytree-ext-table tbody tr td:first-child {
	text-align: center;
}

.fancytree-ext-table tbody tr.fancytree-focused {
	background-color: $light-gray;
}

.fancytree-ext-table tbody tr.fancytree-active {
	background-color: $light-gray;
}

.fancytree-ext-table tbody tr.fancytree-selected {
	background-color: $blue-shade-1;
	color: var(--white);
}

.fancytree-ext-table tbody tr.fancytree-selected .fancytree-checkbox {
	color: var(--white);
}

.fancytree-ext-table tbody tr.fancytree-selected .checker span {
	color: var(--white);
	border-color: var(--white);
}

.fancytree-ext-table tbody tr.fancytree-selected .checker span.checked {
	border-color: transparent;
}

/* ------------------------------------------------------------------------------
*
*  # Ladda progress buttons
*
*  Buttons with built-in loading indicators
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.btn-ladda,
.btn-ladda .ladda-spinner,
.btn-ladda .ladda-label {
	-webkit-transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.3s;
	-o-transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.3s;
	transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.3s;
}

.btn-ladda .ladda-spinner {
	position: absolute;
	z-index: 2;
	display: inline-block;
	top: 50%;
	pointer-events: none;
	opacity: 0;
	filter: alpha(opacity=0);
}

.btn-ladda .ladda-label {
	z-index: 3;
}

.btn-ladda .ladda-progress {
	position: absolute;
	width: 0;
	height: 100%;
	left: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0.2);
	visibility: hidden;
	-webkit-transition: all linear 0.3s;
	-o-transition: all linear 0.3s;
	transition: all linear 0.3s;
	opacity: 0;
	filter: alpha(opacity=0);
}

.btn-ladda[data-loading] .ladda-progress {
	visibility: visible;
	opacity: 1;
	filter: alpha(opacity=100);
}

.btn-ladda[data-style="zoom-in"],
.btn-ladda[data-style="zoom-out"] {
	overflow: hidden;
}

.btn-ladda[data-style="zoom-in"],
.btn-ladda[data-style="zoom-out"],
.btn-ladda[data-style="zoom-in"] .ladda-spinner,
.btn-ladda[data-style="zoom-out"] .ladda-spinner,
.btn-ladda[data-style="zoom-in"] .ladda-label,
.btn-ladda[data-style="zoom-out"] .ladda-label {
	-webkit-transition: 0.3s ease all;
	-o-transition: 0.3s ease all;
	transition: 0.3s ease all;
}

.btn-ladda[data-style="zoom-in"] .ladda-label,
.btn-ladda[data-style="zoom-out"] .ladda-label {
	position: relative;
	display: inline-block;
}

.btn-ladda[data-style="zoom-in"] .ladda-spinner,
.btn-ladda[data-style="zoom-out"] .ladda-spinner {
	left: 50%;
}

.btn-ladda[data-style="zoom-in"][data-loading] .ladda-label,
.btn-ladda[data-style="zoom-out"][data-loading] .ladda-label {
	opacity: 0;
	filter: alpha(opacity=0);
}

.btn-ladda[data-style="zoom-in"][data-loading] .ladda-spinner,
.btn-ladda[data-style="zoom-out"][data-loading] .ladda-spinner {
	-webkit-transform: none;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none;
	opacity: 1;
	filter: alpha(opacity=100);
}

.btn-ladda[data-style="zoom-in"] .ladda-spinner {
	-webkit-transform: scale(0.2);
	-ms-transform: scale(0.2);
	-o-transform: scale(0.2);
	transform: scale(0.2);
}

.btn-ladda[data-style="zoom-in"][data-loading] .ladda-label {
	-webkit-transform: scale(2.2);
	-ms-transform: scale(2.2);
	-o-transform: scale(2.2);
	transform: scale(2.2);
}

.btn-ladda[data-style="zoom-out"] .ladda-spinner {
	-webkit-transform: scale(2.5);
	-ms-transform: scale(2.5);
	-o-transform: scale(2.5);
	transform: scale(2.5);
}

.btn-ladda[data-style="zoom-out"][data-loading] .ladda-label {
	-webkit-transform: scale(0.5);
	-ms-transform: scale(0.5);
	-o-transform: scale(0.5);
	transform: scale(0.5);
}

.btn-ladda[data-style="expand-left"] {
	overflow: hidden;
}

.btn-ladda[data-style="expand-left"] .ladda-spinner {
	left: 20px;
}

.btn-ladda[data-style="expand-left"][data-loading] {
	padding-left: 35px;
}

.btn-ladda[data-style="expand-left"][data-loading] .ladda-spinner {
	opacity: 1;
	filter: alpha(opacity=100);
}

.btn-ladda[data-style="expand-right"] {
	overflow: hidden;
}

.btn-ladda[data-style="expand-right"] .ladda-spinner {
	right: 20px;
}

.btn-ladda[data-style="expand-right"][data-loading] {
	padding-right: 35px;
}

.btn-ladda[data-style="expand-right"][data-loading] .ladda-spinner {
	opacity: 1;
	filter: alpha(opacity=100);
}

.btn-ladda[data-style="expand-up"] {
	overflow: hidden;
}

.btn-ladda[data-style="expand-up"] .ladda-spinner {
	top: -16px;
	left: 50%;
}

.btn-ladda[data-style="expand-up"][data-loading] {
	padding-top: 40px;
}

.btn-ladda[data-style="expand-up"][data-loading] .ladda-spinner {
	top: 12px;
	margin-top: 8px;
	opacity: 1;
	filter: alpha(opacity=100);
}

.btn-ladda[data-style="expand-down"] {
	overflow: hidden;
}

.btn-ladda[data-style="expand-down"] .ladda-spinner {
	top: 44px;
	left: 50%;
}

.btn-ladda[data-style="expand-down"][data-loading] {
	padding-bottom: 40px;
}

.btn-ladda[data-style="expand-down"][data-loading] .ladda-spinner {
	opacity: 1;
	filter: alpha(opacity=100);
}

.btn-ladda[data-style="slide-left"] {
	overflow: hidden;
}

.btn-ladda[data-style="slide-left"] .ladda-label {
	left: 0;
	position: relative;
}

.btn-ladda[data-style="slide-left"] .ladda-spinner {
	right: 0;
}

.btn-ladda[data-style="slide-left"][data-loading] .ladda-label {
	left: -100%;
	opacity: 0;
	filter: alpha(opacity=0);
}

.btn-ladda[data-style="slide-left"][data-loading] .ladda-spinner {
	right: 50%;
	opacity: 1;
	filter: alpha(opacity=100);
}

.btn-ladda[data-style="slide-right"] {
	overflow: hidden;
}

.btn-ladda[data-style="slide-right"] .ladda-label {
	right: 0;
	position: relative;
}

.btn-ladda[data-style="slide-right"] .ladda-spinner {
	left: 0;
}

.btn-ladda[data-style="slide-right"][data-loading] .ladda-label {
	right: -100%;
	opacity: 0;
	filter: alpha(opacity=0);
}

.btn-ladda[data-style="slide-right"][data-loading] .ladda-spinner {
	left: 50%;
	opacity: 1;
	filter: alpha(opacity=100);
}

.btn-ladda[data-style="slide-up"] {
	overflow: hidden;
}

.btn-ladda[data-style="slide-up"] .ladda-label {
	top: 0;
	position: relative;
}

.btn-ladda[data-style="slide-up"] .ladda-spinner {
	top: 100%;
	left: 50%;
}

.btn-ladda[data-style="slide-up"][data-loading] .ladda-label {
	top: -16px;
	opacity: 0;
	filter: alpha(opacity=0);
}

.btn-ladda[data-style="slide-up"][data-loading] .ladda-spinner {
	top: 16px;
	opacity: 1;
	filter: alpha(opacity=100);
}

.btn-ladda[data-style="slide-down"] {
	overflow: hidden;
}

.btn-ladda[data-style="slide-down"] .ladda-label {
	top: 0;
	position: relative;
}

.btn-ladda[data-style="slide-down"] .ladda-spinner {
	top: -100%;
	left: 50%;
}

.btn-ladda[data-style="slide-down"][data-loading] .ladda-label {
	top: 16px;
	opacity: 0;
	filter: alpha(opacity=0);
}

.btn-ladda[data-style="slide-down"][data-loading] .ladda-spinner {
	top: 16px;
	opacity: 1;
	filter: alpha(opacity=100);
}

.btn-ladda[data-style="fade"] {
	overflow: hidden;
}

.btn-ladda[data-style="fade"] .ladda-spinner {
	left: 50%;
}

.btn-ladda[data-style="fade"][data-loading] .ladda-label {
	opacity: 0;
	filter: alpha(opacity=0);
}

.btn-ladda[data-style="fade"][data-loading] .ladda-spinner {
	opacity: 1;
	filter: alpha(opacity=100);
}

.btn-ladda[data-style="radius"] {
	overflow: hidden;
	border-radius: 2px;
	-webkit-transition: all ease-in-out 0.5s;
	-o-transition: all ease-in-out 0.5s;
	transition: all ease-in-out 0.5s;
}

.btn-ladda[data-style="radius"] .ladda-spinner {
	left: 50%;
}

.btn-ladda[data-style="radius"][data-loading] {
	border-radius: 4px;
}

.btn-ladda[data-style="radius"][data-loading] .ladda-label {
	opacity: 0;
	filter: alpha(opacity=0);
}

.btn-ladda[data-style="radius"][data-loading] .ladda-spinner {
	opacity: 1;
	filter: alpha(opacity=100);
}

/* ------------------------------------------------------------------------------
 *
 *  # FullCalendar
 *
 *  A JavaScript event calendar. Customizable and open source
 *
 *  Version: 1.2
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.fc {
	direction: ltr;
	text-align: left;
}

.fc-view > table {
	min-width: 769px;
}

.fc-events-container {
	background-color: $light-gray-3;
	border: 1px solid $light-gray-13;
	padding: 10px;
	border-radius: 3px;
}

.fc-events-container .fc-event {
	margin: 0;
	padding: 4px 14px;
}

.fc-events-container .fc-event + .fc-event {
	margin-top: 10px;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed hr,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
	border-color: $light-gray-13;
}

.fc-unthemed thead,
.fc-unthemed thead td,
.fc-unthemed thead th {
	border-bottom-color: $light-gray-17;
}

.fc-unthemed hr {
	background-color: $light-gray;
}

.fc-unthemed .fc-popover {
	background-color: var(--white);
	border-radius: 3px;
}

.fc-unthemed .fc-popover .fc-header {
	background-color: $light-gray;
}

.fc-unthemed .fc-today {
	background-color: #e8f5e9;
}

.fc-highlight {
	background-color: $light-gray-10;
	opacity: 0.3;
	filter: alpha(opacity=30);
}

.fc-bgevent {
	background-color: #81c784;
	opacity: 0.3;
	filter: alpha(opacity=30);
}

.fc-nonbusiness {
	background-color: $light-gray-14;
}

.fc-icon {
	display: block;
}

.fc-icon:after {
	display: inline-block;
	font-family: "icomoon";
	line-height: 1;
	font-size: 16px;
	vertical-align: middle;
	position: relative;
	top: -1px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.fc-icon-left-single-arrow:after {
	content: "\e9c0";
}

.fc-icon-right-single-arrow:after {
	content: "\e9c3";
}

.fc-icon-left-double-arrow:after {
	content: "\000AB";
}

.fc-icon-right-double-arrow:after {
	content: "\000BB";
}

.fc-icon-x:after {
	content: "\ed6b";
	font-size: 12px;
	display: block;
}

.fc-button {
	margin: 0;
	border: 0;
	padding: 9px 17px;
	white-space: nowrap;
	cursor: pointer;
	outline: 0;
	text-transform: uppercase;
	font-weight: 500;
}

.fc-button::-moz-focus-inner {
	margin: 0;
	padding: 0;
}

.fc-state-default {
	background-color: $light-gray;
}

.fc-state-default.fc-corner-left {
	border-bottom-left-radius: 3px;
	border-top-left-radius: 3px;
}

.fc-state-default.fc-corner-right {
	border-bottom-right-radius: 3px;
	border-top-right-radius: 3px;
}

.fc-state-hover {
	-webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
	box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
}

.fc-state-down,
.fc-state-active {
	-webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
	box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
}

.fc-state-disabled {
	color: $gray-3;
	cursor: not-allowed;
	opacity: 0.65;
	filter: alpha(opacity=65);
	-webkit-box-shadow: none;
	box-shadow: none;
}

.fc-button-group {
	display: inline-block;
}

.fc .fc-button-group > * {
	float: left;
	margin: 0 0 0 1px;
}

.fc .fc-button-group > :first-child {
	margin-left: 0;
}

.fc-popover {
	position: absolute;
	border: 1px solid $light-gray-13;
	-webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
}

.fc-popover .fc-header {
	padding: 10px;
}

.fc-popover .fc-header .fc-close {
	cursor: pointer;
}

.fc-ltr .fc-popover .fc-header .fc-title {
	float: left;
}

.fc-ltr .fc-popover .fc-header .fc-close {
	float: right;
}

.fc-unthemed .fc-popover .fc-header .fc-close {
	margin-top: 2px;
	opacity: 0.6;
	filter: alpha(opacity=60);
}

.fc-unthemed .fc-popover .fc-header .fc-close:hover {
	opacity: 1;
	filter: alpha(opacity=100);
}

.fc hr {
	height: 0;
	margin: 0;
	padding: 0 0 2px;
	border-style: solid;
	border-width: 1px 0;
}

.fc-clear {
	clear: both;
}

.fc-bg,
.fc-bgevent-skeleton,
.fc-highlight-skeleton,
.fc-helper-skeleton {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
}

.fc-bg {
	bottom: 0;
}

.fc-bg table {
	height: 100%;
}

.fc table {
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;
	border-spacing: 0;
}

.fc th,
.fc td {
	border-style: solid;
	border-width: 1px;
	padding: 0;
	vertical-align: top;
}

.fc th {
	padding: 12px 20px;
	font-weight: 500;
	text-align: center;
}

.fc td.fc-today {
	border-style: double;
}

.fc-row {
	border-style: solid;
	border-width: 0;
	position: relative;
}

.fc-row table {
	border-left: 0 hidden transparent;
	border-right: 0 hidden transparent;
	border-bottom: 0 hidden transparent;
}

.fc-row:first-child table {
	border-top: 0 hidden transparent;
}

.fc-row .fc-bg {
	z-index: 1;
}

.fc-row .fc-bgevent-skeleton,
.fc-row .fc-highlight-skeleton {
	bottom: 0;
}

.fc-row .fc-bgevent-skeleton table,
.fc-row .fc-highlight-skeleton table {
	height: 100%;
}

.fc-row .fc-bgevent-skeleton td,
.fc-row .fc-highlight-skeleton td {
	border-color: transparent;
	border: 0;
}

.fc-row .fc-bgevent-skeleton {
	z-index: 2;
}

.fc-row .fc-highlight-skeleton {
	z-index: 3;
}

.fc-row .fc-content-skeleton td,
.fc-row .fc-helper-skeleton td {
	background: none;
	border-color: transparent;
	border-bottom: 0;
}

.fc-row .fc-content-skeleton tbody td,
.fc-row .fc-helper-skeleton tbody td {
	border-top: 0;
}

.fc-row .fc-content-skeleton {
	position: relative;
	z-index: 4;
}

.fc-row .fc-helper-skeleton {
	z-index: 5;
}

.fc-scroller {
	overflow-y: scroll;
	overflow-x: hidden;
	min-height: 500px;
}

.fc-scroller > * {
	position: relative;
	width: 100%;
	overflow: hidden;
}

.fc-event {
	position: relative;
	display: block;
	border: 1px solid #039be5;
	background-color: #039be5;
	font-weight: normal;
	border-radius: 2px;
}

.fc-event,
.fc-event:hover {
	color: var(--white);
	text-decoration: none;
}

.fc-event[href],
.fc-event.fc-draggable {
	cursor: pointer;
}

.fc-not-allowed,
.fc-not-allowed .fc-event {
	cursor: not-allowed;
}

.fc-day-grid-event {
	margin: 0 5px 5px 5px;
	padding: 4px 8px;
}

.fc-day-grid-event > .fc-content {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.fc-day-grid-event .fc-time {
	font-weight: 500;
}

.fc-day-grid-event .fc-resizer {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 7px;
}

.fc-ltr .fc-day-grid-event .fc-resizer {
	right: -3px;
	cursor: e-resize;
}

@media (min-width: 1200px) {
	.fc-day-grid-event > .fc-content {
		background-color: inherit;
	}
	.fc-day-grid-event .fc-time {
		float: right;
		padding-left: 5px;
		display: inline-block;
		z-index: 2;
		position: relative;
		background-color: inherit;
	}
	.fc-day-grid-event .fc-title {
		z-index: 1;
		position: relative;
	}
}

a.fc-more {
	margin: 1px 5px;
	background-color: $light-gray-15;
	display: block;
	padding: 5px;
	text-align: center;
	border-radius: 3px;
	color: $gray-3;
	font-size: 12px;
}

a.fc-more:hover {
	background-color: $light-gray;
	color: $gray-4;
}

.fc-limited {
	display: none;
}

.fc-day-grid .fc-row {
	z-index: 1;
}

.fc-more-popover {
	z-index: 2;
	width: 220px;
}

.fc-more-popover .fc-event-container {
	padding: 10px 5px;
}

.fc-toolbar {
	text-align: center;
	margin-bottom: 20px;
}

.fc-toolbar h2 {
	margin: 5px 0;
	font-size: 17px;
}

.fc-toolbar .fc-left {
	float: left;
	width: 33%;
}

.fc-toolbar .fc-right {
	float: right;
	width: 33%;
}

.fc-toolbar .fc-right .fc-button-group {
	float: right;
}

.fc-toolbar .fc-left > .fc-button,
.fc-toolbar .fc-right > .fc-button {
	margin-left: 10px;
}

.fc-toolbar .fc-center {
	display: inline-block;
	vertical-align: middle;
}

.fc-toolbar > * > * {
	float: left;
}

.fc-toolbar > * > :first-child {
	margin-left: 0;
}

.fc-toolbar button {
	position: relative;
}

.fc-toolbar button:focus {
	z-index: 5;
}

.fc-toolbar .fc-state-hover,
.fc-toolbar .ui-state-hover {
	z-index: 2;
}

.fc-toolbar .fc-state-down {
	z-index: 3;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active {
	z-index: 4;
}

.fc-view-container *,
.fc-view-container *:before,
.fc-view-container *:after {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}

.fc-view,
.fc-view > table {
	position: relative;
	z-index: 1;
}

.fc-basicWeek-view .fc-content-skeleton,
.fc-basicDay-view .fc-content-skeleton {
	padding-top: 5px;
	padding-bottom: 10px;
}

.fc-basic-view tbody .fc-row {
	min-height: 100px;
	min-width: 100px;
}

.fc-row.fc-rigid {
	overflow: hidden;
}

.fc-row.fc-rigid .fc-content-skeleton {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
}

.fc-basic-view .fc-week-number,
.fc-basic-view .fc-day-number {
	padding: 10px;
}

.fc-basic-view .fc-week-number {
	text-align: center;
}

.fc-basic-view .fc-week-number span {
	display: inline-block;
	min-width: 1.25em;
}

.fc-basic-view .fc-day-number {
	text-align: right;
}

.fc-basic-view .fc-day-number.fc-other-month {
	opacity: 0.3;
	filter: alpha(opacity=30);
}

.fc-agenda-view .fc-day-grid {
	position: relative;
	z-index: 2;
}

.fc-agenda-view .fc-day-grid .fc-row {
	min-height: 3em;
}

.fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton {
	padding-top: 5px;
	padding-bottom: 0;
}

.fc-axis {
	text-align: right;
}

.fc .fc-axis {
	vertical-align: middle;
	padding: 10px;
	white-space: nowrap;
}

.fc-time-grid-container,
.fc-time-grid {
	position: relative;
	z-index: 1;
}

.fc-time-grid {
	min-height: 100%;
}

.fc-time-grid table {
	border: 0 hidden transparent;
}

.fc-time-grid > .fc-bg {
	z-index: 1;
}

.fc-time-grid .fc-slats,
.fc-time-grid > hr {
	position: relative;
	z-index: 2;
}

.fc-time-grid .fc-bgevent-skeleton,
.fc-time-grid .fc-content-skeleton {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
}

.fc-time-grid .fc-bgevent-skeleton {
	z-index: 3;
}

.fc-time-grid .fc-highlight-skeleton {
	z-index: 4;
}

.fc-time-grid .fc-content-skeleton {
	z-index: 5;
}

.fc-time-grid .fc-helper-skeleton {
	z-index: 6;
}

.fc-slats td {
	border-bottom: 0;
}

.fc-slats .fc-minor td {
	border-top-style: dotted;
}

.fc-time-grid .fc-highlight-container {
	position: relative;
}

.fc-time-grid .fc-highlight {
	position: absolute;
	left: 0;
	right: 0;
}

.fc-time-grid .fc-event-container,
.fc-time-grid .fc-bgevent-container {
	position: relative;
}

.fc-time-grid .fc-event,
.fc-time-grid .fc-bgevent {
	position: absolute;
	z-index: 1;
	margin: 5px;
	padding: 10px;
	min-height: 45px;
	-webkit-box-shadow: 0 0 0 1px var(--white);
	box-shadow: 0 0 0 1px var(--white);
}

.fc-time-grid .fc-bgevent {
	left: 0;
	right: 0;
}

.fc-time-grid-event {
	overflow: hidden;
}

.fc-time-grid-event.fc-not-start {
	border-top-width: 0;
	padding-top: 1px;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}

.fc-time-grid-event.fc-not-end {
	border-bottom-width: 0;
	padding-bottom: 1px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.fc-time-grid-event > .fc-content {
	position: relative;
	z-index: 2;
}

.fc-time-grid-event .fc-time {
	font-weight: 500;
}

.fc-time-grid-event.fc-short .fc-content {
	white-space: nowrap;
}

.fc-time-grid-event.fc-short .fc-time,
.fc-time-grid-event.fc-short .fc-title {
	display: inline-block;
	vertical-align: top;
}

.fc-time-grid-event.fc-short .fc-time:before {
	content: attr(data-start);
}

.fc-time-grid-event.fc-short .fc-time:after {
	content: "\000A0-\000A0";
}

.fc-time-grid-event.fc-short .fc-time span {
	display: none;
}

.fc-time-grid-event.fc-short .fc-title {
	font-size: 0.85em;
	padding: 0;
}

.fc-time-grid-event .fc-resizer {
	position: absolute;
	z-index: 3;
	left: 0;
	right: 0;
	bottom: 0;
	height: 8px;
	overflow: hidden;
	line-height: 10px;
	font-size: 12px;
	font-family: monospace;
	text-align: center;
	cursor: s-resize;
	background-color: rgba(0, 0, 0, 0.05);
	padding: 1px;
}

.fc-time-grid-event .fc-resizer:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

.fc-time-grid-event .fc-resizer:after {
	content: "=";
}

@media (max-width: 768px) {
	.fc-toolbar .fc-left,
	.fc-toolbar .fc-left > .fc-button,
	.fc-toolbar .fc-right,
	.fc-toolbar .fc-right > .fc-button,
	.fc-toolbar .fc-left .fc-button-group,
	.fc-toolbar .fc-right .fc-button-group {
		float: none;
		width: auto;
		vertical-align: top;
	}
	.fc-toolbar .fc-left,
	.fc-toolbar .fc-right {
		display: block;
		margin-bottom: 10px;
	}
	.fc-view-container {
		border-left: 1px solid $light-gray-13;
		border-right: 1px solid $light-gray-13;
	}
	.fc-view-container > .fc-view > table {
		margin-left: -1px;
	}
	.fc th,
	.fc td {
		border-right-width: 0;
	}
}

/* ------------------------------------------------------------------------------
*
*  # Dragula - drag and drop library
*
*  Styles for Dragula Drag and drop plugin
*
*  Version: 1.0
*  Latest update: Nov 25, 2015
*
* ---------------------------------------------------------------------------- */
.gu-mirror {
	position: fixed !important;
	margin: 0 !important;
	z-index: 9999 !important;
	opacity: 0.8;
	filter: alpha(opacity=80);
}

.gu-unselectable.dropdown-menu li:nth-last-child(2) {
	margin-bottom: 0;
}

.nav-pills-toolbar > li.gu-mirror:not(.active) > a {
	border: 0;
}

.gu-hide {
	display: none !important;
}

.gu-unselectable {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.gu-transit {
	opacity: 0.5;
	filter: alpha(opacity=50);
}

.dragula-handle {
	color: $gray-3;
	cursor: pointer;
}

.dragula-handle:hover,
.dragula-handle:focus {
	color: $gray-4;
}

/* ------------------------------------------------------------------------------
*
*  # Plupload multiple file uploader
*
*  Multi runtime single and multiple file uploader
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.plupload_wrapper {
	width: 100%;
	border: 2px dashed $light-gray-13;
	border-radius: 3px;
}

.panel > div > .plupload_wrapper {
	border: 0;
}

.panel.panel-flat > div > .plupload_wrapper {
	border-top: 1px solid $light-gray-13;
}

.plupload_header:after,
.plupload_filelist:empty:before,
.plupload_filelist li.plupload_droptext:before,
.plupload_file_action:after,
.plupload_delete a:after,
.plupload_failed a:after,
.plupload_done a:after {
	font-family: "icomoon";
	display: inline-block;
	font-size: 16px;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.plupload_header {
	display: none;
	position: relative;
	background-color: #0097a7;
	margin: -1px -1px 0 -1px;
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
}

.plupload_header:after {
	content: "\ec78";
	color: var(--white);
	font-size: 32px;
	position: absolute;
	top: 50%;
	left: 20px;
	margin-top: -16px;
}

.plupload_header_content {
	padding: 15px 20px;
	padding-left: 72px;
	color: var(--white);
}

.plupload_header_title {
	font-size: 15px;
	font-weight: 500;
}

.plupload_header_text {
	font-size: 11px;
	line-height: 1.82;
}

.plupload_clear,
.plupload_clearer {
	clear: both;
}

.plupload_clearer,
.plupload_progress_bar {
	display: block;
	font-size: 0;
	line-height: 0;
}

.plupload_button {
	display: inline-block;
	color: var(--white);
	background-color: $blue-shade-1;
	padding: 9px 17px;
	border-radius: 3px;
	margin-right: 5px;
	text-transform: uppercase;
	font-weight: 500;
}

.plupload_button:hover,
.plupload_button:focus {
	color: var(--white);
	-webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
	box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
}

.plupload_button:active {
	-webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
	box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
}

a.plupload_disabled,
a.plupload_disabled:hover,
a.plupload_disabled:focus {
	color: $gray-3;
	border-color: $light-gray-13;
	background: $light-gray-3;
	cursor: default;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.plupload_start {
	background-color: #26a69a;
}

.plupload_filelist {
	margin: 0;
	padding: 0;
	list-style: none;
}

.plupload_scroll .plupload_filelist {
	height: 250px;
	background: var(--white);
	overflow-y: auto;
}

@media (max-width: 768px) {
	.plupload_scroll .plupload_filelist {
		height: 200px;
	}
}

.plupload_filelist li {
	padding: 12px 20px;
	background-color: var(--white);
}

.plupload_filelist li:hover {
	background-color: $light-gray;
}

.plupload_filelist li.plupload_droptext:hover {
	background-color: transparent;
}

.plupload_filelist:empty,
.plupload_filelist li.plupload_droptext {
	position: relative;
	height: 250px;
	text-align: center;
	background-color: transparent;
	text-indent: -99999px;
}

.plupload_filelist:empty:before,
.plupload_filelist li.plupload_droptext:before {
	content: "\ea0e";
	font-size: 80px;
	padding-top: 65px;
	position: absolute;
	top: 0;
	width: 80px;
	height: 80px;
	left: 50%;
	margin-left: -40px;
	z-index: 2;
	color: $light-gray-13;
	text-indent: 0;
	font-weight: normal;
}

.plupload_filelist:empty:after,
.plupload_filelist li.plupload_droptext:after {
	content: "Drag files to upload";
	font-size: 19px;
	position: absolute;
	top: 50%;
	padding-top: 145px;
	left: 50%;
	margin: -100px 0 0 -150px;
	width: 300px;
	height: 200px;
	color: $light-gray-17;
	text-indent: 0;
}

@media (max-width: 768px) {
	.plupload_filelist:empty,
	.plupload_filelist li.plupload_droptext {
		height: 200px;
	}
	.plupload_filelist:empty:before,
	.plupload_filelist li.plupload_droptext:before,
	.plupload_filelist:empty:after,
	.plupload_filelist li.plupload_droptext:after {
		width: 100%;
		height: 200px;
		left: 0;
		margin: 0;
		top: 0;
	}
	.plupload_filelist:empty:before,
	.plupload_filelist li.plupload_droptext:before {
		font-size: 64px;
		padding-top: 40px;
	}
	.plupload_filelist:empty:after,
	.plupload_filelist li.plupload_droptext:after {
		font-size: 17px;
		border-width: 3px 0;
		padding-top: 120px;
	}
}

.plupload_filelist:empty:after {
	content: "Add files to upload";
}

.plupload_filelist_header {
	padding: 15px 20px;
}

.plupload_filelist_header .plupload_file_name,
.plupload_filelist_header .plupload_file_size,
.plupload_filelist_header .plupload_file_status,
.plupload_filelist_header .plupload_file_action {
	font-weight: 500;
}

.plupload_filelist_footer {
	padding: 15px 20px;
}

.plupload_filelist_footer .plupload_file_size,
.plupload_filelist_footer .plupload_file_status,
.plupload_filelist_footer .plupload_upload_status {
	padding: 8px 0;
	font-weight: 500;
}

@media (max-width: 768px) {
	.plupload_filelist_footer .plupload_file_action,
	.plupload_filelist_footer .plupload_file_status,
	.plupload_filelist_footer .plupload_file_size,
	.plupload_filelist_footer .plupload_progress {
		display: none;
	}
}

.plupload_file_action {
	position: relative;
	float: right;
	margin-left: 30px;
	margin-top: 2px;
	color: $gray-3;
	width: 16px;
	height: 16px;
}

.plupload_file_action * {
	display: none;
}

.plupload_file_action:after {
	content: "\ec67";
	position: absolute;
	left: 0;
	top: 0;
}

.plupload_filelist_footer .plupload_file_action {
	margin-top: 9px;
}

.plupload_filelist .plupload_file_action:after {
	content: none;
}

.plupload_delete .plupload_file_action > a {
	color: $gray-4;
	line-height: 1;
	opacity: 0.5;
	filter: alpha(opacity=50);
}

.plupload_delete .plupload_file_action > a:hover {
	opacity: 1;
	filter: alpha(opacity=100);
}

.plupload_uploading {
	background-color: #e8f5e9;
}

.plupload_delete a:after {
	content: "\ed6b";
}

.plupload_failed a {
	color: $red;
	cursor: default;
}

.plupload_failed a:after {
	content: "\e9cf";
}

.plupload_done {
	color: $gray-3;
}

.plupload_done a {
	color: #4db6ac;
	cursor: default;
}

.plupload_done a:after {
	content: "\e9db";
}

.plupload_progress,
.plupload_upload_status {
	display: none;
}

.plupload_progress_container {
	margin-top: 14px;
	background-color: $light-gray-9;
}

.plupload_progress_bar {
	width: 0px;
	height: 4px;
	background: #4db6ac;
	border-radius: 4px;
}

@media (min-width: 769px) {
	.plupload_file_name {
		float: left;
		overflow: hidden;
	}
	.plupload_filelist .plupload_file_name {
		width: 205px;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.plupload_file_size,
	.plupload_file_status,
	.plupload_progress {
		float: right;
		width: 80px;
	}
	.plupload_file_size,
	.plupload_file_status,
	.plupload_file_action {
		text-align: right;
	}
}

/* ------------------------------------------------------------------------------
 *
 *  # Bootstrap file input
 *
 *  File input styling for Bootstrap 3.0
 *
 *  Version: 1.2
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.file-input.has-error .form-control {
	border-color: $light-gray-13;
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

@media (min-width: 769px) {
	.file-input .btn > [class*="icon-"],
	.file-input .btn > .glyphicon {
		margin-right: 5px;
	}
}

.file-object {
	margin: 0 0 -8px 0;
	padding: 0;
}

.btn-file {
	position: relative;
	overflow: hidden;
}

.btn-file input[type="file"] {
	position: absolute;
	top: 0;
	right: 0;
	min-width: 100%;
	min-height: 100%;
	text-align: right;
	opacity: 0;
	background: none repeat scroll 0 0 transparent;
	cursor: inherit;
	display: block;
}

.file-caption-name {
	display: inline-block;
	overflow: hidden;
	word-break: break-all;

	vertical-align: middle;
	word-break: break-all;
	width: 100%;
	position: absolute;
	left: 0;
	padding-left: 10px;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.file-caption-name [class*="icon-"],
.file-caption-name .glyphicon {
	margin-right: 8px;
}

.file-input-new .file-caption-name {
	color: $gray-3;
}

.file-input-new .file-caption-name > [class*="icon-"],
.file-input-new .file-caption-name > .glyphicon {
	display: none;
}

.file-error-message {
	position: relative;
	background-color: #f2dede;
	color: #a94442;
	text-align: center;
	border-radius: 3px;
	padding: 10px;
	margin: 0 5px 5px 5px;
}

.file-drop-zone .file-error-message {
	margin-top: 5px;
}

.file-error-message .close {
	margin-top: 1px;
	color: #a94442;
}

.file-error-message pre,
.file-error-message ul {
	margin: 8px 0;
	text-align: left;
}

.file-caption-disabled {
	cursor: not-allowed;
	border-bottom-style: dashed;
}

.file-caption-disabled:focus {
	border-bottom-color: $light-gray-13;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.file-caption-disabled .file-caption-name {
	color: $light-gray-14;
}

.file-preview-detail-modal {
	text-align: left;
}

.file-preview {
	border-radius: 2px;
	border: 1px solid $light-gray-13;
	width: 100%;
	margin-bottom: 20px;
	position: relative;
	text-align: center;
}

.file-preview > .close {
	font-weight: 400;
	font-size: 17px;
	position: absolute;
	top: 0;
	right: 0;
	background-color: var(--white);
	padding: 8px;
	line-height: 0.6;
	border-radius: 3px;
	color: #555;
	opacity: 1;
	filter: alpha(opacity=100);
}

.file-preview-frame {
	margin: 8px;
	margin-right: 0;
	height: 160px;
	display: table;
	float: left;
	vertical-align: middle;
}

.file-drop-zone .file-preview-frame {
	border: 1px solid $light-gray-13;
	border-radius: 3px;
}

.file-preview-image {
	height: 160px;
	vertical-align: middle;
	border-radius: 3px;
}

@media (max-width: 768px) {
	.file-preview-image {
		max-width: 100%;
	}
}

.file-preview-text {
	text-align: left;
	width: 160px;
	margin-bottom: 2px;
	color: #428bca;
	background-color: var(--white);
	overflow-x: hidden;
}

.file-preview-other {
	display: table-cell;
	text-align: center;
	vertical-align: middle;
	width: 160px;
	height: 160px;
}

.file-preview-other:after {
	content: "\ea0c";
	font-family: "icomoon";
	display: block;
	font-size: 64px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.file-preview-other .glyphicon {
	display: none;
}

.file-preview-status {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	color: $gray-4;
	background-color: rgba(250, 250, 250, 0.8);
	border-top: 1px solid $light-gray-13;
	padding: 8px 16px;
	font-size: 12px;
	color: $gray-4 !important;
}

.file-preview-status:empty {
	padding: 0;
	background-color: transparent;
	border: 0;
}

.file-thumb-loading:before,
.file-uploading:before {
	content: "";
	display: inline-block;
	background-color: $gray-shade-3;
	width: 32px;
	height: 32px;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -16px 0 0 -16px;
	border-radius: 3px;
	-webkit-box-shadow: 0 1px 5px rgba(255, 255, 255, 0.5);
	box-shadow: 0 1px 5px rgba(255, 255, 255, 0.5);
}

.file-thumb-loading:after,
.file-uploading:after {
	content: "\eb55";
	font-family: "icomoon";
	display: inline-block;
	position: absolute;
	color: var(--white);
	top: 50%;
	left: 50%;
	margin: -8px 0 0 -8px;
	font-size: 16px;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-animation: rotation 1s linear infinite;
	-o-animation: rotation 1s linear infinite;
	animation: rotation 1s linear infinite;
}

.file-upload-indicator {
	padding-top: 7px;
	padding-bottom: 7px;
	cursor: default;
	text-align: left;
	padding-left: 9px;
}

.file-input-new .file-preview,
.file-input-new .close,
.file-input-new .glyphicon-file,
.file-input-new .fileinput-remove-button,
.file-input-new .fileinput-upload-button {
	display: none;
}

.file-input-new .input-group .input-group-btn > .btn-file {
	border-bottom-left-radius: 3px;
	border-top-left-radius: 3px;
}

.file-input-ajax-new .fileinput-remove-button,
.file-input-ajax-new .fileinput-upload-button,
.file-input-ajax-new .fileinput-remove,
.file-input-ajax-new .file-caption-name > [class*="icon-"],
.file-input-ajax-new .file-caption-name > .glyphicon {
	display: none;
}

.file-actions {
	margin-top: 5px;
	border-top: 1px solid $light-gray-9;
}

.file-actions .btn > [class*="icon-"],
.file-actions .btn > .glyphicon {
	margin-right: 0;
}

.file-footer-buttons {
	float: right;
}

.file-footer-caption {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 160px;
	text-align: center;
	font-size: 11px;
	padding: 12px 7px 7px 7px;
	margin-left: auto;
	margin-right: auto;
}

.file-preview-error {
	opacity: 0.65;
	filter: alpha(opacity=65);
	-webkit-box-shadow: none;
	box-shadow: none;
}

.file-drop-zone {
	border: 1px dashed $light-gray-13;
	border-radius: 3px;
	height: 100%;
	vertical-align: middle;
	margin: 5px;
	padding: 5px;
	overflow: auto;
}

.file-drop-zone-title {
	color: $gray-3;
	font-size: 21px;
	font-weight: 300;
	padding: 85px 10px;
}

.file-highlighted {
	border-color: $light-gray-14;
	background-color: $light-gray-15;
}

.file-thumb-progress {
	position: absolute;
	top: -5px;
	left: 0;
	right: 0;
}

.file-thumb-progress .progress,
.file-thumb-progress .progress-bar {
	height: 5px;
	border-radius: 0;
	font-size: 0;
}

.kv-upload-progress {
	margin-bottom: 20px;
}

.file-thumbnail-footer {
	position: relative;
}

.btn-file ::-ms-browse {
	width: 100%;
	height: 100%;
}

/* ------------------------------------------------------------------------------
 *
 *  # Dropzone file uploader
 *
 *  Open source library that provides drag’n’drop file uploads with image previews
 *
 *  Version: 1.2
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.dropzone {
	position: relative;
	border: 3px dashed $light-gray-9;
	min-height: 250px;
	border-radius: 2px;
	background-color: $light-gray-3;
	padding: 5px;
}

.dropzone.dz-clickable,
.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message span {
	cursor: pointer;
}

.dropzone.dz-clickable * {
	cursor: default;
}

.dropzone .dz-message {
	opacity: 1;
	filter: alpha(opacity=100);
}

.dropzone.dz-started .dz-message {
	display: none;
}

.dropzone.dz-drag-hover {
	border-color: rgba(0, 0, 0, 0.15);
	background: rgba(0, 0, 0, 0.04);
}

.sidebar .dropzone {
	min-height: 190px;
	text-align: center;
	border-width: 2px;
}

.sidebar .dropzone .dz-default.dz-message:before {
	font-size: 64px;
	width: 64px;
	height: 64px;
	margin-left: -32px;
	top: 50px;
}

.sidebar .dropzone .dz-default.dz-message span {
	font-size: 15px;
}

.sidebar .dropzone .dz-default.dz-message > span {
	margin-top: 130px;
}

@media (min-width: 769px) {
	.dropzone {
		min-height: 320px;
	}
}

.dropzone .dz-default.dz-message {
	position: absolute;
	height: 230px;
	width: 100%;
	margin-top: -115px;
	top: 50%;
	left: 0;
	padding: 20px;
	text-align: center;
	border-radius: 4px;
	opacity: 1;
	filter: alpha(opacity=100);
	-webkit-transition: opacity 0.3s ease-in-out;
	-o-transition: opacity 0.3s ease-in-out;
	transition: opacity 0.3s ease-in-out;
}

.dropzone .dz-default.dz-message:before {
	content: "\ea0e";
	font-family: "icomoon";
	font-size: 64px;
	position: absolute;
	top: 48px;
	width: 64px;
	height: 64px;
	display: inline-block;
	left: 50%;
	margin-left: -32px;
	line-height: 1;
	z-index: 2;
	color: $light-gray-13;
	text-indent: 0;
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.dropzone .dz-default.dz-message span {
	font-size: 15px;
	color: $light-gray-17;
	text-align: center;
	margin-top: 140px;
	display: block;
	font-weight: 500;
	text-shadow: 0 1px 1px var(--white);
}

.dropzone .dz-default.dz-message span > span {
	margin-top: 2px;
	display: block;
	color: $light-gray-14;
	font-size: 15px;
}

@media (min-width: 769px) {
	.dropzone .dz-default.dz-message:before {
		font-size: 80px;
		top: 48px;
		width: 80px;
		height: 80px;
		margin-left: -32px;
	}
	.dropzone .dz-default.dz-message span {
		font-size: 19px;
		margin-top: 130px;
	}
}

.dropzone.dz-drag-hover .dz-message {
	opacity: 0.15;
	filter: alpha(opacity=15);
}

.dropzone.dz-started .dz-message {
	display: block;
	opacity: 0;
	filter: alpha(opacity=0);
}

.dropzone .dz-preview,
.dropzone-previews .dz-preview {
	background-color: var(--white);
	position: relative;
	display: inline-block;
	margin: 10px;
	vertical-align: top;
	border: 1px solid $light-gray-13;
	padding: 8px;
	border-radius: 2px;
}

.dropzone .dz-preview.dz-file-preview [data-dz-thumbnail],
.dropzone-previews .dz-preview.dz-file-preview [data-dz-thumbnail] {
	display: none;
}

.dropzone .dz-preview .dz-details,
.dropzone-previews .dz-preview .dz-details {
	width: 140px;
	position: relative;
	background-color: $light-gray-15;
	padding: 8px;
	margin-bottom: 25px;
}

.dropzone .dz-preview .dz-details .dz-filename,
.dropzone-previews .dz-preview .dz-details .dz-filename {
	overflow: hidden;
	height: 100%;
}

.dropzone .dz-preview .dz-details img,
.dropzone-previews .dz-preview .dz-details img {
	position: absolute;
	top: 0;
	left: 0;
	width: 140px;
	height: 140px;
}

.dropzone .dz-preview .dz-details .dz-size,
.dropzone-previews .dz-preview .dz-details .dz-size {
	position: absolute;
	bottom: -26px;
	left: 0;
}

.dropzone .dz-preview:hover .dz-details img,
.dropzone-previews .dz-preview:hover .dz-details img {
	display: none;
}

.dropzone .dz-preview .dz-error-mark,
.dropzone-previews .dz-preview .dz-error-mark,
.dropzone .dz-preview .dz-success-mark,
.dropzone-previews .dz-preview .dz-success-mark {
	display: none;
	position: absolute;
	z-index: 9;
	right: 8px;
}

.dropzone .dz-preview .dz-error-mark:after,
.dropzone-previews .dz-preview .dz-error-mark:after,
.dropzone .dz-preview .dz-success-mark:after,
.dropzone-previews .dz-preview .dz-success-mark:after {
	font-family: "icomoon";
	display: block;
	position: absolute;
	right: 0;
	bottom: 3px;
	font-size: 12px;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.dropzone .dz-preview .dz-error-mark span,
.dropzone-previews .dz-preview .dz-error-mark span,
.dropzone .dz-preview .dz-success-mark span,
.dropzone-previews .dz-preview .dz-success-mark span,
.dropzone .dz-preview .dz-error-mark svg,
.dropzone-previews .dz-preview .dz-error-mark svg,
.dropzone .dz-preview .dz-success-mark svg,
.dropzone-previews .dz-preview .dz-success-mark svg {
	display: none;
}

.dropzone .dz-preview .dz-error-mark,
.dropzone-previews .dz-preview .dz-error-mark {
	color: $red;
}

.dropzone .dz-preview .dz-error-mark:after,
.dropzone-previews .dz-preview .dz-error-mark:after {
	content: "\ed6a";
}

.dropzone .dz-preview .dz-success-mark,
.dropzone-previews .dz-preview .dz-success-mark {
	color: $green-1;
}

.dropzone .dz-preview .dz-success-mark:after,
.dropzone-previews .dz-preview .dz-success-mark:after {
	content: "\ed6e";
}

.dropzone .dz-preview.dz-error .dz-error-mark,
.dropzone-previews .dz-preview.dz-error .dz-error-mark,
.dropzone .dz-preview.dz-success .dz-success-mark,
.dropzone-previews .dz-preview.dz-success .dz-success-mark {
	display: block;
	opacity: 1;
	filter: alpha(opacity=100);
}

.dropzone .dz-preview .dz-progress,
.dropzone-previews .dz-preview .dz-progress {
	position: absolute;
	top: 118px;
	left: 8px;
	right: 8px;
	height: 4px;
	background-color: $light-gray-9;
	display: none;
	border-radius: 2px;
}

.dropzone .dz-preview .dz-progress .dz-upload,
.dropzone-previews .dz-preview .dz-progress .dz-upload {
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 0%;
	height: 100%;
	background-color: $blue-9;
	-webkit-transition: width 0.3s ease-in-out;
	-o-transition: width 0.3s ease-in-out;
	transition: width 0.3s ease-in-out;
}

.dropzone .dz-preview.dz-processing .dz-progress,
.dropzone-previews .dz-preview.dz-processing .dz-progress {
	display: block;
}

.dropzone .dz-preview.dz-success .dz-progress,
.dropzone-previews .dz-preview.dz-success .dz-progress {
	display: block;
	opacity: 0;
	filter: alpha(opacity=0);
	-webkit-transition: opacity 0.4s ease-in-out;
	-o-transition: opacity 0.4s ease-in-out;
	transition: opacity 0.4s ease-in-out;
}

.dropzone .dz-preview .dz-error-message,
.dropzone-previews .dz-preview .dz-error-message {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(245, 245, 245, 0.8);
	margin: 8px;
	padding: 8px;
	color: $red;
	max-width: 100%;
	z-index: 5;
	opacity: 0;
	filter: alpha(opacity=0);
	-webkit-transition: opacity 0.3s ease-in-out;
	-o-transition: opacity 0.3s ease-in-out;
	transition: opacity 0.3s ease-in-out;
}

.dropzone .dz-preview.dz-error:hover .dz-error-message,
.dropzone-previews .dz-preview.dz-error:hover .dz-error-message {
	display: block;
	opacity: 1;
	filter: alpha(opacity=100);
}

.dropzone .dz-preview.dz-error .dz-progress .dz-upload,
.dropzone-previews .dz-preview.dz-error .dz-progress .dz-upload {
	background: $red;
}

.dropzone .dz-preview.dz-image-preview:hover .dz-details img,
.dropzone-previews .dz-preview.dz-image-preview:hover .dz-details img {
	display: block;
	opacity: 0.1;
	filter: alpha(opacity=10);
}

.dropzone .dz-preview a.dz-remove,
.dropzone-previews .dz-preview a.dz-remove {
	background-color: $light-gray-15;
	border-radius: 2px;
	border: 1px solid $light-gray-9;
	display: block;
	padding: 5px;
	text-align: center;
	color: $gray-3;
	margin-top: 35px;
	cursor: pointer;
}

.dropzone .dz-preview a.dz-remove:hover,
.dropzone-previews .dz-preview a.dz-remove:hover {
	color: $gray-4;
	background-color: $light-gray;
}

/* ------------------------------------------------------------------------------
 *
 *  # Datatables library
 *
 *  Add advanced interaction controls to any HTML table
 *
 *  Version: 1.2
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.dataTable {
	margin: 0;
	max-width: none;
	border-collapse: separate;
}

.dataTable thead th,
.dataTable thead td {
	outline: 0;
	position: relative;
}

.dataTable thead .sorting_asc,
.dataTable thead .sorting_desc,
.dataTable thead .sorting {
	cursor: pointer;
}

.dataTable thead .sorting,
.dataTable thead .sorting_asc,
.dataTable thead .sorting_desc,
.dataTable thead .sorting_asc_disabled,
.dataTable thead .sorting_desc_disabled {
	padding-right: 40px;
}

.dataTable thead .sorting:before,
.dataTable thead .sorting:after,
.dataTable thead .sorting_asc:after,
.dataTable thead .sorting_desc:after,
.dataTable thead .sorting_asc_disabled:after,
.dataTable thead .sorting_desc_disabled:after {
	content: "";
	font-family: "icomoon";
	position: absolute;
	top: 50%;
	right: 20px;
	font-size: 12px;
	margin-top: -6px;
	display: inline-block;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.dataTable thead .sorting:before {
	content: "\e9c1";
	margin-top: -2px;
	color: $gray-3;
}

.dataTable thead .sorting:after {
	content: "\e9c2";
	margin-top: -10px;
	color: $gray-3;
}

.dataTable thead .sorting_asc:after {
	content: "\e9c2";
}

.dataTable thead .sorting_desc:after {
	content: "\e9c1";
}

.dataTable thead .sorting_asc_disabled:after {
	content: "\e9c2";
	color: $light-gray-14;
}

.dataTable thead .sorting_desc_disabled:after {
	content: "\e9c1";
	color: $light-gray-14;
}

.dataTable tbody > tr:first-child > th,
.dataTable tbody > tr:first-child > td {
	border-top: 0;
}

.dataTable tbody + thead > tr:first-child > th,
.dataTable tbody + tfoot + thead > tr:first-child > th,
.dataTable tbody + thead > tr:first-child > td,
.dataTable tbody + tfoot + thead > tr:first-child > td {
	border-top: 0;
}

.dataTable .dataTables_empty {
	text-align: center;
}

.dataTable.table-bordered {
	border-collapse: collapse;
}

.dataTables_wrapper {
	position: relative;
	clear: both;
}

.dataTables_wrapper:after {
	visibility: hidden;
	display: block;
	content: "";
	clear: both;
	height: 0;
}

.dataTables_wrapper .table-bordered {
	border-top: 0;
}

.dataTables_wrapper + .panel-body {
	border-top: 1px solid $light-gray-13;
}

.dataTables_processing {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 40px;
	margin-left: -50%;
	margin-top: -25px;
	padding-top: 20px;
	text-align: center;
	background-color: var(--white);
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(0%, rgba(255, 255, 255, 0)),
		color-stop(25%, rgba(255, 255, 255, 0.9)),
		color-stop(75%, rgba(255, 255, 255, 0.9)),
		color-stop(100%, rgba(255, 255, 255, 0))
	);
	background: -webkit-linear-gradient(
		left,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.9) 25%,
		rgba(255, 255, 255, 0.9) 75%,
		rgba(255, 255, 255, 0) 100%
	);
	background: -moz-linear-gradient(
		left,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.9) 25%,
		rgba(255, 255, 255, 0.9) 75%,
		rgba(255, 255, 255, 0) 100%
	);
	background: -ms-linear-gradient(
		left,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.9) 25%,
		rgba(255, 255, 255, 0.9) 75%,
		rgba(255, 255, 255, 0) 100%
	);
	background: -o-linear-gradient(
		left,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.9) 25%,
		rgba(255, 255, 255, 0.9) 75%,
		rgba(255, 255, 255, 0) 100%
	);
	background: linear-gradient(
		to right,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.9) 25%,
		rgba(255, 255, 255, 0.9) 75%,
		rgba(255, 255, 255, 0) 100%
	);
}

.datatable-header,
.datatable-footer {
	padding: 20px 20px 0 20px;
}

.datatable-header:after,
.datatable-footer:after {
	content: "";
	display: table;
	clear: both;
}

.datatable-header > div:first-child,
.datatable-footer > div:first-child {
	margin-left: 0;
}

.panel-body .datatable-header,
.panel-body .datatable-footer {
	padding-left: 0;
	padding-right: 0;
}

.datatable-header {
	border-bottom: 1px solid $light-gray-13;
}

.datatable-footer {
	border-top: 1px solid $light-gray-17;
}

.dataTables_length {
	float: right;
	display: inline-block;
	margin: 0 0 20px 20px;
}

.dataTables_length > label {
	margin-bottom: 0;
}

.dataTables_length > label > span:first-child {
	float: left;
	margin: 9px 10px 9px 0;
}

.length-left .dataTables_length {
	float: left;
}

.dataTables_length .select2-container {
	width: auto;
}

.dataTables_length .select2-selection {
	min-width: 60px;
}

.datatable-footer .dataTables_length {
	float: left;
	margin: 0 24px 20px 20px;
}

.dataTables_filter {
	position: relative;
	display: block;
	float: left;
	margin: 0 0 20px 20px;
}

.dataTables_filter > label {
	margin-bottom: 0;
	position: relative;
}

.dataTables_filter > label:after {
	content: "\e98e";
	font-family: "icomoon";
	font-size: 12px;
	display: inline-block;
	position: absolute;
	top: 50%;
	right: 0;
	margin-top: -6px;
	color: $gray-3;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.dataTables_filter > label > span {
	float: left;
	margin: 9px 20px;
	margin-left: 0;
}

.dataTables_filter input {
	outline: 0;
	width: 200px;
	height: 38px;
	padding: 8px 0;
	padding-right: 24px;
	font-size: 13px;
	line-height: 1.5384616;
	color: $gray-4;
	background-color: transparent;
	border: 1px solid transparent;
	border-width: 1px 0;
	border-bottom-color: $light-gray-13;
}

.dataTables_filter input:focus {
	border-bottom-color: #009688;
	-webkit-box-shadow: 0 1px 0 #009688;
	box-shadow: 0 1px 0 #009688;
}

.filter-right .dataTables_filter {
	float: right;
}

.dataTables_info {
	float: left;
	padding: 9px 0;
	margin-bottom: 20px;
}

.info-right .dataTables_info {
	float: right;
}

.dataTables_paginate {
	float: right;
	text-align: right;
	margin: 0 0 20px 20px;
}

.dataTables_paginate .paginate_button {
	display: inline-block;
	padding: 9px;
	min-width: 38px;
	margin-left: 2px;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	color: $gray-4;
	border-radius: 3px;
}

.dataTables_paginate .paginate_button:first-child {
	margin-left: 0;
}

.dataTables_paginate .paginate_button:hover,
.dataTables_paginate .paginate_button:focus {
	background-color: $light-gray;
}

.dataTables_paginate .paginate_button.current,
.dataTables_paginate .paginate_button.current:hover,
.dataTables_paginate .paginate_button.current:focus {
	color: var(--white);
	background-color: $gray-shade-10;
}

.dataTables_paginate .paginate_button.disabled,
.dataTables_paginate .paginate_button.disabled:hover,
.dataTables_paginate .paginate_button.disabled:focus {
	cursor: default;
	background-color: transparent;
	color: $light-gray-17;
}

.dataTables_paginate.paging_simple .paginate_button {
	padding-left: 16px;
	padding-right: 16px;
}

.paginate-left .dataTables_paginate {
	float: left;
}

.paging_simple .paginate_button:hover,
.paging_simple .paginate_button:focus {
	color: var(--white);
	background-color: $gray-shade-10;
}

.dataTables_scroll {
	clear: both;
}

.dataTables_scroll .dataTables_scrollHead table {
	border-bottom: 0;
}

.dataTables_scroll .dataTables_scrollHead th,
.dataTables_scroll .dataTables_scrollHead td {
	white-space: nowrap;
}

.dataTables_scroll .dataTables_scrollBody {
	-webkit-overflow-scrolling: touch;
}

.dataTables_scroll .dataTables_scrollBody table {
	border-bottom: 0;
}

.dataTables_scroll
	.dataTables_scrollBody
	table
	thead
	th[class*="sorting"]:before,
.dataTables_scroll
	.dataTables_scrollBody
	table
	thead
	th[class*="sorting"]:after {
	content: none;
}

.dataTables_scroll .dataTables_scrollBody table tbody tr:first-child > td {
	border-top: 0;
}

.dataTables_scroll .dataTables_scrollBody th,
.dataTables_scroll .dataTables_scrollBody td {
	white-space: nowrap;
}

.dataTables_scroll .dataTables_scrollBody th > .dataTables_sizing,
.dataTables_scroll .dataTables_scrollBody td > .dataTables_sizing {
	height: 0;
	overflow: hidden;
	margin: 0;
	padding: 0;
}

.panel-body + .dataTables_wrapper,
.panel-body + * > .dataTables_wrapper {
	border-top: 1px solid $light-gray-13;
}

.panel-body .dataTables_wrapper .datatable-footer {
	border-top: 0;
}

.panel-body .dataTables_wrapper .datatable-footer .dataTables_length,
.panel-body .dataTables_wrapper .datatable-footer .dataTables_filter,
.panel-body .dataTables_wrapper .datatable-footer .dataTables_info,
.panel-body .dataTables_wrapper .datatable-footer .dataTables_paginate {
	margin-bottom: 0;
}

.panel-flat > .panel-heading + .dataTables_wrapper > .datatable-header {
	padding-top: 0;
}

.panel > .dataTables_wrapper .table-bordered {
	border: 0;
}

.panel > .dataTables_wrapper .table-bordered > thead > tr > td:first-child,
.panel > .dataTables_wrapper .table-bordered > tbody > tr > td:first-child,
.panel > .dataTables_wrapper .table-bordered > tfoot > tr > td:first-child,
.panel > .dataTables_wrapper .table-bordered > thead > tr > th:first-child,
.panel > .dataTables_wrapper .table-bordered > tbody > tr > th:first-child,
.panel > .dataTables_wrapper .table-bordered > tfoot > tr > th:first-child {
	border-left: 0;
}

.panel > .dataTables_wrapper .table-bordered > thead > tr > td:last-child,
.panel > .dataTables_wrapper .table-bordered > tbody > tr > td:last-child,
.panel > .dataTables_wrapper .table-bordered > tfoot > tr > td:last-child,
.panel > .dataTables_wrapper .table-bordered > thead > tr > th:last-child,
.panel > .dataTables_wrapper .table-bordered > tbody > tr > th:last-child,
.panel > .dataTables_wrapper .table-bordered > tfoot > tr > th:last-child {
	border-right: 0;
}

.panel > .dataTables_wrapper .table-bordered > tbody > tr:last-child > th,
.panel > .dataTables_wrapper .table-bordered > tbody > tr:last-child > td {
	border-bottom: 0;
}

.datatable-scroll-lg,
.datatable-scroll,
.datatable-scroll-sm {
	min-height: 0.01%;
}

.datatable-scroll-wrap {
	width: 100%;
	min-height: 0.01%;
	overflow-x: auto;
}

@media (max-width: 768px) {
	.datatable-scroll-sm {
		width: 100%;
		overflow-x: scroll;
	}
	.datatable-scroll-sm th,
	.datatable-scroll-sm td {
		white-space: nowrap;
	}
}

@media (max-width: 1024px) {
	.datatable-scroll {
		width: 100%;
		overflow-x: scroll;
	}
	.datatable-scroll th,
	.datatable-scroll td {
		white-space: nowrap;
	}
}

@media (max-width: 1199px) {
	.datatable-scroll-lg {
		width: 100%;
		overflow-x: scroll;
	}
	.datatable-scroll-lg th,
	.datatable-scroll-lg td {
		white-space: nowrap;
	}
}

@media (max-width: 768px) {
	.dataTables_info,
	.dataTables_paginate,
	.dataTables_length,
	.dataTables_filter,
	.DTTT_container,
	.ColVis {
		float: none !important;
		text-align: center;
		margin-left: 0;
	}
	.dataTables_info,
	.dataTables_paginate {
		margin-top: 0;
	}
	.datatable-header {
		text-align: center;
	}
}

/* ------------------------------------------------------------------------------
*
*  # Fixed Header extension
*
*  This extension lets your users quickly determine what each column refers to
*  rather than needing to scroll back to the top of the table.
*
*  Version: 1.0
*  Latest update: Nov 9, 2015
*
* ---------------------------------------------------------------------------- */
.fixedHeader-floating,
.fixedHeader-locked {
	background-color: var(--white);
	margin-top: 0;
	margin-bottom: 0;
}

.fixedHeader-floating {
	position: fixed;
}

.fixedHeader-floating + .fixedHeader-floating,
.fixedHeader-locked + .fixedHeader-floating {
	border-top: 0 !important;
}

@media (max-width: 768px) {
	.fixedHeader-floating {
		display: none;
	}
}

.fixedHeader-locked {
	position: absolute;
}

@media (max-width: 768px) {
	.fixedHeader-locked {
		display: none;
	}
}

@media print {
	table.fixedHeader-floating {
		display: none;
	}
}

/* ------------------------------------------------------------------------------
*
*  # Autofill extension
*
*  Spreadsheets such as Excel and Google Docs have a very handy data duplication
*  option of an auto fill tool
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.dt-autofill-handle {
	position: absolute;
	height: 8px;
	width: 8px;
	z-index: 102;
	border: 1px solid $blue-shade-1;
	background: $blue-shade-1;
}

.dt-autofill-select {
	position: absolute;
	z-index: 1001;
	background-color: $blue-shade-1;
	background-image: repeating-linear-gradient(
		45deg,
		transparent,
		transparent 5px,
		rgba(255, 255, 255, 0.75) 5px,
		rgba(255, 255, 255, 0.75) 10px
	);
}

.dt-autofill-select.top,
.dt-autofill-select.bottom {
	height: 1px;
}

.dt-autofill-select.left,
.dt-autofill-select.right {
	width: 1px;
}

.dt-autofill-list {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 500px;
	margin-left: -250px;
	background-color: var(--white);
	border-radius: 3px;
	border: 1px solid transparent;
	z-index: 1050;
	padding: 8px 0;
}

.dt-autofill-list ul {
	display: table;
	margin: 0;
	padding: 0;
	list-style: none;
	width: 100%;
}

.dt-autofill-list ul li {
	display: table-row;
}

.dt-autofill-list ul li:hover {
	background-color: $light-gray;
}

.dt-autofill-list .dt-autofill-question {
	display: table-cell;
	padding: 8px 15px;
}

.dt-autofill-list .dt-autofill-question input[type="number"] {
	padding: 6px;
	width: 30px;
	margin: -2px 0;
}

.dt-autofill-list .dt-autofill-button {
	display: table-cell;
	padding: 8px 16px;
	text-align: right;
}

.dt-autofill-list .dt-autofill-button .btn {
	padding: 3px;
	background-color: $blue-shade-1;
	color: var(--white);
	font-size: 0;
}

.dt-autofill-list .dt-autofill-button .btn:after {
	content: "\e9c3";
	font-family: "Icomoon";
	display: block;
	font-size: 16px;
	width: 16px;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.dt-autofill-list .dt-autofill-button .btn:hover {
	opacity: 0.85;
	filter: alpha(opacity=85);
}

.dt-autofill-background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000;
	z-index: 1040;
	opacity: 0.5;
	filter: alpha(opacity=50);
}

/* ------------------------------------------------------------------------------
*
*  # Select extension
*
*  Spreadsheets such as Excel and Google Docs have a very handy data duplication
*  option of an auto fill tool
*
*  Version: 1.1
*  Latest update: Dec 9, 2015
*
* ---------------------------------------------------------------------------- */
.dataTable tbody > tr.selected,
.dataTable tbody > tr > .selected {
	background-color: #e8f5e9;
}

.dataTables_wrapper .select-info,
.dataTables_wrapper .select-item {
	margin-left: 10px;
}

@media (max-width: 768px) {
	.dataTables_wrapper .select-info,
	.dataTables_wrapper .select-item {
		margin-left: 0;
		display: block;
	}
}

.dataTable tbody .select-checkbox {
	position: relative;
}

.dataTable tbody .select-checkbox:before,
.dataTable tbody .select-checkbox:after {
	display: block;
	color: $gray-shade-10;
	position: absolute;
	top: 50%;
	left: 50%;
	cursor: pointer;
}

.dataTable tbody .select-checkbox:before {
	content: "";
	width: 18px;
	height: 18px;
	margin-top: -9px;
	margin-left: -9px;
	border: 2px solid #607d8b;
	border-radius: 2px;
}

.dataTable tbody .select-checkbox:after {
	content: "\ed6e";
	font-family: "icomoon";
	font-size: 16px;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin-top: -8px;
	margin-left: -8px;
	opacity: 0;
	filter: alpha(opacity=0);
	-webkit-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
	-webkit-transition-duration: 0.1s;
	transition-duration: 0.1s;
}

.dataTable tbody .selected .select-checkbox:before {
	border-color: transparent;
	-webkit-transition-duration: 0.1s;
	transition-duration: 0.1s;
}

.dataTable tbody .selected .select-checkbox:after {
	opacity: 1;
	filter: alpha(opacity=100);
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}

/* ------------------------------------------------------------------------------
*
*  # Key Table extension
*
*  KeyTable provides Excel like cell navigation on any table. Events (focus, blur,
*  action etc) can be assigned to individual cells, columns, rows or all cells.
*
*  Version: 1.0
*  Latest update: Nov 10, 2015
*
* ---------------------------------------------------------------------------- */
.dataTable th.focus,
.dataTable td.focus {
	outline: 2px solid $blue-shade-1;
	outline-offset: -1px;
}

.dataTable th.focus-success,
.dataTable td.focus-success {
	background-color: #e8f5e9;
	outline-color: $green-1;
}

.dataTable th.focus-info,
.dataTable td.focus-info {
	background-color: #e3f2fd;
	outline-color: $blue-shade-1;
}

.dataTable th.focus-warning,
.dataTable td.focus-warning {
	background-color: #fbe9e7;
	outline-color: $red-1;
}

.dataTable th.focus-danger,
.dataTable td.focus-danger {
	background-color: #ffebee;
	outline-color: $red;
}

/* ------------------------------------------------------------------------------
*
*  # Datatables Scroller
*
*  Drawing the rows required for the current display only, for fast operation
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.DTS tbody th,
.DTS tbody td {
	white-space: nowrap;
}

.DTS .DTS_Loading {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 200px;
	height: 20px;
	margin-top: -20px;
	margin-left: -100px;
	z-index: 1;
	border: 1px solid $gray-3;
	padding: 20px 0;
	text-align: center;
	background-color: rgba(255, 255, 255, 0.5);
}

.DTS .dataTables_scrollHead,
.DTS .dataTables_scrollFoot {
	background-color: var(--white);
}

.DTS .dataTables_scrollBody {
	z-index: 2;
}

/* ------------------------------------------------------------------------------
*
*  # Responsive extension
*
*  Optimise the table's layout for different screen sizes through the dynamic
*  insertion and removal of columns from the table
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.dtr-inline.collapsed tbody tr td:before,
.dtr-inline.collapsed tbody tr th:before {
	margin-right: 10px;
}

.dtr-inline.collapsed tbody tr td:first-child,
.dtr-inline.collapsed tbody tr th:first-child {
	position: relative;
	cursor: pointer;
	white-space: nowrap;
}

.dtr-inline.collapsed tbody tr td:first-child.dataTables_empty:before,
.dtr-inline.collapsed tbody tr th:first-child.dataTables_empty:before {
	display: none;
}

.dtr-column tbody td.control,
.dtr-column tbody th.control {
	position: relative;
	cursor: pointer;
}

.dtr-inline.collapsed tbody tr td:first-child:before,
.dtr-inline.collapsed tbody tr th:first-child:before,
.dtr-column tbody tr td.control:before,
.dtr-column tbody tr th.control:before {
	content: "\e9e4";
	font-family: "icomoon";
	display: inline-block;
	font-size: 16px;
	width: 16px;
	line-height: 1;
	position: relative;
	top: -1px;
	vertical-align: middle;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.dataTable.dtr-inline.collapsed tbody tr.parent td:first-child:before,
.dataTable.dtr-inline.collapsed tbody tr.parent th:first-child:before,
.dataTable.dtr-column tbody tr.parent td.control:before,
.dataTable.dtr-column tbody tr.parent th.control:before {
	content: "\e9e7";
}

.dtr-inline.collapsed tbody tr.child td:before {
	display: none;
}

.dataTable tr.disabled {
	opacity: 0.5;
}

.dataTable tr.child:hover {
	background-color: transparent;
}

.dataTable tr.child .dtr-title {
	display: table-cell;
	font-weight: 500;
	padding-right: 40px;
}

.dataTable tr.child .dtr-data {
	display: table-cell;
	padding: 8px 0;
}

.dataTable tr td.child {
	white-space: normal;
	position: relative;
}

.dataTable tr td.child > ul {
	display: table;
	table-layout: fixed;
	width: 100%;
	list-style: none;
	margin: 0;
	padding: 0;
}

.dataTable tr td.child > ul > li {
	display: table-row;
}

@media (max-width: 480px) {
	.dataTable tr td.child > ul > li .dropdown,
	.dataTable tr td.child > ul > li .dropup,
	.dataTable tr td.child > ul > li .btn-group {
		position: static;
	}
	.dataTable tr td.child > ul > li .dropdown-menu {
		left: 0;
		right: 0;
		border-radius: 0;
		border-width: 1px 0;
	}
}

/* ------------------------------------------------------------------------------
 *
 *  # Google maps base
 *
 *  Styles related to Google Maps: containers, sizing etc.
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.map-wrapper {
	width: 100%;
	height: 400px;
}

.sidebar .map-wrapper {
	height: 200px;
	border: 1px solid $light-gray-13;
}

/* ------------------------------------------------------------------------------
 *
 *  # Google Maps overrides
 *
 *  Override Google Maps default styles
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.map-container {
	height: 500px;
}

.gm-style {
	font-size: 13px;
}

.gm-style .gm-style-iw {
	font-size: 13px;
	font-weight: 400;
}

/* ------------------------------------------------------------------------------
 *
 *  # Vector maps
 *
 *  Vector map container, tooltip and zooming
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.vector-map-container {
	height: 500px;
}

.jvectormap-label {
	position: absolute;
	display: none;
	border-radius: 2px;
	background-color: $gray-4;
	color: var(--white);
	padding: 8px 16px;
}

.jvectormap-zoomin,
.jvectormap-zoomout {
	position: absolute;
	left: 10px;
	border-radius: 2px;
	padding: 8px;
	color: var(--white);
	cursor: pointer;
	font-size: 16px;
	font-weight: 300;
	line-height: 10px;
	background-color: #37474f;
	text-align: center;
}

.jvectormap-zoomin:hover,
.jvectormap-zoomout:hover {
	background-color: $gray-shade-10;
}

.jvectormap-zoomin {
	top: 10px;
}

.jvectormap-zoomout {
	top: 40px;
}

/* ------------------------------------------------------------------------------
*
*  # Chart styling
*
*  Charts base - container and sizing setup
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.chart-container {
	position: relative;
	width: 100%;
}

.chart-container.has-scroll {
	overflow-x: scroll;
	overflow-y: visible;
	max-width: 100%;
}

@media (max-width: 768px) {
	.chart-container {
		overflow-y: visible;
		max-width: 100%;
	}
}

.chart {
	position: relative;
	display: block;
	width: 100%;
}

.chart.has-minimum-width {
	min-width: 600px;
}

.has-minimum-height {
	min-height: 400px;
}

.chart.has-minimum-height {
	min-height: 600px;
}

.has-fixed-height {
	height: 400px;
}

.chart-pie {
	width: 100%;
	height: 400px;
	min-width: 500px;
}

/* ------------------------------------------------------------------------------
 *
 *  # User list
 *
 *  Styles for user lists - layouts, contact details etc.
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.contact-details {
	padding: 15px;
	padding-left: 30px;
	background-color: $light-gray-3;
	border-top: 1px solid $light-gray-9;
	border-bottom: 1px solid $light-gray-9;
}

.media-list-bordered .contact-details {
	border-bottom: 0;
}

.panel-body > .media-list .contact-details {
	padding-left: 15px;
	background-color: transparent;
	margin-top: 15px;
}

.panel-body > .media-list-bordered .contact-details {
	padding-bottom: 0;
}

.contact-details > ul,
.contact-details > ul > li:first-child {
	margin: 0;
}

/* ------------------------------------------------------------------------------
 *
 *  # Login and related forms
 *
 *  Styles related to user login - logins, registration, password revovery, unlock etc.
 *
 *  Version: 1.2
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.login-cover {
	/*background: url('../images/login_cover.jpg') no-repeat;*/
	background-size: cover;
}

@media (max-width: 768px) {
	.login-options,
	.login-options .text-right {
		text-align: center;
	}

	app-basic-login {
		.login-options {
			text-align: unset;
		}

		.login-options .text-right {
			text-align: right;
		}
	}
}

/* ------------------------------------------------------------------------------
 *
 *  # Timeline
 *
 *  Styles for timeline in 3 layouts: left, right and centered
 *
 *  Version: 1.1
 *  Latest update: Dec 11, 2015
 *
 * ---------------------------------------------------------------------------- */
.timeline {
	position: relative;
	height: 100%;
}

.timeline:before,
.timeline:after {
	content: "";
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: -2px;
	background-color: $light-gray-14;
	height: 4px;
	width: 4px;
	border-radius: 50%;
	z-index: 1;
}

.timeline:after {
	top: auto;
	bottom: 0;
}

.timeline-container {
	position: relative;
	padding-top: 10px;
	margin-top: -10px;
	padding-bottom: 1px;
}

.timeline-container:after {
	content: "";
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: -1px;
	background-color: $light-gray-14;
	height: 100%;
	width: 2px;
	z-index: -1;
}

.timeline-row {
	position: relative;
}

.timeline-content {
	margin-bottom: 20px;
}

.timeline-date {
	text-align: center;
	background-color: $gray-shade-1;
	position: relative;
	z-index: 1;
	padding-top: 20px;
	padding-bottom: 20px;
	margin-bottom: 20px;
}

.timeline-date:before,
.timeline-date:after {
	content: "";
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: -2px;
	background-color: $light-gray-14;
	height: 4px;
	width: 4px;
	border-radius: 50%;
	z-index: 1;
}

.timeline-date:after {
	top: auto;
	bottom: 0;
}

.panel .timeline-date {
	background-color: var(--white);
}

.timeline-time {
	text-align: center;
	padding-top: 20px;
	padding-bottom: 20px;
	background-color: $gray-shade-1;
	position: relative;
	margin-bottom: 20px;
}

.timeline-time:before,
.timeline-time:after {
	content: "";
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: -2px;
	background-color: $light-gray-14;
	height: 4px;
	width: 4px;
	border-radius: 50%;
}

.timeline-time:after {
	top: auto;
	bottom: 0;
}

.panel .timeline-time {
	background-color: var(--white);
}

.timeline-time span {
	display: block;
	color: $gray-3;
	font-size: 12px;
}

.timeline-time span:before {
	content: "•";
	display: inline-block;
	margin-right: 5px;
	position: relative;
	top: 1px;
}

@media (min-width: 1025px) {
	.timeline-time:before,
	.timeline-time:after {
		content: none;
	}
}

.timeline-icon {
	width: 48px;
	height: 48px;
	border-radius: 50%;
	margin: 0 auto 20px auto;
	background-color: var(--white);
	border: 4px solid $gray-shade-1;
}

.panel .timeline-icon {
	border-color: var(--white);
}

.timeline-icon div {
	border-radius: 50%;
	height: 100%;
	-webkit-box-shadow: 0 0 0 2px $light-gray-14 inset;
	box-shadow: 0 0 0 2px $light-gray-14 inset;
}

.timeline-icon div[class*="bg-"] {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.timeline-icon div > i {
	display: block;
	line-height: 40px;
	top: 0;
}

.timeline-icon img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

@media (min-width: 1025px) {
	.timeline-center .timeline-row {
		margin-right: 50%;
		padding-right: 44px;
	}
	.timeline-center .post-even {
		margin-right: 0;
		padding-right: 0;
		margin-left: 50%;
		padding-left: 44px;
	}
	.timeline-center .post-full {
		margin: 0;
		padding: 0;
	}
	.timeline-center .post-full .timeline-content {
		position: relative;
	}
	.timeline-center .post-full .timeline-content:before,
	.timeline-center .post-full .timeline-content:after {
		content: "";
		border-style: solid;
		border-color: $gray-shade-1;
		display: block;
		position: absolute;
		left: 0;
		right: 0;
	}
	.timeline-center .post-full .timeline-content:before {
		border-width: 4px 0 0 0;
		top: -5px;
	}
	.timeline-center .post-full .timeline-content:after {
		border-width: 0 0 4px 0;
		bottom: -5px;
	}
	.timeline-left .timeline-container:after,
	.timeline-left:before,
	.timeline-left:after,
	.timeline-left .timeline-date:before,
	.timeline-left .timeline-date:after {
		left: 24px;
	}
	.timeline-left .timeline-row,
	.timeline-left .timeline-date {
		padding-left: 68px;
	}
	.timeline-right .timeline-container:after,
	.timeline-right:before,
	.timeline-right:after,
	.timeline-right .timeline-date:before,
	.timeline-right .timeline-date:after {
		left: auto;
		right: 24px;
		margin-left: 0;
		margin-right: -2px;
	}
	.timeline-right .timeline-container:after {
		margin-right: -1px;
	}
	.timeline-right .timeline-row,
	.timeline-right .timeline-date {
		padding-right: 68px;
	}
	.timeline-left .timeline-date,
	.timeline-right .timeline-date {
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.timeline-icon {
		position: absolute;
		top: 10px;
	}
	.timeline-icon:after {
		content: "";
		position: absolute;
		top: 50%;
		margin-top: -1px;
		height: 2px;
		width: 16px;
		background-color: $light-gray-14;
		z-index: 1;
	}
	.timeline-left .timeline-icon {
		left: 0;
	}
	.timeline-left .timeline-icon:after {
		left: 100%;
		margin-left: 4px;
	}
	.timeline-right .timeline-icon {
		right: 0;
	}
	.timeline-right .timeline-icon:after {
		right: 100%;
		margin-right: 4px;
	}
	.timeline-center .timeline-row:not(.post-full) .timeline-icon {
		left: 100%;
		margin-left: -24px;
	}
	.timeline-center .timeline-row.post-even .timeline-icon {
		left: auto;
		right: 100%;
		margin-left: 0;
		margin-right: -24px;
	}
	.timeline-center .timeline-icon:after {
		right: 100%;
		margin-right: 4px;
	}
	.timeline-center .post-even .timeline-icon:after {
		left: 100%;
		margin-left: 4px;
	}
	.timeline-center .post-full .timeline-icon {
		position: static;
	}
	.timeline-center .post-full .timeline-icon:after {
		content: none;
	}
	.timeline-time {
		padding: 0;
		text-align: inherit;
		background-color: transparent;
	}
	.timeline-time:before {
		content: none;
	}
	.timeline-left .timeline-time,
	.timeline-right .timeline-time {
		padding-top: 10px;
		margin-bottom: 20px;
		padding-left: 10px;
	}
	.timeline-right .timeline-time {
		text-align: right;
		padding-left: 0;
		padding-right: 10px;
	}
	.timeline-center .timeline-time,
	.timeline-center .post-full .timeline-time {
		position: absolute;
		left: 100%;
		top: 15px;
		width: 100%;
		padding-left: 44px;
	}
	.timeline-center .post-even .timeline-time,
	.timeline-center .post-full .timeline-time {
		left: auto;
		right: 100%;
		padding-left: 0;
		padding-right: 44px;
		text-align: right;
	}
	.timeline-center .post-full .timeline-time {
		right: 50%;
		top: 5px;
	}
}

/* ------------------------------------------------------------------------------
 *
 *  # Chat layouts
 *
 *  Conversation chat styles - layouts, chat elements, colors, options
 *
 *  Version: 1.1
 *  Latest update: Oct 20, 2015
 *
 * ---------------------------------------------------------------------------- */
.chat-list,
.chat-stacked {
	max-height: 520px;
	overflow: auto;
}

.chat-list .media-left {
	padding-right: 20px;
}

.chat-list .media-right {
	padding-left: 20px;
}

.chat-list .media-content {
	position: relative;
	padding: 10px 12px;
	width: auto;
	border-radius: 3px;
	display: inline-block;
}

.chat-list .media-content:not([class*="bg-"]) {
	background-color: $light-gray;
	border-color: $light-gray;
}

.chat-list .media-content > i {
	top: 0;
	margin: 2px;
}

.chat-list .reversed .media-content {
	text-align: left;
	color: var(--white);
}

.chat-list .reversed .media-content:not([class*="bg-"]) {
	background-color: $blue-6;
	border-color: $blue-6;
}

.chat-list .reversed .media-content > a {
	color: var(--white);
}

.chat-list .reversed .media-body {
	text-align: right;
}

.chat-list .date-step.media {
	text-align: center;
	margin-left: 0;
	margin-right: 0;
	color: $gray-3;
}

.chat-list .chat-thumbs img {
	margin-top: 5px;
	margin-bottom: 5px;
	max-height: 100px;
	height: auto;
	max-width: 100%;
	display: inline-block;
	border-radius: 3px;
}

.chat-list .media-content:before,
.chat-list .reversed .media-content:before {
	content: "";
	left: -5px;
	top: 15px;
	position: absolute;
	margin-left: 0;
}

.chat-list .media-content:before {
	border-top: 5px solid transparent;
	border-right: 5px solid;
	border-right-color: inherit;
	border-bottom: 5px solid transparent;
}

.chat-list .reversed .media-content:before {
	left: auto;
	right: -5px;
	border-right: 0;
	border-top: 5px solid transparent;
	border-left: 5px solid;
	border-left-color: inherit;
	border-bottom: 5px solid transparent;
}

@media (min-width: 769px) {
	.chat-list .media {
		margin-right: 20%;
	}
	.chat-list .media.reversed {
		margin-right: 0;
		margin-left: 20%;
	}
}

.chat-list-inverse .media-content:not([class*="bg-"]) {
	color: var(--white);
	background-color: $blue-6;
	border-color: $blue-6;
}

.chat-list-inverse .media-content:before {
	border-right: 0;
	border-top: 5px solid transparent;
	border-right: 5px solid $blue-6;
	border-bottom: 5px solid transparent;
}

.chat-list-inverse .media-content > a {
	color: var(--white);
}

.chat-list-inverse .reversed .media-content:not([class*="bg-"]) {
	background-color: $light-gray;
	color: inherit;
}

.chat-list-inverse .reversed .media-content:before {
	border-top: 5px solid transparent;
	border-left: 5px solid $light-gray;
	border-bottom: 5px solid transparent;
}

/* ------------------------------------------------------------------------------
 *
 *  # Search
 *
 *  Styles for search results and input
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.panel .main-search input[type="text"] {
	background-color: $light-gray-15;
}

.search-results-list .list-inline {
	margin-bottom: 5px;
}

.media-preview {
	width: auto;
	height: auto;
	max-width: none;
	max-height: 100px;
}

@media (max-width: 768px) {
	.search-option-buttons {
		text-align: center;
	}
	.search-option-buttons > .text-right {
		text-align: inherit;
	}
}

/* ------------------------------------------------------------------------------
*
*  # Heading elmeents
*
*  Display default and custom components in page header and panel heading
*
*  Version: 1.2
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.heading-elements-toggle,
.breadcrumb-elements-toggle {
	cursor: pointer;
	display: block;
	line-height: 1;
	position: absolute;
	top: 50%;
	margin-top: -8px;
}

.heading-elements-toggle,
.breadcrumb-elements-toggle,
.heading-elements-toggle:hover,
.breadcrumb-elements-toggle:hover,
.heading-elements-toggle:focus,
.breadcrumb-elements-toggle:focus {
	color: inherit;
}

.heading-elements-toggle > i,
.breadcrumb-elements-toggle > i {
	top: 0;
}

@media (min-width: 769px) {
	.heading-elements-toggle,
	.breadcrumb-elements-toggle {
		display: none;
	}
}

.heading-elements-toggle {
	right: 20px;
}

.breadcrumb-elements-toggle {
	right: 20px;
}

.breadcrumb-line-component .breadcrumb-elements-toggle {
	right: 15px;
}

.heading-elements {
	//background-color: inherit;
	position: absolute;
	right: 20px;
	height: 38px;
	margin-top: -19px;
}

.panel-body > .heading-elements {
	top: 0;
	margin-top: 16px;
	z-index: 10;
}

@media (max-width: 768px) {
	.panel-body > .heading-elements-toggle {
		top: 20px;
		margin-top: 0;
	}
	.panel-body > .heading-elements.visible {
		top: 56px;
		background-color: var(--white);
	}
}

@media (min-width: 769px) {
	.panel-footer > .heading-elements {
		position: static;
		margin-top: 0;
	}
}

@media (max-width: 768px) {
	.panel-footer > .heading-elements > .pull-right {
		float: none !important;
	}
	.panel-footer .heading-elements-toggle {
		position: static;
		padding-top: 10px;
		padding-bottom: 10px;
		margin-top: 0;
		display: block;
		text-align: center;
	}
}

@media (min-width: 769px) {
	.panel-footer-bordered .heading-elements {
		margin-left: -20px;
	}
}

.heading-thumbnails {
	list-style: none;
	margin: 0;
	padding: 0;
	font-size: 0;
}

.heading-thumbnails > li {
	position: relative;
	display: inline-block;
	font-size: 13px;
}

.heading-thumbnails > li + li {
	margin-left: 10px;
}

.heading-thumbnails > li img {
	height: auto;
	max-height: 38px;
	max-width: 100%;
	border-radius: 100px;
}

.heading-thumbnails > li .label,
.heading-thumbnails > li .badge {
	position: absolute;
	top: -8px;
	right: -8px;
	border: 2px solid $light-gray-3;
}

.panel-default > .panel-heading .heading-thumbnails > li .label,
.panel-default > .panel-heading .heading-thumbnails > li .badge,
.panel-footer-transparent .heading-thumbnails > li .label,
.panel-footer-transparent .heading-thumbnails > li .badge {
	border-color: var(--white);
}

.heading-thumbnails > li .status-mark {
	position: absolute;
	top: 0;
	right: 0;
	box-shadow: 0 0 0 2px $light-gray-3;
}

.heading-thumbnails > li .status-mark[class*="border-"] {
	background-color: $light-gray-3;
}

.panel-default
	> .panel-heading
	.heading-thumbnails
	> li
	.status-mark[class*="border-"],
.panel-footer-transparent
	.heading-thumbnails
	> li
	.status-mark[class*="border-"] {
	background-color: var(--white);
}

.panel-default > .panel-heading .heading-thumbnails > li .status-mark,
.panel-footer-transparent .heading-thumbnails > li .status-mark {
	background-color: var(--white);
}

.heading-elements .heading-btn + .heading-btn,
.page-header .heading-elements .icons-list > li + li,
.thumbnail-heading .heading-elements .icons-list > li + li {
	margin-left: 10px;
}

.page-header-inverse .heading-elements .btn-link {
	color: var(--white);
}

.heading-elements .list-inline {
	margin-bottom: 0;
}

.heading-elements .pagination,
.heading-elements .pager {
	margin-top: 0;
	margin-bottom: 0;
}

.heading-elements .breadcrumb {
	padding-top: 0;
	padding-bottom: 0;
}

.heading-elements.panel-nav .nav > li > a {
	text-align: left;
}

.heading-elements .daterange-custom {
	margin-top: 5px;
}

.heading-elements .heading-btn-group {
	font-size: 0;
}

.heading-elements .heading-btn-group > .btn + .btn:not(.btn-link) {
	margin-left: 10px;
}

@media (max-width: 768px) {
	.heading-elements {
		text-align: center;
	}
	.heading-elements .heading-text,
	.heading-elements .heading-btn,
	.heading-elements .heading-btn-group > .btn,
	.heading-elements .ui-slider,
	.heading-elements .noui-slider,
	.heading-elements .nav-tabs,
	.heading-elements .nav-pills,
	.heading-elements .pagination,
	.heading-elements .progress,
	.heading-elements .icons-list,
	.heading-elements .pager,
	.heading-elements .breadcrumb,
	.heading-elements .daterange-custom,
	.heading-elements .heading-thumbnails,
	.heading-elements .heading-form .form-group,
	.heading-elements > .btn-group {
		margin-bottom: 20px;
	}
	.heading-elements .heading-text:not(.label):not(.badge) {
		display: block;
	}
	.heading-elements .select2-container,
	.heading-elements .selectboxit-container,
	.heading-elements .selectboxit-options,
	.heading-elements .multiselect + .btn-group,
	.heading-elements .bootstrap-select {
		width: 100% !important;
	}
	.heading-elements .input-group,
	.heading-elements .input-group-btn,
	.heading-elements .btn-group,
	.heading-elements .dropdown,
	.heading-elements .dropup {
		position: static;
	}
	.heading-elements .dropdown-menu {
		left: 0;
		right: 0;
		margin-top: 0;
		border-top-right-radius: 0;
		border-top-left-radius: 0;
	}
}

@media (min-width: 769px) {
	.heading-elements .heading-text,
	.heading-elements .heading-btn,
	.heading-elements .ui-slider,
	.heading-elements .noui-slider,
	.heading-elements .nav-tabs,
	.heading-elements .nav-pills,
	.heading-elements .pagination,
	.heading-elements .progress,
	.heading-elements .icons-list,
	.heading-elements .breadcrumb,
	.heading-elements .pager,
	.heading-elements .heading-form,
	.heading-elements .daterange-custom,
	.heading-elements .heading-thumbnails,
	.heading-elements > .btn-group {
		float: left;
		margin-left: 20px;
	}
	.heading-elements .heading-text {
		display: inline-block;
	}
	.heading-elements .heading-text + .heading-text {
		margin-left: 20px;
	}
	.heading-elements .selectbox-fixed + .selectboxit-container,
	.heading-elements .selectbox-fixed + .selectboxit-options,
	.heading-elements .progress,
	.heading-elements .ui-slider,
	.heading-elements .noui-slider,
	.heading-elements .input-group,
	.heading-elements .selectboxit-container .selectboxit-options,
	.heading-elements .heading-form .form-control {
		width: 220px;
	}
	.heading-elements .select-sm,
	.heading-elements .input-sm,
	.heading-elements .input-group-sm,
	.heading-elements .uploader-sm,
	.heading-elements .pagination-sm,
	.heading-elements .pager-sm,
	.heading-elements .selectbox-sm + .selectboxit-container,
	.heading-elements .btn-sm,
	.heading-elements .btn-group-sm > .btn {
		margin-top: 1px;
	}
	.heading-elements .select-xs,
	.heading-elements .input-xs,
	.heading-elements .input-group-xs,
	.heading-elements .uploader-xs,
	.heading-elements .pagination-xs,
	.heading-elements .pager-xs,
	.heading-elements .selectbox-xs + .selectboxit-container,
	.heading-elements .btn-xs,
	.heading-elements .btn-group-xs > .btn {
		margin-top: 2px;
	}
	.heading-elements .btn-float {
		margin-top: -7px;
	}
	.heading-elements .btn-float.has-text {
		margin-top: -19.5px;
	}
	.heading-elements .btn-float.btn-link {
		margin-top: -14px;
	}
	.heading-elements .ui-slider,
	.heading-elements .noui-slider {
		margin-top: 17px;
	}
	.heading-elements .ui-slider-lg,
	.heading-elements .noui-slider-lg {
		margin-top: 16.5px;
	}
	.heading-elements .ui-slider-sm,
	.heading-elements .noui-slider-sm {
		margin-top: 17.5px;
	}
	.heading-elements .ui-slider-xs,
	.heading-elements .noui-slider-xs {
		margin-top: 18px;
	}
	.heading-elements .progress {
		margin-top: 10px;
	}
	.heading-elements .progress-lg {
		margin-top: 8px;
	}
	.heading-elements .progress-sm {
		margin-top: 12px;
	}
	.heading-elements .progress-xs {
		margin-top: 14px;
	}
	.heading-elements .progress-xxs {
		margin-top: 16px;
	}
	.heading-elements .progress-micro {
		margin-top: 18px;
	}
	.heading-elements .heading-text {
		margin-top: 9px;
	}
	.heading-elements.panel-nav > .nav {
		margin-bottom: 0;
		margin-top: -1px;
	}
	.heading-elements.panel-nav > .nav-tabs {
		border-bottom: 0;
	}
	.heading-elements.panel-nav
		> .nav-tabs.nav-tabs-bottom
		> li.active
		> a:after {
		background-color: transparent;
	}
	.heading-elements.panel-nav > .nav-sm {
		margin-top: 2px;
	}
	.heading-elements.panel-nav > .nav-xs {
		margin-top: 5px;
	}
}

.heading-form .form-group {
	margin-bottom: 0;
}

.heading-form .checkbox-switchery,
.heading-form .checkbox-switchery[class*="switchery-"] {
	margin-bottom: 0;
}

.heading-form .input-group {
	max-width: 220px;
}

.heading-form .input-group .form-control {
	width: 100%;
	margin-top: 0;
}

.heading-form .input-group.input-group-sm .btn,
.heading-form .input-group.input-group-xs .btn {
	margin-top: 0;
}

@media (min-width: 769px) {
	.heading-form .form-group {
		float: left;
	}
	.heading-form .form-group + .form-group {
		margin-left: 15px;
	}
	.heading-form .checkbox-inline,
	.heading-form .radio-inline {
		margin-top: 9px;
	}
	.heading-form .checkbox-switch {
		margin-top: 2px;
	}
	.heading-form .checkbox-switch-sm {
		margin-top: 3px;
	}
	.heading-form .checkbox-switch-xs {
		margin-top: 4px;
	}
}

@media (max-width: 768px) {
	.heading-elements,
	.breadcrumb-elements {
		display: none;
	}
	.heading-elements.visible,
	.breadcrumb-elements.visible {
		display: block;
		margin-top: 0;
		top: 100%;
		height: auto;
		left: 0;
		right: 0;
		padding: 20px;
		padding-bottom: 0;
		border-top: 1px solid $light-gray-13;
		border-bottom: 1px solid $light-gray-13;
		z-index: 997;
	}
	.panel[class*="bg-"] .heading-elements.visible,
	.panel-heading[class*="bg-"] .heading-elements.visible {
		border-color: rgba(255, 255, 255, 0.5);
		left: 0;
		right: 0;
		background-color: inherit;
	}
	.page-header .heading-elements.visible {
		background-color: $gray-shade-1;
		left: 0;
		right: 0;
		border-color: $gray-shade-2;
		border-bottom-width: 0;
		z-index: 1002;
		-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
			0 1px 2px rgba(0, 0, 0, 0.24);
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	}
	.page-header-default .heading-elements.visible {
		background-color: inherit;
		border-color: $light-gray-13;
	}
	.page-header-inverse .heading-elements.visible {
		background-color: inherit;
		border-color: rgba(255, 255, 255, 0.1);
	}
	.panel-footer-transparent .heading-elements.visible {
		background-color: var(--white);
	}
	.breadcrumb-elements.visible {
		background-color: inherit;
		padding: 0;
		z-index: 9;
		border-width: 0;
		-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
			0 1px 2px rgba(0, 0, 0, 0.24);
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	}
}

/* ------------------------------------------------------------------------------
*
*  # Helper classes
*
*  Custom helper classes
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.no-edge-top {
	top: 0;
}

.no-edge-bottom {
	bottom: 0;
}

.no-edge-left {
	left: 0;
}

.no-edge-right {
	right: 0;
}

@media (min-width: 1200px) {
	.pull-left-lg {
		float: left;
	}
	.pull-right-lg {
		float: right;
	}
}

@media (min-width: 1025px) {
	.pull-left-md {
		float: left;
	}
	.pull-right-md {
		float: right;
	}
}

@media (min-width: 769px) {
	.pull-left-sm {
		float: left;
	}
	.pull-right-sm {
		float: right;
	}
}

@media (min-width: 480px) {
	.pull-left-xs {
		float: left;
	}
	.pull-right-xs {
		float: right;
	}
}

.valign-top {
	vertical-align: top;
}

.valign-middle {
	vertical-align: middle;
}

.valign-bottom {
	vertical-align: bottom;
}

.valign-baseline {
	vertical-align: baseline;
}

.valign-text-top {
	vertical-align: text-top;
}

.valign-text-bottom {
	vertical-align: text-bottom;
}

.position-absolute {
	position: absolute;
}

.position-relative {
	position: relative;
}

.position-static {
	position: static;
}

.display-block,
label.display-block {
	display: block;
}

.display-inline {
	display: inline;
}

.display-inline-block {
	display: inline-block;
}

.display-inline-table {
	display: inline-table;
}

.display-inherit {
	display: inherit !important;
}

.display-flex {
	display: flex !important;
}

.justify-content-space-between {
	justify-content: space-between;
}

.justify-content-center {
	justify-content: center;
}

.justify-content-flex-end {
	justify-content: flex-end;
}

.align-items-center {
	align-items: center;
}

.align-items-end {
	align-items: end;
}

.cursor-pointer {
	cursor: pointer;
}

.cursor-move {
	cursor: move;
}

.cursor-default {
	cursor: default;
}

.cursor-not-allowed {
	cursor: not-allowed;
}

.overflow-hidden {
	overflow: hidden;
}

.overflow-visible {
	overflow: visible;
}

.overflow-auto {
	overflow: auto;
}

.content-group-xs {
	margin-bottom: 10px !important;
}

.content-group-sm {
	margin-bottom: 15px !important;
}

.content-group {
	margin-bottom: 20px !important;
}

.content-group-lg {
	margin-bottom: 30px !important;
}

.no-margin {
	margin: 0 !important;
}

.no-margin-top {
	margin-top: 0 !important;
}

.no-margin-bottom {
	margin-bottom: 0 !important;
}

.no-margin-left {
	margin-left: 0 !important;
}

.no-margin-right {
	margin-right: 0 !important;
}

.m-5 {
	margin: 5px !important;
}

.m-10 {
	margin: 10px !important;
}

.m-15 {
	margin: 15px !important;
}

.m-20 {
	margin: 20px !important;
}

.mt-5 {
	margin-top: 5px !important;
}

.mt-10 {
	margin-top: 10px !important;
}

.mt-15 {
	margin-top: 15px !important;
}

.mt-20 {
	margin-top: 20px !important;
}

.mb-5 {
	margin-bottom: 5px !important;
}

.mb-10 {
	margin-bottom: 10px !important;
}

.mb-15 {
	margin-bottom: 15px !important;
}

.mb-20 {
	margin-bottom: 20px !important;
}

.ml-5 {
	margin-left: 5px !important;
}

.ml-10 {
	margin-left: 10px !important;
}

.ml-15 {
	margin-left: 15px !important;
}

.ml-20 {
	margin-left: 20px !important;
}

.mr-5 {
	margin-right: 5px !important;
}

.mr-10 {
	margin-right: 10px !important;
}

.mr-15 {
	margin-right: 15px !important;
}

.mr-20 {
	margin-right: 20px !important;
}

.no-padding {
	padding: 0 !important;
}

.no-padding-top {
	padding-top: 0 !important;
}

.no-padding-bottom {
	padding-bottom: 0 !important;
}

.no-padding-left {
	padding-left: 0 !important;
}

.no-padding-right {
	padding-right: 0 !important;
}

.p-5 {
	padding: 5px !important;
}

.p-10 {
	padding: 10px !important;
}

.p-15 {
	padding: 15px !important;
}

.p-20 {
	padding: 20px !important;
}

.pt-5 {
	padding-top: 5px !important;
}

.pt-10 {
	padding-top: 10px !important;
}

.pt-15 {
	padding-top: 15px !important;
}

.pt-20 {
	padding-top: 20px !important;
}

.pb-5 {
	padding-bottom: 5px !important;
}

.pb-10 {
	padding-bottom: 10px !important;
}

.pb-15 {
	padding-bottom: 15px !important;
}

.pb-20 {
	padding-bottom: 20px !important;
}

.pl-5 {
	padding-left: 5px !important;
}

.pl-10 {
	padding-left: 10px !important;
}

.pl-15 {
	padding-left: 15px !important;
}

.pl-20 {
	padding-left: 20px !important;
}

.pr-5 {
	padding-right: 5px !important;
}

.pr-10 {
	padding-right: 10px !important;
}

.pr-15 {
	padding-right: 15px !important;
}

.pr-20 {
	padding-right: 20px !important;
}

.no-border {
	border: 0 !important;
}

.no-border-top {
	border-top: 0 !important;
}

.no-border-bottom {
	border-bottom: 0 !important;
}

.no-border-left {
	border-left: 0 !important;
}

.no-border-right {
	border-right: 0 !important;
}

.no-border-radius {
	border-radius: 0;
}

.no-border-radius-top {
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}

.no-border-radius-bottom {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.no-border-radius-left {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}

.no-border-radius-right {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}

.border-top {
	border-top: 1px solid;
}

.border-bottom {
	border-bottom: 1px solid;
}

.border-left {
	border-left: 1px solid;
}

.border-right {
	border-right: 1px solid;
}

.border-lg {
	border-width: 2px;
}

.border-top-lg {
	border-top: 2px solid;
}

.border-bottom-lg {
	border-bottom: 2px solid;
}

.border-left-lg {
	border-left: 2px solid;
}

.border-right-lg {
	border-right: 2px solid;
}

.border-xlg {
	border-width: 3px;
}

.border-top-xlg {
	border-top: 3px solid;
}

.border-bottom-xlg {
	border-bottom: 3px solid;
}

.border-left-xlg {
	border-left: 3px solid;
}

.border-right-xlg {
	border-right: 3px solid;
}

.no-shadow {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.shadow-depth1 {
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
		0 1px 2px rgba(0, 0, 0, 0.24);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.shadow-depth2 {
	-webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
		0 3px 6px rgba(0, 0, 0, 0.23);
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.shadow-depth3 {
	-webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
		0 6px 6px rgba(0, 0, 0, 0.23);
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.shadow-depth4 {
	-webkit-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
		0 10px 10px rgba(0, 0, 0, 0.22);
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.shadow-depth5 {
	-webkit-box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3),
		0 15px 12px rgba(0, 0, 0, 0.22);
	box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.full-width {
	width: 100%;
}

@media (min-width: 769px) {
	.width-200 {
		min-width: 200px;
	}
	.width-250 {
		min-width: 250px;
	}
	.width-300 {
		min-width: 300px;
	}
	.width-350 {
		min-width: 350px;
	}
	.width-400 {
		min-width: 400px;
	}
	.width-450 {
		min-width: 450px;
	}
	.width-500 {
		min-width: 500px;
	}
	.width-550 {
		min-width: 550px;
	}
	.width-600 {
		min-width: 600px;
	}
	.width-650 {
		min-width: 650px;
	}
}

.img-lg {
	width: 44px !important;
	height: 44px !important;
}

.img-sm {
	width: 36px !important;
	height: 36px !important;
}

.img-xs {
	width: 32px !important;
	height: 32px !important;
}

input.auth-input {
	padding: 24px 20px;
	border: 2px solid $light-gray-16;
	background-color: $light-gray-15;
	color: $gray-shade-9 !important;

	&:focus {
		border-width: 2px !important;
	}

	&::placeholder {
		text-transform: uppercase;
		color: $light-gray-16;
	}

	& + .form-control-feedback {
		top: 10px !important;
	}
}
