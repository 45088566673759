.fade-in {
	animation: fade-in-animation 0.5s ease-out;
}

@keyframes fade-in-animation {
	0% {
		opacity: 0;
	}

	20% {
		opacity: 0.2;
	}

	40% {
		opacity: 0.4;
	}

	60% {
		opacity: 0.6;
	}

	80% {
		opacity: 0.8;
	}

	100% {
		opacity: 1;
	}
}
