
/* ------------------------------------------------------------------------------
*
*  # Buttons extension
*
*  The Buttons extension for DataTables provides a common set of options, API
*  methods and styling to display buttons that will interact with a DataTable
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.dt-buttons {
    position: relative;
    float: left;
    display: inline-block;
    margin: 0 0 20px 20px;
}

.dt-buttons > .dt-button {
    display: inline-block;
    border-radius: 0;
    text-transform: none;
    position: relative;
    font-weight: 500;
    border-width: 0;
    padding: 9px 17px;
    color: #333;
    background-color: #f5f5f5;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    font-size: 13px;
    line-height: 1.5384616;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-transition: all ease-in-out 0.15s;
    -o-transition: all ease-in-out 0.15s;
    transition: all ease-in-out 0.15s;
}
.dt-buttons > .dt-button:hover {
    -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
    box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
}

.dt-buttons > .dt-button:first-child {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}
.dt-buttons > .dt-button:last-child {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
}
.dt-buttons > .dt-button + .dt-button {
    margin-left: 1px;
}

.dt-button-collection {
    position: absolute;
    display: block;
    min-width: 180px;
    top: 0;
    left: 0;
    margin-top: 3px;
    padding: 8px 8px 4px 8px;
    border: 1px solid rgba(0,0,0,0.4);
    background-color: white;
    overflow: hidden;
    z-index: 2002;
    border-radius: 5px;
    box-shadow: 3px 3px 5px rgba(0,0,0,0.3);
    -webkit-column-gap: 8px;
    -moz-column-gap: 8px;
    -ms-column-gap: 8px;
    -o-column-gap: 8px;
    column-gap: 8px;
}
.dt-button-collection > .buttons-columnVisibility {
    min-width: 180px;
    position: relative;
    font-weight: 500;
    border-width: 0;
    color: #333;
    background-color: #f5f5f5;
    border-color: transparent;
    text-align: center;
    display: block;
    outline: 0;
    margin-top: 2px;
    padding: 9px 17px;
}
.dt-button-collection > .buttons-columnVisibility.active {
    background-color: transparent;
    -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
}
.dt-button-collection > .buttons-columnVisibility + .buttons-columnVisibility {
    margin-top: 1px;
}
.dt-button-collection > .buttons-columnVisibility:hover,
.dt-button-collection > .buttons-columnVisibility:focus {
    color: #333333;
    background-color: #f5f5f5;
}
.dt-button-collection > * {
    -webkit-column-break-inside: avoid;
    break-inside: avoid;
}

div.dt-button-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
    background: -ms-radial-gradient(center, ellipse farthest-corner, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.7) 100%);
    background: -moz-radial-gradient(center, ellipse farthest-corner, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.7) 100%);
    background: -o-radial-gradient(center, ellipse farthest-corner, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.7) 100%);
    background: -webkit-gradient(radial, center center, 0, center center, 497, color-stop(0, rgba(0,0,0,0.3)), color-stop(1, rgba(0,0,0,0.7)));
    background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.7) 100%);
    background: radial-gradient(ellipse farthest-corner at center, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.7) 100%);
    z-index: 2001;
    opacity: 0.25;
    filter: alpha(opacity=25);
}

@media screen and (max-width: 640px) {
    div.dt-buttons {
        float: none !important;
        text-align: center
    }
}

@media screen and (max-width: 767px) {
    .dt-buttons {
        float: none;
        text-align: center;
        display: block;
    }
    .dt-buttons .btn {
        float: none;
    }
}