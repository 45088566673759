@import "color-scheme.scss";

body {
	font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
	font-size: 13px;
}

a:hover,
a:focus {
	text-decoration: none;
}

a {
	color: $blue-4;
}

a:hover {
	color: #0473b7;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1.5384616;
	font-weight: 400;
}

h1 {
	font-size: 25px;
}

h2 {
	font-size: 23px;
}

h4 {
	font-size: 19px;
}

h5 {
	font-size: 17px;
}

h6 {
	font-size: 15px;
}

.row {
	margin-left: -10px;
	margin-right: -10px;
}

.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
	padding-left: 10px;
	padding-right: 10px;
}

.navbar-inverse .navbar-text {
	color: hsla(0, 0%, 100%, 0.9);
}

.dropdown-menu {
	font-size: 13px;
	z-index: 9999;
}

.breadcrumb {
	padding: 10px 0;
	background: transparent;
}

.panel-heading {
	padding: 20px;
}

.panel-body {
	padding: 20px;
}

.panel-footer {
	background-color: $light-gray-3;
}

.alert-success {
	background-color: #e8f5e9;
	border-color: $green-1;
}

.alert-danger {
	background-color: #fbe9e7;
	border-color: $red-1;
}

.alert-warning {
	background-color: #fff3e0;
	border-color: #ff9800;
	color: #ef6c00;
}

.text-muted {
	color: $gray-3;
}

.close {
	font-size: 19.5px;
	font-weight: 300;
}

.modal-body {
	padding: 20px;
}

.modal-footer {
	border-top: 1px solid transparent;
}

.form-control {
	padding: 8px 10px;
	font-size: 13px;
}

.input-group-addon {
	padding: 8px 16px 8px 0;
	font-size: 13px;
}

.btn-link:hover,
.btn-link:focus {
	text-decoration: none;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
	padding: 12px 20px;
	line-height: inherit;
}

.table-striped > tbody > tr:nth-of-type(odd) {
	background-color: $light-gray-3;
}

.table-hover > tbody > tr:hover {
	background-color: $light-gray-11;
}

.input-group-addon {
	color: $gray-3;
	background-color: $light-gray-4;
	border: 1px solid $light-gray-13;
}

.form-control.date-input {
	border-left: 0;
}

label {
	font-weight: 500;
}

ul.no-bullet {
	padding-left: 5px;
	li {
		list-style: none;
	}
}

.label-success {
	background-color: var(--blue);
	border-color: var(--blue);
}

.label-default {
	background-color: rgba(103, 119, 145, 0.6);
}

.label-secondary {
	background-color: rgba(103, 119, 145, 1);
}

.login-modal-sm {
	.modal-header {
		border-top: 4px solid var(--affirmative);
	}
}
.progress-bar-success {
	background-color: var(--affirmative);
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
	background-color: unset;
	color: $blue-4;
	border-bottom: 2px solid $blue-4;
}
.nav-pills > li > a {
	color: $gray-3;
	border-radius: 0;
	text-transform: unset;
	padding: 5px 10px;
}
