@charset "UTF-8";

@font-face {
  font-family: "font-ht";
  src:url("fonts/font-ht.eot");
  src:url("fonts/font-ht.eot?#iefix") format("embedded-opentype"),
    url("fonts/font-ht.woff") format("woff"),
    url("fonts/font-ht.ttf") format("truetype"),
    url("fonts/font-ht.svg#font-ht") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "font-ht" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="ht-icon-"]:before,
[class*=" ht-icon-"]:before {
  font-family: "font-ht" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ht-icon-logo:before {
  content: "\69";
}
.ht-icon-logo-symbol:before {
  content: "\6a";
}
.ht-icon-high-five {
	color: var(--blue);
}
.ht-icon-high-five:before {
	content: "\6a";
	position: relative;
	top: 2px;
}
.ht-icon-logo-text:before {
  content: "\6b";
}
