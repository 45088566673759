@import "color-scheme";
@import "variables";

.orgchart {
	display: inline-block;
	min-height: 202px;
	min-width: 202px;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	position: relative;
	width: 100%;
}

.orgchart .hidden,
.orgchart ~ .hidden {
	display: none !important;
}

.orgchart *,
.orgchart *:before,
.orgchart *:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.downNodes > div {
	display: table-cell;
}

.downNodes .leftLink {
	background: linear-gradient(lightgray, lightgray) left no-repeat;
	background-size: 50% 2px;
	position: relative;
	left: 35px;
}

.downNodes .rightLink {
	border-left: none;
	background: linear-gradient(lightgray, lightgray) right no-repeat;
	background-size: 50% 2px;
}

.downNodes .link {
	width: 13px;
	height: 1px;
	background-color: lightgray;
	display: inline-block;
	position: absolute;
	top: 25%;
	left: 152px;
}

.orgchart > .spinner {
	font-size: 100px;
	margin-top: 30px;
	color: rgba(68, 157, 68, 0.8);
}

.orgchart table {
	border-spacing: 0 !important;
	border-collapse: separate !important;
}

.orgchart > table:first-child {
	margin: 40px auto;
	background: var(--white);
}

.orgchart td {
	text-align: center;
	vertical-align: top;
	padding: 0 7px 0 7px;
}

tr.lines td {
	padding: 0;
}

.orgchart tr.lines td.topLine {
	border-top: 2px solid lightgray;
}

.orgchart tr.lines td.rightLine {
	border-right: 1px solid lightgray;
	float: none;
	border-radius: 0;
}

.orgchart tr.lines td.leftLine {
	border-left: 1px solid lightgray;
	float: none;
	border-radius: 0;
}

div.leftLine {
	border-left: 2px solid lightgray;
	float: none;
	border-radius: 0;
}

div.rightLine {
	float: none;
	border-radius: 0;
}

.orgchart tr.lines .downLine {
	background-color: lightgray;
	margin: 0 auto;
	height: 42px;
	width: 2px;
	float: none;
}

/* node styling */

.orgchart ~ .mask {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 99;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.3);

	.spinner {
		position: absolute;
		top: calc(50% - 54px);
		left: calc(50% - 54px);
		color: rgba(255, 255, 255, 0.8);
		font-size: 108px;
	}
}

.modal-info-img {
	float: none;
	max-width: 150px;
}

fieldset .orgchart > table:first-child {
	border: 0;
	box-shadow: none;
}

.modal-info-name {
	font-family: "Roboto Condensed", sans-serif;
	text-transform: uppercase;
	font-size: 20px;
	line-height: 1.5;
}

.blue-style {
	font-family: "Glegoo", sans-serif;
	font-size: 16px;
	font-weight: normal;
	color: var(--blue);
	font-style: italic;
}

.additional-info {
	margin-top: 10px;
	font-size: 14px;
}

#modalInfo .additional-info .fa {
	font-size: 12px;
	line-height: 15px;
	color: rgb(102, 102, 102);
	margin-right: 10px;
	width: 16px;
}

.icons-list > li > a.display-none {
	display: none;
}

div.imported {
	position: relative;
	cursor: pointer;

	.org-chart-node-information {
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
			0 0 10px rgba(232, 153, 0, 0.63) !important;
	}

	.content::after {
		background: url($images-folder + "/new-csv-org-node.png");
		width: 41px;
		height: 43px;
		position: absolute;
		top: 26px;
		right: -4px;
	}
}
