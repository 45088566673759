/* ================================================
                Validations
================================================= */

label.label-error {
	color: $orange;
	font-weight: normal;
}

input.label-error,
select.label-error,
textarea.label-error {
	border: 1px solid $orange;
}
