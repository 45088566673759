@import "color-scheme.scss";
@import "variables";

body {
	background: $light-gray-5;
}

.panel,
.sidebar {
	-webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
	box-shadow: 2px 3px 2px rgba(0, 0, 0, 0.05);
	border-radius: 0;
}

.no-box-shadow {
	box-shadow: none;
}

.brand {
	float: left;
	height: 18px;
	width: 123px;
	margin: 15px 16px;
}

.reports-logo {
	position: absolute;
	right: 10px;
	top: 17px;
}

.org-chart-logo {
	position: absolute;
	right: 30px;
	top: 0;
}

.btn-circle {
	border-radius: 50%;
	color: var(--white);
	position: absolute;
	right: 9px;
	bottom: -18px;
	line-height: 1;
	cursor: pointer;
	z-index: 2;
}

.bg-red {
	background: $orange;
	padding: 15px;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
	transition: all 0.3s ease;
}

.bg-red:hover {
	background: #eb6218;
}

.sidebar {
	width: $sidebar-width;
}

.navbar-header {
	min-width: $sidebar-width;
}

.custom-quick-state-boxes {
	.panel {
		box-shadow: none;
		border-radius: 0;
		font-size: 16px;
		color: var(--white);

		.panel-body {
			padding: 0;
		}

		h3 {
			font-size: 30px;
			font-weight: 600;
		}

		.panel-text {
			display: inline-block;
			padding: 20px 20px 0 20px;
		}

		.panel-icon {
			display: inline-block;
			float: right;
			padding: 40px;
			background: rgba(0, 0, 0, 0.05) none repeat scroll 0% 0%;

			.fa {
				font-size: 32px;
			}

			.fa-trophy {
				font-size: 36px;
				line-height: 32px;
			}
		}
	}
}

.bg-no {
	background: none;
	box-shadow: none;
}

.custom-page-title {
	padding: 22px 22px 22px 0;
}

.heading-elements {
	float: right !important;
}

.panel-green,
.panel-gray,
.panel-blue,
.panel-top-info,
.panel-light-blue,
.panel-purple {
	border-radius: 0;
}

.panel-green {
	border-top: 4px solid var(--affirmative);
}

.panel-top-info {
	border-top: 7px solid $blue-9;
}

.panel-gray {
	border-top: 4px solid $gray;
}

.panel-light-blue {
	border-top: 4px solid var(--blue);
}

.panel-blue {
	border-top: 4px solid var(--blue);
}

.panel-purple {
	border-top: 7px solid #9575cd;
}

.panel-heading-padding-10 {
	padding-top: 10px;
	padding-bottom: 10px;
}

.summary-body {
	.fa:not(.fa-spinner) {
		color: $gray-3;
		padding-left: 5px;
	}

	span.btn {
		margin: 5px;
		font-size: 11px;
	}

	span.title {
		font-size: 15px;
		font-weight: 500;
		display: block;
		padding-top: 5px;
	}
}

.panel-footer {
	padding: 15px 0;
	text-align: center;
	text-transform: uppercase;
}

.panel-footer-content {
	padding: 10px 15px;
}

.title-grey-container {
	padding: 10px 20px;
	background: #f7f7f7;
	border-top: 1px solid $light-gray-5;
	border-bottom: 1px solid $light-gray-5;
	margin: 10px -20px;
}

input[type="text"].form-control-custom::-webkit-input-placeholder {
	color: #979797 !important;
	font-style: italic;
	font-weight: 300;
}

input[type="text"].form-control-custom::-moz-placeholder {
	/* Firefox 19+ */
	color: #979797 !important;
	font-style: italic;
	font-weight: 300;
}

input[type="text"].form-control-custom:-ms-input-placeholder {
	/* IE 10+ */
	color: #979797 !important;
	font-style: italic;
	font-weight: 300;
}

input[type="text"].form-control-custom:-moz-placeholder {
	/* Firefox 18- */
	color: #979797 !important;
	font-style: italic;
	font-weight: 300;
}

.inline {
	display: inline-block;
}

.select2-selection--single:not([class*="bg-"]):not([class*="border-"]) {
	border-bottom: 0;
}

.form-control,
.dataTables_filter input,
.select2-container {
	border-radius: 0;
	background: $light-gray-4;
	border: 1px solid $light-gray-13;
	box-shadow: none;
	padding-left: 10px;

	&:focus {
		border: 1px solid var(--blue);
		-webkit-box-shadow: none;
		box-shadow: none;
	}
}

.searchable-select + .select2-container--focus,
.searchable-select + .select2-container--open {
	border: 1px solid var(--blue);
	-webkit-box-shadow: none;
	box-shadow: none;
}

.select2-container--focus
	.select2-selection--single:not([class*="bg-"]):not([class*="border-"]),
.select2-container--open
	.select2-selection--single:not([class*="bg-"]):not([class*="border-"]) {
	border: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.select2-selection--single:not([class*="bg-"]):not([class*="border-"]) {
	border: 0;
}

.select2-results__option[aria-selected="true"] {
	background-color: #039be5;
	color: var(--white);
}

.select2-selection--single .select2-selection__arrow::after {
	right: 5px;
}

.dataTables_filter input[type="search"] {
	padding-right: 30px;
}

.dataTables_filter > label::after {
	right: 12px;
}

.float-left {
	float: left;
}

.nav-tabs.red-material-list {
	li a {
		transition: none;
	}
}

.nav-tabs.red-material-list {
	li.active a,
	.active > a,
	.active > a:hover,
	.active > a:focus {
		color: $orange;
		font-weight: 600;
		border: none;
		border-bottom: 3px solid $orange;
	}
}

.panel-flat > .panel-heading.heading-border {
	border-bottom: 1px solid $light-gray-5;
	padding: 10px 20px;
	margin-bottom: 20px;
}

.divide-rows {
	.panel-body {
		padding: 0;
	}

	.media {
		margin: 0;
	}

	.panel-heading.heading-border {
		margin-bottom: 0;
	}

	.panel-body ul.media-list > li {
		border-bottom: 1px solid $light-gray-5;
		padding: 15px;
	}

	.panel-body ul.media-list > li:last-child {
		border-bottom: 0;
	}

	.fa {
		color: $gray-3;
	}
}

.tags-input-field {
	clear: both;
	margin-top: 10px;
}

.float-form-group {
	min-height: 40px;
}

.tokenfield {
	background: none;
	border: 1px solid var(--white);
}

.sidebar-category {
	.bs-flow-tree-style {
		font-size: 14px;
	}
}

.panel-starter {
	.bs-flow-tree-style {
		font-size: 40px;
		padding: 0 9px;
	}

	.fa-users {
		font-size: 30px;
		padding: 12px;
		line-height: 39px;
		color: var(--blue);
	}

	div .button-text {
		color: $gray-4;
		font-size: 18px;
		margin-top: 20px;
		transition: all 0.3s ease;
	}

	a:hover {
		transition: all 0.3s ease;
	}

	a:hover .button-text {
		color: #26c6da;
	}

	#upload-file {
		margin-bottom: 20px;
	}

	.or {
		line-height: 10em;
		font-size: 1em;
	}

	.upload-file-row {
		margin: 5em 0 2em 0;
	}

	.btn-rounded .fa {
		font-size: 26px;
		padding: 10px;
		line-height: 26px;
	}

	.info-icon {
		position: absolute;
		right: 2em;
		top: 30%;
		color: $gray-4;
	}

	.big-label {
		font-size: 14px;
		color: #666;
	}

	.final {
		margin: 5em 0;

		.checkbox input[type="checkbox"] {
			margin-left: -20px;
			left: auto;
		}

		label {
			font-size: 14px;
		}
	}
}

.navigation li a > i {
	min-width: 15px;
	text-align: center;
}

.media-left .fa {
	padding: 0 2px;
}

.margin-bottom-0 {
	margin-bottom: 0;
}

.margin-right-0 {
	margin-right: 0;
}

.stepy-header li div {
	background-color: $blue-4;
	border: 2px solid $blue-4;
}

.stepy-header li.stepy-active div {
	border-color: $blue-4;
	color: $blue-4;
}

.stepy-header li:before,
.stepy-header li:after {
	background-color: $blue-4;
}

.sidebar-user-material .category-content {
	height: 210px;
}

fieldset {
	display: table-cell;
	width: 100%;
}

.sidebar-xs .category-content {
	height: auto;
}

.content-footer {
	padding-left: 30px;
}

.has-feedback-left select.form-control,
.has-feedback-left.input-group select.form-control {
	padding-left: 10px;
}

.obligatory {
	color: $orange;
}

.special-fixed-title {
	padding: 13px 20px;
	position: absolute;
}

.spec-icon {
	margin-right: 5px;
	color: #ffa726;
}

.margin-20 {
	margin: 20px 0;
}

.skills-container {
	span {
		position: relative;
		font-weight: 500;
		border-width: 0;
		padding: 9px 17px;
		display: inline-block;
		margin-bottom: 0;
		font-weight: normal;
		text-align: center;
		vertical-align: middle;
		cursor: auto;
		background-image: none;
		border: 1px solid transparent;
		white-space: nowrap;
		padding: 8px 16px;
		font-size: 13px;
		line-height: 1.5384616;
		border-radius: 3px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		color: $gray-4;
		background-color: $light-gray;
		border-color: transparent;
		margin-bottom: 5px;
		margin-right: 2px;
	}
}

.breadcrumb-line {
	box-shadow: none;
}

.form-control[disabled],
fieldset[disabled] .form-control {
	background: $light-gray-4;
	border: 1px solid $light-gray-13;
}

.form-control[disabled]::-webkit-input-placeholder {
	color: #c3c3c3 !important;
}

.form-control[disabled]::-moz-placeholder {
	/* Firefox 19+ */
	color: #c3c3c3 !important;
}

.form-control[disabled]:-ms-input-placeholder {
	/* IE 10+ */
	color: #c3c3c3 !important;
}

.form-control[disabled]:-moz-placeholder {
	/* Firefox 18- */
	color: #c3c3c3 !important;
}

#edit-password-row {
	display: none;
}

.imitate-link {
	color: #337ab7;
	transition: all 0.2s ease;

	i {
		font-size: 11px;
		margin-left: 10px;
		margin-right: 2px;
	}
}

.imitate-link:hover {
	color: #0c7cd5;
	cursor: pointer;
}

.comments:hover {
	color: $blue-4;

	.fa {
		color: $blue-4 !important;
	}
}

.relative {
	position: relative;
}

.camera-btn {
	position: absolute;
	right: 10px;
	bottom: 0px;
	width: calc(100% - 20px);
	color: var(--white);
	font-size: 2.6em;
	opacity: 0.8;
	transition: all 0.5s ease;

	i {
		top: 2px;
	}

	span.text-hidden {
		font-size: 14px;
	}

	&:hover {
		cursor: pointer;
	}
}

.photo-upload:hover .camera-btn {
	background: -moz-linear-gradient(
		top,
		transparent 0%,
		rgba(0, 0, 0, 0.65) 74%
	);
	background: -webkit-linear-gradient(
		top,
		transparent 0%,
		rgba(0, 0, 0, 0.65) 74%
	);
	background: linear-gradient(
		to bottom,
		transparent 0%,
		rgba(0, 0, 0, 0.65) 74%
	);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
	opacity: 1;
}

h6.panel-title,
.panel-heading > .panel-title {
	font-size: 13px;
	font-weight: 500;
	text-transform: uppercase;
	color: #0a0a0a;
}

.panel-heading .panel-title.panel-title-export {
	letter-spacing: 0.1px;
}

.i-send-invitation,
.i-resend-invitation {
	cursor: pointer;
}

.bg-none {
	background-color: transparent;
}

.breadcrumb-line.bg-none .breadcrumb > .active,
.breadcrumb-line.bg-none .breadcrumb > li + li::before {
	color: $gray-3;
}

.page-header .breadcrumb-line:first-child {
	z-index: 3;
}

.page-header-padding-5 {
	padding: 5px 0;
}

.custom-page-title {
	padding: 22px 0 0 0;
}

.breadcrumb-line {
	padding: 10px 0;
}

.author-name {
	font-size: 26px;
	font-weight: 400;
	color: #232323;
	line-height: 30px;
}

.author-name:after {
	display: block;
	content: "";
	width: 40px;
	height: 5px;
	margin-top: 15px;
	background-color: var(--blue);
	margin-bottom: 20px;
}

h2.author-name {
	margin-top: 0;
}

.author-name .big-author-name {
	min-width: 200px;
}

.author-name > span,
.new-employee-name > span {
	display: block;
	font-family: "Glegoo", sans-serif;
	font-size: 16px;
	font-weight: normal;
	text-transform: none;
}

.date-hired {
	font-size: 13px;
	color: $light-gray-8;

	.date-hired .fa-calendar {
		font-size: 14px;
	}
}

.user-quote {
	font-size: 15px;
	color: #232323;
}

.add-separator-top {
	border-top: 1px solid $light-gray-5;
	margin-top: 20px;
	padding-top: 20px;
}

.lg-right-xs-left {
	text-align: right;
}

ul.custom-list {
	padding-left: 0;

	li {
		list-style: none;
		border-bottom: dashed 1px #c3c3c3;
		padding-bottom: 15px;
		margin-bottom: 15px;
		position: relative;
	}

	li:last-child,
	li ul li {
		border-bottom: none;
		margin-bottom: 0;
	}

	.title {
		font-size: 18px;
	}

	.sub-title {
		font-size: 14px;
		color: $gray-3;

		i.fa-map-marker {
			padding: 0 2px 0 7px;
		}
	}
}

.ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

ul.fav-list {
	list-style: none;
	padding: 0;

	li {
		padding-left: 1.3em;
	}

	li:before {
		content: "\f08a"; /* FontAwesome Unicode */
		font-family: FontAwesome;
		color: $orange;
		display: inline-block;
		margin-left: -1.3em;
		width: 1.3em;
	}
}

.auto-height .media-left img:not(.media-preview) {
	height: auto;
	width: 60px;
}

.timeline-navigation {
	padding: 20px;
	font-weight: 600;
	text-transform: uppercase;

	.checkbox {
		margin: 0 auto;
	}

	.gray {
		color: $gray;
	}

	.green {
		color: var(--affirmative);
	}

	.blue {
		color: $blue-4;
	}
}

.awards-widget {
	.media-middle .icon {
		font-size: 33px;
		color: #ff9776;
		height: 42px;
	}
}

.heading-elements .padding-list {
	margin-top: 0;
}

.padding-list li a {
	padding: 12px 16px;
	cursor: pointer;
}

/* Editable fields */
.skills-container span.aui-iconfont-edit {
	clear: both;
	padding: 0;
}

.editable-field,
.editable-skills .ms-ctn {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-transition: box-shadow 0.2s ease-in 0ms;
	transition: box-shadow 0.2s ease-in 0ms;
	border-radius: 0;
	cursor: text;
	display: inline-block;
	max-width: 100%;
	box-shadow: 0 0 0 0 transparent;
	padding: 0px 30px 2px 5px;
	position: relative;
	word-break: inherit;
	margin: 2px 0;
	background: transparent;
}

.extra-big-editable-field {
	.editable-field {
		padding-right: 5px;
	}
}

.editable-field:after,
.editable-skills .ms-ctn:after {
	content: "\f040"; /* FontAwesome Unicode */
	font-family: FontAwesome;
	color: #7e7e7e;
	display: inline-block;
	width: 1.3em;
	padding-left: 5px;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	opacity: 1;
	background-color: #f0f0f0;
	border-left: 1px solid $light-gray-6;
	bottom: 0;
	height: auto;
	margin: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 24px;
	z-index: -1;
	text-align: left;
	font-size: 16px;
	font-weight: normal;
}

.editable-field:hover::after,
.editable-list-items li:hover .editable-field:after,
.editable-skills .ms-ctn:hover::after {
	z-index: 1;
}

.editable-field:hover,
.editable-field.saving,
.editable-list-items li:hover .editable-field,
.editable-skills .ms-ctn:hover,
.editable-field.saving,
.editable-list-items li:hover .editable-field {
	box-shadow: 0 0 0 1px $light-gray-6;
}

.editable-field:hover .editable-field:after,
.editable-field.saving .editable-field:after,
.editable-skills .ms-ctn:hover .form-control:after {
	opacity: 1;
	z-index: auto;
}

.editable-field:hover .editable-field:after,
.editable-list-items li:hover .editable-field .editable-field:after,
.editable-skills .ms-ctn:hover,
.editable-skills .ms-ctn:after {
	-webkit-transition: opacity 0.2s ease-in 0ms;
	transition: opacity 0.2ms ease-in 0ms;
}

.editable-field:not(.user-summary, .not-editable-link):empty::before {
	content: attr(data-name) " not provided";
	display: inline-block;
}

.editable-field:empty:focus::before {
	content: "";
}

.word-wrap-break-word {
	word-wrap: break-word;
}

.word-break-break-word {
	word-break: break-word;
}

.word-break-break-all {
	word-break: break-all;
}

textarea.editable_clicked {
	resize: none;
}

.user-summary:empty::before {
	content: "Add your summary...";
	display: inline-block;
}

ul.draggable-list-items li .dragbox i {
	display: none;
}

ul.draggable-list-items li:hover .dragbox i {
	display: block;
	cursor: pointer;
}

label.hover-animation {
	padding: 0px 20px 15px 20px;
	transition: all 0.2s ease;
	border-bottom: 4px solid var(--white);
}

label.hover-animation:hover {
	border-bottom: 4px solid var(--blue);
}

.search-categories-ul,
.categories-search .input-group,
.categories-search .input-group .input-group-btn {
	float: left;
}

.categories-search .input-group .input-group-btn {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.search-categories-ul {
	max-width: 200px;
	margin: 0 20px;
}

.categories-search .input-group {
	width: calc(100% - 200px);
}

.categories-search input[type="text"] {
	width: calc(100% - 80px);
}

.categories-search .input-group-btn > .btn:last-of-type {
	border-bottom-left-radius: 0px;
	border-top-left-radius: 0px;
}

.welcome-comp {
	max-width: 800px;
	margin: 0 auto;

	h3 {
		padding-top: 60px;
		padding-bottom: 10px;
	}

	h6 {
		margin-bottom: 20px;
	}

	img.img-responsive {
		float: left;
	}

	.inline {
		display: inline-block;
		width: 400px;
		margin-left: 60px;

		button,
		i {
			font-weight: normal;
		}
	}
}

li .top-nav-dropdown {
	padding-top: 15px;
}

.form-inline .form-control {
	min-width: 120px;
}

.timeline-column {
	transition: 0.5s ease all;
}

.no-padding-body {
	padding: 0 0 10px 0;
}

.input-xlg + .form-control-feedback {
	line-height: 37px;
}

textarea.disable-resize {
	resize: none;
}

textarea.disable-horizontal-resize {
	resize: vertical;
}

.editable:hover {
	background-color: rgb(232, 232, 232);
	transition: background-color 0.3s ease-out;
	cursor: pointer;
}

.editable-controls {
	position: relative;
}

.editable-controls {
	textarea {
		box-sizing: border-box;
		margin: 0;
		background: $light-gray-4;
		border: 1px solid var(--blue);
		box-shadow: none;
	}

	button {
		border: none;
		margin: 2px;
		z-index: 10;
	}

	.editable-buttons .btn,
	.editable-buttons .btn-light-blue {
		padding: 3px 7px 4px 7px;
		font-size: 12px;
		border-radius: 2px;

		.fa {
			color: $gray-4;
			padding-left: 0;
		}
	}

	.editable-buttons .btn-light-blue {
		padding: 3px 5px 4px 5px;

		.fa {
			color: var(--white);
		}
	}

	label {
		display: block;
		font-size: 15px;
		margin-bottom: 0;
	}
}

.editable-controls,
.editable-controls textarea,
.editable-controls .editable-buttons,
.editableform {
	display: inline-block;
	vertical-align: top;
	min-height: 28px;
	max-width: 100%;
}

.small-editable-fields .editableform .control-group {
	width: calc(100% - 30px);
}

.small-editable-fields .editableform .control-group textarea {
	width: calc(100% - 60px);
}

.big-editable-field {
	.editableform .control-group,
	.editableform .control-group textarea {
		min-height: 34px;
		width: 100%;
		max-width: 100%;
	}

	.editableform .control-group textarea {
		width: 80%;
		max-width: 100%;
	}
}

.extra-big-editable-field,
.high-big-editable-field {
	form,
	.editableform .control-group textarea,
	.editableform .control-group,
	.editable-field {
		width: 100%;
		max-width: 100%;
	}

	.editableform .editable-input {
		display: block;
	}

	.editableform .control-group textarea {
		padding: 5px;
		margin: 2px 0;
	}

	.editable-buttons {
		width: 100%;
		text-align: right;
		margin-top: 10px;
		margin-left: 0;

		button {
			padding: 6px 12px;

			i {
				font-size: 12px;
			}
		}
	}
}

.high-big-editable-field .editableform .control-group textarea {
	min-height: 2.5em;
}

.editableform .control-group,
.editable_clicked {
	font-size: 14px;
}

.editable-skills .ms-ctn-focus {
	background: $light-gray-4;
	border: 1px solid var(--blue);
	border-radius: 0;
}

.editable-skills .ms-ctn.ms-ctn-focus:hover {
	border: 1px solid var(--blue);
}

.editable-skills .ms-ctn-focus .ms-sel-item {
	background: var(--blue);
	color: var(--white);
	border: 1px solid var(--blue);
}

.editable-skills .ms-ctn-focus:after {
	opacity: -1;
}

.org-chart-picture-btn {
	background: $orange;
	padding: 10px;
	font-size: 10px;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
	transition: all 0.3s ease;
	border-radius: 50%;
	color: var(--white);
	position: absolute;
	left: 88px;
	top: 48px;
	line-height: 1;
	cursor: pointer;
	z-index: 70;

	i {
		font-size: 10px;
	}
}

.org-chart-terminated-employee {
	.content {
		.inactive-emp-name,
		.org-chart-node-position {
			opacity: 0.5;
		}

		.org-chart-node-image-container {
			background-color: #fdfdfd !important;
		}

		.btns-container {
			pointer-events: all;
		}
	}

	.org-chart-picture-btn {
		background: #f8b17b;
	}
}

.small-sized-photo {
	width: 36px;
	height: 36px;
	cursor: pointer;
	display: inline-block;
	vertical-align: middle;
}

.small-sized-photos > .small-sized-photo {
	margin: 4px;
}

.input-icon {
	position: absolute;
	display: inline-block;
	font-size: 20px !important;
	right: 120px;
	top: 10px;
}

#paper:not(.company-org-chart) {
	height: 600px;
	width: 100%;
	display: inline-block;
	float: left;
}

#side-nodes {
	padding: 0 2.3%;
	width: 225px;
	float: left;
	overflow-y: auto;
	border: none;
	border-left: 1px solid $light-gray-5;
}

ul.list-unstyled > li {
	list-style: none !important;
}

.search-bar {
	position: relative;
	top: 2px;
	width: 196px;
	height: 40px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.25);
	font-size: 14px;

	.select2-container {
		background: inherit;
		color: rgba(255, 255, 255, 0.6);
		border: none;
		padding-left: 24px;

		::-webkit-input-placeholder {
			/* Chrome/Opera/Safari */
			color: inherit;
		}

		::-moz-placeholder {
			/* Firefox 19+ */
			color: inherit;
		}

		:-ms-input-placeholder {
			/* IE 10+ */
			color: inherit;
		}

		:-moz-placeholder {
			/* Firefox 18- */
			color: inherit;
		}
	}
}

.search-bar-xs {
	display: none !important;
	position: relative;
	top: 2px;
	height: 40px;
	cursor: pointer;
	font-size: 14px;
}

.searchbar-select-dropdown {
	width: 300px !important;
}

.delete-item-icon {
	width: 20px;
	font-size: 20px;

	&:hover {
		cursor: pointer;
	}
}

#modalInfo div.tooltip-inner,
#reportsToSpan div.tooltip-inner {
	max-width: 350px;
}

.agree-button[disabled] {
	pointer-events: none !important;
}

.padding-delete-btn {
	padding: 8px;
}

.delete-btn-muted:hover {
	color: #232323;
	transition: 0.3s color ease;
	cursor: pointer;
}

.professional-profile-skills .skills-label {
	padding-top: 8px;
}

.hobbies-label {
	padding-top: 10px;
}

.editableform {
	font-size: 12px;
}

.high-big-editable-field .editableform .control-group textarea {
	padding: 4px 7px;
}

.user-quote .high-big-editable-field .editableform .control-group textarea {
	padding: 5px 8px;
}

p.user-quote {
	margin-bottom: 0;
	margin-top: 20px;
}

span.user-summary {
	width: 100%;
	padding: 5px 8px;
	font-size: 14px;
}

.padding-left-5 {
	padding-left: 5px;
}

.padding-right-5 {
	padding-right: 5px;
}

.padding-left-10 {
	padding-left: 10px;
}

.padding-right-10 {
	padding-right: 10px;
}

.padding-left-15 {
	padding-left: 15px;
}

.padding-right-15 {
	padding-right: 15px;
}

.padding-left-20 {
	padding-left: 20px;
}

.padding-right-20 {
	padding-right: 20px;
}

.padding-left-30 {
	padding-left: 30px;
}

.padding-right-30 {
	padding-right: 30px;
}

.padding-top-15 {
	padding-top: 15px;
}

.padding-top-10 {
	padding-top: 10px;
}

.padding-top-5 {
	padding-top: 5px;
}

.padding-bottom-5 {
	padding-bottom: 5px;
}

.padding-bottom-10 {
	padding-bottom: 10px;
}

.padding-bottom-15 {
	padding-bottom: 15px;
}

.padding-bottom-20 {
	padding-bottom: 20px;
}

.margin-left-auto {
	margin-left: auto;
}

.margin-left-5 {
	margin-left: 5px;
}

.margin-left-2 {
	margin-left: 2px;
}

.margin-right-5 {
	margin-right: 5px;
}

.margin-top-0 {
	margin-top: 0;
}

.margin-top-5 {
	margin-top: 5px;
}

.margin-bottom-5 {
	margin-bottom: 5px;
}

.margin-left-10 {
	margin-left: 10px;
}

.margin-right-10 {
	margin-right: 10px;
}

.margin-top-10 {
	margin-top: 10px;
}

.margin-bottom-10 {
	margin-bottom: 10px;
}

.margin-left-15 {
	margin-left: 15px;
}

.margin-right-15 {
	margin-right: 15px;
}

.margin-top-15 {
	margin-top: 15px;
}

.margin-bottom-15 {
	margin-bottom: 15px;
}

.margin-left-20 {
	margin-left: 20px;
}

.margin-right-25 {
	margin-right: 25px;
}

.margin-right-20 {
	margin-right: 20px;
}

.margin-top-20 {
	margin-top: 20px;
}

.margin-top-25 {
	margin-top: 25px;
}

.margin-bottom-20 {
	margin-bottom: 20px;
}

.margin-bottom-25 {
	margin-bottom: 25px;
}

.margin-left-30 {
	margin-left: 30px;
}

.margin-right-30 {
	margin-right: 30px;
}

.margin-right-35 {
	margin-right: 35px;
}

.margin-top-30 {
	margin-top: 30px;
}

.margin-bottom-30 {
	margin-bottom: 30px;
}

.margin-5:after {
	margin-left: 5px;
}

.margin-top-28 {
	margin-top: 28px;
}

.width-20-percent {
	width: 20%;
}

.width-80-percent {
	width: 80%;
}

.text-underline {
	text-decoration: underline;
}

.link-style {
	color: $blue-7 !important;
	transition: all 0.3s ease;
	cursor: pointer;
}

.link-style:hover {
	color: $blue-8 !important;
}

p.default-text {
	padding-bottom: 10px;
}

.panel-flat > .panel-heading.panel-heading-5-margin {
	margin-bottom: 10px;
}

.ms-ctn .ms-sel-ctn input,
.ms-ctn-focus .ms-sel-ctn input {
	min-width: 205px;
}

.skills-label,
.hobbies-label {
	font-size: 16px;
}

.fa-grey-info-icon {
	cursor: pointer;
}

.fa-grey-info-icon {
	color: $light-gray-6;
	top: 0;
}

.terms-container {
	max-height: 500px;
	overflow-y: auto;
	background: $light-gray;
	padding: 6px 14px;
}

.professional-profile-skills .tooltip,
.professional-profile-hobbies .tooltip {
	width: 200px;
}

.user-quote .editableform .control-group textarea {
	min-height: 33px;
	padding: 5px 8px;
	margin: 1px -1px;
}

#desired-skills-input input:disabled,
#desiredPositions input:disabled {
	cursor: default;
	font-family: "Roboto", Helvetica Neue, Helvetica, Arial, sans-serif;
	font-size: 13px;
	line-height: 1.5384616;
	color: $gray-4;
}

.btn-smaller {
	padding: 9px 30px;
}

.multiple-tags-select2 ul {
	li.select2-selection__choice {
		background-color: #e4e4e4;
		border: 1px solid #aaa;
		cursor: default;
		float: left;
		margin-right: 5px !important;
		margin-top: 5px !important;
		padding: 0 5px;
		border-radius: 0 !important;
	}

	li.select2-search--inline input {
		margin: 0 auto !important;
	}
}

.clear {
	clear: both;
}

.organization-head-select {
	.select2-container--default {
		width: 100% !important;
	}
}

.org-chart-mobile-menu {
	.dropdown .dropdown-menu li {
		padding: 10px 12px;
		transition: background 0.2s ease;

		span {
			padding: 0;
		}

		&:hover {
			background: $light-gray;
			cursor: pointer;
		}
	}

	.dropdown .dropdown-menu li.divider {
		padding: 0;
	}
}

.ms-sel-ctn {
	line-height: 20px;
}

.desired-skills .ms-ctn {
	margin-bottom: 12px;
}

.tooltip .tooltip-arrow {
	display: block;
}

.popover .arrow {
	display: block;
}

.popover-close-btn {
	cursor: pointer;
	position: absolute;
	top: 10px;
	right: 10px;
}

.select2-selection--multiple:not([class*="bg-"]) .select2-selection__choice {
	background: var(--blue);
	color: var(--white);
	border: none;
	padding: 4px 10px;
	border-radius: 15px !important;
}

.professional-profile-skills .select2-container,
.professional-profile-hobbies .select2-container,
.desired-skills .select2-container,
.desired-positions .select2-container {
	background: none;
	border: none;
}

.desired-positions {
	.select2-selection--multiple:not([class*="bg-"])
		.select2-selection__choice {
		background: none;
		color: $gray-4;
		border: none;

		.select2-selection__choice__remove {
			margin-top: 3.5px;
		}
	}

	.select2-selection--multiple:not([class*="bg-"])
		.select2-selection__choice:before {
		content: "\f08a";
		color: $orange;
		padding-right: 10px;
		display: inline-block;
		font: normal normal normal 14px/1 FontAwesome;
		font-size: inherit;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
	}
}

.label-papaya {
	background-color: #f68b69;
	border-color: #f68b69;
}

.label-tiffany {
	background-color: #64a3d8;
	border-color: #64a3d8;
}

#employees-match {
	.label {
		margin: 2px;
		padding: 4px 10px;
		border-radius: 4px;
	}
}

#table-users-acl,
#skills-per-position-table,
#skills-per-position-edit-modal,
#table-skills {
	.label {
		margin: 2px;
	}

	.fa-times {
		font-size: 10px;
		top: -3px;
		right: -2px;
		cursor: pointer;
	}
}

span.save-row-btn {
	float: right;
	cursor: pointer;
	display: inline-block;
}

.modal-dialog .additional-info {
	p {
		margin-bottom: 5px;
	}

	.fa {
		top: -1px;
		font-size: 12px;
		line-height: 15px;
		color: rgb(102, 102, 102);
		margin-right: 10px;
		width: 16px;
	}
}

.blog-content {
	margin: 0 20px 20px 20px;
}

.bottom-bordered-list {
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;

	li {
		padding: 10px;
	}

	li:not(:last-child) {
		border-bottom: 1px solid $light-gray-6;
	}
}

.text-light-blue {
	color: var(--blue);
}

.full-width {
	width: 100% !important;

	+ span.select2-container {
		width: 100% !important;
	}
}

.full-height {
	height: 100% !important;
}

.remove-margin h4,
.remove-margin h5 {
	margin-top: 0;
}

.professional-business-card #reportsToSpa {
	padding-left: 5px;
}

.extra-big-editable-field .editable-field,
.high-big-editable-field .editable-field,
#hobbies-input.editable-field {
	text-align: justify;
}

ul.custom-list .orange-bold,
.orange-bold {
	color: $orange;
	font-weight: 600;
}

.select2-container--disabled .select2-selection--multiple,
.select2-container--disabled
	.select2-selection--multiple
	.select2-selection__choice,
.select2-container--disabled
	.select2-selection--multiple
	.select2-search__field {
	cursor: text;
}

.select2-container--disabled
	.select2-selection--multiple
	.select2-selection__choice {
	opacity: 1;
	cursor: text;
}

.panel-flat > .panel-heading.smaller-margin {
	margin-bottom: 5px;
}

.big-form-heading .heading-form .form-control {
	width: 200px;
}

.heading-chart-filters {
	margin-bottom: 20px;

	.form-control {
		width: 150px;
		margin-right: 10px;
	}
}

.overview-table {
	height: 147px;
	padding-top: 30px !important;

	h2 {
		font-size: 34px;
	}

	h6 {
		font-size: 18px;
	}
}

.report-multiple-departments {
	height: 110px;
	margin-top: -14px;
}

.panel-flat app-report-panel-heading + .panel-body {
	// fix for nested .panel-heading inside report-panel-heading component
	padding-top: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-box-shadow: 0 0 0 1000px $light-gray-4 inset !important;
}

.text-align-center {
	text-align: center;
}

.text-align-left {
	text-align: left;
}

.text-align-right {
	text-align: right;
}

.display-none {
	display: none;
}

.visibility-hidden {
	visibility: hidden;
}

.font-size-20 {
	font-size: 20px;
}

.font-size-15 {
	font-size: 15px;
}

.font-size-16 {
	font-size: 16px;
}

.font-size-13 {
	font-size: 13px;
}

.font-size-12 {
	font-size: 13px;
}

.visibility-hidden-absolute {
	visibility: hidden;
	position: absolute;
}

.font-size-10 {
	font-size: 10px;
}

.font-size-11 {
	font-size: 11px;
}

.font-size-14 {
	font-size: 14px;
}

.font-size-18 {
	font-size: 18px;
}

.text-slate-main {
	color: $gray;
}

h1.remove-margin {
	margin: 0;
}

.icons-list .dropdown ul li {
	padding-bottom: 0;
}

.icons-list li.dropdown-select {
	color: #4e4e4e;

	& > a {
		font-size: 15px;

		&:focus,
		&:hover {
			opacity: 1;
		}
	}
}

ss-multiselect-dropdown {
	.search {
		.input-group {
			padding: 0 8px;
		}

		.form-control {
			padding-left: 10px;
		}
	}

	.dropdown-item.empty {
		padding-left: 18px;
	}
}

.popover-title {
	padding: 10px 15px 5px;
}

.ui-pnotify-text {
	word-break: break-word;
}

.high-five-count {
	color: var(--blue);
	font-weight: bold;
}

.panel-body .table > tbody > tr > td,
.panel-body .table > tbody > tr > th,
.panel-body .table > tfoot > tr > td,
.panel-body .table > tfoot > tr > th,
.panel-body .table > thead > tr > td,
.panel-body .table > thead > tr > th {
	padding: 10px;
}

.panel-body .dataTable thead .sorting:before,
.panel-body .dataTable thead .sorting:after,
.panel-body .dataTable thead .sorting_asc:after,
.panel-body .dataTable thead .sorting_desc:after,
.panel-body .dataTable thead .sorting_asc_disabled:after,
.panel-body .dataTable thead .sorting_desc_disabled:after {
	right: 5px;
}

.dataTables_paginate .paginate_button.current,
.dataTables_paginate .paginate_button.current:hover,
.dataTables_paginate .paginate_button.current:focus {
	background-color: $gray;
}

.date-range-picker,
.deadline-picker {
	width: 270px !important;

	.form-control[readonly] {
		border-bottom: 1px solid $light-gray-6;
		color: $gray-4;
		-webkit-box-shadow: none;
		box-shadow: none;
		background-color: $light-gray-4;
		opacity: 1;

		&:focus {
			border-bottom: 1px solid var(--blue);
		}
	}
}

.no-select {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
}

.text-line-through {
	text-decoration: line-through;
}

.text-center {
	text-align: center !important;
}

.text-right {
	text-align: right !important;
}

a.disabled {
	color: $light-gray-6 !important;
	pointer-events: none;
}

.modal-confirmation {
	position: relative;
	top: 10%;
}

.location-text-center,
.position-text-center,
.department-text-center,
.skills-text-center {
	text-align: center !important;
}

.datatable-scroll {
	width: 100% !important;
	overflow-x: hidden; // hide horizontal scroll
}

.ngx-datatable {
	.datatable-body {
		.datatable-body-row {
			width: 100% !important;
		}
	}
}

image-cropper img.source-image {
	margin: 0 auto;
}

app-avatar-upload-modal {
	.nav-tabs {
		li.active {
			border-top: 2px solid var(--blue);
		}
	}
}

@media (min-width: 768px) {
	.modal-confirmation {
		width: 450px;
	}
}

.ngx-charts {
	font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;

	text {
		font-weight: bold;
	}
}

.ngx-charts-outer {
	.chart-legend .legend-labels.horizontal-legend {
		white-space: pre-wrap;
		margin-top: 15px;
		max-height: unset !important;
		.legend-label-text {
			white-space: pre-wrap;
		}
	}
}
ng-select {
	z-index: 998; // z-index of bootstarp .navbar is 1000

	&.ng-select-opened {
		z-index: 999;
	}

	.ng-dropdown-panel {
		.ng-dropdown-panel-items {
			.ng-option {
				white-space: normal;
				word-wrap: break-word;
			}
		}
	}
}

.ngx-chart-tooltip-img {
	width: 32px;
	height: 32px;
	border-radius: 1px;
}

.ngx-chart-tooltip-user-name {
	display: inline-block;
	position: relative;
	top: 5px;
	width: 100px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.white-space-pre-wrap {
	white-space: pre-wrap !important;
}

@media screen and (max-width: 768px) {
	.margin-top-sm-20 {
		margin-top: 20px !important;
	}
}

.margin-top-sm-20 {
	margin-top: 0;
}

.chart-responsive {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.mt-negative-2 {
	margin-top: -2px;
}

.font-weigh-normal {
	font-weight: normal;
}

.font-weight-bold {
	font-weight: bold;
}

.invitation-summary {
	max-height: 400px;
	height: max-content;
	overflow-y: auto;
}

.section-title {
	font-size: 16px;
	color: #000;
	position: relative;
	z-index: 1;

	&:before {
		border-top: 1px solid $light-gray-6;
		content: "";
		margin: 0 auto;
		position: absolute;
		top: 11px;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		z-index: -1;
	}

	span {
		background: var(--white);
		padding-right: 14px;
	}
}

.align-items-center {
	align-items: center;
}

.align-items-baseline {
	align-items: baseline;
}

.align-items-flex-end {
	align-items: flex-end;
}

/*
 This is the default style of the ngx-timepicker, our custom styles for the .btn-link were overriding is
and were making the up and down arrows look not centered
*/
timepicker .btn-link {
	padding: 9px 17px !important;
}

.mt-auto {
	margin-top: auto;
}

.mb-auto {
	margin-bottom: auto;
}

.query-edit-check-inputs {
	white-space: initial;

	i {
		top: 0 !important;
	}

	label {
		line-height: 1.4;

		&::before {
			top: 0 !important;
		}
	}
}

.text-ellipsis {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.preview-documents-modal-wrapper {
	width: 100%;
	height: 100%;
	margin: 0;

	.modal-content {
		height: 100%;
		background: transparent;
		box-shadow: none;
		border: none;
		overflow: auto;
	}
}

.full-width-height-modal {
	width: 100%;
	height: 100%;
	margin: 0;

	.modal-content {
		height: 100%;
	}
}

.template-preview-modal {
	width: 769px;
}

.user-group-csv-modal {
	width: 650px;
}

.user-group-edit-modal {
	width: 1200px;
}

.user-rewards-modal {
	width: 650px;
}

@media screen and (max-width: 768px) {
	.template-preview-modal,
	.user-rewards-modal {
		width: auto;
	}
}

@media screen and (max-width: 1200px) {
	.user-group-edit-modal {
		width: auto;
	}
}

.scrollbar {
	&::-webkit-scrollbar {
		width: 7px;
		height: 7px;
		margin-left: 10px;
	}

	&::-webkit-scrollbar-track {
		background-color: $light-gray-4;
		border-radius: 5px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $light-gray-2;
		border-radius: 5px;
	}

	&::-webkit-scrollbar-thumb:hover {
		box-shadow: 0 0 0 100px rgb(0 0 0 / 10%) inset;
	}
}
