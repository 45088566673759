.blog-heading-elements {
	top: 20%;
}

.list-image-holder {
	float: left;
	width: 141px;
	height: 141px;
	overflow: hidden;
	background: $gray;
	color: var(--white);
	font-family: "Source Sans Pro", sans-serif;
	text-align: center;
	padding: 35px 16px;

	img {
		margin: 0 auto;
	}

	.number {
		font-size: 48px;
	}

	.month {
		font-size: 22px;
		text-transform: uppercase;
	}

	.number,
	.month {
		line-height: 1;
	}
}

.list-featured-image-holder {
	float: left;
	width: 114px;
	height: 114px;
	overflow: hidden;
}

.list-image-holder-dashboard {
	float: left;
	width: 80px;
	height: 80px;
	background: $gray;
	color: var(--white);
	font-family: "Source Sans Pro", sans-serif;
	text-align: center;
	margin-right: 10px;
	padding: 10px;

	.number {
		font-size: 36px;
	}

	.month {
		font-size: 18px;
		text-transform: uppercase;
	}

	.number,
	.month {
		line-height: 1;
	}
}

.list-featured-image-holder-dashboard {
	float: left;
	width: 80px;
	height: 80px;
	margin-right: 10px;
}

.blog-section {
	&:first-child {
		padding-top: 0;
	}

	.single-content {
		margin-left: 141px;
		margin-right: 160px;

		.blog-author {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-size: 14px;
			color: #7e7e7e;
		}

		h3 {
			margin: 0;
		}

		p {
			padding-top: 5px;
		}
	}

	.read-more {
		float: right;
		width: 140px;

		a {
			display: block;
			margin-top: 28px;
			padding-left: 24px;
			padding-right: 24px;
			font-size: 16px;
			border-left: 1px solid $light-gray-5;
			line-height: 4;
		}
	}
}

.single-view {
	.content-row {
		padding: 20px 0;
		border-top: 1px solid #ebebee;

		.content-paragraph h1:first-child,
		.content-paragraph h2:first-child,
		.content-paragraph h3:first-child,
		.content-paragraph h4:first-child,
		.content-paragraph h5:first-child,
		.content-paragraph h6:first-child {
			padding-top: 0;
			margin-top: 0;
		}
	}

	.blog-author {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 13px;
		color: #7e7e7e;
	}

	.news-media {
		.media-container {
			margin-right: 20px;
		}
	}

	.read-more {
		border-top: 1px solid #ebebee;

		a {
			display: block;
			font-size: 16px;
			line-height: 4;
		}
	}

	h3 {
		font-size: 20px;
		line-height: 1.2;
		margin: 0;
		padding-top: 8px;
	}
}

.scroll-loader {
	margin-top: 10px;
	display: block;
}

/* News & Events */
.year-title h2 {
	font-size: 30px;
	margin-top: 10px;
	position: relative;
	text-align: center;
	z-index: 1;
	color: #7f8080;
	font-weight: 300;
}

.year-title h2:before {
	border-top: 1px solid $light-gray-6;
	content: "";
	margin: 0 auto;
	position: absolute;
	top: 25px;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	z-index: -1;
}

.year-title h2 span {
	/* to hide the lines from behind the text, you have to set the background color the same as the container */
	background: $light-gray-5;
	padding: 0 15px;
}

.date-title h2 {
	font-size: 20px;
	margin-top: 0;
}

.filter-bar {
	margin-top: 12px;

	input {
		width: 280px;
		padding-right: 25px;
	}

	label {
		position: relative;
	}

	label:after {
		content: "\e98e";
		font-family: "icomoon";
		font-size: 12px;
		display: inline-block;
		position: absolute;
		top: 13px;
		color: $gray-3;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		right: 12px;
	}
}

.event-tabs {
	margin: 20px;
}

.event-active-list-tab {
	background-color: $light-gray-5 !important;
	font-weight: 500;
}

.single-event-dates {
	font-weight: 600;
	display: block;
}

@media (max-width: 1024px) {
	.blog-section {
		&:first-child {
			padding-bottom: 102px;
		}

		article:not(:first-of-type) {
			margin-top: 110px;
		}

		.date-title-margin-sm {
			margin-top: 112px;
		}

		.single-content {
			margin-right: 0;
		}

		.read-more {
			float: none;
			position: absolute;
			bottom: -49px;
			background-color: var(--white);
			width: 100%;
			text-align: center;
			border-top: 1px solid #ececec;
			border-left: 0;

			a {
				margin-top: 0;
				line-height: 3;
			}
		}
	}

	.single-view {
		.list-image-holder {
			width: 100%;
		}

		.list-featured-image-holder {
			//width: 100%;
			//
			float: none;
			width: 100%;

			img {
				margin-top: -9%;
				-webkit-transform: scale(1.6);
				-moz-transform: scale(1.6);
				-ms-transform: scale(1.6);
				-o-transform: scale(1.6);
				transform: scale(1.6);

				-webkit-transition: 0.4s ease all;
				-moz-transition: 0.4s ease all;
				-ms-transition: 0.4s ease all;
				-o-transition: 0.4s ease all;
				transition: 0.4s ease all;
			}
		}
	}
}

@media (max-width: 768px) {
	.scroll-loader {
		margin-top: 50px;
	}
}

@media (max-width: 425px) {
	.blog-section {
		.list-image-holder {
			float: none;
			width: 100%;

			img {
				margin-top: -9%;
				-webkit-transform: scale(1.6);
				-moz-transform: scale(1.6);
				-ms-transform: scale(1.6);
				-o-transform: scale(1.6);
				transform: scale(1.6);

				-webkit-transition: 0.4s ease all;
				-moz-transition: 0.4s ease all;
				-ms-transition: 0.4s ease all;
				-o-transition: 0.4s ease all;
				transition: 0.4s ease all;
			}
		}

		.list-featured-image-holder {
			float: none;
			width: 100%;

			img {
				margin-top: -9%;
				-webkit-transform: scale(1.6);
				-moz-transform: scale(1.6);
				-ms-transform: scale(1.6);
				-o-transform: scale(1.6);
				transform: scale(1.6);

				-webkit-transition: 0.4s ease all;
				-moz-transition: 0.4s ease all;
				-ms-transition: 0.4s ease all;
				-o-transition: 0.4s ease all;
				transition: 0.4s ease all;
			}
		}

		.single-content {
			margin-left: 0;
			margin-right: 0;
		}
	}

	.single-view {
		.news-media {
			width: 100%;

			.media-container {
				margin-right: 0;
				margin-bottom: 12px;

				img {
					margin: 0 auto;
				}
			}
		}
	}
}
