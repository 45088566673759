/* MEDIA QUIRIES */
/* 2x resolution*/
@import "variables";

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
	only screen and (-o-min-device-pixel-ratio: 3 / 2),
	only screen and (min--moz-device-pixel-ratio: 1.5),
	only screen and (min-device-pixel-ratio: 1.5) {
}

// skills-match filters
@media (min-width: 1200px) and (max-width: 1380px) {
	.col-lg-2.col-lg-2-wider {
		width: 19.666667%;
	}
}

@media (min-width: 1260px) {
	.cell-show {
		display: none !important;
	}
}

@media (max-width: 1260px) {
	.mobile-display-hidden {
		display: none !important;
	}

	.mobile-skills-hidden {
		display: none !important;
	}

	.mobile-responsibilities-hidden {
		display: none !important;
	}
}

/* tablet landscape */
@media (min-width: 1200px) and (max-width: 1330px) {
	.custom-quick-state-boxes .panel .panel-text {
		padding: 10px 10px 0 10px;
	}

	.custom-quick-state-boxes .panel {
		font-size: 14px;
	}
}

/* tablet landscape */
@media (max-width: 1200px) {
	#manage-org-chart .heading-elements,
	.public-org-chart .heading-elements {
		.org-chart-mobile-menu {
			display: block;
		}
	}

	.professional-profile-content {
		.col-md-4 {
			.heading-elements {
				position: relative;
				margin-top: 0;
				top: auto;
				right: auto;
				float: none;

				.icons-list {
					margin-left: 0;
				}
			}
		}
	}

	.bs-contact-info {
		text-align: left;
		padding-bottom: 10px;
	}

	.lg-right-sm-left {
		text-align: left;
	}

	.welcome-comp {
		max-width: 100%;

		img.img-responsive {
			max-width: 45%;
		}

		.inline {
			width: 45%;
			margin-left: 8%;
		}
	}

	.custom-quick-state-boxes .panel .panel-icon {
		padding: 30px 10px;
	}

	.custom-quick-state-boxes .panel .panel-text {
		padding: 10px 10px 0 10px;
		font-size: 14px;
	}

	.org-chart-mobile-menu {
		.dropdown-menu > li span.panel-heading-button,
		.dropdown-menu > li {
			padding: 10px 3px;
		}
	}

	.btn.btn-default.btn-clear.clear-filters {
		margin-left: 10px;
	}
}

@media (min-width: 1024px) and (max-width: 1439px) {
	.professional-profile-content .col-md-4 .heading-elements {
		margin-top: -4px;
	}
}

@media only screen and (min-width: 992px) {
	.md-hidden {
		display: none !important;
	}
}

@media only screen and (max-width: 992px) {
	.welcome-comp {
		h3 {
			padding-top: 20px;
		}
	}

	.custom-quick-state-boxes .panel .panel-icon {
		display: none;
	}

	.custom-quick-state-boxes .panel .panel-text {
		padding: 10px;
		min-height: 120px;
	}

	.org-chart-mobile-menu {
		.dropdown-menu > li span.panel-heading-button,
		.dropdown-menu > li {
			padding: 10px 3px;
		}
	}

	webcam video,
	.preview-webcam-image {
		width: 100%;
		height: 100%;
	}

	.mobile-location-hidden,
	.mobile-position-hidden,
	.mobile-department-hidden {
		display: none !important;
	}
}

@media only screen and (max-width: 992px) {
	.row.display-flex.news-edit {
		flex-wrap: wrap;

		div.grow-1 {
			max-width: 100%;
		}

		div.grow-1:last-of-type {
			margin-top: 10px;
			width: 100%;
		}

		div.grow-1:nth-of-type(1),
		div.grow-1:nth-of-type(2) {
			width: 50%;
		}
	}
}

@media (max-width: 769px) {
	.search-bar-xs {
		width: 25px;
		margin-right: 20px;
		border-bottom: none;
		display: block;
	}

	app-avatar-upload-modal .nav-tabs li.active {
		border-top: none;
	}
}

@media (min-width: 768px) {
	.login-modal-sm {
		width: 320px;
	}

	div.grow-1 {
		margin: 5px 0;
	}
}

/* smartphones */
@media (max-width: 768px) {
	.nav-tabs > li.active > a,
	.nav-tabs > li.active > a:hover,
	.nav-tabs > li.active > a:focus {
		border-left-color: #039be5;
	}

	app-avatar-upload-modal .nav-tabs > li.active > a,
	app-avatar-upload-modal .nav-tabs > li.active > a:hover,
	app-avatar-upload-modal .nav-tabs > li.active > a:focus {
		border-left-color: #04c9eb;
	}

	.nav-tabs:before {
		content: "";
		padding: 0;
		margin: 0;
	}

	.search-bar {
		position: absolute;
		left: 0;
		top: 50px;
		width: 100%;

		background-color: var(--header);
		border: 1px solid rgba(255, 255, 255, 0.25);
	}

	#notifications-dropdown {
		margin-right: 12px;
	}

	#notifications-dropdown.open .dropdown-menu {
		position: absolute;
		left: -189px;
		margin-top: 16px;
		min-width: 320px;
		background-color: var(--white);
		color: $gray-4;

		-webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
			0 3px 6px rgba(0, 0, 0, 0.23);
		-moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
			0 3px 6px rgba(0, 0, 0, 0.23);
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

		.text-muted {
			color: $gray-3;
		}
	}

	.welcome-comp {
		img {
			display: none;
		}

		h3 {
			padding-top: 0;
		}

		.inline {
			width: 100%;
			margin-left: 0;
			text-align: center;
		}
	}
	.sidebar,
	.navbar-header {
		width: 100%;
	}

	.professional-business-card h2.author-name {
		margin-top: 20px;
	}

	.page-container {
		top: 96px;
	}

	.page-header {
		margin-top: 0;
	}

	.sidebar-user-material-content > a {
		box-shadow: none;
	}

	.sidebar-user-material .category-content {
		height: 100%;
	}

	.not-on-mobile {
		display: none;
	}

	.lg-right-xs-left {
		text-align: left;
		padding-bottom: 10px;
	}
	.user-quote {
		font-size: 14px;
	}

	.navbar-fixed-top {
		position: fixed;
		top: 0;
	}

	.sidebar-user-material {
		display: none;
	}

	.navigation-main {
		padding: 0;
	}

	.sidebar-content {
		padding-bottom: 0;
	}

	.sidebar-fixed {
		z-index: 999;
	}

	#navigation-main-left {
		li.li-border-top {
			border-top: 1px solid $light-gray-5;
		}
	}

	.brand {
		margin: 14px 4px 10px 10px;
		width: 20px;
	}

	.navbar-text {
		padding: 15px 10px;
	}

	.navbar-inverse {
		.visible-xs-block {
			.open > a {
				color: var(--white);
			}

			.open .dropdown-menu {
				background-color: var(--white);
				color: $gray-4;
				position: absolute;
				border: 1px solid transparent;
				border-radius: 3px;
				-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
				box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
				-webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
					0 3px 6px rgba(0, 0, 0, 0.23);
				box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
					0 3px 6px rgba(0, 0, 0, 0.23);
				top: 50px;
				min-width: 155px;
			}

			.open .dropdown-menu > li > a {
				color: $gray-4;
			}
		}
	}

	.custom-quick-state-boxes .panel h3 {
		font-size: 16px;
	}

	.custom-quick-state-boxes .panel .panel-text {
		padding: 10px;
		min-height: 120px;
		font-size: 14px;
	}

	.custom-quick-state-boxes .panel .panel-icon {
		display: none;
	}

	.search-categories-ul,
	.categories-search .input-group,
	.categories-search .input-group .input-group-btn {
		display: block;
	}

	.search-categories-ul {
		margin: 0;

		li {
			margin-bottom: 10px;
		}
	}

	.categories-search .input-group {
		width: 100%;
	}

	.login-form .or {
		margin: 10px 0;
	}

	.checkbox-inline {
		padding-left: 23px;
	}

	.no-margin {
		margin: 0;
	}

	.skills-container span {
		padding: 4px 7px;
	}
	.table-responsive {
		border: 0;
	}

	.dt-buttons {
		margin: 0;
		text-align: left;

		.btn {
			padding: 5px 10px;
		}
	}

	.file-preview .file-preview-frame .file-preview-text {
		width: 160px !important;
	}

	.heading-chart-filters {
		.form-control {
			width: 100%;
			margin-bottom: 10px;
			margin-left: 0px;
		}
	}

	.heading-filters {
		width: 100%;
		margin-bottom: 10px;
		margin-left: 0px;
	}

	#manage-org-chart .heading-elements,
	.public-org-chart .heading-elements {
		.dropdown-menu {
			left: auto;
		}

		display: block;

		.icons-list {
			float: left;
		}

		.org-chart-mobile-menu {
			li.dropdown {
				float: left;
				margin-left: 30px;
			}
		}

		.search-field .select2-container .select2-selection__arrow:before {
			left: -10px;
		}
	}

	.heading-elements {
		display: inline-block;
	}

	.professional-profile-content .col-md-4 .heading-elements {
		float: right;

		.icons-list {
			margin-top: 5px;
		}
	}

	.panel-title {
		font-size: 12px !important;
	}

	.heading-elements {
		height: 30px;

		.icons-list {
			margin-bottom: 0;
		}
	}

	.margin-top-10-xs {
		margin-top: 10px;
	}
}

@media (max-width: 520px) {
	.row.display-flex.news-edit {
		div.grow-1:nth-of-type(2) {
			margin-top: 10px;
		}

		div.grow-1:nth-of-type(1),
		div.grow-1:nth-of-type(2) {
			width: 100%;
		}

		.date-time-picker {
			max-width: 100%;
		}
	}
}

@media (max-width: 500px) {
	.mobile-hidden {
		display: none !important;
	}
}

/* small smartphones */
@media (max-width: 480px) {
	.org-chart-panel h6 {
		width: 50%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.event-status span.label {
		font-size: 9px;
	}

	h1.content-group {
		width: 60%;
	}

	.professional-business-card {
		p.inline-on-md {
			display: block;
		}

		.contact-detail .fa-map-marker,
		.professional-business-card .contact-detail .fa-phone {
			margin-left: 0;
		}
	}

	.professional-profile-content .col-md-4 .heading-elements {
		float: right;
	}

	.heading-elements {
		height: 30px;

		.icons-list {
			margin-bottom: 0;
		}
	}

	.org-chart-panel .heading-elements {
		position: relative;
		top: 10px;
		display: block;
		height: 45px;
		left: 15px;
		margin-top: 0;
	}

	.new-line-for-xs {
		position: relative;
		top: 10px;
		display: block;
		height: 45px;
		left: 15px;
		margin-top: 0;
	}

	.row-detail-left-padding {
		padding-left: 0 !important;
	}
}

@media (max-width: 420px) {
	.sm-hidden {
		display: none !important;
	}
}

/* small smartphones */
@media (max-width: 370px) {
	.dataTables_filter input {
		width: 177px;
	}

	.dt-buttons {
		.btn {
			padding: 5px;
		}
	}

	.row-detail-left-padding {
		padding: 0;

		span.label.label-default,
		span.label.label-success {
			width: 100%;
		}
	}
}

/* iPhone 5 */
@media (max-width: 320px) {
}
